(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('propertyCommercial', {
            parent: 'entity',
            url: '/propertyCommercial?page&sort&search',
            data: {
                authorities: ["ROLE_PROPERTY"],
                pageTitle: 'global.menu.entities.commoditySpecification'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property-commercial/properties.html',
                    controller: 'PropertyCommercialController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('propertys');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('propertyDetailCommercial', {
            parent: 'propertyCommercial',
            url: '/detail/{id}',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/property-commercial/property-dialog.html',
                    controller: 'PropertyDialogCommercialController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Property', function(Property) {
                            return Property.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('propertyCommercialNew', {
            parent: 'propertyCommercial',
            url: '/new',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property-commercial/property-dialog.html',
                    controller: 'PropertyDialogCommercialController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('propertys');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        data:{
                            propertyLanguages:[],
                            name: null,
                            cancelled: null,
                            id: null
                        }
                    };
                }
            }
        })
        .state('propertyCommercialEdit', {
            parent: 'propertyCommercial',
            url: '/edit/{id}',
            data: {
                authorities: ["ROLE_PROPERTY"]
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/property-commercial/property-dialog.html',
                    controller: 'PropertyDialogCommercialController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('propertys');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Property','$stateParams', function(Property,$stateParams) {
                    return Property.get({id : $stateParams.id}).$promise;
                }]
            }
        });
    }

})();
