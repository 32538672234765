(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderDialogController', SalesOrderDialogController);

    SalesOrderDialogController.$inject = ['$timeout', '$scope', '$state', '$stateParams','$q', 'entity', 'SalesOrder', 'Qinius', 'ExpressHistory',
        'MessageService', 'LocationProvice', 'LocationCity', 'LocationDistrict', 'Upload', 'LogisticsCompany','PlatformSetting','$localStorage'];

    function SalesOrderDialogController ($timeout, $scope, $state,$stateParams, $q, entity, SalesOrder, Qinius, ExpressHistory,
                                         MessageService,  LocationProvice, LocationCity, LocationDistrict, Upload, LogisticsCompany, PlatformSetting,$localStorage) {
        var vm = this;

        vm.salesOrder = entity;
        vm.clear = clear;
        vm.save = save;
        vm.changeProvince = changeProvince;
        vm.changeCity = changeCity;
        vm.provinces = LocationProvice.query();
        vm.domains = Qinius.getDomain();
        $scope.selectTab =($stateParams.status == undefined || $stateParams.status == '')?'':$stateParams.status;

        vm.histories = [];
        // vm.histories = ExpressHistory.byCondition({items:[{key:"orderId",op:"=",value:$stateParams.id}]})
        vm.histories = ExpressHistory.byOrderId({orderId:$stateParams.id})

        LogisticsCompany.byCondition({
                items:[{key:"enabled",op:"=",value:true}]
            }, onSuccess2, onError2);
            function onSuccess2(data) {
                vm.logisticsCompanies = data;
                for (var i = vm.logisticsCompanies.length - 1; i >= 0; i--) {
                    var p = vm.logisticsCompanies[i];
                    if (p.name == vm.salesOrder.deliveryCompany) {
                        vm.deliveryCompany = p;
                        break;
                    }
                }
            }
            function onError2(error) {
                MessageService.error("物流公司获取失败");
            }


        $scope.downFile = function(){
            window.open("service/api/sales-orders/downLocationTemplate/"+vm.salesOrder.id);
        };


        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else{
                $scope.selectTab = status;
            }
            vm.status = status;
            $state.go('sales-order',{'status':vm.status});
        };

        $scope.uploadFile = function(fileWatched) {
            vm.file = fileWatched;
            vm.fileName = fileWatched.name;
            Upload.upload({
                type: 'POST',
                url: 'service/api/sales-orders/importLocation', //上传路径
                data: {
                    id: vm.salesOrder.id,
                    file: vm.file,
                    name: vm.fileName
                }
            }).then(function successCallback(res) {
                vm.salesOrder = res.data;
                for (var j = 0; j < vm.salesOrder.locations.length; j++) {
                    if (vm.salesOrder.locations[j].deleted) {
                        continue;
                    }
                    var location = vm.salesOrder.locations[j];
                    for (var i = vm.provinces.length - 1; i >= 0; i--) {
                        var p = vm.provinces[i];
                        if (p.name == location.province) {
                            location.pro = p;
                            break;
                        }
                    }
                    location.districts = [];
                    location.citys = [];
                    $scope.getCities(location);
                }
            }, function errorCallback(response) {
                MessageService.error("文件导入失败");
            });
        };

        $scope.getCities = function(location){
            if (location.pro != null) {
                LocationCity.findByProvince({
                    provinceId: location.pro.id
                }, function(data) {
                    location.citys = data;
                    for (var z = location.citys.length - 1; z >= 0; z--) {
                        var c = location.citys[z];
                        if (c.name == location.city) {
                            location.ci = c;
                            break;
                        }
                    }
                    $scope.getDistricts(location);
                });
            }
        }

        $scope.getDistricts = function(location){
            if (location.ci != null) {
                LocationDistrict.findByCities({
                    cityId: location.ci.id
                }, function(data) {
                    location.districts = data;
                    for (var y = location.districts.length - 1; y >= 0; y--) {
                        var di = location.districts[y];
                        if (di.name == location.district) {
                            location.dis = di;
                        }
                    }
                });
            }
        }

        $q.all([vm.domains.$promise, vm.salesOrder.$promise, vm.provinces.$promise]).then(function() {
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            // 初始化城市：省市区
            for (var j = 0; j < vm.salesOrder.locations.length; j++) {
                var location = vm.salesOrder.locations[j];
                for (var i = vm.provinces.length - 1; i >= 0; i--) {
                    var p = vm.provinces[i];
                    if (p.name == location.province) {
                        location.pro = p;
                        break;
                    }
                }
                location.districts = [];
                location.citys = [];
                $scope.getCities(location);
            }
        });

        // 改变省份
        function changeProvince(location) {
            LocationCity.findByProvince({provinceId:location.pro.id},function (data) {
                location.citys = data;
                location.districts = [];
            });
        }

        // 改变城市
        function changeCity(location) {
            if (location.ci == null) {
                return;
            }
            LocationDistrict.findByCities({cityId: location.ci.id},function (data) {
                location.districts = data;
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.amountChange = function(amount){
            if (vm.salesOrder[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(vm.salesOrder[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                vm.salesOrder[amount] = Math.round(vm.salesOrder[amount]*100)/100;
                return;
            }

        }

        $scope.goToView = function (orderArticle) {
            debugger
            if(!orderArticle.articleId){
                MessageService.error("没有作品");
                return;
            }

            PlatformSetting.get({}, function (res) {
                if(!res.data.editorNotifyUrl){
                    MessageService.error("请设置editorNotifyUrl");
                    return;
                }
                window.open(res.data.editorNotifyUrl + '/#/' + vm.salesOrder.storeId + '/webView2/' + orderArticle.id + '?authorization=' + $localStorage.authenticationToken)
            })
            return;
            $(".listBGC").hide();
            $state.go('product-view-article2', {fileName: orderArticle.fileName, itemValueId: orderArticle.itemValueId}, {reload: true});
        };

        function clear () {
            $state.go('sales-order', {reload: true});
        }

        $scope.validate = function(){
            if (vm.salesOrder.amount < 0) {
                MessageService.error("金额不能小于零");
                return false;
            };
            if (vm.salesOrder.freight<0) {
                MessageService.error("运费不能小于零");
                return false;
            };
            if (vm.salesOrder.discountAmount<0) {
                MessageService.error("折扣不能小于零");
                return false;
            };
            if (vm.salesOrder.payAmount<=0) {
                MessageService.error("应付金额不能小于等于零");
                return false;
            };
            return true;
        }

        function save () {
            var locationQuantity = 0;
            for (var i = vm.salesOrder.locations.length - 1; i >= 0; i--) {
                var l =  vm.salesOrder.locations[i];
                if (l.deleted) {
                    continue;
                }
                if (l.ci == null || l.pro== null || l.dis == null) {
                    MessageService.error("请选择省市区数据");
                    return;
                }else{
                    l.province = l.pro.name;
                    l.city = l.ci.name;
                    l.district = l.dis.name;
                }
                locationQuantity += l.quantity;
            }
            // var quantity = 0;
            // for (var i = vm.salesOrder.orderLines.length - 1; i >= 0; i--) {
            //     var line = vm.salesOrder.orderLines[i];
            //     quantity += line.quantity;
            // }
            // if (locationQuantity> quantity) {
            //     MessageService.error("收货地址数量多余订单数量，请重新设置");
            //     return;
            // }
            if (vm.deliveryCompany !=null ) {
                vm.salesOrder.deliveryCompanyCode = vm.deliveryCompany.code;
                vm.salesOrder.deliveryCompany = vm.deliveryCompany.name;
            }else{
                vm.salesOrder.deliveryCompanyCode = null;
                vm.salesOrder.deliveryCompany = null;
            }

            vm.isSaving = true;

            if (vm.salesOrder.id !== null) {
                SalesOrder.update(vm.salesOrder, onSaveSuccess, onSaveError);
            } else {
                SalesOrder.save(vm.salesOrder, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:salesOrderUpdate', result);
            MessageService.success("保存成功");
            $state.go('sales-order', {reload: true});
            vm.isSaving = false;
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


    }
})();
