(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('Property', Property);

    Property.$inject = ['$resource'];

    function Property($resource) {
        var resourceUrl = 'manager/' + 'api/properties/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            'query': {method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
})();
