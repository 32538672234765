(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SceneController', SceneController);

    SceneController.$inject = ['$scope', '$q', '$state', 'Mould2Ds', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', '$uibModal', 'Principal'];

    function SceneController ($scope, $q, $state, Mould2Ds, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, $uibModal, Principal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        // 获取qiniu服务器
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        function loadAll () {
            var items = [];
            if (pagingParams.search) {
                items.push({key:"name",op:"like",value:pagingParams.search});
            }
            Mould2Ds.byCondition2({
                items:items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.data = data.data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                MessageService.error("获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-dialog.html',
                controller: 'SceneDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'add',
                            data: ""
                        };
                    }]
                }
            }).result.then(function(item) {
                loadAll()
            });
        }
        $scope.edit = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-dialog.html',
                controller: 'SceneDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'edit',
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                loadAll()
            });
        }
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-dialog.html',
                controller: 'SceneDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size:'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'look',
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                loadAll()
            });
        }
        $scope.delete = function(id){
            MessageService.confirm("确认删除该模型？",function(){
                Mould2Ds.delete({id: id},function () {
                    MessageService.success("删除成功");
                    loadAll();
                },function () {
                    MessageService.error("删除失败");
                });
            },function(){});
        };
    }
})();
