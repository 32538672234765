(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MultilingualDialogController', MultilingualDialogController);

    MultilingualDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'DiyEditorSetting', 'MessageService', 'JhiLanguageService'];

    function MultilingualDialogController($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, DiyEditorSetting, MessageService, JhiLanguageService) {
        /**
         * data 数据 (Object 必填)
         * key 多语言数据的key(String 必填)
         * fieldName 外部这段名(String 非必填)
         * fieldValue 外部字段值(String 非必填)
         */
        var vm = this;
        vm.cancel = cancel;
        vm.save = save;
        vm.multilingual = [];
        vm.data = entity.data || [];
        vm.key = entity.key;
        vm.fieldName = entity.fieldName;
        vm.fieldValue = entity.fieldValue;
        vm.allLanguages = JhiLanguageService.availableLanguages();
        // 弹窗中移除中文
        vm.languages = removeZhLanguage(vm.allLanguages);
        function removeZhLanguage(oldArr){
            var newArr = [];
            for (var i = 0; i < oldArr.length; i++) {
                var item = oldArr[i];
                if (item.value != 'zh-cn') {
                    newArr.push(item)
                }
            }
            return newArr
        }
        vm.languages.forEach(function (language) {
            var newLanguage = {
                key: language.key,
                language: language.value
            }
            newLanguage[vm.key] ='';
            vm.data.forEach(function (item) {
                if (item.language == language.value) {
                    newLanguage[vm.key] = item[vm.key];
                    newLanguage.id = item.id;
                }
            })
            vm.multilingual.push(newLanguage);
        })

        vm.multilingual.forEach(function(item){
            if (item.language == 'zh-cn' && vm.fieldValue && !item[vm.key]) {
                item[vm.key] = vm.fieldValue
            }
        })
        


        function cancel() {
            $uibModalInstance.dismiss();
        }


        function save() {
            // 检验是否为空
            for (var i = 0; i < vm.multilingual.length; i++) {
                var language = vm.multilingual[i];
                if (!language[vm.key]) {
                    MessageService.error('第' + (i + 1) + '项请输入内容');
                    return
                }
            }
            // 返回的数据处理
            var returnArray = [];
            for (var i = 0; i < vm.multilingual.length; i++) {
                var language = vm.multilingual[i];
                var obj = {
                    language: language.language,
                    id: language.id
                }
                obj[vm.key] =language[vm.key];
                returnArray.push(obj);
            }
            $uibModalInstance.close(returnArray);
        }
    }
})();
