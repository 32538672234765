(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderController', SalesOrderController);

    SalesOrderController.$inject = ['$scope',  '$state', 'SalesOrder', 'ParseLinks', 'paginationConstants', '$http', '$uibModal',
        'pagingParams', 'MessageService', '$stateParams', 'DateUtils', 'Qinius','$localStorage','$q','Category', 'Product','Store'];

    function SalesOrderController ($scope, $state, SalesOrder, ParseLinks, paginationConstants, $http, $uibModal,
                                   pagingParams, MessageService, $stateParams, DateUtils, Qinius,$localStorage, $q, Category, Product,Store) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.deleted = pagingParams.deleted;
        vm.productName = pagingParams.productName;
        vm.status = pagingParams.status;
        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;
        vm.categoryId = pagingParams.categoryId?Number(pagingParams.categoryId):null;
        vm.payRefundStatus = pagingParams.payRefundStatus;

        vm.jump = jump;
        vm.reject = reject;
        vm.dateformat = dateformat;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;

        vm.isOpen =false;
        $scope.selectTab ='';
        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        vm.account = $localStorage.user;

        if(vm.status == '' || vm.status == undefined ){
            $scope.selectTab = '';
        }else{
            $scope.selectTab = vm.status;
        }
        if (!!vm.deleted) {
            $scope.selectTab = 'Deleted';
        }
        if(!!vm.payRefundStatus){
            $scope.selectTab = vm.payRefundStatus;
        }

        $scope.showOrders = function(data){
            data.show = !data.show;
        }

        // 商品品类
        vm.categorys = Category.storeGetAllByCondition({items:[{key: "usable", op: "=", value: "true"}]});

        vm.products = Product.getAllByCondition({
            items: [{key:"prototype",op:"in",value:"Store;StoreSelf"}]
        });

        $q.all([vm.categorys.$promise,vm.products.$promise,vm.store.$promise]).then(function() {
            vm.categorys = vm.categorys.data;
            vm.products = vm.products.data;
            loadAll();
        });

        $scope.delivery = function(data,id) {
            if (id == null) {
                return;
            };
            console.log(data)
            createModal('app/entities/sales-order/sales-order-delivery-dialog.html', 'SalesOrderDeliveryController', data).result.then(function () {
                loadAll();
            });
        }

        var createModal = function (url, controller,data) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            data: data
                        };
                    }]
                }
            });
        };

        $scope.changeCategory = function() {
            if (vm.categoryId != null) {
                Product.getAllByCondition({
                    items: [{key:"categoryStore.id",op:"=",value:vm.categoryId}]
                }, function(data1) {
                    vm.products = data1.data;
                });
            }
        };

        $scope.isSotreSelfProduct = function(so){
            for (var i = so.orderLines.length - 1; i >= 0; i--) {
                var l = so.orderLines[i];
                if (l.orderArticle && l.orderArticle && l.orderArticle.prototype == "Store") {
                    return false;
                }
            }
            return true;
        }

        $scope.shrink = function(){
            vm.isOpen = !vm.isOpen;
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }


        $scope.byStatus =function(status){
            if(status == '' || status == undefined){
                $scope.selectTab = '';
            }else {
                $scope.selectTab = status;
            }
            vm.deleted = "";
            vm.status = status;
            vm.payRefundStatus = "";
            search();
        };

        $scope.getDeleted =function(){
            $scope.selectTab = 'Deleted';
            vm.status = "";
            vm.composition = '';
            vm.noComposition = '';
            vm.payRefundStatus = '';
            vm.deleted = 'Deleted';
            search();
        };

        $scope.getRefund = function(){
            $scope.selectTab = 'Required';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Required';
            search();
        };

        $scope.getRefundApproved = function(){
            $scope.selectTab = 'Approved';
            vm.status = "";
            vm.deleted = '';
            vm.noComposition = '';
            vm.composition = '';
            vm.payRefundStatus = 'Approved';
            search();
        };

        $scope.screens = function(){
            search(vm.currentSearch);
        };

        $scope.resetInfo = function(){
            vm.startTime = null;
            vm.endTime = null;
            vm.categoryId = null;
            vm.productName = null;
            vm.page = 1;
            search();
        };

        $scope.checkOrderLineState = function(order){
            var flag = false;
            if (order.status != 'Paid') {
                return flag;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    var orderArticle = line.orderArticle;
                    if (orderArticle != null && orderArticle.productType != 'StandardPrint'  &&
                        orderArticle.productType != 'Standard' && (line.status == 'CombinationError' || line.status == 'New' || order.status == "Paid" )){
                        return true;
                    }
                }
            }
            return flag;
        };

        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "paidDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "paidDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };

            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"orderLines.orderArticle.categoryStoreId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productName !=null) {
                items.push({key:"orderLines.orderArticle.productName",op:"like",value:vm.productName});
            };

            if (vm.status !=null) {
                if (vm.status != 'CombinationError') {
                    items.push({key: "status", op: "=", value: vm.status});
                }else {
                    items.push({key: "orderLines.status", op: "=", value: vm.status});
                }
            }
            if (vm.deleted =='Deleted') {
                items.push({key:"cancelled",op:"=",value:"true"});
            }
            if (vm.payRefundStatus) {
                items.push({key:"payRefundStatus",op:"=",value:vm.payRefundStatus});
            }

            return items;
        }

        function loadAll () {
            var items = getItems();


            if (pagingParams.search) {
                items.push({key:"number,sourceNumber,orderLines.extPlatformNumber",op:"=",value:pagingParams.search});
            }
            if (vm.deleted == 'Deleted') {
                SalesOrder.byCondition2({
                    items:items,
                    available:false,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }else{
                SalesOrder.byCondition2({
                    items:items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return ["createdDate,desc"];
            }
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.allPagesCount = Math.ceil(vm.totalItems/20);
            vm.queryCount = vm.totalItems;
            vm.salesOrders = data;
            vm.page = pagingParams.page;
        }
        function onError(error) {
            MessageService.error("订单获取失败");
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                status:vm.status,
                deleted: vm.deleted,
                startTime: vm.startTime?DateUtils.convertLocalDateToServer(vm.startTime):"",
                endTime: vm.endTime?DateUtils.convertLocalDateToServer(vm.endTime):"",
                productName:vm.productName,
                categoryId:vm.categoryId,
                payRefundStatus:vm.payRefundStatus
            });
        }

        function search(searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        function reject(id) {
            SalesOrder.reject({id: id}, function(data) {
                MessageService.success("订单驳回成功");
                loadAll();
            }, function () {
                MessageService.error("订单驳回失败");
            });
        }

        $scope.approveRefund = function (salesorder) {
            SalesOrder.approveRefund({id: salesorder.id}, function(data) {
                MessageService.success("退款成功");
                loadAll();
            }, function () {
                MessageService.error("退款失败");
            });
        }

        $scope.rejectRefund = function (salesorder) {
            SalesOrder.rejectRefund({id: salesorder.id}, function(data) {
                MessageService.success("拒绝退款成功");
                loadAll();
            }, function () {
                MessageService.error("拒绝退款成功");
            });
        }

        $scope.checkOrderLinecombinationError = function(order){
            if (order.status != 'Paid') {
                return false;
            }
            if (order.orderLines.length > 0) {
                for (var i = order.orderLines.length - 1; i >= 0; i--) {
                    var line = order.orderLines[i];
                    if (line.status == 'CombinationError') {
                        return true;
                    }
                }
            }
            return false;
        }

        $scope.combinationError = function (salesOrder) {
            $http({
                url: 'justify/api/downloadErrorLog',
                method: "GET",
                params: {
                    orderNumber:salesOrder.number
                },
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function (data) {
                var blob = new Blob([data], {type: "application/vnd.ms-excel"});
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "组版失败日志_" +salesOrder.number+ ".zip";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function () {
                MessageService.error("下载失败，请稍后重试！");
            });
        }

        $scope.justifySalesOrder = function (obj) {
            SalesOrder.justifyOrder({id: obj.id}, function (data) {
                MessageService.success("订单文件组版中...");
            }, function () {
                MessageService.error("订单文件组版失败");
            });
        }

        $scope.pay = function (obj) {
            SalesOrder.payOrder({id: obj.id}, function (data) {
                if (data.status == 200) {
                    MessageService.success("支付成功");
                    loadAll();
                }else{
                    loadAll();
                    MessageService.error(data.message);
                }
            }, function () {
                MessageService.error("订单支付失败");
            });
        }


        $scope.close = function (obj) {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                SalesOrder.closeOrder({id: obj.id}, function (data) {
                    if (data.status == 200) {
                        MessageService.success("关闭成功");
                        loadAll();
                    }else{
                        loadAll();
                        MessageService.error(data.message);
                    }
                }, function () {
                    MessageService.error("订单关闭失败");
                });
            },function(){},true);
        }

        $scope.orderCombinationNotify = function (obj) {
            SalesOrder.combinationNotify({id: obj.id}, function (data) {
                MessageService.success("通知成功");
            }, function () {
                MessageService.error("通知失败");
            });
        }
    }
})();
