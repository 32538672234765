(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductPutawayController', ProductPutawayController);

    ProductPutawayController.$inject = ['$state', '$timeout', '$scope', '$stateParams', '$q',
    'Product', 'MessageService', 'Store', 'DateUtils', 'CommodityPrice', 'Category', 'Qinius', '$localStorage', 'ParseLinks', '$uibModal'];

    function ProductPutawayController ($state, $timeout, $scope, $stateParams, $q,
        Product, MessageService, Store, DateUtils, CommodityPrice, Category, Qinius, $localStorage, ParseLinks, $uibModal) {
        var vm = this;

        vm.classifyList = [{name: '全部', id: ''}];
        vm.classify = {name: '全部', id: ''};
        vm.products = [];
        vm.itemValueLines = [];
        vm.item = [];
        vm.domains = Qinius.getDomain();
        vm.page = 1;
        vm.search = search;

        $q.all([vm.domains.$promise]).then(function () {
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            //获取分类
            Category.getAllByCondition({}, function (res) {
                if(res.status == 200){
                    for(var i=0; i<res.data.length; i++){
                        if(!res.data[i].cancelled){
                            vm.classifyList.push(res.data[i]);
                        }
                    }
                    productInit()
                }else{
                    MessageService.error(res.message);
                    return
                }

            })
        });
        $scope.classifyCb = function (data) {
            vm.classify = data;
            vm.page = 1;
            productInit()
        }

        //获取商品
        function productInit(){
            vm.products = [];
            var items = [{key: "prototype", op: "=", value: 'Original'},
                {key: "custom", op: "=", value: "false"}];
            if(vm.classify.id){
                items.push({key:"category.id",op:"=",value: vm.classify.id})
            }
            if(vm.keyword){
                items.push({key:"name",op:"like",value: vm.keyword})
            }
            Product.getAllByCondition2({items: items},function(res){
                if(res.status==200){
                    for(var i=0; i<res.data.length; i++){
                        if(!res.data[i].cancelled){
                            vm.products.push(res.data[i])
                        }
                    }
                    Store.getCurrent({}, function (res) {
                        if(res.status==200){
                            var _products = [];
                            for(var i1=0; i1<res.data.products.length; i1++){
                                if(!res.data.products[i1].cancelled){
                                    if(res.data.products[i1].categoryId == vm.classify.id || !vm.classify.id){
                                        _products.push(res.data.products[i1])
                                    }
                                }
                            }

                            for(var i=0; i<_products.length; i++){
                                var _isResult = vm.products.some(function (item) {
                                    if(item.id == _products[i].id){
                                        return true
                                    }
                                })
                                if(!_isResult){
                                    vm.products.push(_products[i])
                                }
                            }
                            Product.grounded({storeId: res.data.id}, function (items) {
                                if(items.status==200){
                                    for(var i2=0; i2<items.data.length; i2++){
                                        for(var i1=vm.products.length-1; i1>=0; i1--){
                                            if(vm.products[i1].id == items.data[i2].id){
                                                vm.products.splice(i1,1)
                                            }
                                        }
                                    }

                                    for(var i=0; i<vm.products.length; i++){
                                        for(var j=0; j<vm.item.length; j++){
                                            if(vm.item[j].id == vm.products[i].id){
                                                vm.products[i].selected = true;
                                            }
                                        }
                                    }
                                    var nums = 20; //每页出现的数量
                                    var pages = Math.ceil(vm.products.length/nums); //得到总页数
                                    var thisDate = function(curr){
                                        //此处只是演示，实际场景通常是返回已经当前页已经分组好的数据
                                        var str = [], last = curr*nums - 1;
                                        last = last >= vm.products.length ? (vm.products.length-1) : last;
                                        for(var i = (curr*nums - nums); i <= last; i++){
                                            str.push(vm.products[i]);
                                        }
                                        return str;
                                    };

                                    //调用分页
                                    laypage({
                                        cont: 'c-paging',
                                        curr: vm.page, //初始化当前页
                                        pages: pages,
                                        skip: true, //是否开启跳页
                                        first: false,
                                        last: false,
                                        jump: function(obj){
                                            $timeout(function () {
                                                vm.products1=thisDate(obj.curr);
                                                vm.page = obj.curr;
                                            })
                                        }
                                    });
                                }else{
                                    MessageService.error(items.message)
                                }
                            })
                        }else{
                            MessageService.error(res.message)
                        }

                    });
                }else{
                    MessageService.error(res.message)
                }

            })
        }
        function search() {
            vm.page = 1;
            productInit()
        }
        //获取选中商品
        $scope.itemValueLinesCb = function (data) {
            data.selected = !data.selected ? true : false;
            if(!data.selected){
                for(var i=0; i<vm.item.length; i++){
                    if(vm.item[i].id == data.id){
                        vm.item.splice(i, 1);
                    }
                }
            }else {
                vm.item.push(data)
            }
        }

        //返回
        $scope.skip = function () {
            $state.go('product')
        }

        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            });
        };


        //保存
        $scope.save = function () {
            if(vm.item.length == 0){
                MessageService.error("请选择需要的商品！");
                return
            }
            var _ids = [];
            for(var i=0; i<vm.item.length; i++){
                _ids.push(vm.item[i].id)
            }
            var ground = {
                ids:_ids
            }
            createModal('app/entities/product/product-update-category.html', 'ProductUpdateCategoryController', ground).result.then(
            function (data) {
                if (!!data.categoryId) {
                    Product.grounding({ids: data.ids, categoryId: data.categoryId}, function (res) {
                        $state.go('product')
                    })

                }else{
                    Product.grounding({ids: data.ids}, function (res) {
                        $state.go('product')
                    })
                }
            });
        }
    }
})();
