(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotClearController', MapDepotClearController);

    MapDepotClearController.$inject = ['MessageService', "$scope" ,'$uibModal', 'StorageCleanSchedules'];

    function MapDepotClearController(MessageService, $scope , $uibModal , StorageCleanSchedules) {
        var vm = this;

        vm.data = {
            boundary : 12 ,//准备删除多久之前的  单位 月
            exemptOrdered : 90 ,//多久之前下过单的
            ordered:true,
        }

        $scope.creationClear = function () {
            if (!vm.data.boundary) {
                MessageService.error('请选择删除几个月之前的图片');
                return
            }
            if (vm.data.ordered && !vm.data.exemptOrdered) {
                MessageService.error('请输入下单后几天内的用户不清理');
                return
            }

            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot-clear-dialog.html',
                controller: 'MapDepotClearDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }],
                    entity: ['StorageCleanSchedules', function (StorageCleanSchedules) {
                        return null;
                    }]
                }
            }).result.then(function (result) {
                create();
            });
        }

        function create(){
            StorageCleanSchedules.create(vm.data , function(res){
                if (res.status == 200) {
                    MessageService.success('创建清理任务成功')
                }
            })
        }
    }
})();
