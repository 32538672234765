(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreWorkEditController', StoreWorkEditController);

    StoreWorkEditController.$inject = ['$scope', '$q', 'OrderSetting', 'entity', 'Store', 'MessageService', '$state', 'StoreWorkEditConfig', 'StoreConfig'];

    function StoreWorkEditController($scope, $q, OrderSetting, entity, Store, MessageService, $state, StoreWorkEditConfig, StoreConfig) {
        var vm = this;
        //保存
        vm.save = save;
        vm.cancel = cancel;
        vm.storeWorkEdit = StoreWorkEditConfig.storeId();

        $q.all([vm.storeWorkEdit.$promise]).then(function () {
            vm.storeWorkEdit = vm.storeWorkEdit.data;
        });
        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if (flag) {
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left: e.clientX || 0, top: e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        function save() {
            vm.isSaving = true;
            if (vm.storeWorkEdit.id !== null) {
                StoreWorkEditConfig.update(vm.storeWorkEdit, onSaveSuccess, onSaveError);
            } else {
                StoreWorkEditConfig.save(vm.storeWorkEdit, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
            $state.go('store-work', {reload: true})
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
            $state.go('store-work',{reload:true})
        }

        function cancel() {
            $state.go("store-work");
        }
    }
})();
