(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ConsumptionController', ConsumptionController);

    ConsumptionController.$inject = ['$state', 'Consumption', 'ParseLinks',  'paginationConstants', 'pagingParams', 'MessageService', '$scope', 'DateUtils'];

    function ConsumptionController($state, Consumption, ParseLinks,   paginationConstants, pagingParams, MessageService, $scope, DateUtils) {
        var vm = this;
        vm.data={};
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search; 
        vm.data.classType = pagingParams.classType;
        vm.data.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.data.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;
        vm.transition = transition;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.type = type;
        vm.openCalendar = openCalendar;
        vm.search = search;
        vm.clear = clear;

        vm.datePickerOpenStatus = {
            startTime: false,
            endTime: false
        };
        vm.mannerList = [
            {name: '订单流量',id:'SALESORDER'},
            {name: '购买模板',id:'PRODUCTXMLLINE'},
        ];

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        loadAll();
        function loadAll () {
            var items = [];
            if(vm.data.startTime){
                items.push({key:"classDate",op:">=",value:new Date(Date.parse(vm.data.startTime) -8 * 60 * 60 * 1000)})
            }
            if(vm.data.endTime){
                items.push({key:"classDate",op:"<=",value:new Date(Date.parse(vm.data.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            }
            if(vm.data.classType){
                items.push({key:"classType",op:"=",value:vm.data.classType})
            }else{
                items.push({key:"classType",op:"in",value:'SALESORDER;PRODUCTXMLLINE'})
            }
            if(vm.data.searchQuery){
                items.push({key:"classNumber",op:"like",value:vm.data.searchQuery})
            }

            Consumption.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status==200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.page = pagingParams.page;
                    vm.consumption = res.data;
                }else{
                    MessageService.error(res.message)
                }

            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.data.currentSearch,
                startTime: vm.data.startTime?DateUtils.convertLocalDateToServer(vm.data.startTime):"",
                endTime: vm.data.endTime?DateUtils.convertLocalDateToServer(vm.data.endTime):"",
                classType: vm.data.classType,

            });
        }
        function search (searchQuery) {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            // loadAll()
        }

        function clear() {
            vm.data = {
                startTime: '',
                endTime: '',
                classType: '',
                searchQuery: ''
            }
            loadAll()
        }

        function type(target) {
            switch (target) {
                case "SALESORDER":
                    return "订单流量";
                case "PRODUCTXMLLINE":
                    return "购买模板";
                default:
                    return "";
            }
        }
    }
})();
