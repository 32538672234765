(function() {
		'use strict';
		angular
			.module('backApp')
			.factory('regions', regions);

		function regions() {
			var regions = [{
				"c": "86",
				"n": "中国"
			}, {
				"c": "110000",
				"n": "北京市",
				"pc": "86"
			}, {
				"c": "110100",
				"n": "北京市",
				"pc": "110000"
			}, {
				"c": "110101",
				"n": "东城区",
				"pc": "110100"
			}, {
				"c": "110102",
				"n": "西城区",
				"pc": "110100"
			}, {
				"c": "110105",
				"n": "朝阳区",
				"pc": "110100"
			}, {
				"c": "110106",
				"n": "丰台区",
				"pc": "110100"
			}, {
				"c": "110107",
				"n": "石景山区",
				"pc": "110100"
			}, {
				"c": "110108",
				"n": "海淀区",
				"pc": "110100"
			}, {
				"c": "110109",
				"n": "门头沟区",
				"pc": "110100"
			}, {
				"c": "110111",
				"n": "房山区",
				"pc": "110100"
			}, {
				"c": "110112",
				"n": "通州区",
				"pc": "110100"
			}, {
				"c": "110113",
				"n": "顺义区",
				"pc": "110100"
			}, {
				"c": "110114",
				"n": "昌平区",
				"pc": "110100"
			}, {
				"c": "110115",
				"n": "大兴区",
				"pc": "110100"
			}, {
				"c": "110116",
				"n": "怀柔区",
				"pc": "110100"
			}, {
				"c": "110117",
				"n": "平谷区",
				"pc": "110100"
			}, {
				"c": "110228",
				"n": "密云县",
				"pc": "110100"
			}, {
				"c": "110229",
				"n": "延庆县",
				"pc": "110100"
			}, {
				"c": "120000",
				"n": "天津市",
				"pc": "86"
			}, {
				"c": "120100",
				"n": "天津市",
				"pc": "120000"
			}, {
				"c": "120101",
				"n": "和平区",
				"pc": "120100"
			}, {
				"c": "120102",
				"n": "河东区",
				"pc": "120100"
			}, {
				"c": "120103",
				"n": "河西区",
				"pc": "120100"
			}, {
				"c": "120104",
				"n": "南开区",
				"pc": "120100"
			}, {
				"c": "120105",
				"n": "河北区",
				"pc": "120100"
			}, {
				"c": "120106",
				"n": "红桥区",
				"pc": "120100"
			}, {
				"c": "120110",
				"n": "东丽区",
				"pc": "120100"
			}, {
				"c": "120111",
				"n": "西青区",
				"pc": "120100"
			}, {
				"c": "120112",
				"n": "津南区",
				"pc": "120100"
			}, {
				"c": "120113",
				"n": "北辰区",
				"pc": "120100"
			}, {
				"c": "120114",
				"n": "武清区",
				"pc": "120100"
			}, {
				"c": "120115",
				"n": "宝坻区",
				"pc": "120100"
			}, {
				"c": "120116",
				"n": "滨海新区",
				"pc": "120100"
			}, {
				"c": "120221",
				"n": "宁河县",
				"pc": "120100"
			}, {
				"c": "120223",
				"n": "静海县",
				"pc": "120100"
			}, {
				"c": "120225",
				"n": "蓟县",
				"pc": "120100"
			}, {
				"c": "130000",
				"n": "河北省",
				"pc": "86"
			}, {
				"c": "130100",
				"n": "石家庄市",
				"pc": "130000"
			}, {
				"c": "130101",
				"n": "市辖区",
				"pc": "130100"
			}, {
				"c": "130102",
				"n": "长安区",
				"pc": "130100"
			}, {
				"c": "130103",
				"n": "桥东区",
				"pc": "130100"
			}, {
				"c": "130104",
				"n": "桥西区",
				"pc": "130100"
			}, {
				"c": "130105",
				"n": "新华区",
				"pc": "130100"
			}, {
				"c": "130107",
				"n": "井陉矿区",
				"pc": "130100"
			}, {
				"c": "130108",
				"n": "裕华区",
				"pc": "130100"
			}, {
				"c": "130121",
				"n": "井陉县",
				"pc": "130100"
			}, {
				"c": "130123",
				"n": "正定县",
				"pc": "130100"
			}, {
				"c": "130124",
				"n": "栾城县",
				"pc": "130100"
			}, {
				"c": "130125",
				"n": "行唐县",
				"pc": "130100"
			}, {
				"c": "130126",
				"n": "灵寿县",
				"pc": "130100"
			}, {
				"c": "130127",
				"n": "高邑县",
				"pc": "130100"
			}, {
				"c": "130128",
				"n": "深泽县",
				"pc": "130100"
			}, {
				"c": "130129",
				"n": "赞皇县",
				"pc": "130100"
			}, {
				"c": "130130",
				"n": "无极县",
				"pc": "130100"
			}, {
				"c": "130131",
				"n": "平山县",
				"pc": "130100"
			}, {
				"c": "130132",
				"n": "元氏县",
				"pc": "130100"
			}, {
				"c": "130133",
				"n": "赵县",
				"pc": "130100"
			}, {
				"c": "130181",
				"n": "辛集市",
				"pc": "130100"
			}, {
				"c": "130182",
				"n": "藁城市",
				"pc": "130100"
			}, {
				"c": "130183",
				"n": "晋州市",
				"pc": "130100"
			}, {
				"c": "130184",
				"n": "新乐市",
				"pc": "130100"
			}, {
				"c": "130185",
				"n": "鹿泉市",
				"pc": "130100"
			}, {
				"c": "130200",
				"n": "唐山市",
				"pc": "130000"
			}, {
				"c": "130201",
				"n": "市辖区",
				"pc": "130200"
			}, {
				"c": "130202",
				"n": "路南区",
				"pc": "130200"
			}, {
				"c": "130203",
				"n": "路北区",
				"pc": "130200"
			}, {
				"c": "130204",
				"n": "古冶区",
				"pc": "130200"
			}, {
				"c": "130205",
				"n": "开平区",
				"pc": "130200"
			}, {
				"c": "130207",
				"n": "丰南区",
				"pc": "130200"
			}, {
				"c": "130208",
				"n": "丰润区",
				"pc": "130200"
			}, {
				"c": "130209",
				"n": "曹妃甸区",
				"pc": "130200"
			}, {
				"c": "130223",
				"n": "滦县",
				"pc": "130200"
			}, {
				"c": "130224",
				"n": "滦南县",
				"pc": "130200"
			}, {
				"c": "130225",
				"n": "乐亭县",
				"pc": "130200"
			}, {
				"c": "130227",
				"n": "迁西县",
				"pc": "130200"
			}, {
				"c": "130229",
				"n": "玉田县",
				"pc": "130200"
			}, {
				"c": "130281",
				"n": "遵化市",
				"pc": "130200"
			}, {
				"c": "130283",
				"n": "迁安市",
				"pc": "130200"
			}, {
				"c": "130300",
				"n": "秦皇岛市",
				"pc": "130000"
			}, {
				"c": "130301",
				"n": "市辖区",
				"pc": "130300"
			}, {
				"c": "130302",
				"n": "海港区",
				"pc": "130300"
			}, {
				"c": "130303",
				"n": "山海关区",
				"pc": "130300"
			}, {
				"c": "130304",
				"n": "北戴河区",
				"pc": "130300"
			}, {
				"c": "130321",
				"n": "青龙满族自治县",
				"pc": "130300"
			}, {
				"c": "130322",
				"n": "昌黎县",
				"pc": "130300"
			}, {
				"c": "130323",
				"n": "抚宁县",
				"pc": "130300"
			}, {
				"c": "130324",
				"n": "卢龙县",
				"pc": "130300"
			}, {
				"c": "130400",
				"n": "邯郸市",
				"pc": "130000"
			}, {
				"c": "130401",
				"n": "市辖区",
				"pc": "130400"
			}, {
				"c": "130402",
				"n": "邯山区",
				"pc": "130400"
			}, {
				"c": "130403",
				"n": "丛台区",
				"pc": "130400"
			}, {
				"c": "130404",
				"n": "复兴区",
				"pc": "130400"
			}, {
				"c": "130406",
				"n": "峰峰矿区",
				"pc": "130400"
			}, {
				"c": "130421",
				"n": "邯郸县",
				"pc": "130400"
			}, {
				"c": "130423",
				"n": "临漳县",
				"pc": "130400"
			}, {
				"c": "130424",
				"n": "成安县",
				"pc": "130400"
			}, {
				"c": "130425",
				"n": "大名县",
				"pc": "130400"
			}, {
				"c": "130426",
				"n": "涉县",
				"pc": "130400"
			}, {
				"c": "130427",
				"n": "磁县",
				"pc": "130400"
			}, {
				"c": "130428",
				"n": "肥乡县",
				"pc": "130400"
			}, {
				"c": "130429",
				"n": "永年县",
				"pc": "130400"
			}, {
				"c": "130430",
				"n": "邱县",
				"pc": "130400"
			}, {
				"c": "130431",
				"n": "鸡泽县",
				"pc": "130400"
			}, {
				"c": "130432",
				"n": "广平县",
				"pc": "130400"
			}, {
				"c": "130433",
				"n": "馆陶县",
				"pc": "130400"
			}, {
				"c": "130434",
				"n": "魏县",
				"pc": "130400"
			}, {
				"c": "130435",
				"n": "曲周县",
				"pc": "130400"
			}, {
				"c": "130481",
				"n": "武安市",
				"pc": "130400"
			}, {
				"c": "130500",
				"n": "邢台市",
				"pc": "130000"
			}, {
				"c": "130501",
				"n": "市辖区",
				"pc": "130500"
			}, {
				"c": "130502",
				"n": "桥东区",
				"pc": "130500"
			}, {
				"c": "130503",
				"n": "桥西区",
				"pc": "130500"
			}, {
				"c": "130521",
				"n": "邢台县",
				"pc": "130500"
			}, {
				"c": "130522",
				"n": "临城县",
				"pc": "130500"
			}, {
				"c": "130523",
				"n": "内丘县",
				"pc": "130500"
			}, {
				"c": "130524",
				"n": "柏乡县",
				"pc": "130500"
			}, {
				"c": "130525",
				"n": "隆尧县",
				"pc": "130500"
			}, {
				"c": "130526",
				"n": "任县",
				"pc": "130500"
			}, {
				"c": "130527",
				"n": "南和县",
				"pc": "130500"
			}, {
				"c": "130528",
				"n": "宁晋县",
				"pc": "130500"
			}, {
				"c": "130529",
				"n": "巨鹿县",
				"pc": "130500"
			}, {
				"c": "130530",
				"n": "新河县",
				"pc": "130500"
			}, {
				"c": "130531",
				"n": "广宗县",
				"pc": "130500"
			}, {
				"c": "130532",
				"n": "平乡县",
				"pc": "130500"
			}, {
				"c": "130533",
				"n": "威县",
				"pc": "130500"
			}, {
				"c": "130534",
				"n": "清河县",
				"pc": "130500"
			}, {
				"c": "130535",
				"n": "临西县",
				"pc": "130500"
			}, {
				"c": "130581",
				"n": "南宫市",
				"pc": "130500"
			}, {
				"c": "130582",
				"n": "沙河市",
				"pc": "130500"
			}, {
				"c": "130600",
				"n": "保定市",
				"pc": "130000"
			}, {
				"c": "130601",
				"n": "市辖区",
				"pc": "130600"
			}, {
				"c": "130602",
				"n": "新市区",
				"pc": "130600"
			}, {
				"c": "130603",
				"n": "北市区",
				"pc": "130600"
			}, {
				"c": "130604",
				"n": "南市区",
				"pc": "130600"
			}, {
				"c": "130621",
				"n": "满城县",
				"pc": "130600"
			}, {
				"c": "130622",
				"n": "清苑县",
				"pc": "130600"
			}, {
				"c": "130623",
				"n": "涞水县",
				"pc": "130600"
			}, {
				"c": "130624",
				"n": "阜平县",
				"pc": "130600"
			}, {
				"c": "130625",
				"n": "徐水县",
				"pc": "130600"
			}, {
				"c": "130626",
				"n": "定兴县",
				"pc": "130600"
			}, {
				"c": "130627",
				"n": "唐县",
				"pc": "130600"
			}, {
				"c": "130628",
				"n": "高阳县",
				"pc": "130600"
			}, {
				"c": "130629",
				"n": "容城县",
				"pc": "130600"
			}, {
				"c": "130630",
				"n": "涞源县",
				"pc": "130600"
			}, {
				"c": "130631",
				"n": "望都县",
				"pc": "130600"
			}, {
				"c": "130632",
				"n": "安新县",
				"pc": "130600"
			}, {
				"c": "130633",
				"n": "易县",
				"pc": "130600"
			}, {
				"c": "130634",
				"n": "曲阳县",
				"pc": "130600"
			}, {
				"c": "130635",
				"n": "蠡县",
				"pc": "130600"
			}, {
				"c": "130636",
				"n": "顺平县",
				"pc": "130600"
			}, {
				"c": "130637",
				"n": "博野县",
				"pc": "130600"
			}, {
				"c": "130638",
				"n": "雄县",
				"pc": "130600"
			}, {
				"c": "130681",
				"n": "涿州市",
				"pc": "130600"
			}, {
				"c": "130682",
				"n": "定州市",
				"pc": "130600"
			}, {
				"c": "130683",
				"n": "安国市",
				"pc": "130600"
			}, {
				"c": "130684",
				"n": "高碑店市",
				"pc": "130600"
			}, {
				"c": "130700",
				"n": "张家口市",
				"pc": "130000"
			}, {
				"c": "130701",
				"n": "市辖区",
				"pc": "130700"
			}, {
				"c": "130702",
				"n": "桥东区",
				"pc": "130700"
			}, {
				"c": "130703",
				"n": "桥西区",
				"pc": "130700"
			}, {
				"c": "130705",
				"n": "宣化区",
				"pc": "130700"
			}, {
				"c": "130706",
				"n": "下花园区",
				"pc": "130700"
			}, {
				"c": "130721",
				"n": "宣化县",
				"pc": "130700"
			}, {
				"c": "130722",
				"n": "张北县",
				"pc": "130700"
			}, {
				"c": "130723",
				"n": "康保县",
				"pc": "130700"
			}, {
				"c": "130724",
				"n": "沽源县",
				"pc": "130700"
			}, {
				"c": "130725",
				"n": "尚义县",
				"pc": "130700"
			}, {
				"c": "130726",
				"n": "蔚县",
				"pc": "130700"
			}, {
				"c": "130727",
				"n": "阳原县",
				"pc": "130700"
			}, {
				"c": "130728",
				"n": "怀安县",
				"pc": "130700"
			}, {
				"c": "130729",
				"n": "万全县",
				"pc": "130700"
			}, {
				"c": "130730",
				"n": "怀来县",
				"pc": "130700"
			}, {
				"c": "130731",
				"n": "涿鹿县",
				"pc": "130700"
			}, {
				"c": "130732",
				"n": "赤城县",
				"pc": "130700"
			}, {
				"c": "130733",
				"n": "崇礼县",
				"pc": "130700"
			}, {
				"c": "130800",
				"n": "承德市",
				"pc": "130000"
			}, {
				"c": "130801",
				"n": "市辖区",
				"pc": "130800"
			}, {
				"c": "130802",
				"n": "双桥区",
				"pc": "130800"
			}, {
				"c": "130803",
				"n": "双滦区",
				"pc": "130800"
			}, {
				"c": "130804",
				"n": "鹰手营子矿区",
				"pc": "130800"
			}, {
				"c": "130821",
				"n": "承德县",
				"pc": "130800"
			}, {
				"c": "130822",
				"n": "兴隆县",
				"pc": "130800"
			}, {
				"c": "130823",
				"n": "平泉县",
				"pc": "130800"
			}, {
				"c": "130824",
				"n": "滦平县",
				"pc": "130800"
			}, {
				"c": "130825",
				"n": "隆化县",
				"pc": "130800"
			}, {
				"c": "130826",
				"n": "丰宁满族自治县",
				"pc": "130800"
			}, {
				"c": "130827",
				"n": "宽城满族自治县",
				"pc": "130800"
			}, {
				"c": "130828",
				"n": "围场满族蒙古族自治县",
				"pc": "130800"
			}, {
				"c": "130900",
				"n": "沧州市",
				"pc": "130000"
			}, {
				"c": "130901",
				"n": "市辖区",
				"pc": "130900"
			}, {
				"c": "130902",
				"n": "新华区",
				"pc": "130900"
			}, {
				"c": "130903",
				"n": "运河区",
				"pc": "130900"
			}, {
				"c": "130921",
				"n": "沧县",
				"pc": "130900"
			}, {
				"c": "130922",
				"n": "青县",
				"pc": "130900"
			}, {
				"c": "130923",
				"n": "东光县",
				"pc": "130900"
			}, {
				"c": "130924",
				"n": "海兴县",
				"pc": "130900"
			}, {
				"c": "130925",
				"n": "盐山县",
				"pc": "130900"
			}, {
				"c": "130926",
				"n": "肃宁县",
				"pc": "130900"
			}, {
				"c": "130927",
				"n": "南皮县",
				"pc": "130900"
			}, {
				"c": "130928",
				"n": "吴桥县",
				"pc": "130900"
			}, {
				"c": "130929",
				"n": "献县",
				"pc": "130900"
			}, {
				"c": "130930",
				"n": "孟村回族自治县",
				"pc": "130900"
			}, {
				"c": "130981",
				"n": "泊头市",
				"pc": "130900"
			}, {
				"c": "130982",
				"n": "任丘市",
				"pc": "130900"
			}, {
				"c": "130983",
				"n": "黄骅市",
				"pc": "130900"
			}, {
				"c": "130984",
				"n": "河间市",
				"pc": "130900"
			}, {
				"c": "131000",
				"n": "廊坊市",
				"pc": "130000"
			}, {
				"c": "131001",
				"n": "市辖区",
				"pc": "131000"
			}, {
				"c": "131002",
				"n": "安次区",
				"pc": "131000"
			}, {
				"c": "131003",
				"n": "广阳区",
				"pc": "131000"
			}, {
				"c": "131022",
				"n": "固安县",
				"pc": "131000"
			}, {
				"c": "131023",
				"n": "永清县",
				"pc": "131000"
			}, {
				"c": "131024",
				"n": "香河县",
				"pc": "131000"
			}, {
				"c": "131025",
				"n": "大城县",
				"pc": "131000"
			}, {
				"c": "131026",
				"n": "文安县",
				"pc": "131000"
			}, {
				"c": "131028",
				"n": "大厂回族自治县",
				"pc": "131000"
			}, {
				"c": "131081",
				"n": "霸州市",
				"pc": "131000"
			}, {
				"c": "131082",
				"n": "三河市",
				"pc": "131000"
			}, {
				"c": "131100",
				"n": "衡水市",
				"pc": "130000"
			}, {
				"c": "131101",
				"n": "市辖区",
				"pc": "131100"
			}, {
				"c": "131102",
				"n": "桃城区",
				"pc": "131100"
			}, {
				"c": "131121",
				"n": "枣强县",
				"pc": "131100"
			}, {
				"c": "131122",
				"n": "武邑县",
				"pc": "131100"
			}, {
				"c": "131123",
				"n": "武强县",
				"pc": "131100"
			}, {
				"c": "131124",
				"n": "饶阳县",
				"pc": "131100"
			}, {
				"c": "131125",
				"n": "安平县",
				"pc": "131100"
			}, {
				"c": "131126",
				"n": "故城县",
				"pc": "131100"
			}, {
				"c": "131127",
				"n": "景县",
				"pc": "131100"
			}, {
				"c": "131128",
				"n": "阜城县",
				"pc": "131100"
			}, {
				"c": "131181",
				"n": "冀州市",
				"pc": "131100"
			}, {
				"c": "131182",
				"n": "深州市",
				"pc": "131100"
			}, {
				"c": "140000",
				"n": "山西省",
				"pc": "86"
			}, {
				"c": "140100",
				"n": "太原市",
				"pc": "140000"
			}, {
				"c": "140101",
				"n": "市辖区",
				"pc": "140100"
			}, {
				"c": "140105",
				"n": "小店区",
				"pc": "140100"
			}, {
				"c": "140106",
				"n": "迎泽区",
				"pc": "140100"
			}, {
				"c": "140107",
				"n": "杏花岭区",
				"pc": "140100"
			}, {
				"c": "140108",
				"n": "尖草坪区",
				"pc": "140100"
			}, {
				"c": "140109",
				"n": "万柏林区",
				"pc": "140100"
			}, {
				"c": "140110",
				"n": "晋源区",
				"pc": "140100"
			}, {
				"c": "140121",
				"n": "清徐县",
				"pc": "140100"
			}, {
				"c": "140122",
				"n": "阳曲县",
				"pc": "140100"
			}, {
				"c": "140123",
				"n": "娄烦县",
				"pc": "140100"
			}, {
				"c": "140181",
				"n": "古交市",
				"pc": "140100"
			}, {
				"c": "140200",
				"n": "大同市",
				"pc": "140000"
			}, {
				"c": "140201",
				"n": "市辖区",
				"pc": "140200"
			}, {
				"c": "140202",
				"n": "城区",
				"pc": "140200"
			}, {
				"c": "140203",
				"n": "矿区",
				"pc": "140200"
			}, {
				"c": "140211",
				"n": "南郊区",
				"pc": "140200"
			}, {
				"c": "140212",
				"n": "新荣区",
				"pc": "140200"
			}, {
				"c": "140221",
				"n": "阳高县",
				"pc": "140200"
			}, {
				"c": "140222",
				"n": "天镇县",
				"pc": "140200"
			}, {
				"c": "140223",
				"n": "广灵县",
				"pc": "140200"
			}, {
				"c": "140224",
				"n": "灵丘县",
				"pc": "140200"
			}, {
				"c": "140225",
				"n": "浑源县",
				"pc": "140200"
			}, {
				"c": "140226",
				"n": "左云县",
				"pc": "140200"
			}, {
				"c": "140227",
				"n": "大同县",
				"pc": "140200"
			}, {
				"c": "140300",
				"n": "阳泉市",
				"pc": "140000"
			}, {
				"c": "140301",
				"n": "市辖区",
				"pc": "140300"
			}, {
				"c": "140302",
				"n": "城区",
				"pc": "140300"
			}, {
				"c": "140303",
				"n": "矿区",
				"pc": "140300"
			}, {
				"c": "140311",
				"n": "郊区",
				"pc": "140300"
			}, {
				"c": "140321",
				"n": "平定县",
				"pc": "140300"
			}, {
				"c": "140322",
				"n": "盂县",
				"pc": "140300"
			}, {
				"c": "140400",
				"n": "长治市",
				"pc": "140000"
			}, {
				"c": "140401",
				"n": "市辖区",
				"pc": "140400"
			}, {
				"c": "140402",
				"n": "城区",
				"pc": "140400"
			}, {
				"c": "140411",
				"n": "郊区",
				"pc": "140400"
			}, {
				"c": "140421",
				"n": "长治县",
				"pc": "140400"
			}, {
				"c": "140423",
				"n": "襄垣县",
				"pc": "140400"
			}, {
				"c": "140424",
				"n": "屯留县",
				"pc": "140400"
			}, {
				"c": "140425",
				"n": "平顺县",
				"pc": "140400"
			}, {
				"c": "140426",
				"n": "黎城县",
				"pc": "140400"
			}, {
				"c": "140427",
				"n": "壶关县",
				"pc": "140400"
			}, {
				"c": "140428",
				"n": "长子县",
				"pc": "140400"
			}, {
				"c": "140429",
				"n": "武乡县",
				"pc": "140400"
			}, {
				"c": "140430",
				"n": "沁县",
				"pc": "140400"
			}, {
				"c": "140431",
				"n": "沁源县",
				"pc": "140400"
			}, {
				"c": "140481",
				"n": "潞城市",
				"pc": "140400"
			}, {
				"c": "140500",
				"n": "晋城市",
				"pc": "140000"
			}, {
				"c": "140501",
				"n": "晋城市市辖区",
				"pc": "140500"
			}, {
				"c": "140502",
				"n": "城区",
				"pc": "140500"
			}, {
				"c": "140521",
				"n": "沁水县",
				"pc": "140500"
			}, {
				"c": "140522",
				"n": "阳城县",
				"pc": "140500"
			}, {
				"c": "140524",
				"n": "陵川县",
				"pc": "140500"
			}, {
				"c": "140525",
				"n": "泽州县",
				"pc": "140500"
			}, {
				"c": "140581",
				"n": "高平市",
				"pc": "140500"
			}, {
				"c": "140600",
				"n": "朔州市",
				"pc": "140000"
			}, {
				"c": "140601",
				"n": "市辖区",
				"pc": "140600"
			}, {
				"c": "140602",
				"n": "朔城区",
				"pc": "140600"
			}, {
				"c": "140603",
				"n": "平鲁区",
				"pc": "140600"
			}, {
				"c": "140621",
				"n": "山阴县",
				"pc": "140600"
			}, {
				"c": "140622",
				"n": "应县",
				"pc": "140600"
			}, {
				"c": "140623",
				"n": "右玉县",
				"pc": "140600"
			}, {
				"c": "140624",
				"n": "怀仁县",
				"pc": "140600"
			}, {
				"c": "140700",
				"n": "晋中市",
				"pc": "140000"
			}, {
				"c": "140701",
				"n": "市辖区",
				"pc": "140700"
			}, {
				"c": "140702",
				"n": "榆次区",
				"pc": "140700"
			}, {
				"c": "140721",
				"n": "榆社县",
				"pc": "140700"
			}, {
				"c": "140722",
				"n": "左权县",
				"pc": "140700"
			}, {
				"c": "140723",
				"n": "和顺县",
				"pc": "140700"
			}, {
				"c": "140724",
				"n": "昔阳县",
				"pc": "140700"
			}, {
				"c": "140725",
				"n": "寿阳县",
				"pc": "140700"
			}, {
				"c": "140726",
				"n": "太谷县",
				"pc": "140700"
			}, {
				"c": "140727",
				"n": "祁县",
				"pc": "140700"
			}, {
				"c": "140728",
				"n": "平遥县",
				"pc": "140700"
			}, {
				"c": "140729",
				"n": "灵石县",
				"pc": "140700"
			}, {
				"c": "140781",
				"n": "介休市",
				"pc": "140700"
			}, {
				"c": "140800",
				"n": "运城市",
				"pc": "140000"
			}, {
				"c": "140801",
				"n": "市辖区",
				"pc": "140800"
			}, {
				"c": "140802",
				"n": "盐湖区",
				"pc": "140800"
			}, {
				"c": "140821",
				"n": "临猗县",
				"pc": "140800"
			}, {
				"c": "140822",
				"n": "万荣县",
				"pc": "140800"
			}, {
				"c": "140823",
				"n": "闻喜县",
				"pc": "140800"
			}, {
				"c": "140824",
				"n": "稷山县",
				"pc": "140800"
			}, {
				"c": "140825",
				"n": "新绛县",
				"pc": "140800"
			}, {
				"c": "140826",
				"n": "绛县",
				"pc": "140800"
			}, {
				"c": "140827",
				"n": "垣曲县",
				"pc": "140800"
			}, {
				"c": "140828",
				"n": "夏县",
				"pc": "140800"
			}, {
				"c": "140829",
				"n": "平陆县",
				"pc": "140800"
			}, {
				"c": "140830",
				"n": "芮城县",
				"pc": "140800"
			}, {
				"c": "140881",
				"n": "永济市",
				"pc": "140800"
			}, {
				"c": "140882",
				"n": "河津市",
				"pc": "140800"
			}, {
				"c": "140900",
				"n": "忻州市",
				"pc": "140000"
			}, {
				"c": "140901",
				"n": "市辖区",
				"pc": "140900"
			}, {
				"c": "140902",
				"n": "忻府区",
				"pc": "140900"
			}, {
				"c": "140921",
				"n": "定襄县",
				"pc": "140900"
			}, {
				"c": "140922",
				"n": "五台县",
				"pc": "140900"
			}, {
				"c": "140923",
				"n": "代县",
				"pc": "140900"
			}, {
				"c": "140924",
				"n": "繁峙县",
				"pc": "140900"
			}, {
				"c": "140925",
				"n": "宁武县",
				"pc": "140900"
			}, {
				"c": "140926",
				"n": "静乐县",
				"pc": "140900"
			}, {
				"c": "140927",
				"n": "神池县",
				"pc": "140900"
			}, {
				"c": "140928",
				"n": "五寨县",
				"pc": "140900"
			}, {
				"c": "140929",
				"n": "岢岚县",
				"pc": "140900"
			}, {
				"c": "140930",
				"n": "河曲县",
				"pc": "140900"
			}, {
				"c": "140931",
				"n": "保德县",
				"pc": "140900"
			}, {
				"c": "140932",
				"n": "偏关县",
				"pc": "140900"
			}, {
				"c": "140981",
				"n": "原平市",
				"pc": "140900"
			}, {
				"c": "141000",
				"n": "临汾市",
				"pc": "140000"
			}, {
				"c": "141001",
				"n": "市辖区",
				"pc": "141000"
			}, {
				"c": "141002",
				"n": "尧都区",
				"pc": "141000"
			}, {
				"c": "141021",
				"n": "曲沃县",
				"pc": "141000"
			}, {
				"c": "141022",
				"n": "翼城县",
				"pc": "141000"
			}, {
				"c": "141023",
				"n": "襄汾县",
				"pc": "141000"
			}, {
				"c": "141024",
				"n": "洪洞县",
				"pc": "141000"
			}, {
				"c": "141025",
				"n": "古县",
				"pc": "141000"
			}, {
				"c": "141026",
				"n": "安泽县",
				"pc": "141000"
			}, {
				"c": "141027",
				"n": "浮山县",
				"pc": "141000"
			}, {
				"c": "141028",
				"n": "吉县",
				"pc": "141000"
			}, {
				"c": "141029",
				"n": "乡宁县",
				"pc": "141000"
			}, {
				"c": "141030",
				"n": "大宁县",
				"pc": "141000"
			}, {
				"c": "141031",
				"n": "隰县",
				"pc": "141000"
			}, {
				"c": "141032",
				"n": "永和县",
				"pc": "141000"
			}, {
				"c": "141033",
				"n": "蒲县",
				"pc": "141000"
			}, {
				"c": "141034",
				"n": "汾西县",
				"pc": "141000"
			}, {
				"c": "141081",
				"n": "侯马市",
				"pc": "141000"
			}, {
				"c": "141082",
				"n": "霍州市",
				"pc": "141000"
			}, {
				"c": "141100",
				"n": "吕梁市",
				"pc": "140000"
			}, {
				"c": "141101",
				"n": "市辖区",
				"pc": "141100"
			}, {
				"c": "141102",
				"n": "离石区",
				"pc": "141100"
			}, {
				"c": "141121",
				"n": "文水县",
				"pc": "141100"
			}, {
				"c": "141122",
				"n": "交城县",
				"pc": "141100"
			}, {
				"c": "141123",
				"n": "兴县",
				"pc": "141100"
			}, {
				"c": "141124",
				"n": "临县",
				"pc": "141100"
			}, {
				"c": "141125",
				"n": "柳林县",
				"pc": "141100"
			}, {
				"c": "141126",
				"n": "石楼县",
				"pc": "141100"
			}, {
				"c": "141127",
				"n": "岚县",
				"pc": "141100"
			}, {
				"c": "141128",
				"n": "方山县",
				"pc": "141100"
			}, {
				"c": "141129",
				"n": "中阳县",
				"pc": "141100"
			}, {
				"c": "141130",
				"n": "交口县",
				"pc": "141100"
			}, {
				"c": "141181",
				"n": "孝义市",
				"pc": "141100"
			}, {
				"c": "141182",
				"n": "汾阳市",
				"pc": "141100"
			}, {
				"c": "150000",
				"n": "内蒙古自治区",
				"pc": "86"
			}, {
				"c": "150100",
				"n": "呼和浩特市",
				"pc": "150000"
			}, {
				"c": "150101",
				"n": "市辖区",
				"pc": "150100"
			}, {
				"c": "150102",
				"n": "新城区",
				"pc": "150100"
			}, {
				"c": "150103",
				"n": "回民区",
				"pc": "150100"
			}, {
				"c": "150104",
				"n": "玉泉区",
				"pc": "150100"
			}, {
				"c": "150105",
				"n": "赛罕区",
				"pc": "150100"
			}, {
				"c": "150121",
				"n": "土默特左旗",
				"pc": "150100"
			}, {
				"c": "150122",
				"n": "托克托县",
				"pc": "150100"
			}, {
				"c": "150123",
				"n": "和林格尔县",
				"pc": "150100"
			}, {
				"c": "150124",
				"n": "清水河县",
				"pc": "150100"
			}, {
				"c": "150125",
				"n": "武川县",
				"pc": "150100"
			}, {
				"c": "150200",
				"n": "包头市",
				"pc": "150000"
			}, {
				"c": "150201",
				"n": "市辖区",
				"pc": "150200"
			}, {
				"c": "150202",
				"n": "东河区",
				"pc": "150200"
			}, {
				"c": "150203",
				"n": "昆都仑区",
				"pc": "150200"
			}, {
				"c": "150204",
				"n": "青山区",
				"pc": "150200"
			}, {
				"c": "150205",
				"n": "石拐区",
				"pc": "150200"
			}, {
				"c": "150206",
				"n": "白云鄂博矿区",
				"pc": "150200"
			}, {
				"c": "150207",
				"n": "九原区",
				"pc": "150200"
			}, {
				"c": "150221",
				"n": "土默特右旗",
				"pc": "150200"
			}, {
				"c": "150222",
				"n": "固阳县",
				"pc": "150200"
			}, {
				"c": "150223",
				"n": "达尔罕茂明安联合旗",
				"pc": "150200"
			}, {
				"c": "150300",
				"n": "乌海市",
				"pc": "150000"
			}, {
				"c": "150301",
				"n": "市辖区",
				"pc": "150300"
			}, {
				"c": "150302",
				"n": "海勃湾区",
				"pc": "150300"
			}, {
				"c": "150303",
				"n": "海南区",
				"pc": "150300"
			}, {
				"c": "150304",
				"n": "乌达区",
				"pc": "150300"
			}, {
				"c": "150400",
				"n": "赤峰市",
				"pc": "150000"
			}, {
				"c": "150401",
				"n": "市辖区",
				"pc": "150400"
			}, {
				"c": "150402",
				"n": "红山区",
				"pc": "150400"
			}, {
				"c": "150403",
				"n": "元宝山区",
				"pc": "150400"
			}, {
				"c": "150404",
				"n": "松山区",
				"pc": "150400"
			}, {
				"c": "150421",
				"n": "阿鲁科尔沁旗",
				"pc": "150400"
			}, {
				"c": "150422",
				"n": "巴林左旗",
				"pc": "150400"
			}, {
				"c": "150423",
				"n": "巴林右旗",
				"pc": "150400"
			}, {
				"c": "150424",
				"n": "林西县",
				"pc": "150400"
			}, {
				"c": "150425",
				"n": "克什克腾旗",
				"pc": "150400"
			}, {
				"c": "150426",
				"n": "翁牛特旗",
				"pc": "150400"
			}, {
				"c": "150428",
				"n": "喀喇沁旗",
				"pc": "150400"
			}, {
				"c": "150429",
				"n": "宁城县",
				"pc": "150400"
			}, {
				"c": "150430",
				"n": "敖汉旗",
				"pc": "150400"
			}, {
				"c": "150500",
				"n": "通辽市",
				"pc": "150000"
			}, {
				"c": "150501",
				"n": "市辖区",
				"pc": "150500"
			}, {
				"c": "150502",
				"n": "科尔沁区",
				"pc": "150500"
			}, {
				"c": "150521",
				"n": "科尔沁左翼中旗",
				"pc": "150500"
			}, {
				"c": "150522",
				"n": "科尔沁左翼后旗",
				"pc": "150500"
			}, {
				"c": "150523",
				"n": "开鲁县",
				"pc": "150500"
			}, {
				"c": "150524",
				"n": "库伦旗",
				"pc": "150500"
			}, {
				"c": "150525",
				"n": "奈曼旗",
				"pc": "150500"
			}, {
				"c": "150526",
				"n": "扎鲁特旗",
				"pc": "150500"
			}, {
				"c": "150581",
				"n": "霍林郭勒市",
				"pc": "150500"
			}, {
				"c": "150600",
				"n": "鄂尔多斯市",
				"pc": "150000"
			}, {
				"c": "150601",
				"n": "市辖区",
				"pc": "150600"
			}, {
				"c": "150602",
				"n": "东胜区",
				"pc": "150600"
			}, {
				"c": "150621",
				"n": "达拉特旗",
				"pc": "150600"
			}, {
				"c": "150622",
				"n": "准格尔旗",
				"pc": "150600"
			}, {
				"c": "150623",
				"n": "鄂托克前旗",
				"pc": "150600"
			}, {
				"c": "150624",
				"n": "鄂托克旗",
				"pc": "150600"
			}, {
				"c": "150625",
				"n": "杭锦旗",
				"pc": "150600"
			}, {
				"c": "150626",
				"n": "乌审旗",
				"pc": "150600"
			}, {
				"c": "150627",
				"n": "伊金霍洛旗",
				"pc": "150600"
			}, {
				"c": "150700",
				"n": "呼伦贝尔市",
				"pc": "150000"
			}, {
				"c": "150701",
				"n": "市辖区",
				"pc": "150700"
			}, {
				"c": "150702",
				"n": "海拉尔区",
				"pc": "150700"
			}, {
				"c": "150721",
				"n": "阿荣旗",
				"pc": "150700"
			}, {
				"c": "150722",
				"n": "莫力达瓦达斡尔族自治旗",
				"pc": "150700"
			}, {
				"c": "150723",
				"n": "鄂伦春自治旗",
				"pc": "150700"
			}, {
				"c": "150724",
				"n": "鄂温克族自治旗",
				"pc": "150700"
			}, {
				"c": "150725",
				"n": "陈巴尔虎旗",
				"pc": "150700"
			}, {
				"c": "150726",
				"n": "新巴尔虎左旗",
				"pc": "150700"
			}, {
				"c": "150727",
				"n": "新巴尔虎右旗",
				"pc": "150700"
			}, {
				"c": "150781",
				"n": "满洲里市",
				"pc": "150700"
			}, {
				"c": "150782",
				"n": "牙克石市",
				"pc": "150700"
			}, {
				"c": "150783",
				"n": "扎兰屯市",
				"pc": "150700"
			}, {
				"c": "150784",
				"n": "额尔古纳市",
				"pc": "150700"
			}, {
				"c": "150785",
				"n": "根河市",
				"pc": "150700"
			}, {
				"c": "150800",
				"n": "巴彦淖尔市",
				"pc": "150000"
			}, {
				"c": "150801",
				"n": "市辖区",
				"pc": "150800"
			}, {
				"c": "150802",
				"n": "临河区",
				"pc": "150800"
			}, {
				"c": "150821",
				"n": "五原县",
				"pc": "150800"
			}, {
				"c": "150822",
				"n": "磴口县",
				"pc": "150800"
			}, {
				"c": "150823",
				"n": "乌拉特前旗",
				"pc": "150800"
			}, {
				"c": "150824",
				"n": "乌拉特中旗",
				"pc": "150800"
			}, {
				"c": "150825",
				"n": "乌拉特后旗",
				"pc": "150800"
			}, {
				"c": "150826",
				"n": "杭锦后旗",
				"pc": "150800"
			}, {
				"c": "150900",
				"n": "乌兰察布市",
				"pc": "150000"
			}, {
				"c": "150901",
				"n": "市辖区",
				"pc": "150900"
			}, {
				"c": "150902",
				"n": "集宁区",
				"pc": "150900"
			}, {
				"c": "150921",
				"n": "卓资县",
				"pc": "150900"
			}, {
				"c": "150922",
				"n": "化德县",
				"pc": "150900"
			}, {
				"c": "150923",
				"n": "商都县",
				"pc": "150900"
			}, {
				"c": "150924",
				"n": "兴和县",
				"pc": "150900"
			}, {
				"c": "150925",
				"n": "凉城县",
				"pc": "150900"
			}, {
				"c": "150926",
				"n": "察哈尔右翼前旗",
				"pc": "150900"
			}, {
				"c": "150927",
				"n": "察哈尔右翼中旗",
				"pc": "150900"
			}, {
				"c": "150928",
				"n": "察哈尔右翼后旗",
				"pc": "150900"
			}, {
				"c": "150929",
				"n": "四子王旗",
				"pc": "150900"
			}, {
				"c": "150981",
				"n": "丰镇市",
				"pc": "150900"
			}, {
				"c": "152200",
				"n": "兴安盟",
				"pc": "150000"
			}, {
				"c": "152201",
				"n": "乌兰浩特市",
				"pc": "152200"
			}, {
				"c": "152202",
				"n": "阿尔山市",
				"pc": "152200"
			}, {
				"c": "152221",
				"n": "科尔沁右翼前旗",
				"pc": "152200"
			}, {
				"c": "152222",
				"n": "科尔沁右翼中旗",
				"pc": "152200"
			}, {
				"c": "152223",
				"n": "扎赉特旗",
				"pc": "152200"
			}, {
				"c": "152224",
				"n": "突泉县",
				"pc": "152200"
			}, {
				"c": "152500",
				"n": "锡林郭勒盟",
				"pc": "150000"
			}, {
				"c": "152501",
				"n": "二连浩特市",
				"pc": "152500"
			}, {
				"c": "152502",
				"n": "锡林浩特市",
				"pc": "152500"
			}, {
				"c": "152522",
				"n": "阿巴嘎旗",
				"pc": "152500"
			}, {
				"c": "152523",
				"n": "苏尼特左旗",
				"pc": "152500"
			}, {
				"c": "152524",
				"n": "苏尼特右旗",
				"pc": "152500"
			}, {
				"c": "152525",
				"n": "东乌珠穆沁旗",
				"pc": "152500"
			}, {
				"c": "152526",
				"n": "西乌珠穆沁旗",
				"pc": "152500"
			}, {
				"c": "152527",
				"n": "太仆寺旗",
				"pc": "152500"
			}, {
				"c": "152528",
				"n": "镶黄旗",
				"pc": "152500"
			}, {
				"c": "152529",
				"n": "正镶白旗",
				"pc": "152500"
			}, {
				"c": "152530",
				"n": "正蓝旗",
				"pc": "152500"
			}, {
				"c": "152531",
				"n": "多伦县",
				"pc": "152500"
			}, {
				"c": "152900",
				"n": "阿拉善盟",
				"pc": "150000"
			}, {
				"c": "152921",
				"n": "阿拉善左旗",
				"pc": "152900"
			}, {
				"c": "152922",
				"n": "阿拉善右旗",
				"pc": "152900"
			}, {
				"c": "152923",
				"n": "额济纳旗",
				"pc": "152900"
			}, {
				"c": "210000",
				"n": "辽宁省",
				"pc": "86"
			}, {
				"c": "210100",
				"n": "沈阳市",
				"pc": "210000"
			}, {
				"c": "210101",
				"n": "市辖区",
				"pc": "210100"
			}, {
				"c": "210102",
				"n": "和平区",
				"pc": "210100"
			}, {
				"c": "210103",
				"n": "沈河区",
				"pc": "210100"
			}, {
				"c": "210104",
				"n": "大东区",
				"pc": "210100"
			}, {
				"c": "210105",
				"n": "皇姑区",
				"pc": "210100"
			}, {
				"c": "210106",
				"n": "铁西区",
				"pc": "210100"
			}, {
				"c": "210111",
				"n": "苏家屯区",
				"pc": "210100"
			}, {
				"c": "210112",
				"n": "东陵区",
				"pc": "210100"
			}, {
				"c": "210113",
				"n": "沈北新区",
				"pc": "210100"
			}, {
				"c": "210114",
				"n": "于洪区",
				"pc": "210100"
			}, {
				"c": "210122",
				"n": "辽中县",
				"pc": "210100"
			}, {
				"c": "210123",
				"n": "康平县",
				"pc": "210100"
			}, {
				"c": "210124",
				"n": "法库县",
				"pc": "210100"
			}, {
				"c": "210181",
				"n": "新民市",
				"pc": "210100"
			}, {
				"c": "210200",
				"n": "大连市",
				"pc": "210000"
			}, {
				"c": "210201",
				"n": "市辖区",
				"pc": "210200"
			}, {
				"c": "210202",
				"n": "中山区",
				"pc": "210200"
			}, {
				"c": "210203",
				"n": "西岗区",
				"pc": "210200"
			}, {
				"c": "210204",
				"n": "沙河口区",
				"pc": "210200"
			}, {
				"c": "210211",
				"n": "甘井子区",
				"pc": "210200"
			}, {
				"c": "210212",
				"n": "旅顺口区",
				"pc": "210200"
			}, {
				"c": "210213",
				"n": "金州区",
				"pc": "210200"
			}, {
				"c": "210224",
				"n": "长海县",
				"pc": "210200"
			}, {
				"c": "210281",
				"n": "瓦房店市",
				"pc": "210200"
			}, {
				"c": "210282",
				"n": "普兰店市",
				"pc": "210200"
			}, {
				"c": "210283",
				"n": "庄河市",
				"pc": "210200"
			}, {
				"c": "210300",
				"n": "鞍山市",
				"pc": "210000"
			}, {
				"c": "210301",
				"n": "市辖区",
				"pc": "210300"
			}, {
				"c": "210302",
				"n": "铁东区",
				"pc": "210300"
			}, {
				"c": "210303",
				"n": "铁西区",
				"pc": "210300"
			}, {
				"c": "210304",
				"n": "立山区",
				"pc": "210300"
			}, {
				"c": "210311",
				"n": "千山区",
				"pc": "210300"
			}, {
				"c": "210321",
				"n": "台安县",
				"pc": "210300"
			}, {
				"c": "210323",
				"n": "岫岩满族自治县",
				"pc": "210300"
			}, {
				"c": "210381",
				"n": "海城市",
				"pc": "210300"
			}, {
				"c": "210400",
				"n": "抚顺市",
				"pc": "210000"
			}, {
				"c": "210401",
				"n": "市辖区",
				"pc": "210400"
			}, {
				"c": "210402",
				"n": "新抚区",
				"pc": "210400"
			}, {
				"c": "210403",
				"n": "东洲区",
				"pc": "210400"
			}, {
				"c": "210404",
				"n": "望花区",
				"pc": "210400"
			}, {
				"c": "210411",
				"n": "顺城区",
				"pc": "210400"
			}, {
				"c": "210421",
				"n": "抚顺县",
				"pc": "210400"
			}, {
				"c": "210422",
				"n": "新宾满族自治县",
				"pc": "210400"
			}, {
				"c": "210423",
				"n": "清原满族自治县",
				"pc": "210400"
			}, {
				"c": "210500",
				"n": "本溪市",
				"pc": "210000"
			}, {
				"c": "210501",
				"n": "市辖区",
				"pc": "210500"
			}, {
				"c": "210502",
				"n": "平山区",
				"pc": "210500"
			}, {
				"c": "210503",
				"n": "溪湖区",
				"pc": "210500"
			}, {
				"c": "210504",
				"n": "明山区",
				"pc": "210500"
			}, {
				"c": "210505",
				"n": "南芬区",
				"pc": "210500"
			}, {
				"c": "210521",
				"n": "本溪满族自治县",
				"pc": "210500"
			}, {
				"c": "210522",
				"n": "桓仁满族自治县",
				"pc": "210500"
			}, {
				"c": "210600",
				"n": "丹东市",
				"pc": "210000"
			}, {
				"c": "210601",
				"n": "市辖区",
				"pc": "210600"
			}, {
				"c": "210602",
				"n": "元宝区",
				"pc": "210600"
			}, {
				"c": "210603",
				"n": "振兴区",
				"pc": "210600"
			}, {
				"c": "210604",
				"n": "振安区",
				"pc": "210600"
			}, {
				"c": "210624",
				"n": "宽甸满族自治县",
				"pc": "210600"
			}, {
				"c": "210681",
				"n": "东港市",
				"pc": "210600"
			}, {
				"c": "210682",
				"n": "凤城市",
				"pc": "210600"
			}, {
				"c": "210700",
				"n": "锦州市",
				"pc": "210000"
			}, {
				"c": "210701",
				"n": "市辖区",
				"pc": "210700"
			}, {
				"c": "210702",
				"n": "古塔区",
				"pc": "210700"
			}, {
				"c": "210703",
				"n": "凌河区",
				"pc": "210700"
			}, {
				"c": "210711",
				"n": "太和区",
				"pc": "210700"
			}, {
				"c": "210726",
				"n": "黑山县",
				"pc": "210700"
			}, {
				"c": "210727",
				"n": "义县",
				"pc": "210700"
			}, {
				"c": "210781",
				"n": "凌海市",
				"pc": "210700"
			}, {
				"c": "210782",
				"n": "北镇市",
				"pc": "210700"
			}, {
				"c": "210800",
				"n": "营口市",
				"pc": "210000"
			}, {
				"c": "210801",
				"n": "市辖区",
				"pc": "210800"
			}, {
				"c": "210802",
				"n": "站前区",
				"pc": "210800"
			}, {
				"c": "210803",
				"n": "西市区",
				"pc": "210800"
			}, {
				"c": "210804",
				"n": "鲅鱼圈区",
				"pc": "210800"
			}, {
				"c": "210811",
				"n": "老边区",
				"pc": "210800"
			}, {
				"c": "210881",
				"n": "盖州市",
				"pc": "210800"
			}, {
				"c": "210882",
				"n": "大石桥市",
				"pc": "210800"
			}, {
				"c": "210900",
				"n": "阜新市",
				"pc": "210000"
			}, {
				"c": "210901",
				"n": "市辖区",
				"pc": "210900"
			}, {
				"c": "210902",
				"n": "海州区",
				"pc": "210900"
			}, {
				"c": "210903",
				"n": "新邱区",
				"pc": "210900"
			}, {
				"c": "210904",
				"n": "太平区",
				"pc": "210900"
			}, {
				"c": "210905",
				"n": "清河门区",
				"pc": "210900"
			}, {
				"c": "210911",
				"n": "细河区",
				"pc": "210900"
			}, {
				"c": "210921",
				"n": "阜新蒙古族自治县",
				"pc": "210900"
			}, {
				"c": "210922",
				"n": "彰武县",
				"pc": "210900"
			}, {
				"c": "211000",
				"n": "辽阳市",
				"pc": "210000"
			}, {
				"c": "211001",
				"n": "市辖区",
				"pc": "211000"
			}, {
				"c": "211002",
				"n": "白塔区",
				"pc": "211000"
			}, {
				"c": "211003",
				"n": "文圣区",
				"pc": "211000"
			}, {
				"c": "211004",
				"n": "宏伟区",
				"pc": "211000"
			}, {
				"c": "211005",
				"n": "弓长岭区",
				"pc": "211000"
			}, {
				"c": "211011",
				"n": "太子河区",
				"pc": "211000"
			}, {
				"c": "211021",
				"n": "辽阳县",
				"pc": "211000"
			}, {
				"c": "211081",
				"n": "灯塔市",
				"pc": "211000"
			}, {
				"c": "211100",
				"n": "盘锦市",
				"pc": "210000"
			}, {
				"c": "211101",
				"n": "市辖区",
				"pc": "211100"
			}, {
				"c": "211102",
				"n": "双台子区",
				"pc": "211100"
			}, {
				"c": "211103",
				"n": "兴隆台区",
				"pc": "211100"
			}, {
				"c": "211121",
				"n": "大洼县",
				"pc": "211100"
			}, {
				"c": "211122",
				"n": "盘山县",
				"pc": "211100"
			}, {
				"c": "211200",
				"n": "铁岭市",
				"pc": "210000"
			}, {
				"c": "211201",
				"n": "市辖区",
				"pc": "211200"
			}, {
				"c": "211202",
				"n": "银州区",
				"pc": "211200"
			}, {
				"c": "211204",
				"n": "清河区",
				"pc": "211200"
			}, {
				"c": "211221",
				"n": "铁岭县",
				"pc": "211200"
			}, {
				"c": "211223",
				"n": "西丰县",
				"pc": "211200"
			}, {
				"c": "211224",
				"n": "昌图县",
				"pc": "211200"
			}, {
				"c": "211281",
				"n": "调兵山市",
				"pc": "211200"
			}, {
				"c": "211282",
				"n": "开原市",
				"pc": "211200"
			}, {
				"c": "211300",
				"n": "朝阳市",
				"pc": "210000"
			}, {
				"c": "211301",
				"n": "市辖区",
				"pc": "211300"
			}, {
				"c": "211302",
				"n": "双塔区",
				"pc": "211300"
			}, {
				"c": "211303",
				"n": "龙城区",
				"pc": "211300"
			}, {
				"c": "211321",
				"n": "朝阳县",
				"pc": "211300"
			}, {
				"c": "211322",
				"n": "建平县",
				"pc": "211300"
			}, {
				"c": "211324",
				"n": "喀喇沁左翼蒙古族自治县",
				"pc": "211300"
			}, {
				"c": "211381",
				"n": "北票市",
				"pc": "211300"
			}, {
				"c": "211382",
				"n": "凌源市",
				"pc": "211300"
			}, {
				"c": "211400",
				"n": "葫芦岛市",
				"pc": "210000"
			}, {
				"c": "211401",
				"n": "市辖区",
				"pc": "211400"
			}, {
				"c": "211402",
				"n": "连山区",
				"pc": "211400"
			}, {
				"c": "211403",
				"n": "龙港区",
				"pc": "211400"
			}, {
				"c": "211404",
				"n": "南票区",
				"pc": "211400"
			}, {
				"c": "211421",
				"n": "绥中县",
				"pc": "211400"
			}, {
				"c": "211422",
				"n": "建昌县",
				"pc": "211400"
			}, {
				"c": "211481",
				"n": "兴城市",
				"pc": "211400"
			}, {
				"c": "220000",
				"n": "吉林省",
				"pc": "86"
			}, {
				"c": "220100",
				"n": "长春市",
				"pc": "220000"
			}, {
				"c": "220101",
				"n": "市辖区",
				"pc": "220100"
			}, {
				"c": "220102",
				"n": "南关区",
				"pc": "220100"
			}, {
				"c": "220103",
				"n": "宽城区",
				"pc": "220100"
			}, {
				"c": "220104",
				"n": "朝阳区",
				"pc": "220100"
			}, {
				"c": "220105",
				"n": "二道区",
				"pc": "220100"
			}, {
				"c": "220106",
				"n": "绿园区",
				"pc": "220100"
			}, {
				"c": "220112",
				"n": "双阳区",
				"pc": "220100"
			}, {
				"c": "220122",
				"n": "农安县",
				"pc": "220100"
			}, {
				"c": "220181",
				"n": "九台市",
				"pc": "220100"
			}, {
				"c": "220182",
				"n": "榆树市",
				"pc": "220100"
			}, {
				"c": "220183",
				"n": "德惠市",
				"pc": "220100"
			}, {
				"c": "220200",
				"n": "吉林市",
				"pc": "220000"
			}, {
				"c": "220201",
				"n": "市辖区",
				"pc": "220200"
			}, {
				"c": "220202",
				"n": "昌邑区",
				"pc": "220200"
			}, {
				"c": "220203",
				"n": "龙潭区",
				"pc": "220200"
			}, {
				"c": "220204",
				"n": "船营区",
				"pc": "220200"
			}, {
				"c": "220211",
				"n": "丰满区",
				"pc": "220200"
			}, {
				"c": "220221",
				"n": "永吉县",
				"pc": "220200"
			}, {
				"c": "220281",
				"n": "蛟河市",
				"pc": "220200"
			}, {
				"c": "220282",
				"n": "桦甸市",
				"pc": "220200"
			}, {
				"c": "220283",
				"n": "舒兰市",
				"pc": "220200"
			}, {
				"c": "220284",
				"n": "磐石市",
				"pc": "220200"
			}, {
				"c": "220300",
				"n": "四平市",
				"pc": "220000"
			}, {
				"c": "220301",
				"n": "市辖区",
				"pc": "220300"
			}, {
				"c": "220302",
				"n": "铁西区",
				"pc": "220300"
			}, {
				"c": "220303",
				"n": "铁东区",
				"pc": "220300"
			}, {
				"c": "220322",
				"n": "梨树县",
				"pc": "220300"
			}, {
				"c": "220323",
				"n": "伊通满族自治县",
				"pc": "220300"
			}, {
				"c": "220381",
				"n": "公主岭市",
				"pc": "220300"
			}, {
				"c": "220382",
				"n": "双辽市",
				"pc": "220300"
			}, {
				"c": "220400",
				"n": "辽源市",
				"pc": "220000"
			}, {
				"c": "220401",
				"n": "市辖区",
				"pc": "220400"
			}, {
				"c": "220402",
				"n": "龙山区",
				"pc": "220400"
			}, {
				"c": "220403",
				"n": "西安区",
				"pc": "220400"
			}, {
				"c": "220421",
				"n": "东丰县",
				"pc": "220400"
			}, {
				"c": "220422",
				"n": "东辽县",
				"pc": "220400"
			}, {
				"c": "220500",
				"n": "通化市",
				"pc": "220000"
			}, {
				"c": "220501",
				"n": "市辖区",
				"pc": "220500"
			}, {
				"c": "220502",
				"n": "东昌区",
				"pc": "220500"
			}, {
				"c": "220503",
				"n": "二道江区",
				"pc": "220500"
			}, {
				"c": "220521",
				"n": "通化县",
				"pc": "220500"
			}, {
				"c": "220523",
				"n": "辉南县",
				"pc": "220500"
			}, {
				"c": "220524",
				"n": "柳河县",
				"pc": "220500"
			}, {
				"c": "220581",
				"n": "梅河口市",
				"pc": "220500"
			}, {
				"c": "220582",
				"n": "集安市",
				"pc": "220500"
			}, {
				"c": "220600",
				"n": "白山市",
				"pc": "220000"
			}, {
				"c": "220601",
				"n": "市辖区",
				"pc": "220600"
			}, {
				"c": "220602",
				"n": "浑江区",
				"pc": "220600"
			}, {
				"c": "220605",
				"n": "江源区",
				"pc": "220600"
			}, {
				"c": "220621",
				"n": "抚松县",
				"pc": "220600"
			}, {
				"c": "220622",
				"n": "靖宇县",
				"pc": "220600"
			}, {
				"c": "220623",
				"n": "长白朝鲜族自治县",
				"pc": "220600"
			}, {
				"c": "220681",
				"n": "临江市",
				"pc": "220600"
			}, {
				"c": "220700",
				"n": "松原市",
				"pc": "220000"
			}, {
				"c": "220701",
				"n": "市辖区",
				"pc": "220700"
			}, {
				"c": "220702",
				"n": "宁江区",
				"pc": "220700"
			}, {
				"c": "220721",
				"n": "前郭尔罗斯蒙古族自治县",
				"pc": "220700"
			}, {
				"c": "220722",
				"n": "长岭县",
				"pc": "220700"
			}, {
				"c": "220723",
				"n": "乾安县",
				"pc": "220700"
			}, {
				"c": "220724",
				"n": "扶余县",
				"pc": "220700"
			}, {
				"c": "220800",
				"n": "白城市",
				"pc": "220000"
			}, {
				"c": "220801",
				"n": "市辖区",
				"pc": "220800"
			}, {
				"c": "220802",
				"n": "洮北区",
				"pc": "220800"
			}, {
				"c": "220821",
				"n": "镇赉县",
				"pc": "220800"
			}, {
				"c": "220822",
				"n": "通榆县",
				"pc": "220800"
			}, {
				"c": "220881",
				"n": "洮南市",
				"pc": "220800"
			}, {
				"c": "220882",
				"n": "大安市",
				"pc": "220800"
			}, {
				"c": "222400",
				"n": "延边朝鲜族自治州",
				"pc": "220000"
			}, {
				"c": "222401",
				"n": "延吉市",
				"pc": "222400"
			}, {
				"c": "222402",
				"n": "图们市",
				"pc": "222400"
			}, {
				"c": "222403",
				"n": "敦化市",
				"pc": "222400"
			}, {
				"c": "222404",
				"n": "珲春市",
				"pc": "222400"
			}, {
				"c": "222405",
				"n": "龙井市",
				"pc": "222400"
			}, {
				"c": "222406",
				"n": "和龙市",
				"pc": "222400"
			}, {
				"c": "222424",
				"n": "汪清县",
				"pc": "222400"
			}, {
				"c": "222426",
				"n": "安图县",
				"pc": "222400"
			}, {
				"c": "230000",
				"n": "黑龙江省",
				"pc": "86"
			}, {
				"c": "230100",
				"n": "哈尔滨市",
				"pc": "230000"
			}, {
				"c": "230101",
				"n": "市辖区",
				"pc": "230100"
			}, {
				"c": "230102",
				"n": "道里区",
				"pc": "230100"
			}, {
				"c": "230103",
				"n": "南岗区",
				"pc": "230100"
			}, {
				"c": "230104",
				"n": "道外区",
				"pc": "230100"
			}, {
				"c": "230108",
				"n": "平房区",
				"pc": "230100"
			}, {
				"c": "230109",
				"n": "松北区",
				"pc": "230100"
			}, {
				"c": "230110",
				"n": "香坊区",
				"pc": "230100"
			}, {
				"c": "230111",
				"n": "呼兰区",
				"pc": "230100"
			}, {
				"c": "230112",
				"n": "阿城区",
				"pc": "230100"
			}, {
				"c": "230123",
				"n": "依兰县",
				"pc": "230100"
			}, {
				"c": "230124",
				"n": "方正县",
				"pc": "230100"
			}, {
				"c": "230125",
				"n": "宾县",
				"pc": "230100"
			}, {
				"c": "230126",
				"n": "巴彦县",
				"pc": "230100"
			}, {
				"c": "230127",
				"n": "木兰县",
				"pc": "230100"
			}, {
				"c": "230128",
				"n": "通河县",
				"pc": "230100"
			}, {
				"c": "230129",
				"n": "延寿县",
				"pc": "230100"
			}, {
				"c": "230182",
				"n": "双城市",
				"pc": "230100"
			}, {
				"c": "230183",
				"n": "尚志市",
				"pc": "230100"
			}, {
				"c": "230184",
				"n": "五常市",
				"pc": "230100"
			}, {
				"c": "230200",
				"n": "齐齐哈尔市",
				"pc": "230000"
			}, {
				"c": "230201",
				"n": "市辖区",
				"pc": "230200"
			}, {
				"c": "230202",
				"n": "龙沙区",
				"pc": "230200"
			}, {
				"c": "230203",
				"n": "建华区",
				"pc": "230200"
			}, {
				"c": "230204",
				"n": "铁锋区",
				"pc": "230200"
			}, {
				"c": "230205",
				"n": "昂昂溪区",
				"pc": "230200"
			}, {
				"c": "230206",
				"n": "富拉尔基区",
				"pc": "230200"
			}, {
				"c": "230207",
				"n": "碾子山区",
				"pc": "230200"
			}, {
				"c": "230208",
				"n": "梅里斯达斡尔族区",
				"pc": "230200"
			}, {
				"c": "230221",
				"n": "龙江县",
				"pc": "230200"
			}, {
				"c": "230223",
				"n": "依安县",
				"pc": "230200"
			}, {
				"c": "230224",
				"n": "泰来县",
				"pc": "230200"
			}, {
				"c": "230225",
				"n": "甘南县",
				"pc": "230200"
			}, {
				"c": "230227",
				"n": "富裕县",
				"pc": "230200"
			}, {
				"c": "230229",
				"n": "克山县",
				"pc": "230200"
			}, {
				"c": "230230",
				"n": "克东县",
				"pc": "230200"
			}, {
				"c": "230231",
				"n": "拜泉县",
				"pc": "230200"
			}, {
				"c": "230281",
				"n": "讷河市",
				"pc": "230200"
			}, {
				"c": "230300",
				"n": "鸡西市",
				"pc": "230000"
			}, {
				"c": "230301",
				"n": "市辖区",
				"pc": "230300"
			}, {
				"c": "230302",
				"n": "鸡冠区",
				"pc": "230300"
			}, {
				"c": "230303",
				"n": "恒山区",
				"pc": "230300"
			}, {
				"c": "230304",
				"n": "滴道区",
				"pc": "230300"
			}, {
				"c": "230305",
				"n": "梨树区",
				"pc": "230300"
			}, {
				"c": "230306",
				"n": "城子河区",
				"pc": "230300"
			}, {
				"c": "230307",
				"n": "麻山区",
				"pc": "230300"
			}, {
				"c": "230321",
				"n": "鸡东县",
				"pc": "230300"
			}, {
				"c": "230381",
				"n": "虎林市",
				"pc": "230300"
			}, {
				"c": "230382",
				"n": "密山市",
				"pc": "230300"
			}, {
				"c": "230400",
				"n": "鹤岗市",
				"pc": "230000"
			}, {
				"c": "230401",
				"n": "市辖区",
				"pc": "230400"
			}, {
				"c": "230402",
				"n": "向阳区",
				"pc": "230400"
			}, {
				"c": "230403",
				"n": "工农区",
				"pc": "230400"
			}, {
				"c": "230404",
				"n": "南山区",
				"pc": "230400"
			}, {
				"c": "230405",
				"n": "兴安区",
				"pc": "230400"
			}, {
				"c": "230406",
				"n": "东山区",
				"pc": "230400"
			}, {
				"c": "230407",
				"n": "兴山区",
				"pc": "230400"
			}, {
				"c": "230421",
				"n": "萝北县",
				"pc": "230400"
			}, {
				"c": "230422",
				"n": "绥滨县",
				"pc": "230400"
			}, {
				"c": "230500",
				"n": "双鸭山市",
				"pc": "230000"
			}, {
				"c": "230501",
				"n": "市辖区",
				"pc": "230500"
			}, {
				"c": "230502",
				"n": "尖山区",
				"pc": "230500"
			}, {
				"c": "230503",
				"n": "岭东区",
				"pc": "230500"
			}, {
				"c": "230505",
				"n": "四方台区",
				"pc": "230500"
			}, {
				"c": "230506",
				"n": "宝山区",
				"pc": "230500"
			}, {
				"c": "230521",
				"n": "集贤县",
				"pc": "230500"
			}, {
				"c": "230522",
				"n": "友谊县",
				"pc": "230500"
			}, {
				"c": "230523",
				"n": "宝清县",
				"pc": "230500"
			}, {
				"c": "230524",
				"n": "饶河县",
				"pc": "230500"
			}, {
				"c": "230600",
				"n": "大庆市",
				"pc": "230000"
			}, {
				"c": "230601",
				"n": "市辖区",
				"pc": "230600"
			}, {
				"c": "230602",
				"n": "萨尔图区",
				"pc": "230600"
			}, {
				"c": "230603",
				"n": "龙凤区",
				"pc": "230600"
			}, {
				"c": "230604",
				"n": "让胡路区",
				"pc": "230600"
			}, {
				"c": "230605",
				"n": "红岗区",
				"pc": "230600"
			}, {
				"c": "230606",
				"n": "大同区",
				"pc": "230600"
			}, {
				"c": "230621",
				"n": "肇州县",
				"pc": "230600"
			}, {
				"c": "230622",
				"n": "肇源县",
				"pc": "230600"
			}, {
				"c": "230623",
				"n": "林甸县",
				"pc": "230600"
			}, {
				"c": "230624",
				"n": "杜尔伯特蒙古族自治县",
				"pc": "230600"
			}, {
				"c": "230700",
				"n": "伊春市",
				"pc": "230000"
			}, {
				"c": "230701",
				"n": "市辖区",
				"pc": "230700"
			}, {
				"c": "230702",
				"n": "伊春区",
				"pc": "230700"
			}, {
				"c": "230703",
				"n": "南岔区",
				"pc": "230700"
			}, {
				"c": "230704",
				"n": "友好区",
				"pc": "230700"
			}, {
				"c": "230705",
				"n": "西林区",
				"pc": "230700"
			}, {
				"c": "230706",
				"n": "翠峦区",
				"pc": "230700"
			}, {
				"c": "230707",
				"n": "新青区",
				"pc": "230700"
			}, {
				"c": "230708",
				"n": "美溪区",
				"pc": "230700"
			}, {
				"c": "230709",
				"n": "金山屯区",
				"pc": "230700"
			}, {
				"c": "230710",
				"n": "五营区",
				"pc": "230700"
			}, {
				"c": "230711",
				"n": "乌马河区",
				"pc": "230700"
			}, {
				"c": "230712",
				"n": "汤旺河区",
				"pc": "230700"
			}, {
				"c": "230713",
				"n": "带岭区",
				"pc": "230700"
			}, {
				"c": "230714",
				"n": "乌伊岭区",
				"pc": "230700"
			}, {
				"c": "230715",
				"n": "红星区",
				"pc": "230700"
			}, {
				"c": "230716",
				"n": "上甘岭区",
				"pc": "230700"
			}, {
				"c": "230722",
				"n": "嘉荫县",
				"pc": "230700"
			}, {
				"c": "230781",
				"n": "铁力市",
				"pc": "230700"
			}, {
				"c": "230800",
				"n": "佳木斯市",
				"pc": "230000"
			}, {
				"c": "230801",
				"n": "市辖区",
				"pc": "230800"
			}, {
				"c": "230803",
				"n": "向阳区",
				"pc": "230800"
			}, {
				"c": "230804",
				"n": "前进区",
				"pc": "230800"
			}, {
				"c": "230805",
				"n": "东风区",
				"pc": "230800"
			}, {
				"c": "230811",
				"n": "郊区",
				"pc": "230800"
			}, {
				"c": "230822",
				"n": "桦南县",
				"pc": "230800"
			}, {
				"c": "230826",
				"n": "桦川县",
				"pc": "230800"
			}, {
				"c": "230828",
				"n": "汤原县",
				"pc": "230800"
			}, {
				"c": "230833",
				"n": "抚远县",
				"pc": "230800"
			}, {
				"c": "230881",
				"n": "同江市",
				"pc": "230800"
			}, {
				"c": "230882",
				"n": "富锦市",
				"pc": "230800"
			}, {
				"c": "230900",
				"n": "七台河市",
				"pc": "230000"
			}, {
				"c": "230901",
				"n": "市辖区",
				"pc": "230900"
			}, {
				"c": "230902",
				"n": "新兴区",
				"pc": "230900"
			}, {
				"c": "230903",
				"n": "桃山区",
				"pc": "230900"
			}, {
				"c": "230904",
				"n": "茄子河区",
				"pc": "230900"
			}, {
				"c": "230921",
				"n": "勃利县",
				"pc": "230900"
			}, {
				"c": "231000",
				"n": "牡丹江市",
				"pc": "230000"
			}, {
				"c": "231001",
				"n": "市辖区",
				"pc": "231000"
			}, {
				"c": "231002",
				"n": "东安区",
				"pc": "231000"
			}, {
				"c": "231003",
				"n": "阳明区",
				"pc": "231000"
			}, {
				"c": "231004",
				"n": "爱民区",
				"pc": "231000"
			}, {
				"c": "231005",
				"n": "西安区",
				"pc": "231000"
			}, {
				"c": "231024",
				"n": "东宁县",
				"pc": "231000"
			}, {
				"c": "231025",
				"n": "林口县",
				"pc": "231000"
			}, {
				"c": "231081",
				"n": "绥芬河市",
				"pc": "231000"
			}, {
				"c": "231083",
				"n": "海林市",
				"pc": "231000"
			}, {
				"c": "231084",
				"n": "宁安市",
				"pc": "231000"
			}, {
				"c": "231085",
				"n": "穆棱市",
				"pc": "231000"
			}, {
				"c": "231100",
				"n": "黑河市",
				"pc": "230000"
			}, {
				"c": "231101",
				"n": "市辖区",
				"pc": "231100"
			}, {
				"c": "231102",
				"n": "爱辉区",
				"pc": "231100"
			}, {
				"c": "231121",
				"n": "嫩江县",
				"pc": "231100"
			}, {
				"c": "231123",
				"n": "逊克县",
				"pc": "231100"
			}, {
				"c": "231124",
				"n": "孙吴县",
				"pc": "231100"
			}, {
				"c": "231181",
				"n": "北安市",
				"pc": "231100"
			}, {
				"c": "231182",
				"n": "五大连池市",
				"pc": "231100"
			}, {
				"c": "231200",
				"n": "绥化市",
				"pc": "230000"
			}, {
				"c": "231201",
				"n": "市辖区",
				"pc": "231200"
			}, {
				"c": "231202",
				"n": "北林区",
				"pc": "231200"
			}, {
				"c": "231221",
				"n": "望奎县",
				"pc": "231200"
			}, {
				"c": "231222",
				"n": "兰西县",
				"pc": "231200"
			}, {
				"c": "231223",
				"n": "青冈县",
				"pc": "231200"
			}, {
				"c": "231224",
				"n": "庆安县",
				"pc": "231200"
			}, {
				"c": "231225",
				"n": "明水县",
				"pc": "231200"
			}, {
				"c": "231226",
				"n": "绥棱县",
				"pc": "231200"
			}, {
				"c": "231281",
				"n": "安达市",
				"pc": "231200"
			}, {
				"c": "231282",
				"n": "肇东市",
				"pc": "231200"
			}, {
				"c": "231283",
				"n": "海伦市",
				"pc": "231200"
			}, {
				"c": "232700",
				"n": "大兴安岭地区",
				"pc": "230000"
			}, {
				"c": "232721",
				"n": "呼玛县",
				"pc": "232700"
			}, {
				"c": "232722",
				"n": "塔河县",
				"pc": "232700"
			}, {
				"c": "232723",
				"n": "漠河县",
				"pc": "232700"
			}, {
				"c": "310000",
				"n": "上海市",
				"pc": "86"
			}, {
				"c": "310100",
				"n": "上海市",
				"pc": "310000"
			}, {
				"c": "310101",
				"n": "黄浦区",
				"pc": "310100"
			}, {
				"c": "310104",
				"n": "徐汇区",
				"pc": "310100"
			}, {
				"c": "310105",
				"n": "长宁区",
				"pc": "310100"
			}, {
				"c": "310106",
				"n": "静安区",
				"pc": "310100"
			}, {
				"c": "310107",
				"n": "普陀区",
				"pc": "310100"
			}, {
				"c": "310108",
				"n": "闸北区",
				"pc": "310100"
			}, {
				"c": "310109",
				"n": "虹口区",
				"pc": "310100"
			}, {
				"c": "310110",
				"n": "杨浦区",
				"pc": "310100"
			}, {
				"c": "310112",
				"n": "闵行区",
				"pc": "310100"
			}, {
				"c": "310113",
				"n": "宝山区",
				"pc": "310100"
			}, {
				"c": "310114",
				"n": "嘉定区",
				"pc": "310100"
			}, {
				"c": "310115",
				"n": "浦东新区",
				"pc": "310100"
			}, {
				"c": "310116",
				"n": "金山区",
				"pc": "310100"
			}, {
				"c": "310117",
				"n": "松江区",
				"pc": "310100"
			}, {
				"c": "310118",
				"n": "青浦区",
				"pc": "310100"
			}, {
				"c": "310120",
				"n": "奉贤区",
				"pc": "310100"
			}, {
				"c": "310230",
				"n": "崇明县",
				"pc": "310100"
			}, {
				"c": "320000",
				"n": "江苏省",
				"pc": "86"
			}, {
				"c": "320100",
				"n": "南京市",
				"pc": "320000"
			}, {
				"c": "320101",
				"n": "市辖区",
				"pc": "320100"
			}, {
				"c": "320102",
				"n": "玄武区",
				"pc": "320100"
			}, {
				"c": "320103",
				"n": "白下区",
				"pc": "320100"
			}, {
				"c": "320104",
				"n": "秦淮区",
				"pc": "320100"
			}, {
				"c": "320105",
				"n": "建邺区",
				"pc": "320100"
			}, {
				"c": "320106",
				"n": "鼓楼区",
				"pc": "320100"
			}, {
				"c": "320107",
				"n": "下关区",
				"pc": "320100"
			}, {
				"c": "320111",
				"n": "浦口区",
				"pc": "320100"
			}, {
				"c": "320113",
				"n": "栖霞区",
				"pc": "320100"
			}, {
				"c": "320114",
				"n": "雨花台区",
				"pc": "320100"
			}, {
				"c": "320115",
				"n": "江宁区",
				"pc": "320100"
			}, {
				"c": "320116",
				"n": "六合区",
				"pc": "320100"
			}, {
				"c": "320124",
				"n": "溧水县",
				"pc": "320100"
			}, {
				"c": "320125",
				"n": "高淳县",
				"pc": "320100"
			}, {
				"c": "320200",
				"n": "无锡市",
				"pc": "320000"
			}, {
				"c": "320201",
				"n": "市辖区",
				"pc": "320200"
			}, {
				"c": "320202",
				"n": "崇安区",
				"pc": "320200"
			}, {
				"c": "320203",
				"n": "南长区",
				"pc": "320200"
			}, {
				"c": "320204",
				"n": "北塘区",
				"pc": "320200"
			}, {
				"c": "320205",
				"n": "锡山区",
				"pc": "320200"
			}, {
				"c": "320206",
				"n": "惠山区",
				"pc": "320200"
			}, {
				"c": "320211",
				"n": "滨湖区",
				"pc": "320200"
			}, {
				"c": "320281",
				"n": "江阴市",
				"pc": "320200"
			}, {
				"c": "320282",
				"n": "宜兴市",
				"pc": "320200"
			}, {
				"c": "320300",
				"n": "徐州市",
				"pc": "320000"
			}, {
				"c": "320301",
				"n": "市辖区",
				"pc": "320300"
			}, {
				"c": "320302",
				"n": "鼓楼区",
				"pc": "320300"
			}, {
				"c": "320303",
				"n": "云龙区",
				"pc": "320300"
			}, {
				"c": "320305",
				"n": "贾汪区",
				"pc": "320300"
			}, {
				"c": "320311",
				"n": "泉山区",
				"pc": "320300"
			}, {
				"c": "320312",
				"n": "铜山区",
				"pc": "320300"
			}, {
				"c": "320321",
				"n": "丰县",
				"pc": "320300"
			}, {
				"c": "320322",
				"n": "沛县",
				"pc": "320300"
			}, {
				"c": "320324",
				"n": "睢宁县",
				"pc": "320300"
			}, {
				"c": "320381",
				"n": "新沂市",
				"pc": "320300"
			}, {
				"c": "320382",
				"n": "邳州市",
				"pc": "320300"
			}, {
				"c": "320400",
				"n": "常州市",
				"pc": "320000"
			}, {
				"c": "320401",
				"n": "市辖区",
				"pc": "320400"
			}, {
				"c": "320402",
				"n": "天宁区",
				"pc": "320400"
			}, {
				"c": "320404",
				"n": "钟楼区",
				"pc": "320400"
			}, {
				"c": "320405",
				"n": "戚墅堰区",
				"pc": "320400"
			}, {
				"c": "320411",
				"n": "新北区",
				"pc": "320400"
			}, {
				"c": "320412",
				"n": "武进区",
				"pc": "320400"
			}, {
				"c": "320481",
				"n": "溧阳市",
				"pc": "320400"
			}, {
				"c": "320482",
				"n": "金坛市",
				"pc": "320400"
			}, {
				"c": "320500",
				"n": "苏州市",
				"pc": "320000"
			}, {
				"c": "320501",
				"n": "市辖区",
				"pc": "320500"
			}, {
				"c": "320505",
				"n": "虎丘区",
				"pc": "320500"
			}, {
				"c": "320506",
				"n": "吴中区",
				"pc": "320500"
			}, {
				"c": "320507",
				"n": "相城区",
				"pc": "320500"
			}, {
				"c": "320508",
				"n": "姑苏区",
				"pc": "320500"
			}, {
				"c": "320509",
				"n": "吴江区",
				"pc": "320500"
			}, {
				"c": "320581",
				"n": "常熟市",
				"pc": "320500"
			}, {
				"c": "320582",
				"n": "张家港市",
				"pc": "320500"
			}, {
				"c": "320583",
				"n": "昆山市",
				"pc": "320500"
			}, {
				"c": "320585",
				"n": "太仓市",
				"pc": "320500"
			}, {
				"c": "320600",
				"n": "南通市",
				"pc": "320000"
			}, {
				"c": "320601",
				"n": "市辖区",
				"pc": "320600"
			}, {
				"c": "320602",
				"n": "崇川区",
				"pc": "320600"
			}, {
				"c": "320611",
				"n": "港闸区",
				"pc": "320600"
			}, {
				"c": "320612",
				"n": "通州区",
				"pc": "320600"
			}, {
				"c": "320621",
				"n": "海安县",
				"pc": "320600"
			}, {
				"c": "320623",
				"n": "如东县",
				"pc": "320600"
			}, {
				"c": "320681",
				"n": "启东市",
				"pc": "320600"
			}, {
				"c": "320682",
				"n": "如皋市",
				"pc": "320600"
			}, {
				"c": "320684",
				"n": "海门市",
				"pc": "320600"
			}, {
				"c": "320700",
				"n": "连云港市",
				"pc": "320000"
			}, {
				"c": "320701",
				"n": "市辖区",
				"pc": "320700"
			}, {
				"c": "320703",
				"n": "连云区",
				"pc": "320700"
			}, {
				"c": "320705",
				"n": "新浦区",
				"pc": "320700"
			}, {
				"c": "320706",
				"n": "海州区",
				"pc": "320700"
			}, {
				"c": "320721",
				"n": "赣榆县",
				"pc": "320700"
			}, {
				"c": "320722",
				"n": "东海县",
				"pc": "320700"
			}, {
				"c": "320723",
				"n": "灌云县",
				"pc": "320700"
			}, {
				"c": "320724",
				"n": "灌南县",
				"pc": "320700"
			}, {
				"c": "320800",
				"n": "淮安市",
				"pc": "320000"
			}, {
				"c": "320801",
				"n": "市辖区",
				"pc": "320800"
			}, {
				"c": "320802",
				"n": "清河区",
				"pc": "320800"
			}, {
				"c": "320803",
				"n": "淮安区",
				"pc": "320800"
			}, {
				"c": "320804",
				"n": "淮阴区",
				"pc": "320800"
			}, {
				"c": "320811",
				"n": "清浦区",
				"pc": "320800"
			}, {
				"c": "320826",
				"n": "涟水县",
				"pc": "320800"
			}, {
				"c": "320829",
				"n": "洪泽县",
				"pc": "320800"
			}, {
				"c": "320830",
				"n": "盱眙县",
				"pc": "320800"
			}, {
				"c": "320831",
				"n": "金湖县",
				"pc": "320800"
			}, {
				"c": "320900",
				"n": "盐城市",
				"pc": "320000"
			}, {
				"c": "320901",
				"n": "市辖区",
				"pc": "320900"
			}, {
				"c": "320902",
				"n": "亭湖区",
				"pc": "320900"
			}, {
				"c": "320903",
				"n": "盐都区",
				"pc": "320900"
			}, {
				"c": "320921",
				"n": "响水县",
				"pc": "320900"
			}, {
				"c": "320922",
				"n": "滨海县",
				"pc": "320900"
			}, {
				"c": "320923",
				"n": "阜宁县",
				"pc": "320900"
			}, {
				"c": "320924",
				"n": "射阳县",
				"pc": "320900"
			}, {
				"c": "320925",
				"n": "建湖县",
				"pc": "320900"
			}, {
				"c": "320981",
				"n": "东台市",
				"pc": "320900"
			}, {
				"c": "320982",
				"n": "大丰市",
				"pc": "320900"
			}, {
				"c": "321000",
				"n": "扬州市",
				"pc": "320000"
			}, {
				"c": "321001",
				"n": "市辖区",
				"pc": "321000"
			}, {
				"c": "321002",
				"n": "广陵区",
				"pc": "321000"
			}, {
				"c": "321003",
				"n": "邗江区",
				"pc": "321000"
			}, {
				"c": "321012",
				"n": "江都区",
				"pc": "321000"
			}, {
				"c": "321023",
				"n": "宝应县",
				"pc": "321000"
			}, {
				"c": "321081",
				"n": "仪征市",
				"pc": "321000"
			}, {
				"c": "321084",
				"n": "高邮市",
				"pc": "321000"
			}, {
				"c": "321100",
				"n": "镇江市",
				"pc": "320000"
			}, {
				"c": "321101",
				"n": "市辖区",
				"pc": "321100"
			}, {
				"c": "321102",
				"n": "京口区",
				"pc": "321100"
			}, {
				"c": "321111",
				"n": "润州区",
				"pc": "321100"
			}, {
				"c": "321112",
				"n": "丹徒区",
				"pc": "321100"
			}, {
				"c": "321181",
				"n": "丹阳市",
				"pc": "321100"
			}, {
				"c": "321182",
				"n": "扬中市",
				"pc": "321100"
			}, {
				"c": "321183",
				"n": "句容市",
				"pc": "321100"
			}, {
				"c": "321200",
				"n": "泰州市",
				"pc": "320000"
			}, {
				"c": "321201",
				"n": "市辖区",
				"pc": "321200"
			}, {
				"c": "321202",
				"n": "海陵区",
				"pc": "321200"
			}, {
				"c": "321203",
				"n": "高港区",
				"pc": "321200"
			}, {
				"c": "321281",
				"n": "兴化市",
				"pc": "321200"
			}, {
				"c": "321282",
				"n": "靖江市",
				"pc": "321200"
			}, {
				"c": "321283",
				"n": "泰兴市",
				"pc": "321200"
			}, {
				"c": "321284",
				"n": "姜堰市",
				"pc": "321200"
			}, {
				"c": "321300",
				"n": "宿迁市",
				"pc": "320000"
			}, {
				"c": "321301",
				"n": "市辖区",
				"pc": "321300"
			}, {
				"c": "321302",
				"n": "宿城区",
				"pc": "321300"
			}, {
				"c": "321311",
				"n": "宿豫区",
				"pc": "321300"
			}, {
				"c": "321322",
				"n": "沭阳县",
				"pc": "321300"
			}, {
				"c": "321323",
				"n": "泗阳县",
				"pc": "321300"
			}, {
				"c": "321324",
				"n": "泗洪县",
				"pc": "321300"
			}, {
				"c": "330000",
				"n": "浙江省",
				"pc": "86"
			}, {
				"c": "330100",
				"n": "杭州市",
				"pc": "330000"
			}, {
				"c": "330101",
				"n": "市辖区",
				"pc": "330100"
			}, {
				"c": "330102",
				"n": "上城区",
				"pc": "330100"
			}, {
				"c": "330103",
				"n": "下城区",
				"pc": "330100"
			}, {
				"c": "330104",
				"n": "江干区",
				"pc": "330100"
			}, {
				"c": "330105",
				"n": "拱墅区",
				"pc": "330100"
			}, {
				"c": "330106",
				"n": "西湖区",
				"pc": "330100"
			}, {
				"c": "330108",
				"n": "滨江区",
				"pc": "330100"
			}, {
				"c": "330109",
				"n": "萧山区",
				"pc": "330100"
			}, {
				"c": "330110",
				"n": "余杭区",
				"pc": "330100"
			}, {
				"c": "330122",
				"n": "桐庐县",
				"pc": "330100"
			}, {
				"c": "330127",
				"n": "淳安县",
				"pc": "330100"
			}, {
				"c": "330182",
				"n": "建德市",
				"pc": "330100"
			}, {
				"c": "330183",
				"n": "富阳市",
				"pc": "330100"
			}, {
				"c": "330185",
				"n": "临安市",
				"pc": "330100"
			}, {
				"c": "330200",
				"n": "宁波市",
				"pc": "330000"
			}, {
				"c": "330201",
				"n": "市辖区",
				"pc": "330200"
			}, {
				"c": "330203",
				"n": "海曙区",
				"pc": "330200"
			}, {
				"c": "330204",
				"n": "江东区",
				"pc": "330200"
			}, {
				"c": "330205",
				"n": "江北区",
				"pc": "330200"
			}, {
				"c": "330206",
				"n": "北仑区",
				"pc": "330200"
			}, {
				"c": "330211",
				"n": "镇海区",
				"pc": "330200"
			}, {
				"c": "330212",
				"n": "鄞州区",
				"pc": "330200"
			}, {
				"c": "330225",
				"n": "象山县",
				"pc": "330200"
			}, {
				"c": "330226",
				"n": "宁海县",
				"pc": "330200"
			}, {
				"c": "330281",
				"n": "余姚市",
				"pc": "330200"
			}, {
				"c": "330282",
				"n": "慈溪市",
				"pc": "330200"
			}, {
				"c": "330283",
				"n": "奉化市",
				"pc": "330200"
			}, {
				"c": "330300",
				"n": "温州市",
				"pc": "330000"
			}, {
				"c": "330301",
				"n": "市辖区",
				"pc": "330300"
			}, {
				"c": "330302",
				"n": "鹿城区",
				"pc": "330300"
			}, {
				"c": "330303",
				"n": "龙湾区",
				"pc": "330300"
			}, {
				"c": "330304",
				"n": "瓯海区",
				"pc": "330300"
			}, {
				"c": "330322",
				"n": "洞头县",
				"pc": "330300"
			}, {
				"c": "330324",
				"n": "永嘉县",
				"pc": "330300"
			}, {
				"c": "330326",
				"n": "平阳县",
				"pc": "330300"
			}, {
				"c": "330327",
				"n": "苍南县",
				"pc": "330300"
			}, {
				"c": "330328",
				"n": "文成县",
				"pc": "330300"
			}, {
				"c": "330329",
				"n": "泰顺县",
				"pc": "330300"
			}, {
				"c": "330381",
				"n": "瑞安市",
				"pc": "330300"
			}, {
				"c": "330382",
				"n": "乐清市",
				"pc": "330300"
			}, {
				"c": "330400",
				"n": "嘉兴市",
				"pc": "330000"
			}, {
				"c": "330401",
				"n": "市辖区",
				"pc": "330400"
			}, {
				"c": "330402",
				"n": "南湖区",
				"pc": "330400"
			}, {
				"c": "330411",
				"n": "秀洲区",
				"pc": "330400"
			}, {
				"c": "330421",
				"n": "嘉善县",
				"pc": "330400"
			}, {
				"c": "330424",
				"n": "海盐县",
				"pc": "330400"
			}, {
				"c": "330481",
				"n": "海宁市",
				"pc": "330400"
			}, {
				"c": "330482",
				"n": "平湖市",
				"pc": "330400"
			}, {
				"c": "330483",
				"n": "桐乡市",
				"pc": "330400"
			}, {
				"c": "330500",
				"n": "湖州市",
				"pc": "330000"
			}, {
				"c": "330501",
				"n": "市辖区",
				"pc": "330500"
			}, {
				"c": "330502",
				"n": "吴兴区",
				"pc": "330500"
			}, {
				"c": "330503",
				"n": "南浔区",
				"pc": "330500"
			}, {
				"c": "330521",
				"n": "德清县",
				"pc": "330500"
			}, {
				"c": "330522",
				"n": "长兴县",
				"pc": "330500"
			}, {
				"c": "330523",
				"n": "安吉县",
				"pc": "330500"
			}, {
				"c": "330600",
				"n": "绍兴市",
				"pc": "330000"
			}, {
				"c": "330601",
				"n": "市辖区",
				"pc": "330600"
			}, {
				"c": "330602",
				"n": "越城区",
				"pc": "330600"
			}, {
				"c": "330621",
				"n": "绍兴县",
				"pc": "330600"
			}, {
				"c": "330624",
				"n": "新昌县",
				"pc": "330600"
			}, {
				"c": "330681",
				"n": "诸暨市",
				"pc": "330600"
			}, {
				"c": "330682",
				"n": "上虞市",
				"pc": "330600"
			}, {
				"c": "330683",
				"n": "嵊州市",
				"pc": "330600"
			}, {
				"c": "330700",
				"n": "金华市",
				"pc": "330000"
			}, {
				"c": "330701",
				"n": "市辖区",
				"pc": "330700"
			}, {
				"c": "330702",
				"n": "婺城区",
				"pc": "330700"
			}, {
				"c": "330703",
				"n": "金东区",
				"pc": "330700"
			}, {
				"c": "330723",
				"n": "武义县",
				"pc": "330700"
			}, {
				"c": "330726",
				"n": "浦江县",
				"pc": "330700"
			}, {
				"c": "330727",
				"n": "磐安县",
				"pc": "330700"
			}, {
				"c": "330781",
				"n": "兰溪市",
				"pc": "330700"
			}, {
				"c": "330782",
				"n": "义乌市",
				"pc": "330700"
			}, {
				"c": "330783",
				"n": "东阳市",
				"pc": "330700"
			}, {
				"c": "330784",
				"n": "永康市",
				"pc": "330700"
			}, {
				"c": "330800",
				"n": "衢州市",
				"pc": "330000"
			}, {
				"c": "330801",
				"n": "市辖区",
				"pc": "330800"
			}, {
				"c": "330802",
				"n": "柯城区",
				"pc": "330800"
			}, {
				"c": "330803",
				"n": "衢江区",
				"pc": "330800"
			}, {
				"c": "330822",
				"n": "常山县",
				"pc": "330800"
			}, {
				"c": "330824",
				"n": "开化县",
				"pc": "330800"
			}, {
				"c": "330825",
				"n": "龙游县",
				"pc": "330800"
			}, {
				"c": "330881",
				"n": "江山市",
				"pc": "330800"
			}, {
				"c": "330900",
				"n": "舟山市",
				"pc": "330000"
			}, {
				"c": "330901",
				"n": "市辖区",
				"pc": "330900"
			}, {
				"c": "330902",
				"n": "定海区",
				"pc": "330900"
			}, {
				"c": "330903",
				"n": "普陀区",
				"pc": "330900"
			}, {
				"c": "330921",
				"n": "岱山县",
				"pc": "330900"
			}, {
				"c": "330922",
				"n": "嵊泗县",
				"pc": "330900"
			}, {
				"c": "331000",
				"n": "台州市",
				"pc": "330000"
			}, {
				"c": "331001",
				"n": "市辖区",
				"pc": "331000"
			}, {
				"c": "331002",
				"n": "椒江区",
				"pc": "331000"
			}, {
				"c": "331003",
				"n": "黄岩区",
				"pc": "331000"
			}, {
				"c": "331004",
				"n": "路桥区",
				"pc": "331000"
			}, {
				"c": "331021",
				"n": "玉环县",
				"pc": "331000"
			}, {
				"c": "331022",
				"n": "三门县",
				"pc": "331000"
			}, {
				"c": "331023",
				"n": "天台县",
				"pc": "331000"
			}, {
				"c": "331024",
				"n": "仙居县",
				"pc": "331000"
			}, {
				"c": "331081",
				"n": "温岭市",
				"pc": "331000"
			}, {
				"c": "331082",
				"n": "临海市",
				"pc": "331000"
			}, {
				"c": "331100",
				"n": "丽水市",
				"pc": "330000"
			}, {
				"c": "331101",
				"n": "市辖区",
				"pc": "331100"
			}, {
				"c": "331102",
				"n": "莲都区",
				"pc": "331100"
			}, {
				"c": "331121",
				"n": "青田县",
				"pc": "331100"
			}, {
				"c": "331122",
				"n": "缙云县",
				"pc": "331100"
			}, {
				"c": "331123",
				"n": "遂昌县",
				"pc": "331100"
			}, {
				"c": "331124",
				"n": "松阳县",
				"pc": "331100"
			}, {
				"c": "331125",
				"n": "云和县",
				"pc": "331100"
			}, {
				"c": "331126",
				"n": "庆元县",
				"pc": "331100"
			}, {
				"c": "331127",
				"n": "景宁畲族自治县",
				"pc": "331100"
			}, {
				"c": "331181",
				"n": "龙泉市",
				"pc": "331100"
			}, {
				"c": "340000",
				"n": "安徽省",
				"pc": "86"
			}, {
				"c": "340100",
				"n": "合肥市",
				"pc": "340000"
			}, {
				"c": "340101",
				"n": "市辖区",
				"pc": "340100"
			}, {
				"c": "340102",
				"n": "瑶海区",
				"pc": "340100"
			}, {
				"c": "340103",
				"n": "庐阳区",
				"pc": "340100"
			}, {
				"c": "340104",
				"n": "蜀山区",
				"pc": "340100"
			}, {
				"c": "340111",
				"n": "包河区",
				"pc": "340100"
			}, {
				"c": "340121",
				"n": "长丰县",
				"pc": "340100"
			}, {
				"c": "340122",
				"n": "肥东县",
				"pc": "340100"
			}, {
				"c": "340123",
				"n": "肥西县",
				"pc": "340100"
			}, {
				"c": "340124",
				"n": "庐江县",
				"pc": "340100"
			}, {
				"c": "340181",
				"n": "巢湖市",
				"pc": "340100"
			}, {
				"c": "340200",
				"n": "芜湖市",
				"pc": "340000"
			}, {
				"c": "340201",
				"n": "市辖区",
				"pc": "340200"
			}, {
				"c": "340202",
				"n": "镜湖区",
				"pc": "340200"
			}, {
				"c": "340203",
				"n": "弋江区",
				"pc": "340200"
			}, {
				"c": "340207",
				"n": "鸠江区",
				"pc": "340200"
			}, {
				"c": "340208",
				"n": "三山区",
				"pc": "340200"
			}, {
				"c": "340221",
				"n": "芜湖县",
				"pc": "340200"
			}, {
				"c": "340222",
				"n": "繁昌县",
				"pc": "340200"
			}, {
				"c": "340223",
				"n": "南陵县",
				"pc": "340200"
			}, {
				"c": "340225",
				"n": "无为县",
				"pc": "340200"
			}, {
				"c": "340300",
				"n": "蚌埠市",
				"pc": "340000"
			}, {
				"c": "340301",
				"n": "市辖区",
				"pc": "340300"
			}, {
				"c": "340302",
				"n": "龙子湖区",
				"pc": "340300"
			}, {
				"c": "340303",
				"n": "蚌山区",
				"pc": "340300"
			}, {
				"c": "340304",
				"n": "禹会区",
				"pc": "340300"
			}, {
				"c": "340311",
				"n": "淮上区",
				"pc": "340300"
			}, {
				"c": "340321",
				"n": "怀远县",
				"pc": "340300"
			}, {
				"c": "340322",
				"n": "五河县",
				"pc": "340300"
			}, {
				"c": "340323",
				"n": "固镇县",
				"pc": "340300"
			}, {
				"c": "340400",
				"n": "淮南市",
				"pc": "340000"
			}, {
				"c": "340401",
				"n": "市辖区",
				"pc": "340400"
			}, {
				"c": "340402",
				"n": "大通区",
				"pc": "340400"
			}, {
				"c": "340403",
				"n": "田家庵区",
				"pc": "340400"
			}, {
				"c": "340404",
				"n": "谢家集区",
				"pc": "340400"
			}, {
				"c": "340405",
				"n": "八公山区",
				"pc": "340400"
			}, {
				"c": "340406",
				"n": "潘集区",
				"pc": "340400"
			}, {
				"c": "340421",
				"n": "凤台县",
				"pc": "340400"
			}, {
				"c": "340500",
				"n": "马鞍山市",
				"pc": "340000"
			}, {
				"c": "340501",
				"n": "市辖区",
				"pc": "340500"
			}, {
				"c": "340503",
				"n": "花山区",
				"pc": "340500"
			}, {
				"c": "340504",
				"n": "雨山区",
				"pc": "340500"
			}, {
				"c": "340506",
				"n": "博望区",
				"pc": "340500"
			}, {
				"c": "340521",
				"n": "当涂县",
				"pc": "340500"
			}, {
				"c": "340522",
				"n": "含山县",
				"pc": "340500"
			}, {
				"c": "340523",
				"n": "和县",
				"pc": "340500"
			}, {
				"c": "340600",
				"n": "淮北市",
				"pc": "340000"
			}, {
				"c": "340601",
				"n": "市辖区",
				"pc": "340600"
			}, {
				"c": "340602",
				"n": "杜集区",
				"pc": "340600"
			}, {
				"c": "340603",
				"n": "相山区",
				"pc": "340600"
			}, {
				"c": "340604",
				"n": "烈山区",
				"pc": "340600"
			}, {
				"c": "340621",
				"n": "濉溪县",
				"pc": "340600"
			}, {
				"c": "340700",
				"n": "铜陵市",
				"pc": "340000"
			}, {
				"c": "340701",
				"n": "市辖区",
				"pc": "340700"
			}, {
				"c": "340702",
				"n": "铜官山区",
				"pc": "340700"
			}, {
				"c": "340703",
				"n": "狮子山区",
				"pc": "340700"
			}, {
				"c": "340711",
				"n": "郊区",
				"pc": "340700"
			}, {
				"c": "340721",
				"n": "铜陵县",
				"pc": "340700"
			}, {
				"c": "340800",
				"n": "安庆市",
				"pc": "340000"
			}, {
				"c": "340801",
				"n": "市辖区",
				"pc": "340800"
			}, {
				"c": "340802",
				"n": "迎江区",
				"pc": "340800"
			}, {
				"c": "340803",
				"n": "大观区",
				"pc": "340800"
			}, {
				"c": "340811",
				"n": "宜秀区",
				"pc": "340800"
			}, {
				"c": "340822",
				"n": "怀宁县",
				"pc": "340800"
			}, {
				"c": "340823",
				"n": "枞阳县",
				"pc": "340800"
			}, {
				"c": "340824",
				"n": "潜山县",
				"pc": "340800"
			}, {
				"c": "340825",
				"n": "太湖县",
				"pc": "340800"
			}, {
				"c": "340826",
				"n": "宿松县",
				"pc": "340800"
			}, {
				"c": "340827",
				"n": "望江县",
				"pc": "340800"
			}, {
				"c": "340828",
				"n": "岳西县",
				"pc": "340800"
			}, {
				"c": "340881",
				"n": "桐城市",
				"pc": "340800"
			}, {
				"c": "341000",
				"n": "黄山市",
				"pc": "340000"
			}, {
				"c": "341001",
				"n": "市辖区",
				"pc": "341000"
			}, {
				"c": "341002",
				"n": "屯溪区",
				"pc": "341000"
			}, {
				"c": "341003",
				"n": "黄山区o:p>",
				"pc": "341000"
			}, {
				"c": "341004",
				"n": "徽州区",
				"pc": "341000"
			}, {
				"c": "341021",
				"n": "歙县",
				"pc": "341000"
			}, {
				"c": "341022",
				"n": "休宁县",
				"pc": "341000"
			}, {
				"c": "341023",
				"n": "黟县",
				"pc": "341000"
			}, {
				"c": "341024",
				"n": "祁门县",
				"pc": "341000"
			}, {
				"c": "341100",
				"n": "滁州市",
				"pc": "340000"
			}, {
				"c": "341101",
				"n": "市辖区",
				"pc": "341100"
			}, {
				"c": "341102",
				"n": "琅琊区",
				"pc": "341100"
			}, {
				"c": "341103",
				"n": "南谯区",
				"pc": "341100"
			}, {
				"c": "341122",
				"n": "来安县",
				"pc": "341100"
			}, {
				"c": "341124",
				"n": "全椒县",
				"pc": "341100"
			}, {
				"c": "341125",
				"n": "定远县",
				"pc": "341100"
			}, {
				"c": "341126",
				"n": "凤阳县",
				"pc": "341100"
			}, {
				"c": "341181",
				"n": "天长市",
				"pc": "341100"
			}, {
				"c": "341182",
				"n": "明光市",
				"pc": "341100"
			}, {
				"c": "341200",
				"n": "阜阳市",
				"pc": "340000"
			}, {
				"c": "341201",
				"n": "市辖区",
				"pc": "341200"
			}, {
				"c": "341202",
				"n": "颍州区",
				"pc": "341200"
			}, {
				"c": "341203",
				"n": "颍东区",
				"pc": "341200"
			}, {
				"c": "341204",
				"n": "颍泉区",
				"pc": "341200"
			}, {
				"c": "341221",
				"n": "临泉县",
				"pc": "341200"
			}, {
				"c": "341222",
				"n": "太和县",
				"pc": "341200"
			}, {
				"c": "341225",
				"n": "阜南县",
				"pc": "341200"
			}, {
				"c": "341226",
				"n": "颍上县",
				"pc": "341200"
			}, {
				"c": "341282",
				"n": "界首市",
				"pc": "341200"
			}, {
				"c": "341300",
				"n": "宿州市",
				"pc": "340000"
			}, {
				"c": "341301",
				"n": "市辖区",
				"pc": "341300"
			}, {
				"c": "341302",
				"n": "埇桥区",
				"pc": "341300"
			}, {
				"c": "341321",
				"n": "砀山县",
				"pc": "341300"
			}, {
				"c": "341322",
				"n": "萧县",
				"pc": "341300"
			}, {
				"c": "341323",
				"n": "灵璧县",
				"pc": "341300"
			}, {
				"c": "341324",
				"n": "泗县",
				"pc": "341300"
			}, {
				"c": "341500",
				"n": "六安市",
				"pc": "340000"
			}, {
				"c": "341501",
				"n": "市辖区",
				"pc": "341500"
			}, {
				"c": "341502",
				"n": "金安区",
				"pc": "341500"
			}, {
				"c": "341503",
				"n": "裕安区",
				"pc": "341500"
			}, {
				"c": "341521",
				"n": "寿县",
				"pc": "341500"
			}, {
				"c": "341522",
				"n": "霍邱县",
				"pc": "341500"
			}, {
				"c": "341523",
				"n": "舒城县",
				"pc": "341500"
			}, {
				"c": "341524",
				"n": "金寨县",
				"pc": "341500"
			}, {
				"c": "341525",
				"n": "霍山县",
				"pc": "341500"
			}, {
				"c": "341600",
				"n": "亳州市",
				"pc": "340000"
			}, {
				"c": "341601",
				"n": "市辖区",
				"pc": "341600"
			}, {
				"c": "341602",
				"n": "谯城区",
				"pc": "341600"
			}, {
				"c": "341621",
				"n": "涡阳县",
				"pc": "341600"
			}, {
				"c": "341622",
				"n": "蒙城县",
				"pc": "341600"
			}, {
				"c": "341623",
				"n": "利辛县",
				"pc": "341600"
			}, {
				"c": "341700",
				"n": "池州市",
				"pc": "340000"
			}, {
				"c": "341701",
				"n": "市辖区",
				"pc": "341700"
			}, {
				"c": "341702",
				"n": "贵池区",
				"pc": "341700"
			}, {
				"c": "341721",
				"n": "东至县",
				"pc": "341700"
			}, {
				"c": "341722",
				"n": "石台县",
				"pc": "341700"
			}, {
				"c": "341723",
				"n": "青阳县",
				"pc": "341700"
			}, {
				"c": "341800",
				"n": "宣城市",
				"pc": "340000"
			}, {
				"c": "341801",
				"n": "市辖区",
				"pc": "341800"
			}, {
				"c": "341802",
				"n": "宣州区",
				"pc": "341800"
			}, {
				"c": "341821",
				"n": "郎溪县",
				"pc": "341800"
			}, {
				"c": "341822",
				"n": "广德县",
				"pc": "341800"
			}, {
				"c": "341823",
				"n": "泾县",
				"pc": "341800"
			}, {
				"c": "341824",
				"n": "绩溪县",
				"pc": "341800"
			}, {
				"c": "341825",
				"n": "旌德县",
				"pc": "341800"
			}, {
				"c": "341881",
				"n": "宁国市",
				"pc": "341800"
			}, {
				"c": "350000",
				"n": "福建省",
				"pc": "86"
			}, {
				"c": "350100",
				"n": "福州市",
				"pc": "350000"
			}, {
				"c": "350101",
				"n": "市辖区",
				"pc": "350100"
			}, {
				"c": "350102",
				"n": "鼓楼区",
				"pc": "350100"
			}, {
				"c": "350103",
				"n": "台江区",
				"pc": "350100"
			}, {
				"c": "350104",
				"n": "仓山区",
				"pc": "350100"
			}, {
				"c": "350105",
				"n": "马尾区",
				"pc": "350100"
			}, {
				"c": "350111",
				"n": "晋安区",
				"pc": "350100"
			}, {
				"c": "350121",
				"n": "闽侯县",
				"pc": "350100"
			}, {
				"c": "350122",
				"n": "连江县",
				"pc": "350100"
			}, {
				"c": "350123",
				"n": "罗源县",
				"pc": "350100"
			}, {
				"c": "350124",
				"n": "闽清县",
				"pc": "350100"
			}, {
				"c": "350125",
				"n": "永泰县",
				"pc": "350100"
			}, {
				"c": "350128",
				"n": "平潭县",
				"pc": "350100"
			}, {
				"c": "350181",
				"n": "福清市",
				"pc": "350100"
			}, {
				"c": "350182",
				"n": "长乐市",
				"pc": "350100"
			}, {
				"c": "350200",
				"n": "厦门市",
				"pc": "350000"
			}, {
				"c": "350201",
				"n": "市辖区",
				"pc": "350200"
			}, {
				"c": "350203",
				"n": "思明区",
				"pc": "350200"
			}, {
				"c": "350205",
				"n": "海沧区",
				"pc": "350200"
			}, {
				"c": "350206",
				"n": "湖里区",
				"pc": "350200"
			}, {
				"c": "350211",
				"n": "集美区",
				"pc": "350200"
			}, {
				"c": "350212",
				"n": "同安区",
				"pc": "350200"
			}, {
				"c": "350213",
				"n": "翔安区",
				"pc": "350200"
			}, {
				"c": "350300",
				"n": "莆田市",
				"pc": "350000"
			}, {
				"c": "350301",
				"n": "市辖区",
				"pc": "350300"
			}, {
				"c": "350302",
				"n": "城厢区",
				"pc": "350300"
			}, {
				"c": "350303",
				"n": "涵江区",
				"pc": "350300"
			}, {
				"c": "350304",
				"n": "荔城区",
				"pc": "350300"
			}, {
				"c": "350305",
				"n": "秀屿区",
				"pc": "350300"
			}, {
				"c": "350322",
				"n": "仙游县",
				"pc": "350300"
			}, {
				"c": "350400",
				"n": "三明市",
				"pc": "350000"
			}, {
				"c": "350401",
				"n": "市辖区",
				"pc": "350400"
			}, {
				"c": "350402",
				"n": "梅列区",
				"pc": "350400"
			}, {
				"c": "350403",
				"n": "三元区",
				"pc": "350400"
			}, {
				"c": "350421",
				"n": "明溪县",
				"pc": "350400"
			}, {
				"c": "350423",
				"n": "清流县",
				"pc": "350400"
			}, {
				"c": "350424",
				"n": "宁化县",
				"pc": "350400"
			}, {
				"c": "350425",
				"n": "大田县",
				"pc": "350400"
			}, {
				"c": "350426",
				"n": "尤溪县",
				"pc": "350400"
			}, {
				"c": "350427",
				"n": "沙县",
				"pc": "350400"
			}, {
				"c": "350428",
				"n": "将乐县",
				"pc": "350400"
			}, {
				"c": "350429",
				"n": "泰宁县",
				"pc": "350400"
			}, {
				"c": "350430",
				"n": "建宁县",
				"pc": "350400"
			}, {
				"c": "350481",
				"n": "永安市",
				"pc": "350400"
			}, {
				"c": "350500",
				"n": "泉州市",
				"pc": "350000"
			}, {
				"c": "350501",
				"n": "市辖区",
				"pc": "350500"
			}, {
				"c": "350502",
				"n": "鲤城区",
				"pc": "350500"
			}, {
				"c": "350503",
				"n": "丰泽区",
				"pc": "350500"
			}, {
				"c": "350504",
				"n": "洛江区",
				"pc": "350500"
			}, {
				"c": "350505",
				"n": "泉港区",
				"pc": "350500"
			}, {
				"c": "350521",
				"n": "惠安县",
				"pc": "350500"
			}, {
				"c": "350524",
				"n": "安溪县",
				"pc": "350500"
			}, {
				"c": "350525",
				"n": "永春县",
				"pc": "350500"
			}, {
				"c": "350526",
				"n": "德化县",
				"pc": "350500"
			}, {
				"c": "350527",
				"n": "金门县",
				"pc": "350500"
			}, {
				"c": "350581",
				"n": "石狮市",
				"pc": "350500"
			}, {
				"c": "350582",
				"n": "晋江市",
				"pc": "350500"
			}, {
				"c": "350583",
				"n": "南安市",
				"pc": "350500"
			}, {
				"c": "350600",
				"n": "漳州市",
				"pc": "350000"
			}, {
				"c": "350601",
				"n": "市辖区",
				"pc": "350600"
			}, {
				"c": "350602",
				"n": "芗城区",
				"pc": "350600"
			}, {
				"c": "350603",
				"n": "龙文区",
				"pc": "350600"
			}, {
				"c": "350622",
				"n": "云霄县",
				"pc": "350600"
			}, {
				"c": "350623",
				"n": "漳浦县",
				"pc": "350600"
			}, {
				"c": "350624",
				"n": "诏安县",
				"pc": "350600"
			}, {
				"c": "350625",
				"n": "长泰县",
				"pc": "350600"
			}, {
				"c": "350626",
				"n": "东山县",
				"pc": "350600"
			}, {
				"c": "350627",
				"n": "南靖县",
				"pc": "350600"
			}, {
				"c": "350628",
				"n": "平和县",
				"pc": "350600"
			}, {
				"c": "350629",
				"n": "华安县",
				"pc": "350600"
			}, {
				"c": "350681",
				"n": "龙海市",
				"pc": "350600"
			}, {
				"c": "350700",
				"n": "南平市",
				"pc": "350000"
			}, {
				"c": "350701",
				"n": "市辖区",
				"pc": "350700"
			}, {
				"c": "350702",
				"n": "延平区",
				"pc": "350700"
			}, {
				"c": "350721",
				"n": "顺昌县",
				"pc": "350700"
			}, {
				"c": "350722",
				"n": "浦城县",
				"pc": "350700"
			}, {
				"c": "350723",
				"n": "光泽县",
				"pc": "350700"
			}, {
				"c": "350724",
				"n": "松溪县",
				"pc": "350700"
			}, {
				"c": "350725",
				"n": "政和县",
				"pc": "350700"
			}, {
				"c": "350781",
				"n": "邵武市",
				"pc": "350700"
			}, {
				"c": "350782",
				"n": "武夷山市",
				"pc": "350700"
			}, {
				"c": "350783",
				"n": "建瓯市",
				"pc": "350700"
			}, {
				"c": "350784",
				"n": "建阳市",
				"pc": "350700"
			}, {
				"c": "350800",
				"n": "龙岩市",
				"pc": "350000"
			}, {
				"c": "350801",
				"n": "市辖区",
				"pc": "350800"
			}, {
				"c": "350802",
				"n": "新罗区",
				"pc": "350800"
			}, {
				"c": "350821",
				"n": "长汀县",
				"pc": "350800"
			}, {
				"c": "350822",
				"n": "永定县",
				"pc": "350800"
			}, {
				"c": "350823",
				"n": "上杭县",
				"pc": "350800"
			}, {
				"c": "350824",
				"n": "武平县",
				"pc": "350800"
			}, {
				"c": "350825",
				"n": "连城县",
				"pc": "350800"
			}, {
				"c": "350881",
				"n": "漳平市",
				"pc": "350800"
			}, {
				"c": "350900",
				"n": "宁德市",
				"pc": "350000"
			}, {
				"c": "350901",
				"n": "市辖区",
				"pc": "350900"
			}, {
				"c": "350902",
				"n": "蕉城区",
				"pc": "350900"
			}, {
				"c": "350921",
				"n": "霞浦县",
				"pc": "350900"
			}, {
				"c": "350922",
				"n": "古田县",
				"pc": "350900"
			}, {
				"c": "350923",
				"n": "屏南县",
				"pc": "350900"
			}, {
				"c": "350924",
				"n": "寿宁县",
				"pc": "350900"
			}, {
				"c": "350925",
				"n": "周宁县",
				"pc": "350900"
			}, {
				"c": "350926",
				"n": "柘荣县",
				"pc": "350900"
			}, {
				"c": "350981",
				"n": "福安市",
				"pc": "350900"
			}, {
				"c": "350982",
				"n": "福鼎市",
				"pc": "350900"
			}, {
				"c": "360000",
				"n": "江西省",
				"pc": "86"
			}, {
				"c": "360100",
				"n": "南昌市",
				"pc": "360000"
			}, {
				"c": "360101",
				"n": "市辖区",
				"pc": "360100"
			}, {
				"c": "360102",
				"n": "东湖区",
				"pc": "360100"
			}, {
				"c": "360103",
				"n": "西湖区",
				"pc": "360100"
			}, {
				"c": "360104",
				"n": "青云谱区",
				"pc": "360100"
			}, {
				"c": "360105",
				"n": "湾里区",
				"pc": "360100"
			}, {
				"c": "360111",
				"n": "青山湖区",
				"pc": "360100"
			}, {
				"c": "360121",
				"n": "南昌县",
				"pc": "360100"
			}, {
				"c": "360122",
				"n": "新建县",
				"pc": "360100"
			}, {
				"c": "360123",
				"n": "安义县",
				"pc": "360100"
			}, {
				"c": "360124",
				"n": "进贤县",
				"pc": "360100"
			}, {
				"c": "360200",
				"n": "景德镇市",
				"pc": "360000"
			}, {
				"c": "360201",
				"n": "市辖区",
				"pc": "360200"
			}, {
				"c": "360202",
				"n": "昌江区",
				"pc": "360200"
			}, {
				"c": "360203",
				"n": "珠山区",
				"pc": "360200"
			}, {
				"c": "360222",
				"n": "浮梁县",
				"pc": "360200"
			}, {
				"c": "360281",
				"n": "乐平市",
				"pc": "360200"
			}, {
				"c": "360300",
				"n": "萍乡市",
				"pc": "360000"
			}, {
				"c": "360301",
				"n": "市辖区",
				"pc": "360300"
			}, {
				"c": "360302",
				"n": "安源区",
				"pc": "360300"
			}, {
				"c": "360313",
				"n": "湘东区",
				"pc": "360300"
			}, {
				"c": "360321",
				"n": "莲花县",
				"pc": "360300"
			}, {
				"c": "360322",
				"n": "上栗县",
				"pc": "360300"
			}, {
				"c": "360323",
				"n": "芦溪县",
				"pc": "360300"
			}, {
				"c": "360400",
				"n": "九江市",
				"pc": "360000"
			}, {
				"c": "360401",
				"n": "市辖区",
				"pc": "360400"
			}, {
				"c": "360402",
				"n": "庐山区",
				"pc": "360400"
			}, {
				"c": "360403",
				"n": "浔阳区",
				"pc": "360400"
			}, {
				"c": "360421",
				"n": "九江县",
				"pc": "360400"
			}, {
				"c": "360423",
				"n": "武宁县",
				"pc": "360400"
			}, {
				"c": "360424",
				"n": "修水县",
				"pc": "360400"
			}, {
				"c": "360425",
				"n": "永修县",
				"pc": "360400"
			}, {
				"c": "360426",
				"n": "德安县",
				"pc": "360400"
			}, {
				"c": "360427",
				"n": "星子县",
				"pc": "360400"
			}, {
				"c": "360428",
				"n": "都昌县",
				"pc": "360400"
			}, {
				"c": "360429",
				"n": "湖口县",
				"pc": "360400"
			}, {
				"c": "360430",
				"n": "彭泽县",
				"pc": "360400"
			}, {
				"c": "360481",
				"n": "瑞昌市",
				"pc": "360400"
			}, {
				"c": "360482",
				"n": "共青城市",
				"pc": "360400"
			}, {
				"c": "360500",
				"n": "新余市",
				"pc": "360000"
			}, {
				"c": "360501",
				"n": "市辖区",
				"pc": "360500"
			}, {
				"c": "360502",
				"n": "渝水区",
				"pc": "360500"
			}, {
				"c": "360521",
				"n": "分宜县",
				"pc": "360500"
			}, {
				"c": "360600",
				"n": "鹰潭市",
				"pc": "360000"
			}, {
				"c": "360601",
				"n": "市辖区",
				"pc": "360600"
			}, {
				"c": "360602",
				"n": "月湖区",
				"pc": "360600"
			}, {
				"c": "360622",
				"n": "余江县",
				"pc": "360600"
			}, {
				"c": "360681",
				"n": "贵溪市",
				"pc": "360600"
			}, {
				"c": "360700",
				"n": "赣州市",
				"pc": "360000"
			}, {
				"c": "360701",
				"n": "市辖区",
				"pc": "360700"
			}, {
				"c": "360702",
				"n": "章贡区",
				"pc": "360700"
			}, {
				"c": "360721",
				"n": "赣县",
				"pc": "360700"
			}, {
				"c": "360722",
				"n": "信丰县",
				"pc": "360700"
			}, {
				"c": "360723",
				"n": "大余县",
				"pc": "360700"
			}, {
				"c": "360724",
				"n": "上犹县",
				"pc": "360700"
			}, {
				"c": "360725",
				"n": "崇义县",
				"pc": "360700"
			}, {
				"c": "360726",
				"n": "安远县",
				"pc": "360700"
			}, {
				"c": "360727",
				"n": "龙南县",
				"pc": "360700"
			}, {
				"c": "360728",
				"n": "定南县",
				"pc": "360700"
			}, {
				"c": "360729",
				"n": "全南县",
				"pc": "360700"
			}, {
				"c": "360730",
				"n": "宁都县",
				"pc": "360700"
			}, {
				"c": "360731",
				"n": "于都县",
				"pc": "360700"
			}, {
				"c": "360732",
				"n": "兴国县",
				"pc": "360700"
			}, {
				"c": "360733",
				"n": "会昌县",
				"pc": "360700"
			}, {
				"c": "360734",
				"n": "寻乌县",
				"pc": "360700"
			}, {
				"c": "360735",
				"n": "石城县",
				"pc": "360700"
			}, {
				"c": "360781",
				"n": "瑞金市",
				"pc": "360700"
			}, {
				"c": "360782",
				"n": "南康市",
				"pc": "360700"
			}, {
				"c": "360800",
				"n": "吉安市",
				"pc": "360000"
			}, {
				"c": "360801",
				"n": "市辖区",
				"pc": "360800"
			}, {
				"c": "360802",
				"n": "吉州区",
				"pc": "360800"
			}, {
				"c": "360803",
				"n": "青原区",
				"pc": "360800"
			}, {
				"c": "360821",
				"n": "吉安县",
				"pc": "360800"
			}, {
				"c": "360822",
				"n": "吉水县",
				"pc": "360800"
			}, {
				"c": "360823",
				"n": "峡江县",
				"pc": "360800"
			}, {
				"c": "360824",
				"n": "新干县",
				"pc": "360800"
			}, {
				"c": "360825",
				"n": "永丰县",
				"pc": "360800"
			}, {
				"c": "360826",
				"n": "泰和县",
				"pc": "360800"
			}, {
				"c": "360827",
				"n": "遂川县",
				"pc": "360800"
			}, {
				"c": "360828",
				"n": "万安县",
				"pc": "360800"
			}, {
				"c": "360829",
				"n": "安福县",
				"pc": "360800"
			}, {
				"c": "360830",
				"n": "永新县",
				"pc": "360800"
			}, {
				"c": "360881",
				"n": "井冈山市",
				"pc": "360800"
			}, {
				"c": "360900",
				"n": "宜春市",
				"pc": "360000"
			}, {
				"c": "360901",
				"n": "市辖区",
				"pc": "360900"
			}, {
				"c": "360902",
				"n": "袁州区",
				"pc": "360900"
			}, {
				"c": "360921",
				"n": "奉新县",
				"pc": "360900"
			}, {
				"c": "360922",
				"n": "万载县",
				"pc": "360900"
			}, {
				"c": "360923",
				"n": "上高县",
				"pc": "360900"
			}, {
				"c": "360924",
				"n": "宜丰县",
				"pc": "360900"
			}, {
				"c": "360925",
				"n": "靖安县",
				"pc": "360900"
			}, {
				"c": "360926",
				"n": "铜鼓县",
				"pc": "360900"
			}, {
				"c": "360981",
				"n": "丰城市",
				"pc": "360900"
			}, {
				"c": "360982",
				"n": "樟树市",
				"pc": "360900"
			}, {
				"c": "360983",
				"n": "高安市",
				"pc": "360900"
			}, {
				"c": "361000",
				"n": "抚州市",
				"pc": "360000"
			}, {
				"c": "361001",
				"n": "市辖区",
				"pc": "361000"
			}, {
				"c": "361002",
				"n": "临川区",
				"pc": "361000"
			}, {
				"c": "361021",
				"n": "南城县",
				"pc": "361000"
			}, {
				"c": "361022",
				"n": "黎川县",
				"pc": "361000"
			}, {
				"c": "361023",
				"n": "南丰县",
				"pc": "361000"
			}, {
				"c": "361024",
				"n": "崇仁县",
				"pc": "361000"
			}, {
				"c": "361025",
				"n": "乐安县",
				"pc": "361000"
			}, {
				"c": "361026",
				"n": "宜黄县",
				"pc": "361000"
			}, {
				"c": "361027",
				"n": "金溪县",
				"pc": "361000"
			}, {
				"c": "361028",
				"n": "资溪县",
				"pc": "361000"
			}, {
				"c": "361029",
				"n": "东乡县",
				"pc": "361000"
			}, {
				"c": "361030",
				"n": "广昌县",
				"pc": "361000"
			}, {
				"c": "361100",
				"n": "上饶市",
				"pc": "360000"
			}, {
				"c": "361101",
				"n": "市辖区",
				"pc": "361100"
			}, {
				"c": "361102",
				"n": "信州区",
				"pc": "361100"
			}, {
				"c": "361121",
				"n": "上饶县",
				"pc": "361100"
			}, {
				"c": "361122",
				"n": "广丰县",
				"pc": "361100"
			}, {
				"c": "361123",
				"n": "玉山县",
				"pc": "361100"
			}, {
				"c": "361124",
				"n": "铅山县",
				"pc": "361100"
			}, {
				"c": "361125",
				"n": "横峰县",
				"pc": "361100"
			}, {
				"c": "361126",
				"n": "弋阳县",
				"pc": "361100"
			}, {
				"c": "361127",
				"n": "余干县",
				"pc": "361100"
			}, {
				"c": "361128",
				"n": "鄱阳县",
				"pc": "361100"
			}, {
				"c": "361129",
				"n": "万年县",
				"pc": "361100"
			}, {
				"c": "361130",
				"n": "婺源县",
				"pc": "361100"
			}, {
				"c": "361181",
				"n": "德兴市",
				"pc": "361100"
			}, {
				"c": "370000",
				"n": "山东省",
				"pc": "86"
			}, {
				"c": "370100",
				"n": "济南市",
				"pc": "370000"
			}, {
				"c": "370101",
				"n": "市辖区",
				"pc": "370100"
			}, {
				"c": "370102",
				"n": "历下区",
				"pc": "370100"
			}, {
				"c": "370103",
				"n": "市中区",
				"pc": "370100"
			}, {
				"c": "370104",
				"n": "槐荫区",
				"pc": "370100"
			}, {
				"c": "370105",
				"n": "天桥区",
				"pc": "370100"
			}, {
				"c": "370112",
				"n": "历城区",
				"pc": "370100"
			}, {
				"c": "370113",
				"n": "长清区",
				"pc": "370100"
			}, {
				"c": "370124",
				"n": "平阴县",
				"pc": "370100"
			}, {
				"c": "370125",
				"n": "济阳县",
				"pc": "370100"
			}, {
				"c": "370126",
				"n": "商河县",
				"pc": "370100"
			}, {
				"c": "370181",
				"n": "章丘市",
				"pc": "370100"
			}, {
				"c": "370200",
				"n": "青岛市",
				"pc": "370000"
			}, {
				"c": "370201",
				"n": "市辖区",
				"pc": "370200"
			}, {
				"c": "370202",
				"n": "市南区",
				"pc": "370200"
			}, {
				"c": "370203",
				"n": "市北区",
				"pc": "370200"
			}, {
				"c": "370205",
				"n": "四方区",
				"pc": "370200"
			}, {
				"c": "370211",
				"n": "黄岛区",
				"pc": "370200"
			}, {
				"c": "370212",
				"n": "崂山区",
				"pc": "370200"
			}, {
				"c": "370213",
				"n": "李沧区",
				"pc": "370200"
			}, {
				"c": "370214",
				"n": "城阳区",
				"pc": "370200"
			}, {
				"c": "370281",
				"n": "胶州市",
				"pc": "370200"
			}, {
				"c": "370282",
				"n": "即墨市",
				"pc": "370200"
			}, {
				"c": "370283",
				"n": "平度市",
				"pc": "370200"
			}, {
				"c": "370284",
				"n": "胶南市",
				"pc": "370200"
			}, {
				"c": "370285",
				"n": "莱西市",
				"pc": "370200"
			}, {
				"c": "370300",
				"n": "淄博市",
				"pc": "370000"
			}, {
				"c": "370301",
				"n": "市辖区",
				"pc": "370300"
			}, {
				"c": "370302",
				"n": "淄川区",
				"pc": "370300"
			}, {
				"c": "370303",
				"n": "张店区",
				"pc": "370300"
			}, {
				"c": "370304",
				"n": "博山区",
				"pc": "370300"
			}, {
				"c": "370305",
				"n": "临淄区",
				"pc": "370300"
			}, {
				"c": "370306",
				"n": "周村区",
				"pc": "370300"
			}, {
				"c": "370321",
				"n": "桓台县",
				"pc": "370300"
			}, {
				"c": "370322",
				"n": "高青县",
				"pc": "370300"
			}, {
				"c": "370323",
				"n": "沂源县",
				"pc": "370300"
			}, {
				"c": "370400",
				"n": "枣庄市",
				"pc": "370000"
			}, {
				"c": "370401",
				"n": "市辖区",
				"pc": "370400"
			}, {
				"c": "370402",
				"n": "市中区",
				"pc": "370400"
			}, {
				"c": "370403",
				"n": "薛城区",
				"pc": "370400"
			}, {
				"c": "370404",
				"n": "峄城区",
				"pc": "370400"
			}, {
				"c": "370405",
				"n": "台儿庄区",
				"pc": "370400"
			}, {
				"c": "370406",
				"n": "山亭区",
				"pc": "370400"
			}, {
				"c": "370481",
				"n": "滕州市",
				"pc": "370400"
			}, {
				"c": "370500",
				"n": "东营市",
				"pc": "370000"
			}, {
				"c": "370501",
				"n": "市辖区",
				"pc": "370500"
			}, {
				"c": "370502",
				"n": "东营区",
				"pc": "370500"
			}, {
				"c": "370503",
				"n": "河口区",
				"pc": "370500"
			}, {
				"c": "370521",
				"n": "垦利县",
				"pc": "370500"
			}, {
				"c": "370522",
				"n": "利津县",
				"pc": "370500"
			}, {
				"c": "370523",
				"n": "广饶县",
				"pc": "370500"
			}, {
				"c": "370600",
				"n": "烟台市",
				"pc": "370000"
			}, {
				"c": "370601",
				"n": "市辖区",
				"pc": "370600"
			}, {
				"c": "370602",
				"n": "芝罘区",
				"pc": "370600"
			}, {
				"c": "370611",
				"n": "福山区",
				"pc": "370600"
			}, {
				"c": "370612",
				"n": "牟平区",
				"pc": "370600"
			}, {
				"c": "370613",
				"n": "莱山区",
				"pc": "370600"
			}, {
				"c": "370634",
				"n": "长岛县",
				"pc": "370600"
			}, {
				"c": "370681",
				"n": "龙口市",
				"pc": "370600"
			}, {
				"c": "370682",
				"n": "莱阳市",
				"pc": "370600"
			}, {
				"c": "370683",
				"n": "莱州市",
				"pc": "370600"
			}, {
				"c": "370684",
				"n": "蓬莱市",
				"pc": "370600"
			}, {
				"c": "370685",
				"n": "招远市",
				"pc": "370600"
			}, {
				"c": "3706",
				"n": "栖霞市",
				"pc": "370600"
			}, {
				"c": "370687",
				"n": "海阳市",
				"pc": "370600"
			}, {
				"c": "370700",
				"n": "潍坊市",
				"pc": "370000"
			}, {
				"c": "370701",
				"n": "市辖区",
				"pc": "370700"
			}, {
				"c": "370702",
				"n": "潍城区",
				"pc": "370700"
			}, {
				"c": "370703",
				"n": "寒亭区",
				"pc": "370700"
			}, {
				"c": "370704",
				"n": "坊子区",
				"pc": "370700"
			}, {
				"c": "370705",
				"n": "奎文区",
				"pc": "370700"
			}, {
				"c": "370724",
				"n": "临朐县",
				"pc": "370700"
			}, {
				"c": "370725",
				"n": "昌乐县",
				"pc": "370700"
			}, {
				"c": "370781",
				"n": "青州市",
				"pc": "370700"
			}, {
				"c": "370782",
				"n": "诸城市",
				"pc": "370700"
			}, {
				"c": "370783",
				"n": "寿光市",
				"pc": "370700"
			}, {
				"c": "370784",
				"n": "安丘市",
				"pc": "370700"
			}, {
				"c": "370785",
				"n": "高密市",
				"pc": "370700"
			}, {
				"c": "3707",
				"n": "昌邑市",
				"pc": "370700"
			}, {
				"c": "370800",
				"n": "济宁市",
				"pc": "370000"
			}, {
				"c": "370801",
				"n": "市辖区",
				"pc": "370800"
			}, {
				"c": "370802",
				"n": "市中区",
				"pc": "370800"
			}, {
				"c": "370811",
				"n": "任城区",
				"pc": "370800"
			}, {
				"c": "370826",
				"n": "微山县",
				"pc": "370800"
			}, {
				"c": "370827",
				"n": "鱼台县",
				"pc": "370800"
			}, {
				"c": "370828",
				"n": "金乡县",
				"pc": "370800"
			}, {
				"c": "370829",
				"n": "嘉祥县",
				"pc": "370800"
			}, {
				"c": "370830",
				"n": "汶上县",
				"pc": "370800"
			}, {
				"c": "370831",
				"n": "泗水县",
				"pc": "370800"
			}, {
				"c": "370832",
				"n": "梁山县",
				"pc": "370800"
			}, {
				"c": "370881",
				"n": "曲阜市",
				"pc": "370800"
			}, {
				"c": "370882",
				"n": "兖州市",
				"pc": "370800"
			}, {
				"c": "370883",
				"n": "邹城市",
				"pc": "370800"
			}, {
				"c": "370900",
				"n": "泰安市",
				"pc": "370000"
			}, {
				"c": "370901",
				"n": "市辖区",
				"pc": "370900"
			}, {
				"c": "370902",
				"n": "泰山区",
				"pc": "370900"
			}, {
				"c": "370911",
				"n": "岱岳区",
				"pc": "370900"
			}, {
				"c": "370921",
				"n": "宁阳县",
				"pc": "370900"
			}, {
				"c": "370923",
				"n": "东平县",
				"pc": "370900"
			}, {
				"c": "370982",
				"n": "新泰市",
				"pc": "370900"
			}, {
				"c": "370983",
				"n": "肥城市",
				"pc": "370900"
			}, {
				"c": "371000",
				"n": "威海市",
				"pc": "370000"
			}, {
				"c": "371001",
				"n": "市辖区",
				"pc": "371000"
			}, {
				"c": "371002",
				"n": "环翠区",
				"pc": "371000"
			}, {
				"c": "371081",
				"n": "文登市",
				"pc": "371000"
			}, {
				"c": "371082",
				"n": "荣成市",
				"pc": "371000"
			}, {
				"c": "371083",
				"n": "乳山市",
				"pc": "371000"
			}, {
				"c": "371100",
				"n": "日照市",
				"pc": "370000"
			}, {
				"c": "371101",
				"n": "市辖区",
				"pc": "371100"
			}, {
				"c": "371102",
				"n": "东港区",
				"pc": "371100"
			}, {
				"c": "371103",
				"n": "岚山区",
				"pc": "371100"
			}, {
				"c": "371121",
				"n": "五莲县",
				"pc": "371100"
			}, {
				"c": "371122",
				"n": "莒县",
				"pc": "371100"
			}, {
				"c": "371200",
				"n": "莱芜市",
				"pc": "370000"
			}, {
				"c": "371201",
				"n": "市辖区",
				"pc": "371200"
			}, {
				"c": "371202",
				"n": "莱城区",
				"pc": "371200"
			}, {
				"c": "371203",
				"n": "钢城区",
				"pc": "371200"
			}, {
				"c": "371300",
				"n": "临沂市",
				"pc": "370000"
			}, {
				"c": "371301",
				"n": "市辖区",
				"pc": "371300"
			}, {
				"c": "371302",
				"n": "兰山区",
				"pc": "371300"
			}, {
				"c": "371311",
				"n": "罗庄区",
				"pc": "371300"
			}, {
				"c": "371312",
				"n": "河东区",
				"pc": "371300"
			}, {
				"c": "371321",
				"n": "沂南县",
				"pc": "371300"
			}, {
				"c": "371322",
				"n": "郯城县",
				"pc": "371300"
			}, {
				"c": "371323",
				"n": "沂水县",
				"pc": "371300"
			}, {
				"c": "371324",
				"n": "苍山县",
				"pc": "371300"
			}, {
				"c": "371325",
				"n": "费县",
				"pc": "371300"
			}, {
				"c": "371326",
				"n": "平邑县",
				"pc": "371300"
			}, {
				"c": "371327",
				"n": "莒南县",
				"pc": "371300"
			}, {
				"c": "371328",
				"n": "蒙阴县",
				"pc": "371300"
			}, {
				"c": "371329",
				"n": "临沭县",
				"pc": "371300"
			}, {
				"c": "371400",
				"n": "德州市",
				"pc": "370000"
			}, {
				"c": "371401",
				"n": "市辖区",
				"pc": "371400"
			}, {
				"c": "371402",
				"n": "德城区",
				"pc": "371400"
			}, {
				"c": "371421",
				"n": "陵县",
				"pc": "371400"
			}, {
				"c": "371422",
				"n": "宁津县",
				"pc": "371400"
			}, {
				"c": "371423",
				"n": "庆云县",
				"pc": "371400"
			}, {
				"c": "371424",
				"n": "临邑县",
				"pc": "371400"
			}, {
				"c": "371425",
				"n": "齐河县",
				"pc": "371400"
			}, {
				"c": "371426",
				"n": "平原县",
				"pc": "371400"
			}, {
				"c": "371427",
				"n": "夏津县",
				"pc": "371400"
			}, {
				"c": "371428",
				"n": "武城县",
				"pc": "371400"
			}, {
				"c": "371481",
				"n": "乐陵市",
				"pc": "371400"
			}, {
				"c": "371482",
				"n": "禹城市",
				"pc": "371400"
			}, {
				"c": "371500",
				"n": "聊城市",
				"pc": "370000"
			}, {
				"c": "371501",
				"n": "市辖区",
				"pc": "371500"
			}, {
				"c": "371502",
				"n": "东昌府区",
				"pc": "371500"
			}, {
				"c": "371521",
				"n": "阳谷县",
				"pc": "371500"
			}, {
				"c": "371522",
				"n": "莘县",
				"pc": "371500"
			}, {
				"c": "371523",
				"n": "茌平县",
				"pc": "371500"
			}, {
				"c": "371524",
				"n": "东阿县",
				"pc": "371500"
			}, {
				"c": "371525",
				"n": "冠县",
				"pc": "371500"
			}, {
				"c": "371526",
				"n": "高唐县",
				"pc": "371500"
			}, {
				"c": "371581",
				"n": "临清市",
				"pc": "371500"
			}, {
				"c": "371600",
				"n": "滨州市",
				"pc": "370000"
			}, {
				"c": "371601",
				"n": "市辖区",
				"pc": "371600"
			}, {
				"c": "371602",
				"n": "滨城区",
				"pc": "371600"
			}, {
				"c": "371621",
				"n": "惠民县",
				"pc": "371600"
			}, {
				"c": "371622",
				"n": "阳信县",
				"pc": "371600"
			}, {
				"c": "371623",
				"n": "无棣县",
				"pc": "371600"
			}, {
				"c": "371624",
				"n": "沾化县",
				"pc": "371600"
			}, {
				"c": "371625",
				"n": "博兴县",
				"pc": "371600"
			}, {
				"c": "371626",
				"n": "邹平县",
				"pc": "371600"
			}, {
				"c": "371700",
				"n": "菏泽市",
				"pc": "370000"
			}, {
				"c": "371701",
				"n": "市辖区",
				"pc": "371700"
			}, {
				"c": "371702",
				"n": "牡丹区",
				"pc": "371700"
			}, {
				"c": "371721",
				"n": "曹县",
				"pc": "371700"
			}, {
				"c": "371722",
				"n": "单县",
				"pc": "371700"
			}, {
				"c": "371723",
				"n": "成武县",
				"pc": "371700"
			}, {
				"c": "371724",
				"n": "巨野县",
				"pc": "371700"
			}, {
				"c": "371725",
				"n": "郓城县",
				"pc": "371700"
			}, {
				"c": "371726",
				"n": "鄄城县",
				"pc": "371700"
			}, {
				"c": "371727",
				"n": "定陶县",
				"pc": "371700"
			}, {
				"c": "371728",
				"n": "东明县",
				"pc": "371700"
			}, {
				"c": "410000",
				"n": "河南省",
				"pc": "86"
			}, {
				"c": "410100",
				"n": "郑州市",
				"pc": "410000"
			}, {
				"c": "410101",
				"n": "市辖区",
				"pc": "410100"
			}, {
				"c": "410102",
				"n": "中原区",
				"pc": "410100"
			}, {
				"c": "410103",
				"n": "二七区",
				"pc": "410100"
			}, {
				"c": "410104",
				"n": "管城回族区",
				"pc": "410100"
			}, {
				"c": "410105",
				"n": "金水区",
				"pc": "410100"
			}, {
				"c": "410106",
				"n": "上街区",
				"pc": "410100"
			}, {
				"c": "410108",
				"n": "惠济区",
				"pc": "410100"
			}, {
				"c": "410122",
				"n": "中牟县",
				"pc": "410100"
			}, {
				"c": "410181",
				"n": "巩义市",
				"pc": "410100"
			}, {
				"c": "410182",
				"n": "荥阳市",
				"pc": "410100"
			}, {
				"c": "410183",
				"n": "新密市",
				"pc": "410100"
			}, {
				"c": "410184",
				"n": "新郑市",
				"pc": "410100"
			}, {
				"c": "410185",
				"n": "登封市",
				"pc": "410100"
			}, {
				"c": "410200",
				"n": "开封市",
				"pc": "410000"
			}, {
				"c": "410201",
				"n": "市辖区",
				"pc": "410200"
			}, {
				"c": "410202",
				"n": "龙亭区",
				"pc": "410200"
			}, {
				"c": "410203",
				"n": "顺河回族区",
				"pc": "410200"
			}, {
				"c": "410204",
				"n": "鼓楼区",
				"pc": "410200"
			}, {
				"c": "410205",
				"n": "禹王台区",
				"pc": "410200"
			}, {
				"c": "410211",
				"n": "金明区",
				"pc": "410200"
			}, {
				"c": "410221",
				"n": "杞县",
				"pc": "410200"
			}, {
				"c": "410222",
				"n": "通许县",
				"pc": "410200"
			}, {
				"c": "410223",
				"n": "尉氏县",
				"pc": "410200"
			}, {
				"c": "410224",
				"n": "开封县",
				"pc": "410200"
			}, {
				"c": "410225",
				"n": "兰考县",
				"pc": "410200"
			}, {
				"c": "410300",
				"n": "洛阳市",
				"pc": "410000"
			}, {
				"c": "410301",
				"n": "市辖区",
				"pc": "410300"
			}, {
				"c": "410302",
				"n": "老城区",
				"pc": "410300"
			}, {
				"c": "410303",
				"n": "西工区",
				"pc": "410300"
			}, {
				"c": "410304",
				"n": "瀍河回族区",
				"pc": "410300"
			}, {
				"c": "410305",
				"n": "涧西区",
				"pc": "410300"
			}, {
				"c": "410306",
				"n": "吉利区",
				"pc": "410300"
			}, {
				"c": "410311",
				"n": "洛龙区",
				"pc": "410300"
			}, {
				"c": "410322",
				"n": "孟津县",
				"pc": "410300"
			}, {
				"c": "410323",
				"n": "新安县",
				"pc": "410300"
			}, {
				"c": "410324",
				"n": "栾川县",
				"pc": "410300"
			}, {
				"c": "410325",
				"n": "嵩县",
				"pc": "410300"
			}, {
				"c": "410326",
				"n": "汝阳县",
				"pc": "410300"
			}, {
				"c": "410327",
				"n": "宜阳县",
				"pc": "410300"
			}, {
				"c": "410328",
				"n": "洛宁县",
				"pc": "410300"
			}, {
				"c": "410329",
				"n": "伊川县",
				"pc": "410300"
			}, {
				"c": "410381",
				"n": "偃师市",
				"pc": "410300"
			}, {
				"c": "410400",
				"n": "平顶山市",
				"pc": "410000"
			}, {
				"c": "410401",
				"n": "市辖区",
				"pc": "410400"
			}, {
				"c": "410402",
				"n": "新华区",
				"pc": "410400"
			}, {
				"c": "410403",
				"n": "卫东区",
				"pc": "410400"
			}, {
				"c": "410404",
				"n": "石龙区",
				"pc": "410400"
			}, {
				"c": "410411",
				"n": "湛河区",
				"pc": "410400"
			}, {
				"c": "410421",
				"n": "宝丰县",
				"pc": "410400"
			}, {
				"c": "410422",
				"n": "叶县",
				"pc": "410400"
			}, {
				"c": "410423",
				"n": "鲁山县",
				"pc": "410400"
			}, {
				"c": "410425",
				"n": "郏县",
				"pc": "410400"
			}, {
				"c": "410481",
				"n": "舞钢市",
				"pc": "410400"
			}, {
				"c": "410482",
				"n": "汝州市",
				"pc": "410400"
			}, {
				"c": "410500",
				"n": "安阳市",
				"pc": "410000"
			}, {
				"c": "410501",
				"n": "市辖区",
				"pc": "410500"
			}, {
				"c": "410502",
				"n": "文峰区",
				"pc": "410500"
			}, {
				"c": "410503",
				"n": "北关区",
				"pc": "410500"
			}, {
				"c": "410505",
				"n": "殷都区",
				"pc": "410500"
			}, {
				"c": "410506",
				"n": "龙安区",
				"pc": "410500"
			}, {
				"c": "410522",
				"n": "安阳县",
				"pc": "410500"
			}, {
				"c": "410523",
				"n": "汤阴县",
				"pc": "410500"
			}, {
				"c": "410526",
				"n": "滑县",
				"pc": "410500"
			}, {
				"c": "410527",
				"n": "内黄县",
				"pc": "410500"
			}, {
				"c": "410581",
				"n": "林州市",
				"pc": "410500"
			}, {
				"c": "410600",
				"n": "鹤壁市",
				"pc": "410000"
			}, {
				"c": "410601",
				"n": "市辖区",
				"pc": "410600"
			}, {
				"c": "410602",
				"n": "鹤山区",
				"pc": "410600"
			}, {
				"c": "410603",
				"n": "山城区",
				"pc": "410600"
			}, {
				"c": "410611",
				"n": "淇滨区",
				"pc": "410600"
			}, {
				"c": "410621",
				"n": "浚县",
				"pc": "410600"
			}, {
				"c": "410622",
				"n": "淇县",
				"pc": "410600"
			}, {
				"c": "410700",
				"n": "新乡市",
				"pc": "410000"
			}, {
				"c": "410701",
				"n": "市辖区",
				"pc": "410700"
			}, {
				"c": "410702",
				"n": "红旗区",
				"pc": "410700"
			}, {
				"c": "410703",
				"n": "卫滨区",
				"pc": "410700"
			}, {
				"c": "410704",
				"n": "凤泉区",
				"pc": "410700"
			}, {
				"c": "410711",
				"n": "牧野区",
				"pc": "410700"
			}, {
				"c": "410721",
				"n": "新乡县",
				"pc": "410700"
			}, {
				"c": "410724",
				"n": "获嘉县",
				"pc": "410700"
			}, {
				"c": "410725",
				"n": "原阳县",
				"pc": "410700"
			}, {
				"c": "410726",
				"n": "延津县",
				"pc": "410700"
			}, {
				"c": "410727",
				"n": "封丘县",
				"pc": "410700"
			}, {
				"c": "410728",
				"n": "长垣县",
				"pc": "410700"
			}, {
				"c": "410781",
				"n": "卫辉市",
				"pc": "410700"
			}, {
				"c": "410782",
				"n": "辉县市",
				"pc": "410700"
			}, {
				"c": "410800",
				"n": "焦作市",
				"pc": "410000"
			}, {
				"c": "410801",
				"n": "市辖区",
				"pc": "410800"
			}, {
				"c": "410802",
				"n": "解放区",
				"pc": "410800"
			}, {
				"c": "410803",
				"n": "中站区",
				"pc": "410800"
			}, {
				"c": "410804",
				"n": "马村区",
				"pc": "410800"
			}, {
				"c": "410811",
				"n": "山阳区",
				"pc": "410800"
			}, {
				"c": "410821",
				"n": "修武县",
				"pc": "410800"
			}, {
				"c": "410822",
				"n": "博爱县",
				"pc": "410800"
			}, {
				"c": "410823",
				"n": "武陟县",
				"pc": "410800"
			}, {
				"c": "410825",
				"n": "温县",
				"pc": "410800"
			}, {
				"c": "410882",
				"n": "沁阳市",
				"pc": "410800"
			}, {
				"c": "410883",
				"n": "孟州市",
				"pc": "410800"
			}, {
				"c": "410900",
				"n": "濮阳市",
				"pc": "410000"
			}, {
				"c": "410901",
				"n": "市辖区",
				"pc": "410900"
			}, {
				"c": "410902",
				"n": "华龙区",
				"pc": "410900"
			}, {
				"c": "410922",
				"n": "清丰县",
				"pc": "410900"
			}, {
				"c": "410923",
				"n": "南乐县",
				"pc": "410900"
			}, {
				"c": "410926",
				"n": "范县",
				"pc": "410900"
			}, {
				"c": "410927",
				"n": "台前县",
				"pc": "410900"
			}, {
				"c": "410928",
				"n": "濮阳县",
				"pc": "410900"
			}, {
				"c": "411000",
				"n": "许昌市",
				"pc": "410000"
			}, {
				"c": "411001",
				"n": "市辖区",
				"pc": "411000"
			}, {
				"c": "411002",
				"n": "魏都区",
				"pc": "411000"
			}, {
				"c": "411023",
				"n": "许昌县",
				"pc": "411000"
			}, {
				"c": "411024",
				"n": "鄢陵县",
				"pc": "411000"
			}, {
				"c": "411025",
				"n": "襄城县",
				"pc": "411000"
			}, {
				"c": "411081",
				"n": "禹州市",
				"pc": "411000"
			}, {
				"c": "411082",
				"n": "长葛市",
				"pc": "411000"
			}, {
				"c": "411100",
				"n": "漯河市",
				"pc": "410000"
			}, {
				"c": "411101",
				"n": "市辖区",
				"pc": "411100"
			}, {
				"c": "411102",
				"n": "源汇区",
				"pc": "411100"
			}, {
				"c": "411103",
				"n": "郾城区",
				"pc": "411100"
			}, {
				"c": "411104",
				"n": "召陵区",
				"pc": "411100"
			}, {
				"c": "411121",
				"n": "舞阳县",
				"pc": "411100"
			}, {
				"c": "411122",
				"n": "临颍县",
				"pc": "411100"
			}, {
				"c": "411200",
				"n": "三门峡市",
				"pc": "410000"
			}, {
				"c": "411201",
				"n": "市辖区",
				"pc": "411200"
			}, {
				"c": "411202",
				"n": "湖滨区",
				"pc": "411200"
			}, {
				"c": "411221",
				"n": "渑池县",
				"pc": "411200"
			}, {
				"c": "411222",
				"n": "陕县",
				"pc": "411200"
			}, {
				"c": "411224",
				"n": "卢氏县",
				"pc": "411200"
			}, {
				"c": "411281",
				"n": "义马市",
				"pc": "411200"
			}, {
				"c": "411282",
				"n": "灵宝市",
				"pc": "411200"
			}, {
				"c": "411300",
				"n": "南阳市",
				"pc": "410000"
			}, {
				"c": "411301",
				"n": "市辖区",
				"pc": "411300"
			}, {
				"c": "411302",
				"n": "宛城区",
				"pc": "411300"
			}, {
				"c": "411303",
				"n": "卧龙区",
				"pc": "411300"
			}, {
				"c": "411321",
				"n": "南召县",
				"pc": "411300"
			}, {
				"c": "411322",
				"n": "方城县",
				"pc": "411300"
			}, {
				"c": "411323",
				"n": "西峡县",
				"pc": "411300"
			}, {
				"c": "411324",
				"n": "镇平县",
				"pc": "411300"
			}, {
				"c": "411325",
				"n": "内乡县",
				"pc": "411300"
			}, {
				"c": "411326",
				"n": "淅川县",
				"pc": "411300"
			}, {
				"c": "411327",
				"n": "社旗县",
				"pc": "411300"
			}, {
				"c": "411328",
				"n": "唐河县",
				"pc": "411300"
			}, {
				"c": "411329",
				"n": "新野县",
				"pc": "411300"
			}, {
				"c": "411330",
				"n": "桐柏县",
				"pc": "411300"
			}, {
				"c": "411381",
				"n": "邓州市",
				"pc": "411300"
			}, {
				"c": "411400",
				"n": "商丘市",
				"pc": "410000"
			}, {
				"c": "411401",
				"n": "市辖区",
				"pc": "411400"
			}, {
				"c": "411402",
				"n": "梁园区",
				"pc": "411400"
			}, {
				"c": "411403",
				"n": "睢阳区",
				"pc": "411400"
			}, {
				"c": "411421",
				"n": "民权县",
				"pc": "411400"
			}, {
				"c": "411422",
				"n": "睢县",
				"pc": "411400"
			}, {
				"c": "411423",
				"n": "宁陵县",
				"pc": "411400"
			}, {
				"c": "411424",
				"n": "柘城县",
				"pc": "411400"
			}, {
				"c": "411425",
				"n": "虞城县",
				"pc": "411400"
			}, {
				"c": "411426",
				"n": "夏邑县",
				"pc": "411400"
			}, {
				"c": "411481",
				"n": "永城市",
				"pc": "411400"
			}, {
				"c": "411500",
				"n": "信阳市",
				"pc": "410000"
			}, {
				"c": "411501",
				"n": "市辖区",
				"pc": "411500"
			}, {
				"c": "411502",
				"n": "浉河区",
				"pc": "411500"
			}, {
				"c": "411503",
				"n": "平桥区",
				"pc": "411500"
			}, {
				"c": "411521",
				"n": "罗山县",
				"pc": "411500"
			}, {
				"c": "411522",
				"n": "光山县",
				"pc": "411500"
			}, {
				"c": "411523",
				"n": "新县",
				"pc": "411500"
			}, {
				"c": "411524",
				"n": "商城县",
				"pc": "411500"
			}, {
				"c": "411525",
				"n": "固始县",
				"pc": "411500"
			}, {
				"c": "411526",
				"n": "潢川县",
				"pc": "411500"
			}, {
				"c": "411527",
				"n": "淮滨县",
				"pc": "411500"
			}, {
				"c": "411528",
				"n": "息县",
				"pc": "411500"
			}, {
				"c": "411600",
				"n": "周口市",
				"pc": "410000"
			}, {
				"c": "411601",
				"n": "市辖区",
				"pc": "411600"
			}, {
				"c": "411602",
				"n": "川汇区",
				"pc": "411600"
			}, {
				"c": "411621",
				"n": "扶沟县",
				"pc": "411600"
			}, {
				"c": "411622",
				"n": "西华县",
				"pc": "411600"
			}, {
				"c": "411623",
				"n": "商水县",
				"pc": "411600"
			}, {
				"c": "411624",
				"n": "沈丘县",
				"pc": "411600"
			}, {
				"c": "411625",
				"n": "郸城县",
				"pc": "411600"
			}, {
				"c": "411626",
				"n": "淮阳县",
				"pc": "411600"
			}, {
				"c": "411627",
				"n": "太康县",
				"pc": "411600"
			}, {
				"c": "411628",
				"n": "鹿邑县",
				"pc": "411600"
			}, {
				"c": "411681",
				"n": "项城市",
				"pc": "411600"
			}, {
				"c": "411700",
				"n": "驻马店市",
				"pc": "410000"
			}, {
				"c": "411701",
				"n": "市辖区",
				"pc": "411700"
			}, {
				"c": "411702",
				"n": "驿城区",
				"pc": "411700"
			}, {
				"c": "411721",
				"n": "西平县",
				"pc": "411700"
			}, {
				"c": "411722",
				"n": "上蔡县",
				"pc": "411700"
			}, {
				"c": "411723",
				"n": "平舆县",
				"pc": "411700"
			}, {
				"c": "411724",
				"n": "正阳县",
				"pc": "411700"
			}, {
				"c": "411725",
				"n": "确山县",
				"pc": "411700"
			}, {
				"c": "411726",
				"n": "泌阳县",
				"pc": "411700"
			}, {
				"c": "411727",
				"n": "汝南县",
				"pc": "411700"
			}, {
				"c": "411728",
				"n": "遂平县",
				"pc": "411700"
			}, {
				"c": "411729",
				"n": "新蔡县",
				"pc": "411700"
			}, {
				"c": "419000",
				"n": "省直辖县级行政区划",
				"pc": "410000"
			}, {
				"c": "419001",
				"n": "济源市",
				"pc": "419000"
			}, {
				"c": "420000",
				"n": "湖北省",
				"pc": "86"
			}, {
				"c": "420100",
				"n": "武汉市",
				"pc": "420000"
			}, {
				"c": "420101",
				"n": "市辖区",
				"pc": "420100"
			}, {
				"c": "420102",
				"n": "江岸区",
				"pc": "420100"
			}, {
				"c": "420103",
				"n": "江汉区",
				"pc": "420100"
			}, {
				"c": "420104",
				"n": "硚口区",
				"pc": "420100"
			}, {
				"c": "420105",
				"n": "汉阳区",
				"pc": "420100"
			}, {
				"c": "420106",
				"n": "武昌区",
				"pc": "420100"
			}, {
				"c": "420107",
				"n": "青山区",
				"pc": "420100"
			}, {
				"c": "420111",
				"n": "洪山区",
				"pc": "420100"
			}, {
				"c": "420112",
				"n": "东西湖区",
				"pc": "420100"
			}, {
				"c": "420113",
				"n": "汉南区",
				"pc": "420100"
			}, {
				"c": "420114",
				"n": "蔡甸区",
				"pc": "420100"
			}, {
				"c": "420115",
				"n": "江夏区",
				"pc": "420100"
			}, {
				"c": "420116",
				"n": "黄陂区",
				"pc": "420100"
			}, {
				"c": "420117",
				"n": "新洲区",
				"pc": "420100"
			}, {
				"c": "420200",
				"n": "黄石市",
				"pc": "420000"
			}, {
				"c": "420201",
				"n": "市辖区",
				"pc": "420200"
			}, {
				"c": "420202",
				"n": "黄石港区",
				"pc": "420200"
			}, {
				"c": "420203",
				"n": "西塞山区",
				"pc": "420200"
			}, {
				"c": "420204",
				"n": "下陆区",
				"pc": "420200"
			}, {
				"c": "420205",
				"n": "铁山区",
				"pc": "420200"
			}, {
				"c": "420222",
				"n": "阳新县",
				"pc": "420200"
			}, {
				"c": "420281",
				"n": "大冶市",
				"pc": "420200"
			}, {
				"c": "420300",
				"n": "十堰市",
				"pc": "420000"
			}, {
				"c": "420301",
				"n": "市辖区",
				"pc": "420300"
			}, {
				"c": "420302",
				"n": "茅箭区",
				"pc": "420300"
			}, {
				"c": "420303",
				"n": "张湾区",
				"pc": "420300"
			}, {
				"c": "420321",
				"n": "郧县",
				"pc": "420300"
			}, {
				"c": "420322",
				"n": "郧西县",
				"pc": "420300"
			}, {
				"c": "420323",
				"n": "竹山县",
				"pc": "420300"
			}, {
				"c": "420324",
				"n": "竹溪县",
				"pc": "420300"
			}, {
				"c": "420325",
				"n": "房县",
				"pc": "420300"
			}, {
				"c": "420381",
				"n": "丹江口市",
				"pc": "420300"
			}, {
				"c": "420500",
				"n": "宜昌市",
				"pc": "420000"
			}, {
				"c": "420501",
				"n": "市辖区",
				"pc": "420500"
			}, {
				"c": "420502",
				"n": "西陵区",
				"pc": "420500"
			}, {
				"c": "420503",
				"n": "伍家岗区",
				"pc": "420500"
			}, {
				"c": "420504",
				"n": "点军区",
				"pc": "420500"
			}, {
				"c": "420505",
				"n": "猇亭区",
				"pc": "420500"
			}, {
				"c": "420506",
				"n": "夷陵区",
				"pc": "420500"
			}, {
				"c": "420525",
				"n": "远安县",
				"pc": "420500"
			}, {
				"c": "420526",
				"n": "兴山县",
				"pc": "420500"
			}, {
				"c": "420527",
				"n": "秭归县",
				"pc": "420500"
			}, {
				"c": "420528",
				"n": "长阳土家族自治县",
				"pc": "420500"
			}, {
				"c": "420529",
				"n": "五峰土家族自治县",
				"pc": "420500"
			}, {
				"c": "420581",
				"n": "宜都市",
				"pc": "420500"
			}, {
				"c": "420582",
				"n": "当阳市",
				"pc": "420500"
			}, {
				"c": "420583",
				"n": "枝江市",
				"pc": "420500"
			}, {
				"c": "420600",
				"n": "襄阳市",
				"pc": "420000"
			}, {
				"c": "420601",
				"n": "市辖区",
				"pc": "420600"
			}, {
				"c": "420602",
				"n": "襄城区",
				"pc": "420600"
			}, {
				"c": "420606",
				"n": "樊城区",
				"pc": "420600"
			}, {
				"c": "420607",
				"n": "襄州区",
				"pc": "420600"
			}, {
				"c": "420624",
				"n": "南漳县",
				"pc": "420600"
			}, {
				"c": "420625",
				"n": "谷城县",
				"pc": "420600"
			}, {
				"c": "420626",
				"n": "保康县",
				"pc": "420600"
			}, {
				"c": "420682",
				"n": "老河口市",
				"pc": "420600"
			}, {
				"c": "420683",
				"n": "枣阳市",
				"pc": "420600"
			}, {
				"c": "420684",
				"n": "宜城市",
				"pc": "420600"
			}, {
				"c": "420700",
				"n": "鄂州市",
				"pc": "420000"
			}, {
				"c": "420701",
				"n": "市辖区",
				"pc": "420700"
			}, {
				"c": "420702",
				"n": "梁子湖区",
				"pc": "420700"
			}, {
				"c": "420703",
				"n": "华容区",
				"pc": "420700"
			}, {
				"c": "420704",
				"n": "鄂城区",
				"pc": "420700"
			}, {
				"c": "420800",
				"n": "荆门市",
				"pc": "420000"
			}, {
				"c": "420801",
				"n": "市辖区",
				"pc": "420800"
			}, {
				"c": "420802",
				"n": "东宝区",
				"pc": "420800"
			}, {
				"c": "420804",
				"n": "掇刀区",
				"pc": "420800"
			}, {
				"c": "420821",
				"n": "京山县",
				"pc": "420800"
			}, {
				"c": "420822",
				"n": "沙洋县",
				"pc": "420800"
			}, {
				"c": "420881",
				"n": "钟祥市",
				"pc": "420800"
			}, {
				"c": "420900",
				"n": "孝感市",
				"pc": "420000"
			}, {
				"c": "420901",
				"n": "市辖区",
				"pc": "420900"
			}, {
				"c": "420902",
				"n": "孝南区",
				"pc": "420900"
			}, {
				"c": "420921",
				"n": "孝昌县",
				"pc": "420900"
			}, {
				"c": "420922",
				"n": "大悟县",
				"pc": "420900"
			}, {
				"c": "420923",
				"n": "云梦县",
				"pc": "420900"
			}, {
				"c": "420981",
				"n": "应城市",
				"pc": "420900"
			}, {
				"c": "420982",
				"n": "安陆市",
				"pc": "420900"
			}, {
				"c": "420984",
				"n": "汉川市",
				"pc": "420900"
			}, {
				"c": "421000",
				"n": "荆州市",
				"pc": "420000"
			}, {
				"c": "421001",
				"n": "市辖区",
				"pc": "421000"
			}, {
				"c": "421002",
				"n": "沙市区",
				"pc": "421000"
			}, {
				"c": "421003",
				"n": "荆州区",
				"pc": "421000"
			}, {
				"c": "421022",
				"n": "公安县",
				"pc": "421000"
			}, {
				"c": "421023",
				"n": "监利县",
				"pc": "421000"
			}, {
				"c": "421024",
				"n": "江陵县",
				"pc": "421000"
			}, {
				"c": "421081",
				"n": "石首市",
				"pc": "421000"
			}, {
				"c": "421083",
				"n": "洪湖市",
				"pc": "421000"
			}, {
				"c": "421087",
				"n": "松滋市",
				"pc": "421000"
			}, {
				"c": "421100",
				"n": "黄冈市",
				"pc": "420000"
			}, {
				"c": "421101",
				"n": "市辖区",
				"pc": "421100"
			}, {
				"c": "421102",
				"n": "黄州区",
				"pc": "421100"
			}, {
				"c": "421121",
				"n": "团风县",
				"pc": "421100"
			}, {
				"c": "421122",
				"n": "红安县",
				"pc": "421100"
			}, {
				"c": "421123",
				"n": "罗田县",
				"pc": "421100"
			}, {
				"c": "421124",
				"n": "英山县",
				"pc": "421100"
			}, {
				"c": "421125",
				"n": "浠水县",
				"pc": "421100"
			}, {
				"c": "421126",
				"n": "蕲春县",
				"pc": "421100"
			}, {
				"c": "421127",
				"n": "黄梅县",
				"pc": "421100"
			}, {
				"c": "421181",
				"n": "麻城市",
				"pc": "421100"
			}, {
				"c": "421182",
				"n": "武穴市",
				"pc": "421100"
			}, {
				"c": "421200",
				"n": "咸宁市",
				"pc": "420000"
			}, {
				"c": "421201",
				"n": "市辖区",
				"pc": "421200"
			}, {
				"c": "421202",
				"n": "咸安区",
				"pc": "421200"
			}, {
				"c": "421221",
				"n": "嘉鱼县",
				"pc": "421200"
			}, {
				"c": "421222",
				"n": "通城县",
				"pc": "421200"
			}, {
				"c": "421223",
				"n": "崇阳县",
				"pc": "421200"
			}, {
				"c": "421224",
				"n": "通山县",
				"pc": "421200"
			}, {
				"c": "421281",
				"n": "赤壁市",
				"pc": "421200"
			}, {
				"c": "421300",
				"n": "随州市",
				"pc": "420000"
			}, {
				"c": "421301",
				"n": "市辖区",
				"pc": "421300"
			}, {
				"c": "421303",
				"n": "曾都区",
				"pc": "421300"
			}, {
				"c": "421321",
				"n": "随县",
				"pc": "421300"
			}, {
				"c": "421381",
				"n": "广水市",
				"pc": "421300"
			}, {
				"c": "422800",
				"n": "恩施土家族苗族自治州",
				"pc": "420000"
			}, {
				"c": "422801",
				"n": "恩施市",
				"pc": "422800"
			}, {
				"c": "422802",
				"n": "利川市",
				"pc": "422800"
			}, {
				"c": "422822",
				"n": "建始县",
				"pc": "422800"
			}, {
				"c": "422823",
				"n": "巴东县",
				"pc": "422800"
			}, {
				"c": "422825",
				"n": "宣恩县",
				"pc": "422800"
			}, {
				"c": "422826",
				"n": "咸丰县",
				"pc": "422800"
			}, {
				"c": "422827",
				"n": "来凤县",
				"pc": "422800"
			}, {
				"c": "422828",
				"n": "鹤峰县",
				"pc": "422800"
			}, {
				"c": "429000",
				"n": "省直辖县级行政区划",
				"pc": "420000"
			}, {
				"c": "429004",
				"n": "仙桃市",
				"pc": "429000"
			}, {
				"c": "429005",
				"n": "潜江市",
				"pc": "429000"
			}, {
				"c": "429006",
				"n": "天门市",
				"pc": "429000"
			}, {
				"c": "429021",
				"n": "神农架林区",
				"pc": "429000"
			}, {
				"c": "430000",
				"n": "湖南省",
				"pc": "86"
			}, {
				"c": "430100",
				"n": "长沙市",
				"pc": "430000"
			}, {
				"c": "430101",
				"n": "市辖区",
				"pc": "430100"
			}, {
				"c": "430102",
				"n": "芙蓉区",
				"pc": "430100"
			}, {
				"c": "430103",
				"n": "天心区",
				"pc": "430100"
			}, {
				"c": "430104",
				"n": "岳麓区",
				"pc": "430100"
			}, {
				"c": "430105",
				"n": "开福区",
				"pc": "430100"
			}, {
				"c": "430111",
				"n": "雨花区",
				"pc": "430100"
			}, {
				"c": "430112",
				"n": "望城区",
				"pc": "430100"
			}, {
				"c": "430121",
				"n": "长沙县",
				"pc": "430100"
			}, {
				"c": "430124",
				"n": "宁乡县",
				"pc": "430100"
			}, {
				"c": "430181",
				"n": "浏阳市",
				"pc": "430100"
			}, {
				"c": "430200",
				"n": "株洲市",
				"pc": "430000"
			}, {
				"c": "430201",
				"n": "市辖区",
				"pc": "430200"
			}, {
				"c": "430202",
				"n": "荷塘区",
				"pc": "430200"
			}, {
				"c": "430203",
				"n": "芦淞区",
				"pc": "430200"
			}, {
				"c": "430204",
				"n": "石峰区",
				"pc": "430200"
			}, {
				"c": "430211",
				"n": "天元区",
				"pc": "430200"
			}, {
				"c": "430221",
				"n": "株洲县",
				"pc": "430200"
			}, {
				"c": "430223",
				"n": "攸县",
				"pc": "430200"
			}, {
				"c": "430224",
				"n": "茶陵县",
				"pc": "430200"
			}, {
				"c": "430225",
				"n": "炎陵县",
				"pc": "430200"
			}, {
				"c": "430281",
				"n": "醴陵市",
				"pc": "430200"
			}, {
				"c": "430300",
				"n": "湘潭市",
				"pc": "430000"
			}, {
				"c": "430301",
				"n": "市辖区",
				"pc": "430300"
			}, {
				"c": "430302",
				"n": "雨湖区",
				"pc": "430300"
			}, {
				"c": "430304",
				"n": "岳塘区",
				"pc": "430300"
			}, {
				"c": "430321",
				"n": "湘潭县",
				"pc": "430300"
			}, {
				"c": "430381",
				"n": "湘乡市",
				"pc": "430300"
			}, {
				"c": "430382",
				"n": "韶山市",
				"pc": "430300"
			}, {
				"c": "430400",
				"n": "衡阳市",
				"pc": "430000"
			}, {
				"c": "430401",
				"n": "市辖区",
				"pc": "430400"
			}, {
				"c": "430405",
				"n": "珠晖区",
				"pc": "430400"
			}, {
				"c": "430406",
				"n": "雁峰区",
				"pc": "430400"
			}, {
				"c": "430407",
				"n": "石鼓区",
				"pc": "430400"
			}, {
				"c": "430408",
				"n": "蒸湘区",
				"pc": "430400"
			}, {
				"c": "430412",
				"n": "南岳区",
				"pc": "430400"
			}, {
				"c": "430421",
				"n": "衡阳县",
				"pc": "430400"
			}, {
				"c": "430422",
				"n": "衡南县",
				"pc": "430400"
			}, {
				"c": "430423",
				"n": "衡山县",
				"pc": "430400"
			}, {
				"c": "430424",
				"n": "衡东县",
				"pc": "430400"
			}, {
				"c": "430426",
				"n": "祁东县",
				"pc": "430400"
			}, {
				"c": "430481",
				"n": "耒阳市",
				"pc": "430400"
			}, {
				"c": "430482",
				"n": "常宁市",
				"pc": "430400"
			}, {
				"c": "430500",
				"n": "邵阳市",
				"pc": "430000"
			}, {
				"c": "430501",
				"n": "市辖区",
				"pc": "430500"
			}, {
				"c": "430502",
				"n": "双清区",
				"pc": "430500"
			}, {
				"c": "430503",
				"n": "大祥区",
				"pc": "430500"
			}, {
				"c": "430511",
				"n": "北塔区",
				"pc": "430500"
			}, {
				"c": "430521",
				"n": "邵东县",
				"pc": "430500"
			}, {
				"c": "430522",
				"n": "新邵县",
				"pc": "430500"
			}, {
				"c": "430523",
				"n": "邵阳县",
				"pc": "430500"
			}, {
				"c": "430524",
				"n": "隆回县",
				"pc": "430500"
			}, {
				"c": "430525",
				"n": "洞口县",
				"pc": "430500"
			}, {
				"c": "430527",
				"n": "绥宁县",
				"pc": "430500"
			}, {
				"c": "430528",
				"n": "新宁县",
				"pc": "430500"
			}, {
				"c": "430529",
				"n": "城步苗族自治县",
				"pc": "430500"
			}, {
				"c": "430581",
				"n": "武冈市",
				"pc": "430500"
			}, {
				"c": "430600",
				"n": "岳阳市",
				"pc": "430000"
			}, {
				"c": "430601",
				"n": "市辖区",
				"pc": "430600"
			}, {
				"c": "430602",
				"n": "岳阳楼区",
				"pc": "430600"
			}, {
				"c": "430603",
				"n": "云溪区",
				"pc": "430600"
			}, {
				"c": "430611",
				"n": "君山区",
				"pc": "430600"
			}, {
				"c": "430621",
				"n": "岳阳县",
				"pc": "430600"
			}, {
				"c": "430623",
				"n": "华容县",
				"pc": "430600"
			}, {
				"c": "430624",
				"n": "湘阴县",
				"pc": "430600"
			}, {
				"c": "430626",
				"n": "平江县",
				"pc": "430600"
			}, {
				"c": "430681",
				"n": "汨罗市",
				"pc": "430600"
			}, {
				"c": "430682",
				"n": "临湘市",
				"pc": "430600"
			}, {
				"c": "430700",
				"n": "常德市",
				"pc": "430000"
			}, {
				"c": "430701",
				"n": "市辖区",
				"pc": "430700"
			}, {
				"c": "430702",
				"n": "武陵区",
				"pc": "430700"
			}, {
				"c": "430703",
				"n": "鼎城区",
				"pc": "430700"
			}, {
				"c": "430721",
				"n": "安乡县",
				"pc": "430700"
			}, {
				"c": "430722",
				"n": "汉寿县",
				"pc": "430700"
			}, {
				"c": "430723",
				"n": "澧县",
				"pc": "430700"
			}, {
				"c": "430724",
				"n": "临澧县",
				"pc": "430700"
			}, {
				"c": "430725",
				"n": "桃源县",
				"pc": "430700"
			}, {
				"c": "430726",
				"n": "石门县",
				"pc": "430700"
			}, {
				"c": "430781",
				"n": "津市市",
				"pc": "430700"
			}, {
				"c": "430800",
				"n": "张家界市",
				"pc": "430000"
			}, {
				"c": "430801",
				"n": "市辖区",
				"pc": "430800"
			}, {
				"c": "430802",
				"n": "永定区",
				"pc": "430800"
			}, {
				"c": "430811",
				"n": "武陵源区",
				"pc": "430800"
			}, {
				"c": "430821",
				"n": "慈利县",
				"pc": "430800"
			}, {
				"c": "430822",
				"n": "桑植县",
				"pc": "430800"
			}, {
				"c": "430900",
				"n": "益阳市",
				"pc": "430000"
			}, {
				"c": "430901",
				"n": "市辖区",
				"pc": "430900"
			}, {
				"c": "430902",
				"n": "资阳区",
				"pc": "430900"
			}, {
				"c": "430903",
				"n": "赫山区",
				"pc": "430900"
			}, {
				"c": "430921",
				"n": "南县",
				"pc": "430900"
			}, {
				"c": "430922",
				"n": "桃江县",
				"pc": "430900"
			}, {
				"c": "430923",
				"n": "安化县",
				"pc": "430900"
			}, {
				"c": "430981",
				"n": "沅江市",
				"pc": "430900"
			}, {
				"c": "431000",
				"n": "郴州市",
				"pc": "430000"
			}, {
				"c": "431001",
				"n": "市辖区",
				"pc": "431000"
			}, {
				"c": "431002",
				"n": "北湖区",
				"pc": "431000"
			}, {
				"c": "431003",
				"n": "苏仙区",
				"pc": "431000"
			}, {
				"c": "431021",
				"n": "桂阳县",
				"pc": "431000"
			}, {
				"c": "431022",
				"n": "宜章县",
				"pc": "431000"
			}, {
				"c": "431023",
				"n": "永兴县",
				"pc": "431000"
			}, {
				"c": "431024",
				"n": "嘉禾县",
				"pc": "431000"
			}, {
				"c": "431025",
				"n": "临武县",
				"pc": "431000"
			}, {
				"c": "431026",
				"n": "汝城县",
				"pc": "431000"
			}, {
				"c": "431027",
				"n": "桂东县",
				"pc": "431000"
			}, {
				"c": "431028",
				"n": "安仁县",
				"pc": "431000"
			}, {
				"c": "431081",
				"n": "资兴市",
				"pc": "431000"
			}, {
				"c": "431100",
				"n": "永州市",
				"pc": "430000"
			}, {
				"c": "431101",
				"n": "市辖区",
				"pc": "431100"
			}, {
				"c": "431102",
				"n": "零陵区",
				"pc": "431100"
			}, {
				"c": "431103",
				"n": "冷水滩区",
				"pc": "431100"
			}, {
				"c": "431121",
				"n": "祁阳县",
				"pc": "431100"
			}, {
				"c": "431122",
				"n": "东安县",
				"pc": "431100"
			}, {
				"c": "431123",
				"n": "双牌县",
				"pc": "431100"
			}, {
				"c": "431124",
				"n": "道县",
				"pc": "431100"
			}, {
				"c": "431125",
				"n": "江永县",
				"pc": "431100"
			}, {
				"c": "431126",
				"n": "宁远县",
				"pc": "431100"
			}, {
				"c": "431127",
				"n": "蓝山县",
				"pc": "431100"
			}, {
				"c": "431128",
				"n": "新田县",
				"pc": "431100"
			}, {
				"c": "431129",
				"n": "江华瑶族自治县",
				"pc": "431100"
			}, {
				"c": "431200",
				"n": "怀化市",
				"pc": "430000"
			}, {
				"c": "431201",
				"n": "市辖区",
				"pc": "431200"
			}, {
				"c": "431202",
				"n": "鹤城区",
				"pc": "431200"
			}, {
				"c": "431221",
				"n": "中方县",
				"pc": "431200"
			}, {
				"c": "431222",
				"n": "沅陵县",
				"pc": "431200"
			}, {
				"c": "431223",
				"n": "辰溪县",
				"pc": "431200"
			}, {
				"c": "431224",
				"n": "溆浦县",
				"pc": "431200"
			}, {
				"c": "431225",
				"n": "会同县",
				"pc": "431200"
			}, {
				"c": "431226",
				"n": "麻阳苗族自治县",
				"pc": "431200"
			}, {
				"c": "431227",
				"n": "新晃侗族自治县",
				"pc": "431200"
			}, {
				"c": "431228",
				"n": "芷江侗族自治县",
				"pc": "431200"
			}, {
				"c": "431229",
				"n": "靖州苗族侗族自治县",
				"pc": "431200"
			}, {
				"c": "431230",
				"n": "通道侗族自治县",
				"pc": "431200"
			}, {
				"c": "431281",
				"n": "洪江市",
				"pc": "431200"
			}, {
				"c": "431300",
				"n": "娄底市",
				"pc": "430000"
			}, {
				"c": "431301",
				"n": "市辖区",
				"pc": "431300"
			}, {
				"c": "431302",
				"n": "娄星区",
				"pc": "431300"
			}, {
				"c": "431321",
				"n": "双峰县",
				"pc": "431300"
			}, {
				"c": "431322",
				"n": "新化县",
				"pc": "431300"
			}, {
				"c": "431381",
				"n": "冷水江市",
				"pc": "431300"
			}, {
				"c": "431382",
				"n": "涟源市",
				"pc": "431300"
			}, {
				"c": "433100",
				"n": "湘西土家族苗族自治州",
				"pc": "430000"
			}, {
				"c": "433101",
				"n": "吉首市",
				"pc": "433100"
			}, {
				"c": "433122",
				"n": "泸溪县",
				"pc": "433100"
			}, {
				"c": "433123",
				"n": "凤凰县",
				"pc": "433100"
			}, {
				"c": "433124",
				"n": "花垣县",
				"pc": "433100"
			}, {
				"c": "433125",
				"n": "保靖县",
				"pc": "433100"
			}, {
				"c": "433126",
				"n": "古丈县",
				"pc": "433100"
			}, {
				"c": "433127",
				"n": "永顺县",
				"pc": "433100"
			}, {
				"c": "433130",
				"n": "龙山县",
				"pc": "433100"
			}, {
				"c": "440000",
				"n": "广东省",
				"pc": "86"
			}, {
				"c": "440100",
				"n": "广州市",
				"pc": "440000"
			}, {
				"c": "440101",
				"n": "市辖区",
				"pc": "440100"
			}, {
				"c": "440103",
				"n": "荔湾区",
				"pc": "440100"
			}, {
				"c": "440104",
				"n": "越秀区",
				"pc": "440100"
			}, {
				"c": "440105",
				"n": "海珠区",
				"pc": "440100"
			}, {
				"c": "440106",
				"n": "天河区",
				"pc": "440100"
			}, {
				"c": "440111",
				"n": "白云区",
				"pc": "440100"
			}, {
				"c": "440112",
				"n": "黄埔区",
				"pc": "440100"
			}, {
				"c": "440113",
				"n": "番禺区",
				"pc": "440100"
			}, {
				"c": "440114",
				"n": "花都区",
				"pc": "440100"
			}, {
				"c": "440115",
				"n": "南沙区",
				"pc": "440100"
			}, {
				"c": "440116",
				"n": "萝岗区",
				"pc": "440100"
			}, {
				"c": "440183",
				"n": "增城市",
				"pc": "440100"
			}, {
				"c": "440184",
				"n": "从化市",
				"pc": "440100"
			}, {
				"c": "440200",
				"n": "韶关市",
				"pc": "440000"
			}, {
				"c": "440201",
				"n": "市辖区",
				"pc": "440200"
			}, {
				"c": "440203",
				"n": "武江区",
				"pc": "440200"
			}, {
				"c": "440204",
				"n": "浈江区",
				"pc": "440200"
			}, {
				"c": "440205",
				"n": "曲江区",
				"pc": "440200"
			}, {
				"c": "440222",
				"n": "始兴县",
				"pc": "440200"
			}, {
				"c": "440224",
				"n": "仁化县",
				"pc": "440200"
			}, {
				"c": "440229",
				"n": "翁源县",
				"pc": "440200"
			}, {
				"c": "440232",
				"n": "乳源瑶族自治县",
				"pc": "440200"
			}, {
				"c": "440233",
				"n": "新丰县",
				"pc": "440200"
			}, {
				"c": "440281",
				"n": "乐昌市",
				"pc": "440200"
			}, {
				"c": "440282",
				"n": "南雄市",
				"pc": "440200"
			}, {
				"c": "440300",
				"n": "深圳市",
				"pc": "440000"
			}, {
				"c": "440301",
				"n": "市辖区",
				"pc": "440300"
			}, {
				"c": "440303",
				"n": "罗湖区",
				"pc": "440300"
			}, {
				"c": "440304",
				"n": "福田区",
				"pc": "440300"
			}, {
				"c": "440305",
				"n": "南山区",
				"pc": "440300"
			}, {
				"c": "440306",
				"n": "宝安区",
				"pc": "440300"
			}, {
				"c": "440307",
				"n": "龙岗区",
				"pc": "440300"
			}, {
				"c": "440308",
				"n": "盐田区",
				"pc": "440300"
			}, {
				"c": "440400",
				"n": "珠海市",
				"pc": "440000"
			}, {
				"c": "440401",
				"n": "市辖区",
				"pc": "440400"
			}, {
				"c": "440402",
				"n": "香洲区",
				"pc": "440400"
			}, {
				"c": "440403",
				"n": "斗门区",
				"pc": "440400"
			}, {
				"c": "440404",
				"n": "金湾区",
				"pc": "440400"
			}, {
				"c": "440500",
				"n": "汕头市",
				"pc": "440000"
			}, {
				"c": "440501",
				"n": "市辖区",
				"pc": "440500"
			}, {
				"c": "440507",
				"n": "龙湖区",
				"pc": "440500"
			}, {
				"c": "440511",
				"n": "金平区",
				"pc": "440500"
			}, {
				"c": "440512",
				"n": "濠江区",
				"pc": "440500"
			}, {
				"c": "440513",
				"n": "潮阳区",
				"pc": "440500"
			}, {
				"c": "440514",
				"n": "潮南区",
				"pc": "440500"
			}, {
				"c": "440515",
				"n": "澄海区",
				"pc": "440500"
			}, {
				"c": "440523",
				"n": "南澳县",
				"pc": "440500"
			}, {
				"c": "440600",
				"n": "佛山市",
				"pc": "440000"
			}, {
				"c": "440601",
				"n": "市辖区",
				"pc": "440600"
			}, {
				"c": "440604",
				"n": "禅城区",
				"pc": "440600"
			}, {
				"c": "440605",
				"n": "南海区",
				"pc": "440600"
			}, {
				"c": "440606",
				"n": "顺德区",
				"pc": "440600"
			}, {
				"c": "440607",
				"n": "三水区",
				"pc": "440600"
			}, {
				"c": "440608",
				"n": "高明区",
				"pc": "440600"
			}, {
				"c": "440700",
				"n": "江门市",
				"pc": "440000"
			}, {
				"c": "440701",
				"n": "市辖区",
				"pc": "440700"
			}, {
				"c": "440703",
				"n": "蓬江区",
				"pc": "440700"
			}, {
				"c": "440704",
				"n": "江海区",
				"pc": "440700"
			}, {
				"c": "440705",
				"n": "新会区",
				"pc": "440700"
			}, {
				"c": "440781",
				"n": "台山市",
				"pc": "440700"
			}, {
				"c": "440783",
				"n": "开平市",
				"pc": "440700"
			}, {
				"c": "440784",
				"n": "鹤山市",
				"pc": "440700"
			}, {
				"c": "440785",
				"n": "恩平市",
				"pc": "440700"
			}, {
				"c": "440800",
				"n": "湛江市",
				"pc": "440000"
			}, {
				"c": "440801",
				"n": "市辖区",
				"pc": "440800"
			}, {
				"c": "440802",
				"n": "赤坎区",
				"pc": "440800"
			}, {
				"c": "440803",
				"n": "霞山区",
				"pc": "440800"
			}, {
				"c": "440804",
				"n": "坡头区",
				"pc": "440800"
			}, {
				"c": "440811",
				"n": "麻章区",
				"pc": "440800"
			}, {
				"c": "440823",
				"n": "遂溪县",
				"pc": "440800"
			}, {
				"c": "440825",
				"n": "徐闻县",
				"pc": "440800"
			}, {
				"c": "440881",
				"n": "廉江市",
				"pc": "440800"
			}, {
				"c": "440882",
				"n": "雷州市",
				"pc": "440800"
			}, {
				"c": "440883",
				"n": "吴川市",
				"pc": "440800"
			}, {
				"c": "440900",
				"n": "茂名市",
				"pc": "440000"
			}, {
				"c": "440901",
				"n": "市辖区",
				"pc": "440900"
			}, {
				"c": "440902",
				"n": "茂南区",
				"pc": "440900"
			}, {
				"c": "440903",
				"n": "茂港区",
				"pc": "440900"
			}, {
				"c": "440923",
				"n": "电白县",
				"pc": "440900"
			}, {
				"c": "440981",
				"n": "高州市",
				"pc": "440900"
			}, {
				"c": "440982",
				"n": "化州市",
				"pc": "440900"
			}, {
				"c": "440983",
				"n": "信宜市",
				"pc": "440900"
			}, {
				"c": "441200",
				"n": "肇庆市",
				"pc": "440000"
			}, {
				"c": "441201",
				"n": "市辖区",
				"pc": "441200"
			}, {
				"c": "441202",
				"n": "端州区",
				"pc": "441200"
			}, {
				"c": "441203",
				"n": "鼎湖区",
				"pc": "441200"
			}, {
				"c": "441223",
				"n": "广宁县",
				"pc": "441200"
			}, {
				"c": "441224",
				"n": "怀集县",
				"pc": "441200"
			}, {
				"c": "441225",
				"n": "封开县",
				"pc": "441200"
			}, {
				"c": "441226",
				"n": "德庆县",
				"pc": "441200"
			}, {
				"c": "441283",
				"n": "高要市",
				"pc": "441200"
			}, {
				"c": "441284",
				"n": "四会市",
				"pc": "441200"
			}, {
				"c": "441300",
				"n": "惠州市",
				"pc": "440000"
			}, {
				"c": "441301",
				"n": "市辖区",
				"pc": "441300"
			}, {
				"c": "441302",
				"n": "惠城区",
				"pc": "441300"
			}, {
				"c": "441303",
				"n": "惠阳区",
				"pc": "441300"
			}, {
				"c": "441322",
				"n": "博罗县",
				"pc": "441300"
			}, {
				"c": "441323",
				"n": "惠东县",
				"pc": "441300"
			}, {
				"c": "441324",
				"n": "龙门县",
				"pc": "441300"
			}, {
				"c": "441400",
				"n": "梅州市",
				"pc": "440000"
			}, {
				"c": "441401",
				"n": "市辖区",
				"pc": "441400"
			}, {
				"c": "441402",
				"n": "梅江区",
				"pc": "441400"
			}, {
				"c": "441421",
				"n": "梅县",
				"pc": "441400"
			}, {
				"c": "441422",
				"n": "大埔县",
				"pc": "441400"
			}, {
				"c": "441423",
				"n": "丰顺县",
				"pc": "441400"
			}, {
				"c": "441424",
				"n": "五华县",
				"pc": "441400"
			}, {
				"c": "441426",
				"n": "平远县",
				"pc": "441400"
			}, {
				"c": "441427",
				"n": "蕉岭县",
				"pc": "441400"
			}, {
				"c": "441481",
				"n": "兴宁市",
				"pc": "441400"
			}, {
				"c": "441500",
				"n": "汕尾市",
				"pc": "440000"
			}, {
				"c": "441501",
				"n": "市辖区",
				"pc": "441500"
			}, {
				"c": "441502",
				"n": "城区",
				"pc": "441500"
			}, {
				"c": "441521",
				"n": "海丰县",
				"pc": "441500"
			}, {
				"c": "441523",
				"n": "陆河县",
				"pc": "441500"
			}, {
				"c": "441581",
				"n": "陆丰市",
				"pc": "441500"
			}, {
				"c": "441600",
				"n": "河源市",
				"pc": "440000"
			}, {
				"c": "441601",
				"n": "市辖区",
				"pc": "441600"
			}, {
				"c": "441602",
				"n": "源城区",
				"pc": "441600"
			}, {
				"c": "441621",
				"n": "紫金县",
				"pc": "441600"
			}, {
				"c": "441622",
				"n": "龙川县",
				"pc": "441600"
			}, {
				"c": "441623",
				"n": "连平县",
				"pc": "441600"
			}, {
				"c": "441624",
				"n": "和平县",
				"pc": "441600"
			}, {
				"c": "441625",
				"n": "东源县",
				"pc": "441600"
			}, {
				"c": "441700",
				"n": "阳江市",
				"pc": "440000"
			}, {
				"c": "441701",
				"n": "市辖区",
				"pc": "441700"
			}, {
				"c": "441702",
				"n": "江城区",
				"pc": "441700"
			}, {
				"c": "441721",
				"n": "阳西县",
				"pc": "441700"
			}, {
				"c": "441723",
				"n": "阳东县",
				"pc": "441700"
			}, {
				"c": "441781",
				"n": "阳春市",
				"pc": "441700"
			}, {
				"c": "441800",
				"n": "清远市",
				"pc": "440000"
			}, {
				"c": "441801",
				"n": "市辖区",
				"pc": "441800"
			}, {
				"c": "441802",
				"n": "清城区",
				"pc": "441800"
			}, {
				"c": "441821",
				"n": "佛冈县",
				"pc": "441800"
			}, {
				"c": "441823",
				"n": "阳山县",
				"pc": "441800"
			}, {
				"c": "441825",
				"n": "连山壮族瑶族自治县",
				"pc": "441800"
			}, {
				"c": "441826",
				"n": "连南瑶族自治县",
				"pc": "441800"
			}, {
				"c": "441827",
				"n": "清新县",
				"pc": "441800"
			}, {
				"c": "441881",
				"n": "英德市",
				"pc": "441800"
			}, {
				"c": "441882",
				"n": "连州市",
				"pc": "441800"
			}, {
				"c": "441900",
				"n": "东莞市",
				"pc": "440000"
			}, {
				"c": "442000",
				"n": "中山市",
				"pc": "440000"
			}, {
				"c": "445100",
				"n": "潮州市",
				"pc": "440000"
			}, {
				"c": "445101",
				"n": "市辖区",
				"pc": "445100"
			}, {
				"c": "445102",
				"n": "湘桥区",
				"pc": "445100"
			}, {
				"c": "445121",
				"n": "潮安县",
				"pc": "445100"
			}, {
				"c": "445122",
				"n": "饶平县",
				"pc": "445100"
			}, {
				"c": "445200",
				"n": "揭阳市",
				"pc": "440000"
			}, {
				"c": "445201",
				"n": "市辖区",
				"pc": "445200"
			}, {
				"c": "445202",
				"n": "榕城区",
				"pc": "445200"
			}, {
				"c": "445221",
				"n": "揭东县",
				"pc": "445200"
			}, {
				"c": "445222",
				"n": "揭西县",
				"pc": "445200"
			}, {
				"c": "445224",
				"n": "惠来县",
				"pc": "445200"
			}, {
				"c": "445281",
				"n": "普宁市",
				"pc": "445200"
			}, {
				"c": "445300",
				"n": "云浮市",
				"pc": "440000"
			}, {
				"c": "445301",
				"n": "市辖区",
				"pc": "445300"
			}, {
				"c": "445302",
				"n": "云城区",
				"pc": "445300"
			}, {
				"c": "445321",
				"n": "新兴县",
				"pc": "445300"
			}, {
				"c": "445322",
				"n": "郁南县",
				"pc": "445300"
			}, {
				"c": "445323",
				"n": "云安县",
				"pc": "445300"
			}, {
				"c": "445381",
				"n": "罗定市",
				"pc": "445300"
			}, {
				"c": "450000",
				"n": "广西壮族自治区",
				"pc": "86"
			}, {
				"c": "450100",
				"n": "南宁市",
				"pc": "450000"
			}, {
				"c": "450101",
				"n": "市辖区",
				"pc": "450100"
			}, {
				"c": "450102",
				"n": "兴宁区",
				"pc": "450100"
			}, {
				"c": "450103",
				"n": "青秀区",
				"pc": "450100"
			}, {
				"c": "450105",
				"n": "江南区",
				"pc": "450100"
			}, {
				"c": "450107",
				"n": "西乡塘区",
				"pc": "450100"
			}, {
				"c": "450108",
				"n": "良庆区",
				"pc": "450100"
			}, {
				"c": "450109",
				"n": "邕宁区",
				"pc": "450100"
			}, {
				"c": "450122",
				"n": "武鸣县",
				"pc": "450100"
			}, {
				"c": "450123",
				"n": "隆安县",
				"pc": "450100"
			}, {
				"c": "450124",
				"n": "马山县",
				"pc": "450100"
			}, {
				"c": "450125",
				"n": "上林县",
				"pc": "450100"
			}, {
				"c": "450126",
				"n": "宾阳县",
				"pc": "450100"
			}, {
				"c": "450127",
				"n": "横县",
				"pc": "450100"
			}, {
				"c": "450200",
				"n": "柳州市",
				"pc": "450000"
			}, {
				"c": "450201",
				"n": "市辖区",
				"pc": "450200"
			}, {
				"c": "450202",
				"n": "城中区",
				"pc": "450200"
			}, {
				"c": "450203",
				"n": "鱼峰区",
				"pc": "450200"
			}, {
				"c": "450204",
				"n": "柳南区",
				"pc": "450200"
			}, {
				"c": "450205",
				"n": "柳北区",
				"pc": "450200"
			}, {
				"c": "450221",
				"n": "柳江县",
				"pc": "450200"
			}, {
				"c": "450222",
				"n": "柳城县",
				"pc": "450200"
			}, {
				"c": "450223",
				"n": "鹿寨县",
				"pc": "450200"
			}, {
				"c": "450224",
				"n": "融安县",
				"pc": "450200"
			}, {
				"c": "450225",
				"n": "融水苗族自治县",
				"pc": "450200"
			}, {
				"c": "450226",
				"n": "三江侗族自治县",
				"pc": "450200"
			}, {
				"c": "450300",
				"n": "桂林市",
				"pc": "450000"
			}, {
				"c": "450301",
				"n": "市辖区",
				"pc": "450300"
			}, {
				"c": "450302",
				"n": "秀峰区",
				"pc": "450300"
			}, {
				"c": "450303",
				"n": "叠彩区",
				"pc": "450300"
			}, {
				"c": "450304",
				"n": "象山区",
				"pc": "450300"
			}, {
				"c": "450305",
				"n": "七星区",
				"pc": "450300"
			}, {
				"c": "450311",
				"n": "雁山区",
				"pc": "450300"
			}, {
				"c": "450321",
				"n": "阳朔县",
				"pc": "450300"
			}, {
				"c": "450322",
				"n": "临桂县",
				"pc": "450300"
			}, {
				"c": "450323",
				"n": "灵川县",
				"pc": "450300"
			}, {
				"c": "450324",
				"n": "全州县",
				"pc": "450300"
			}, {
				"c": "450325",
				"n": "兴安县",
				"pc": "450300"
			}, {
				"c": "450326",
				"n": "永福县",
				"pc": "450300"
			}, {
				"c": "450327",
				"n": "灌阳县",
				"pc": "450300"
			}, {
				"c": "450328",
				"n": "龙胜各族自治县",
				"pc": "450300"
			}, {
				"c": "450329",
				"n": "资源县",
				"pc": "450300"
			}, {
				"c": "450330",
				"n": "平乐县",
				"pc": "450300"
			}, {
				"c": "450331",
				"n": "荔浦县",
				"pc": "450300"
			}, {
				"c": "450332",
				"n": "恭城瑶族自治县",
				"pc": "450300"
			}, {
				"c": "450400",
				"n": "梧州市",
				"pc": "450000"
			}, {
				"c": "450401",
				"n": "市辖区",
				"pc": "450400"
			}, {
				"c": "450403",
				"n": "万秀区",
				"pc": "450400"
			}, {
				"c": "450404",
				"n": "蝶山区",
				"pc": "450400"
			}, {
				"c": "450405",
				"n": "长洲区",
				"pc": "450400"
			}, {
				"c": "450421",
				"n": "苍梧县",
				"pc": "450400"
			}, {
				"c": "450422",
				"n": "藤县",
				"pc": "450400"
			}, {
				"c": "450423",
				"n": "蒙山县",
				"pc": "450400"
			}, {
				"c": "450481",
				"n": "岑溪市",
				"pc": "450400"
			}, {
				"c": "450500",
				"n": "北海市",
				"pc": "450000"
			}, {
				"c": "450501",
				"n": "市辖区",
				"pc": "450500"
			}, {
				"c": "450502",
				"n": "海城区",
				"pc": "450500"
			}, {
				"c": "450503",
				"n": "银海区",
				"pc": "450500"
			}, {
				"c": "450512",
				"n": "铁山港区",
				"pc": "450500"
			}, {
				"c": "450521",
				"n": "合浦县",
				"pc": "450500"
			}, {
				"c": "450600",
				"n": "防城港市",
				"pc": "450000"
			}, {
				"c": "450601",
				"n": "市辖区",
				"pc": "450600"
			}, {
				"c": "450602",
				"n": "港口区",
				"pc": "450600"
			}, {
				"c": "450603",
				"n": "防城区",
				"pc": "450600"
			}, {
				"c": "450621",
				"n": "上思县",
				"pc": "450600"
			}, {
				"c": "450681",
				"n": "东兴市",
				"pc": "450600"
			}, {
				"c": "450700",
				"n": "钦州市",
				"pc": "450000"
			}, {
				"c": "450701",
				"n": "市辖区",
				"pc": "450700"
			}, {
				"c": "450702",
				"n": "钦南区",
				"pc": "450700"
			}, {
				"c": "450703",
				"n": "钦北区",
				"pc": "450700"
			}, {
				"c": "450721",
				"n": "灵山县",
				"pc": "450700"
			}, {
				"c": "450722",
				"n": "浦北县",
				"pc": "450700"
			}, {
				"c": "450800",
				"n": "贵港市",
				"pc": "450000"
			}, {
				"c": "450801",
				"n": "市辖区",
				"pc": "450800"
			}, {
				"c": "450802",
				"n": "港北区",
				"pc": "450800"
			}, {
				"c": "450803",
				"n": "港南区",
				"pc": "450800"
			}, {
				"c": "450804",
				"n": "覃塘区",
				"pc": "450800"
			}, {
				"c": "450821",
				"n": "平南县",
				"pc": "450800"
			}, {
				"c": "450881",
				"n": "桂平市",
				"pc": "450800"
			}, {
				"c": "450900",
				"n": "玉林市",
				"pc": "450000"
			}, {
				"c": "450901",
				"n": "市辖区",
				"pc": "450900"
			}, {
				"c": "450902",
				"n": "玉州区",
				"pc": "450900"
			}, {
				"c": "450921",
				"n": "容县",
				"pc": "450900"
			}, {
				"c": "450922",
				"n": "陆川县",
				"pc": "450900"
			}, {
				"c": "450923",
				"n": "博白县",
				"pc": "450900"
			}, {
				"c": "450924",
				"n": "兴业县",
				"pc": "450900"
			}, {
				"c": "450981",
				"n": "北流市",
				"pc": "450900"
			}, {
				"c": "451000",
				"n": "百色市",
				"pc": "450000"
			}, {
				"c": "451001",
				"n": "市辖区",
				"pc": "451000"
			}, {
				"c": "451002",
				"n": "右江区",
				"pc": "451000"
			}, {
				"c": "451021",
				"n": "田阳县",
				"pc": "451000"
			}, {
				"c": "451022",
				"n": "田东县",
				"pc": "451000"
			}, {
				"c": "451023",
				"n": "平果县",
				"pc": "451000"
			}, {
				"c": "451024",
				"n": "德保县",
				"pc": "451000"
			}, {
				"c": "451025",
				"n": "靖西县",
				"pc": "451000"
			}, {
				"c": "451026",
				"n": "那坡县",
				"pc": "451000"
			}, {
				"c": "451027",
				"n": "凌云县",
				"pc": "451000"
			}, {
				"c": "451028",
				"n": "乐业县",
				"pc": "451000"
			}, {
				"c": "451029",
				"n": "田林县",
				"pc": "451000"
			}, {
				"c": "451030",
				"n": "西林县",
				"pc": "451000"
			}, {
				"c": "451031",
				"n": "隆林各族自治县",
				"pc": "451000"
			}, {
				"c": "451100",
				"n": "贺州市",
				"pc": "450000"
			}, {
				"c": "451101",
				"n": "市辖区",
				"pc": "451100"
			}, {
				"c": "451102",
				"n": "八步区",
				"pc": "451100"
			}, {
				"c": "451121",
				"n": "昭平县",
				"pc": "451100"
			}, {
				"c": "451122",
				"n": "钟山县",
				"pc": "451100"
			}, {
				"c": "451123",
				"n": "富川瑶族自治县",
				"pc": "451100"
			}, {
				"c": "451200",
				"n": "河池市",
				"pc": "450000"
			}, {
				"c": "451201",
				"n": "市辖区",
				"pc": "451200"
			}, {
				"c": "451202",
				"n": "金城江区",
				"pc": "451200"
			}, {
				"c": "451221",
				"n": "南丹县",
				"pc": "451200"
			}, {
				"c": "451222",
				"n": "天峨县",
				"pc": "451200"
			}, {
				"c": "451223",
				"n": "凤山县",
				"pc": "451200"
			}, {
				"c": "451224",
				"n": "东兰县",
				"pc": "451200"
			}, {
				"c": "451225",
				"n": "罗城仫佬族自治县",
				"pc": "451200"
			}, {
				"c": "451226",
				"n": "环江毛南族自治县",
				"pc": "451200"
			}, {
				"c": "451227",
				"n": "巴马瑶族自治县",
				"pc": "451200"
			}, {
				"c": "451228",
				"n": "都安瑶族自治县",
				"pc": "451200"
			}, {
				"c": "451229",
				"n": "大化瑶族自治县",
				"pc": "451200"
			}, {
				"c": "451281",
				"n": "宜州市",
				"pc": "451200"
			}, {
				"c": "451300",
				"n": "来宾市",
				"pc": "450000"
			}, {
				"c": "451301",
				"n": "市辖区",
				"pc": "451300"
			}, {
				"c": "451302",
				"n": "兴宾区",
				"pc": "451300"
			}, {
				"c": "451321",
				"n": "忻城县",
				"pc": "451300"
			}, {
				"c": "451322",
				"n": "象州县",
				"pc": "451300"
			}, {
				"c": "451323",
				"n": "武宣县",
				"pc": "451300"
			}, {
				"c": "451324",
				"n": "金秀瑶族自治县",
				"pc": "451300"
			}, {
				"c": "451381",
				"n": "合山市",
				"pc": "451300"
			}, {
				"c": "451400",
				"n": "崇左市",
				"pc": "450000"
			}, {
				"c": "451401",
				"n": "市辖区",
				"pc": "451400"
			}, {
				"c": "451402",
				"n": "江洲区",
				"pc": "451400"
			}, {
				"c": "451421",
				"n": "扶绥县",
				"pc": "451400"
			}, {
				"c": "451422",
				"n": "宁明县",
				"pc": "451400"
			}, {
				"c": "451423",
				"n": "龙州县",
				"pc": "451400"
			}, {
				"c": "451424",
				"n": "大新县",
				"pc": "451400"
			}, {
				"c": "451425",
				"n": "天等县",
				"pc": "451400"
			}, {
				"c": "451481",
				"n": "凭祥市",
				"pc": "451400"
			}, {
				"c": "460000",
				"n": "海南省",
				"pc": "86"
			}, {
				"c": "460100",
				"n": "海口市",
				"pc": "460000"
			}, {
				"c": "460101",
				"n": "市辖区",
				"pc": "460100"
			}, {
				"c": "460105",
				"n": "秀英区",
				"pc": "460100"
			}, {
				"c": "460106",
				"n": "龙华区",
				"pc": "460100"
			}, {
				"c": "460107",
				"n": "琼山区",
				"pc": "460100"
			}, {
				"c": "460108",
				"n": "美兰区",
				"pc": "460100"
			}, {
				"c": "460200",
				"n": "三亚市",
				"pc": "460000"
			}, {
				"c": "460201",
				"n": "市辖区",
				"pc": "460200"
			}, {
				"c": "460300",
				"n": "三沙市",
				"pc": "460000"
			}, {
				"c": "460321",
				"n": "西沙群岛",
				"pc": "460300"
			}, {
				"c": "460322",
				"n": "南沙群岛",
				"pc": "460300"
			}, {
				"c": "460323",
				"n": "中沙群岛的岛礁及其海域",
				"pc": "460300"
			}, {
				"c": "469000",
				"n": "省直辖县级行政区划",
				"pc": "460000"
			}, {
				"c": "469001",
				"n": "五指山市",
				"pc": "469000"
			}, {
				"c": "469002",
				"n": "琼海市",
				"pc": "469000"
			}, {
				"c": "469003",
				"n": "儋州市",
				"pc": "469000"
			}, {
				"c": "469005",
				"n": "文昌市",
				"pc": "469000"
			}, {
				"c": "469006",
				"n": "万宁市",
				"pc": "469000"
			}, {
				"c": "469007",
				"n": "东方市",
				"pc": "469000"
			}, {
				"c": "469021",
				"n": "定安县",
				"pc": "469000"
			}, {
				"c": "469022",
				"n": "屯昌县",
				"pc": "469000"
			}, {
				"c": "469023",
				"n": "澄迈县",
				"pc": "469000"
			}, {
				"c": "469024",
				"n": "临高县",
				"pc": "469000"
			}, {
				"c": "469025",
				"n": "白沙黎族自治县",
				"pc": "469000"
			}, {
				"c": "469026",
				"n": "昌江黎族自治县",
				"pc": "469000"
			}, {
				"c": "469027",
				"n": "乐东黎族自治县",
				"pc": "469000"
			}, {
				"c": "469028",
				"n": "陵水黎族自治县",
				"pc": "469000"
			}, {
				"c": "469029",
				"n": "保亭黎族苗族自治县",
				"pc": "469000"
			}, {
				"c": "469030",
				"n": "琼中黎族苗族自治县",
				"pc": "469000"
			}, {
				"c": "500000",
				"n": "重庆市",
				"pc": "86"
			}, {
				"c": "500100",
				"n": "重庆市",
				"pc": "500000"
			}, {
				"c": "500101",
				"n": "万州区",
				"pc": "500100"
			}, {
				"c": "500102",
				"n": "涪陵区",
				"pc": "500100"
			}, {
				"c": "500103",
				"n": "渝中区",
				"pc": "500100"
			}, {
				"c": "500104",
				"n": "大渡口区",
				"pc": "500100"
			}, {
				"c": "500105",
				"n": "江北区",
				"pc": "500100"
			}, {
				"c": "500106",
				"n": "沙坪坝区",
				"pc": "500100"
			}, {
				"c": "500107",
				"n": "九龙坡区",
				"pc": "500100"
			}, {
				"c": "500108",
				"n": "南岸区",
				"pc": "500100"
			}, {
				"c": "500109",
				"n": "北碚区",
				"pc": "500100"
			}, {
				"c": "500110",
				"n": "綦江区",
				"pc": "500100"
			}, {
				"c": "500111",
				"n": "大足区",
				"pc": "500100"
			}, {
				"c": "500112",
				"n": "渝北区",
				"pc": "500100"
			}, {
				"c": "500113",
				"n": "巴南区",
				"pc": "500100"
			}, {
				"c": "500114",
				"n": "黔江区",
				"pc": "500100"
			}, {
				"c": "500115",
				"n": "长寿区",
				"pc": "500100"
			}, {
				"c": "500116",
				"n": "江津区",
				"pc": "500100"
			}, {
				"c": "500117",
				"n": "合川区",
				"pc": "500100"
			}, {
				"c": "500118",
				"n": "永川区",
				"pc": "500100"
			}, {
				"c": "500119",
				"n": "南川区",
				"pc": "500100"
			}, {
				"c": "500100",
				"n": "县",
				"pc": "500000"
			}, {
				"c": "500223",
				"n": "潼南县",
				"pc": "500100"
			}, {
				"c": "500224",
				"n": "铜梁县",
				"pc": "500100"
			}, {
				"c": "500226",
				"n": "荣昌县",
				"pc": "500100"
			}, {
				"c": "500227",
				"n": "璧山县",
				"pc": "500100"
			}, {
				"c": "500228",
				"n": "梁平县",
				"pc": "500100"
			}, {
				"c": "500229",
				"n": "城口县",
				"pc": "500100"
			}, {
				"c": "500230",
				"n": "丰都县",
				"pc": "500100"
			}, {
				"c": "500231",
				"n": "垫江县",
				"pc": "500100"
			}, {
				"c": "500232",
				"n": "武隆县",
				"pc": "500100"
			}, {
				"c": "500233",
				"n": "忠县",
				"pc": "500100"
			}, {
				"c": "500234",
				"n": "开县",
				"pc": "500100"
			}, {
				"c": "500235",
				"n": "云阳县",
				"pc": "500100"
			}, {
				"c": "500236",
				"n": "奉节县",
				"pc": "500100"
			}, {
				"c": "500237",
				"n": "巫山县",
				"pc": "500100"
			}, {
				"c": "500238",
				"n": "巫溪县",
				"pc": "500100"
			}, {
				"c": "500240",
				"n": "石柱土家族自治县",
				"pc": "500100"
			}, {
				"c": "500241",
				"n": "秀山土家族苗族自治县",
				"pc": "500100"
			}, {
				"c": "500242",
				"n": "酉阳土家族苗族自治县",
				"pc": "500100"
			}, {
				"c": "500243",
				"n": "彭水苗族土家族自治县",
				"pc": "500100"
			}, {
				"c": "510000",
				"n": "四川省",
				"pc": "86"
			}, {
				"c": "510100",
				"n": "成都市",
				"pc": "510000"
			}, {
				"c": "510101",
				"n": "市辖区",
				"pc": "510100"
			}, {
				"c": "510104",
				"n": "锦江区",
				"pc": "510100"
			}, {
				"c": "510105",
				"n": "青羊区",
				"pc": "510100"
			}, {
				"c": "510106",
				"n": "金牛区",
				"pc": "510100"
			}, {
				"c": "510107",
				"n": "武侯区",
				"pc": "510100"
			}, {
				"c": "510108",
				"n": "成华区",
				"pc": "510100"
			}, {
				"c": "510112",
				"n": "龙泉驿区",
				"pc": "510100"
			}, {
				"c": "510113",
				"n": "青白江区",
				"pc": "510100"
			}, {
				"c": "510114",
				"n": "新都区",
				"pc": "510100"
			}, {
				"c": "510115",
				"n": "温江区",
				"pc": "510100"
			}, {
				"c": "510121",
				"n": "金堂县",
				"pc": "510100"
			}, {
				"c": "510122",
				"n": "双流县",
				"pc": "510100"
			}, {
				"c": "510124",
				"n": "郫县",
				"pc": "510100"
			}, {
				"c": "510129",
				"n": "大邑县",
				"pc": "510100"
			}, {
				"c": "510131",
				"n": "蒲江县",
				"pc": "510100"
			}, {
				"c": "510132",
				"n": "新津县",
				"pc": "510100"
			}, {
				"c": "510181",
				"n": "都江堰市",
				"pc": "510100"
			}, {
				"c": "510182",
				"n": "彭州市",
				"pc": "510100"
			}, {
				"c": "510183",
				"n": "邛崃市",
				"pc": "510100"
			}, {
				"c": "510184",
				"n": "崇州市",
				"pc": "510100"
			}, {
				"c": "510300",
				"n": "自贡市",
				"pc": "510000"
			}, {
				"c": "510301",
				"n": "市辖区",
				"pc": "510300"
			}, {
				"c": "510302",
				"n": "自流井区",
				"pc": "510300"
			}, {
				"c": "510303",
				"n": "贡井区",
				"pc": "510300"
			}, {
				"c": "510304",
				"n": "大安区",
				"pc": "510300"
			}, {
				"c": "510311",
				"n": "沿滩区",
				"pc": "510300"
			}, {
				"c": "510321",
				"n": "荣县",
				"pc": "510300"
			}, {
				"c": "510322",
				"n": "富顺县",
				"pc": "510300"
			}, {
				"c": "510400",
				"n": "攀枝花市",
				"pc": "510000"
			}, {
				"c": "510401",
				"n": "市辖区",
				"pc": "510400"
			}, {
				"c": "510402",
				"n": "东区",
				"pc": "510400"
			}, {
				"c": "510403",
				"n": "西区",
				"pc": "510400"
			}, {
				"c": "510411",
				"n": "仁和区",
				"pc": "510400"
			}, {
				"c": "510421",
				"n": "米易县",
				"pc": "510400"
			}, {
				"c": "510422",
				"n": "盐边县",
				"pc": "510400"
			}, {
				"c": "510500",
				"n": "泸州市",
				"pc": "510000"
			}, {
				"c": "510501",
				"n": "市辖区",
				"pc": "510500"
			}, {
				"c": "510502",
				"n": "江阳区",
				"pc": "510500"
			}, {
				"c": "510503",
				"n": "纳溪区",
				"pc": "510500"
			}, {
				"c": "510504",
				"n": "龙马潭区",
				"pc": "510500"
			}, {
				"c": "510521",
				"n": "泸县",
				"pc": "510500"
			}, {
				"c": "510522",
				"n": "合江县",
				"pc": "510500"
			}, {
				"c": "510524",
				"n": "叙永县",
				"pc": "510500"
			}, {
				"c": "510525",
				"n": "古蔺县",
				"pc": "510500"
			}, {
				"c": "510600",
				"n": "德阳市",
				"pc": "510000"
			}, {
				"c": "510601",
				"n": "市辖区",
				"pc": "510600"
			}, {
				"c": "510603",
				"n": "旌阳区",
				"pc": "510600"
			}, {
				"c": "510623",
				"n": "中江县",
				"pc": "510600"
			}, {
				"c": "510626",
				"n": "罗江县",
				"pc": "510600"
			}, {
				"c": "510681",
				"n": "广汉市",
				"pc": "510600"
			}, {
				"c": "510682",
				"n": "什邡市",
				"pc": "510600"
			}, {
				"c": "510683",
				"n": "绵竹市",
				"pc": "510600"
			}, {
				"c": "510700",
				"n": "绵阳市",
				"pc": "510000"
			}, {
				"c": "510701",
				"n": "市辖区",
				"pc": "510700"
			}, {
				"c": "510703",
				"n": "涪城区",
				"pc": "510700"
			}, {
				"c": "510704",
				"n": "游仙区",
				"pc": "510700"
			}, {
				"c": "510722",
				"n": "三台县",
				"pc": "510700"
			}, {
				"c": "510723",
				"n": "盐亭县",
				"pc": "510700"
			}, {
				"c": "510724",
				"n": "安县",
				"pc": "510700"
			}, {
				"c": "510725",
				"n": "梓潼县",
				"pc": "510700"
			}, {
				"c": "510726",
				"n": "北川羌族自治县",
				"pc": "510700"
			}, {
				"c": "510727",
				"n": "平武县",
				"pc": "510700"
			}, {
				"c": "510781",
				"n": "江油市",
				"pc": "510700"
			}, {
				"c": "510800",
				"n": "广元市",
				"pc": "510000"
			}, {
				"c": "510801",
				"n": "市辖区",
				"pc": "510800"
			}, {
				"c": "510802",
				"n": "利州区",
				"pc": "510800"
			}, {
				"c": "510811",
				"n": "元坝区",
				"pc": "510800"
			}, {
				"c": "510812",
				"n": "朝天区",
				"pc": "510800"
			}, {
				"c": "510821",
				"n": "旺苍县",
				"pc": "510800"
			}, {
				"c": "510822",
				"n": "青川县",
				"pc": "510800"
			}, {
				"c": "510823",
				"n": "剑阁县",
				"pc": "510800"
			}, {
				"c": "510824",
				"n": "苍溪县",
				"pc": "510800"
			}, {
				"c": "510900",
				"n": "遂宁市",
				"pc": "510000"
			}, {
				"c": "510901",
				"n": "市辖区",
				"pc": "510900"
			}, {
				"c": "510903",
				"n": "船山区",
				"pc": "510900"
			}, {
				"c": "510904",
				"n": "安居区",
				"pc": "510900"
			}, {
				"c": "510921",
				"n": "蓬溪县",
				"pc": "510900"
			}, {
				"c": "510922",
				"n": "射洪县",
				"pc": "510900"
			}, {
				"c": "510923",
				"n": "大英县",
				"pc": "510900"
			}, {
				"c": "511000",
				"n": "内江市",
				"pc": "510000"
			}, {
				"c": "511001",
				"n": "市辖区",
				"pc": "511000"
			}, {
				"c": "511002",
				"n": "市中区",
				"pc": "511000"
			}, {
				"c": "511011",
				"n": "东兴区",
				"pc": "511000"
			}, {
				"c": "511024",
				"n": "威远县",
				"pc": "511000"
			}, {
				"c": "511025",
				"n": "资中县",
				"pc": "511000"
			}, {
				"c": "511028",
				"n": "隆昌县",
				"pc": "511000"
			}, {
				"c": "511100",
				"n": "乐山市",
				"pc": "510000"
			}, {
				"c": "511101",
				"n": "市辖区",
				"pc": "511100"
			}, {
				"c": "511102",
				"n": "市中区",
				"pc": "511100"
			}, {
				"c": "511111",
				"n": "沙湾区",
				"pc": "511100"
			}, {
				"c": "511112",
				"n": "五通桥区",
				"pc": "511100"
			}, {
				"c": "511113",
				"n": "金口河区",
				"pc": "511100"
			}, {
				"c": "511123",
				"n": "犍为县",
				"pc": "511100"
			}, {
				"c": "511124",
				"n": "井研县",
				"pc": "511100"
			}, {
				"c": "511126",
				"n": "夹江县",
				"pc": "511100"
			}, {
				"c": "511129",
				"n": "沐川县",
				"pc": "511100"
			}, {
				"c": "511132",
				"n": "峨边彝族自治县",
				"pc": "511100"
			}, {
				"c": "511133",
				"n": "马边彝族自治县",
				"pc": "511100"
			}, {
				"c": "511181",
				"n": "峨眉山市",
				"pc": "511100"
			}, {
				"c": "511300",
				"n": "南充市",
				"pc": "510000"
			}, {
				"c": "511301",
				"n": "市辖区",
				"pc": "511300"
			}, {
				"c": "511302",
				"n": "顺庆区",
				"pc": "511300"
			}, {
				"c": "511303",
				"n": "高坪区",
				"pc": "511300"
			}, {
				"c": "511304",
				"n": "嘉陵区",
				"pc": "511300"
			}, {
				"c": "511321",
				"n": "南部县",
				"pc": "511300"
			}, {
				"c": "511322",
				"n": "营山县",
				"pc": "511300"
			}, {
				"c": "511323",
				"n": "蓬安县",
				"pc": "511300"
			}, {
				"c": "511324",
				"n": "仪陇县",
				"pc": "511300"
			}, {
				"c": "511325",
				"n": "西充县",
				"pc": "511300"
			}, {
				"c": "511381",
				"n": "阆中市",
				"pc": "511300"
			}, {
				"c": "511400",
				"n": "眉山市",
				"pc": "510000"
			}, {
				"c": "511401",
				"n": "市辖区",
				"pc": "511400"
			}, {
				"c": "511402",
				"n": "东坡区",
				"pc": "511400"
			}, {
				"c": "511421",
				"n": "仁寿县",
				"pc": "511400"
			}, {
				"c": "511422",
				"n": "彭山县",
				"pc": "511400"
			}, {
				"c": "511423",
				"n": "洪雅县",
				"pc": "511400"
			}, {
				"c": "511424",
				"n": "丹棱县",
				"pc": "511400"
			}, {
				"c": "511425",
				"n": "青神县",
				"pc": "511400"
			}, {
				"c": "511500",
				"n": "宜宾市",
				"pc": "510000"
			}, {
				"c": "511501",
				"n": "市辖区",
				"pc": "511500"
			}, {
				"c": "511502",
				"n": "翠屏区",
				"pc": "511500"
			}, {
				"c": "511503",
				"n": "南溪区",
				"pc": "511500"
			}, {
				"c": "511521",
				"n": "宜宾县",
				"pc": "511500"
			}, {
				"c": "511523",
				"n": "江安县",
				"pc": "511500"
			}, {
				"c": "511524",
				"n": "长宁县",
				"pc": "511500"
			}, {
				"c": "511525",
				"n": "高县",
				"pc": "511500"
			}, {
				"c": "511526",
				"n": "珙县",
				"pc": "511500"
			}, {
				"c": "511527",
				"n": "筠连县",
				"pc": "511500"
			}, {
				"c": "511528",
				"n": "兴文县",
				"pc": "511500"
			}, {
				"c": "511529",
				"n": "屏山县",
				"pc": "511500"
			}, {
				"c": "511600",
				"n": "广安市",
				"pc": "510000"
			}, {
				"c": "511601",
				"n": "市辖区",
				"pc": "511600"
			}, {
				"c": "511602",
				"n": "广安区",
				"pc": "511600"
			}, {
				"c": "511621",
				"n": "岳池县",
				"pc": "511600"
			}, {
				"c": "511622",
				"n": "武胜县",
				"pc": "511600"
			}, {
				"c": "511623",
				"n": "邻水县",
				"pc": "511600"
			}, {
				"c": "511681",
				"n": "华蓥市",
				"pc": "511600"
			}, {
				"c": "511700",
				"n": "达州市",
				"pc": "510000"
			}, {
				"c": "511701",
				"n": "市辖区",
				"pc": "511700"
			}, {
				"c": "511702",
				"n": "通川区",
				"pc": "511700"
			}, {
				"c": "511721",
				"n": "达县",
				"pc": "511700"
			}, {
				"c": "511722",
				"n": "宣汉县",
				"pc": "511700"
			}, {
				"c": "511723",
				"n": "开江县",
				"pc": "511700"
			}, {
				"c": "511724",
				"n": "大竹县",
				"pc": "511700"
			}, {
				"c": "511725",
				"n": "渠县",
				"pc": "511700"
			}, {
				"c": "511781",
				"n": "万源市",
				"pc": "511700"
			}, {
				"c": "511800",
				"n": "雅安市",
				"pc": "510000"
			}, {
				"c": "511801",
				"n": "市辖区",
				"pc": "511800"
			}, {
				"c": "511802",
				"n": "雨城区",
				"pc": "511800"
			}, {
				"c": "511803",
				"n": "名山区",
				"pc": "511800"
			}, {
				"c": "511822",
				"n": "荥经县",
				"pc": "511800"
			}, {
				"c": "511823",
				"n": "汉源县",
				"pc": "511800"
			}, {
				"c": "511824",
				"n": "石棉县",
				"pc": "511800"
			}, {
				"c": "511825",
				"n": "天全县",
				"pc": "511800"
			}, {
				"c": "511826",
				"n": "芦山县",
				"pc": "511800"
			}, {
				"c": "511827",
				"n": "宝兴县",
				"pc": "511800"
			}, {
				"c": "511900",
				"n": "巴中市",
				"pc": "510000"
			}, {
				"c": "511901",
				"n": "市辖区",
				"pc": "511900"
			}, {
				"c": "511902",
				"n": "巴州区",
				"pc": "511900"
			}, {
				"c": "511921",
				"n": "通江县",
				"pc": "511900"
			}, {
				"c": "511922",
				"n": "南江县",
				"pc": "511900"
			}, {
				"c": "511923",
				"n": "平昌县",
				"pc": "511900"
			}, {
				"c": "512000",
				"n": "资阳市",
				"pc": "510000"
			}, {
				"c": "512001",
				"n": "市辖区",
				"pc": "512000"
			}, {
				"c": "512002",
				"n": "雁江区",
				"pc": "512000"
			}, {
				"c": "512021",
				"n": "安岳县",
				"pc": "512000"
			}, {
				"c": "512022",
				"n": "乐至县",
				"pc": "512000"
			}, {
				"c": "512081",
				"n": "简阳市",
				"pc": "512000"
			}, {
				"c": "513200",
				"n": "阿坝藏族羌族自治州",
				"pc": "510000"
			}, {
				"c": "513221",
				"n": "汶川县",
				"pc": "513200"
			}, {
				"c": "513222",
				"n": "理县",
				"pc": "513200"
			}, {
				"c": "513223",
				"n": "茂县",
				"pc": "513200"
			}, {
				"c": "513224",
				"n": "松潘县",
				"pc": "513200"
			}, {
				"c": "513225",
				"n": "九寨沟县",
				"pc": "513200"
			}, {
				"c": "513226",
				"n": "金川县",
				"pc": "513200"
			}, {
				"c": "513227",
				"n": "小金县",
				"pc": "513200"
			}, {
				"c": "513228",
				"n": "黑水县",
				"pc": "513200"
			}, {
				"c": "513229",
				"n": "马尔康县",
				"pc": "513200"
			}, {
				"c": "513230",
				"n": "壤塘县",
				"pc": "513200"
			}, {
				"c": "513231",
				"n": "阿坝县",
				"pc": "513200"
			}, {
				"c": "513232",
				"n": "若尔盖县",
				"pc": "513200"
			}, {
				"c": "513233",
				"n": "红原县",
				"pc": "513200"
			}, {
				"c": "513300",
				"n": "甘孜藏族自治州",
				"pc": "510000"
			}, {
				"c": "513321",
				"n": "康定县",
				"pc": "513300"
			}, {
				"c": "513322",
				"n": "泸定县",
				"pc": "513300"
			}, {
				"c": "513323",
				"n": "丹巴县",
				"pc": "513300"
			}, {
				"c": "513324",
				"n": "九龙县",
				"pc": "513300"
			}, {
				"c": "513325",
				"n": "雅江县",
				"pc": "513300"
			}, {
				"c": "513326",
				"n": "道孚县",
				"pc": "513300"
			}, {
				"c": "513327",
				"n": "炉霍县",
				"pc": "513300"
			}, {
				"c": "513328",
				"n": "甘孜县",
				"pc": "513300"
			}, {
				"c": "513329",
				"n": "新龙县",
				"pc": "513300"
			}, {
				"c": "513330",
				"n": "德格县",
				"pc": "513300"
			}, {
				"c": "513331",
				"n": "白玉县",
				"pc": "513300"
			}, {
				"c": "513332",
				"n": "石渠县",
				"pc": "513300"
			}, {
				"c": "513333",
				"n": "色达县",
				"pc": "513300"
			}, {
				"c": "513334",
				"n": "理塘县",
				"pc": "513300"
			}, {
				"c": "513335",
				"n": "巴塘县",
				"pc": "513300"
			}, {
				"c": "513336",
				"n": "乡城县",
				"pc": "513300"
			}, {
				"c": "513337",
				"n": "稻城县",
				"pc": "513300"
			}, {
				"c": "513338",
				"n": "得荣县",
				"pc": "513300"
			}, {
				"c": "513400",
				"n": "凉山彝族自治州",
				"pc": "510000"
			}, {
				"c": "513401",
				"n": "西昌市",
				"pc": "513400"
			}, {
				"c": "513422",
				"n": "木里藏族自治县",
				"pc": "513400"
			}, {
				"c": "513423",
				"n": "盐源县",
				"pc": "513400"
			}, {
				"c": "513424",
				"n": "德昌县",
				"pc": "513400"
			}, {
				"c": "513425",
				"n": "会理县",
				"pc": "513400"
			}, {
				"c": "513426",
				"n": "会东县",
				"pc": "513400"
			}, {
				"c": "513427",
				"n": "宁南县",
				"pc": "513400"
			}, {
				"c": "513428",
				"n": "普格县",
				"pc": "513400"
			}, {
				"c": "513429",
				"n": "布拖县",
				"pc": "513400"
			}, {
				"c": "513430",
				"n": "金阳县",
				"pc": "513400"
			}, {
				"c": "513431",
				"n": "昭觉县",
				"pc": "513400"
			}, {
				"c": "513432",
				"n": "喜德县",
				"pc": "513400"
			}, {
				"c": "513433",
				"n": "冕宁县",
				"pc": "513400"
			}, {
				"c": "513434",
				"n": "越西县",
				"pc": "513400"
			}, {
				"c": "513435",
				"n": "甘洛县",
				"pc": "513400"
			}, {
				"c": "513436",
				"n": "美姑县",
				"pc": "513400"
			}, {
				"c": "513437",
				"n": "雷波县",
				"pc": "513400"
			}, {
				"c": "520000",
				"n": "贵州省",
				"pc": "86"
			}, {
				"c": "520100",
				"n": "贵阳市",
				"pc": "520000"
			}, {
				"c": "520101",
				"n": "市辖区",
				"pc": "520100"
			}, {
				"c": "520102",
				"n": "南明区",
				"pc": "520100"
			}, {
				"c": "520103",
				"n": "云岩区",
				"pc": "520100"
			}, {
				"c": "520111",
				"n": "花溪区",
				"pc": "520100"
			}, {
				"c": "520112",
				"n": "乌当区",
				"pc": "520100"
			}, {
				"c": "520113",
				"n": "白云区",
				"pc": "520100"
			}, {
				"c": "520114",
				"n": "小河区",
				"pc": "520100"
			}, {
				"c": "520121",
				"n": "开阳县",
				"pc": "520100"
			}, {
				"c": "520122",
				"n": "息烽县",
				"pc": "520100"
			}, {
				"c": "520123",
				"n": "修文县",
				"pc": "520100"
			}, {
				"c": "520181",
				"n": "清镇市",
				"pc": "520100"
			}, {
				"c": "520200",
				"n": "六盘水市",
				"pc": "520000"
			}, {
				"c": "520201",
				"n": "钟山区",
				"pc": "520200"
			}, {
				"c": "520203",
				"n": "六枝特区",
				"pc": "520200"
			}, {
				"c": "520221",
				"n": "水城县",
				"pc": "520200"
			}, {
				"c": "520222",
				"n": "盘县",
				"pc": "520200"
			}, {
				"c": "520300",
				"n": "遵义市",
				"pc": "520000"
			}, {
				"c": "520301",
				"n": "市辖区",
				"pc": "520300"
			}, {
				"c": "520302",
				"n": "红花岗区",
				"pc": "520300"
			}, {
				"c": "520303",
				"n": "汇川区",
				"pc": "520300"
			}, {
				"c": "520321",
				"n": "遵义县",
				"pc": "520300"
			}, {
				"c": "520322",
				"n": "桐梓县",
				"pc": "520300"
			}, {
				"c": "520323",
				"n": "绥阳县",
				"pc": "520300"
			}, {
				"c": "520324",
				"n": "正安县",
				"pc": "520300"
			}, {
				"c": "520325",
				"n": "道真仡佬族苗族自治县",
				"pc": "520300"
			}, {
				"c": "520326",
				"n": "务川仡佬族苗族自治县",
				"pc": "520300"
			}, {
				"c": "520327",
				"n": "凤冈县",
				"pc": "520300"
			}, {
				"c": "520328",
				"n": "湄潭县",
				"pc": "520300"
			}, {
				"c": "520329",
				"n": "余庆县",
				"pc": "520300"
			}, {
				"c": "520330",
				"n": "习水县",
				"pc": "520300"
			}, {
				"c": "520381",
				"n": "赤水市",
				"pc": "520300"
			}, {
				"c": "520382",
				"n": "仁怀市",
				"pc": "520300"
			}, {
				"c": "520400",
				"n": "安顺市",
				"pc": "520000"
			}, {
				"c": "520401",
				"n": "市辖区",
				"pc": "520400"
			}, {
				"c": "520402",
				"n": "西秀区",
				"pc": "520400"
			}, {
				"c": "520421",
				"n": "平坝县",
				"pc": "520400"
			}, {
				"c": "520422",
				"n": "普定县",
				"pc": "520400"
			}, {
				"c": "520423",
				"n": "镇宁布依族苗族自治县",
				"pc": "520400"
			}, {
				"c": "520424",
				"n": "关岭布依族苗族自治县",
				"pc": "520400"
			}, {
				"c": "520425",
				"n": "紫云苗族布依族自治县",
				"pc": "520400"
			}, {
				"c": "520500",
				"n": "毕节市",
				"pc": "520000"
			}, {
				"c": "520502",
				"n": "七星关区",
				"pc": "520500"
			}, {
				"c": "520521",
				"n": "大方县",
				"pc": "520500"
			}, {
				"c": "520522",
				"n": "黔西县",
				"pc": "520500"
			}, {
				"c": "520523",
				"n": "金沙县",
				"pc": "520500"
			}, {
				"c": "520524",
				"n": "织金县",
				"pc": "520500"
			}, {
				"c": "520525",
				"n": "纳雍县",
				"pc": "520500"
			}, {
				"c": "520526",
				"n": "威宁彝族回族苗族自治县",
				"pc": "520500"
			}, {
				"c": "520527",
				"n": "赫章县",
				"pc": "520500"
			}, {
				"c": "520600",
				"n": "铜仁市",
				"pc": "520000"
			}, {
				"c": "520602",
				"n": "碧江区",
				"pc": "520600"
			}, {
				"c": "520603",
				"n": "万山区",
				"pc": "520600"
			}, {
				"c": "520621",
				"n": "江口县",
				"pc": "520600"
			}, {
				"c": "520622",
				"n": "玉屏侗族自治县",
				"pc": "520600"
			}, {
				"c": "520623",
				"n": "石阡县",
				"pc": "520600"
			}, {
				"c": "520624",
				"n": "思南县",
				"pc": "520600"
			}, {
				"c": "520625",
				"n": "印江土家族苗族自治县",
				"pc": "520600"
			}, {
				"c": "520626",
				"n": "德江县",
				"pc": "520600"
			}, {
				"c": "520627",
				"n": "沿河土家族自治县",
				"pc": "520600"
			}, {
				"c": "520628",
				"n": "松桃苗族自治县",
				"pc": "520600"
			}, {
				"c": "522300",
				"n": "黔西南布依族苗族自治州",
				"pc": "520000"
			}, {
				"c": "522301",
				"n": "兴义市",
				"pc": "522300"
			}, {
				"c": "522322",
				"n": "兴仁县",
				"pc": "522300"
			}, {
				"c": "522323",
				"n": "普安县",
				"pc": "522300"
			}, {
				"c": "522324",
				"n": "晴隆县",
				"pc": "522300"
			}, {
				"c": "522325",
				"n": "贞丰县",
				"pc": "522300"
			}, {
				"c": "522326",
				"n": "望谟县",
				"pc": "522300"
			}, {
				"c": "522327",
				"n": "册亨县",
				"pc": "522300"
			}, {
				"c": "522328",
				"n": "安龙县",
				"pc": "522300"
			}, {
				"c": "522600",
				"n": "黔东南苗族侗族自治州",
				"pc": "520000"
			}, {
				"c": "522601",
				"n": "凯里市",
				"pc": "522600"
			}, {
				"c": "522622",
				"n": "黄平县",
				"pc": "522600"
			}, {
				"c": "522623",
				"n": "施秉县",
				"pc": "522600"
			}, {
				"c": "522624",
				"n": "三穗县",
				"pc": "522600"
			}, {
				"c": "522625",
				"n": "镇远县",
				"pc": "522600"
			}, {
				"c": "522626",
				"n": "岑巩县",
				"pc": "522600"
			}, {
				"c": "522627",
				"n": "天柱县",
				"pc": "522600"
			}, {
				"c": "522628",
				"n": "锦屏县",
				"pc": "522600"
			}, {
				"c": "522629",
				"n": "剑河县",
				"pc": "522600"
			}, {
				"c": "522630",
				"n": "台江县",
				"pc": "522600"
			}, {
				"c": "522631",
				"n": "黎平县",
				"pc": "522600"
			}, {
				"c": "522632",
				"n": "榕江县",
				"pc": "522600"
			}, {
				"c": "522633",
				"n": "从江县",
				"pc": "522600"
			}, {
				"c": "522634",
				"n": "雷山县",
				"pc": "522600"
			}, {
				"c": "522635",
				"n": "麻江县",
				"pc": "522600"
			}, {
				"c": "522636",
				"n": "丹寨县",
				"pc": "522600"
			}, {
				"c": "522700",
				"n": "黔南布依族苗族自治州",
				"pc": "520000"
			}, {
				"c": "522701",
				"n": "都匀市",
				"pc": "522700"
			}, {
				"c": "522702",
				"n": "福泉市",
				"pc": "522700"
			}, {
				"c": "522722",
				"n": "荔波县",
				"pc": "522700"
			}, {
				"c": "522723",
				"n": "贵定县",
				"pc": "522700"
			}, {
				"c": "522725",
				"n": "瓮安县",
				"pc": "522700"
			}, {
				"c": "522726",
				"n": "独山县",
				"pc": "522700"
			}, {
				"c": "522727",
				"n": "平塘县",
				"pc": "522700"
			}, {
				"c": "522728",
				"n": "罗甸县",
				"pc": "522700"
			}, {
				"c": "522729",
				"n": "长顺县",
				"pc": "522700"
			}, {
				"c": "522730",
				"n": "龙里县",
				"pc": "522700"
			}, {
				"c": "522731",
				"n": "惠水县",
				"pc": "522700"
			}, {
				"c": "522732",
				"n": "三都水族自治县",
				"pc": "522700"
			}, {
				"c": "530000",
				"n": "云南省",
				"pc": "86"
			}, {
				"c": "530100",
				"n": "昆明市",
				"pc": "530000"
			}, {
				"c": "530101",
				"n": "市辖区",
				"pc": "530100"
			}, {
				"c": "530102",
				"n": "五华区",
				"pc": "530100"
			}, {
				"c": "530103",
				"n": "盘龙区",
				"pc": "530100"
			}, {
				"c": "530111",
				"n": "官渡区",
				"pc": "530100"
			}, {
				"c": "530112",
				"n": "西山区",
				"pc": "530100"
			}, {
				"c": "530113",
				"n": "东川区",
				"pc": "530100"
			}, {
				"c": "530114",
				"n": "呈贡区",
				"pc": "530100"
			}, {
				"c": "530122",
				"n": "晋宁县",
				"pc": "530100"
			}, {
				"c": "530124",
				"n": "富民县",
				"pc": "530100"
			}, {
				"c": "530125",
				"n": "宜良县",
				"pc": "530100"
			}, {
				"c": "530126",
				"n": "石林彝族自治县",
				"pc": "530100"
			}, {
				"c": "530127",
				"n": "嵩明县",
				"pc": "530100"
			}, {
				"c": "530128",
				"n": "禄劝彝族苗族自治县",
				"pc": "530100"
			}, {
				"c": "530129",
				"n": "寻甸回族彝族自治县",
				"pc": "530100"
			}, {
				"c": "530181",
				"n": "安宁市",
				"pc": "530100"
			}, {
				"c": "530300",
				"n": "曲靖市",
				"pc": "530000"
			}, {
				"c": "530301",
				"n": "市辖区",
				"pc": "530300"
			}, {
				"c": "530302",
				"n": "麒麟区",
				"pc": "530300"
			}, {
				"c": "530321",
				"n": "马龙县",
				"pc": "530300"
			}, {
				"c": "530322",
				"n": "陆良县",
				"pc": "530300"
			}, {
				"c": "530323",
				"n": "师宗县",
				"pc": "530300"
			}, {
				"c": "530324",
				"n": "罗平县",
				"pc": "530300"
			}, {
				"c": "530325",
				"n": "富源县",
				"pc": "530300"
			}, {
				"c": "530326",
				"n": "会泽县",
				"pc": "530300"
			}, {
				"c": "530328",
				"n": "沾益县",
				"pc": "530300"
			}, {
				"c": "530381",
				"n": "宣威市",
				"pc": "530300"
			}, {
				"c": "530400",
				"n": "玉溪市",
				"pc": "530000"
			}, {
				"c": "530402",
				"n": "红塔区",
				"pc": "530400"
			}, {
				"c": "530421",
				"n": "江川县",
				"pc": "530400"
			}, {
				"c": "530422",
				"n": "澄江县",
				"pc": "530400"
			}, {
				"c": "530423",
				"n": "通海县",
				"pc": "530400"
			}, {
				"c": "530424",
				"n": "华宁县",
				"pc": "530400"
			}, {
				"c": "530425",
				"n": "易门县",
				"pc": "530400"
			}, {
				"c": "530426",
				"n": "峨山彝族自治县",
				"pc": "530400"
			}, {
				"c": "530427",
				"n": "新平彝族傣族自治县",
				"pc": "530400"
			}, {
				"c": "530428",
				"n": "元江哈尼族彝族傣族自治县",
				"pc": "530400"
			}, {
				"c": "530500",
				"n": "保山市",
				"pc": "530000"
			}, {
				"c": "530501",
				"n": "市辖区",
				"pc": "530500"
			}, {
				"c": "530502",
				"n": "隆阳区",
				"pc": "530500"
			}, {
				"c": "530521",
				"n": "施甸县",
				"pc": "530500"
			}, {
				"c": "530522",
				"n": "腾冲县",
				"pc": "530500"
			}, {
				"c": "530523",
				"n": "龙陵县",
				"pc": "530500"
			}, {
				"c": "530524",
				"n": "昌宁县",
				"pc": "530500"
			}, {
				"c": "530600",
				"n": "昭通市",
				"pc": "530000"
			}, {
				"c": "530601",
				"n": "市辖区",
				"pc": "530600"
			}, {
				"c": "530602",
				"n": "昭阳区",
				"pc": "530600"
			}, {
				"c": "530621",
				"n": "鲁甸县",
				"pc": "530600"
			}, {
				"c": "530622",
				"n": "巧家县",
				"pc": "530600"
			}, {
				"c": "530623",
				"n": "盐津县",
				"pc": "530600"
			}, {
				"c": "530624",
				"n": "大关县",
				"pc": "530600"
			}, {
				"c": "530625",
				"n": "永善县",
				"pc": "530600"
			}, {
				"c": "530626",
				"n": "绥江县",
				"pc": "530600"
			}, {
				"c": "530627",
				"n": "镇雄县",
				"pc": "530600"
			}, {
				"c": "530628",
				"n": "彝良县",
				"pc": "530600"
			}, {
				"c": "530629",
				"n": "威信县",
				"pc": "530600"
			}, {
				"c": "530630",
				"n": "水富县",
				"pc": "530600"
			}, {
				"c": "530700",
				"n": "丽江市",
				"pc": "530000"
			}, {
				"c": "530701",
				"n": "市辖区",
				"pc": "530700"
			}, {
				"c": "530702",
				"n": "古城区",
				"pc": "530700"
			}, {
				"c": "530721",
				"n": "玉龙纳西族自治县",
				"pc": "530700"
			}, {
				"c": "530722",
				"n": "永胜县",
				"pc": "530700"
			}, {
				"c": "530723",
				"n": "华坪县",
				"pc": "530700"
			}, {
				"c": "530724",
				"n": "宁蒗彝族自治县",
				"pc": "530700"
			}, {
				"c": "530800",
				"n": "普洱市",
				"pc": "530000"
			}, {
				"c": "530801",
				"n": "市辖区",
				"pc": "530800"
			}, {
				"c": "530802",
				"n": "思茅区",
				"pc": "530800"
			}, {
				"c": "530821",
				"n": "宁洱哈尼族彝族自治县",
				"pc": "530800"
			}, {
				"c": "530822",
				"n": "墨江哈尼族自治县",
				"pc": "530800"
			}, {
				"c": "530823",
				"n": "景东彝族自治县",
				"pc": "530800"
			}, {
				"c": "530824",
				"n": "景谷傣族彝族自治县",
				"pc": "530800"
			}, {
				"c": "530825",
				"n": "镇沅彝族哈尼族拉祜族自治县",
				"pc": "530800"
			}, {
				"c": "530826",
				"n": "江城哈尼族彝族自治县",
				"pc": "530800"
			}, {
				"c": "530827",
				"n": "孟连傣族拉祜族佤族自治县",
				"pc": "530800"
			}, {
				"c": "530828",
				"n": "澜沧拉祜族自治县",
				"pc": "530800"
			}, {
				"c": "530829",
				"n": "西盟佤族自治县",
				"pc": "530800"
			}, {
				"c": "530900",
				"n": "临沧市",
				"pc": "530000"
			}, {
				"c": "530901",
				"n": "市辖区",
				"pc": "530900"
			}, {
				"c": "530902",
				"n": "临翔区",
				"pc": "530900"
			}, {
				"c": "530921",
				"n": "凤庆县",
				"pc": "530900"
			}, {
				"c": "530922",
				"n": "云县",
				"pc": "530900"
			}, {
				"c": "530923",
				"n": "永德县",
				"pc": "530900"
			}, {
				"c": "530924",
				"n": "镇康县",
				"pc": "530900"
			}, {
				"c": "530925",
				"n": "双江拉祜族佤族布朗族傣族自治县",
				"pc": "530900"
			}, {
				"c": "530926",
				"n": "耿马傣族佤族自治县",
				"pc": "530900"
			}, {
				"c": "530927",
				"n": "沧源佤族自治县",
				"pc": "530900"
			}, {
				"c": "532300",
				"n": "楚雄彝族自治州",
				"pc": "530000"
			}, {
				"c": "532301",
				"n": "楚雄市",
				"pc": "532300"
			}, {
				"c": "532322",
				"n": "双柏县",
				"pc": "532300"
			}, {
				"c": "532323",
				"n": "牟定县",
				"pc": "532300"
			}, {
				"c": "532324",
				"n": "南华县",
				"pc": "532300"
			}, {
				"c": "532325",
				"n": "姚安县",
				"pc": "532300"
			}, {
				"c": "532326",
				"n": "大姚县",
				"pc": "532300"
			}, {
				"c": "532327",
				"n": "永仁县",
				"pc": "532300"
			}, {
				"c": "532328",
				"n": "元谋县",
				"pc": "532300"
			}, {
				"c": "532329",
				"n": "武定县",
				"pc": "532300"
			}, {
				"c": "532331",
				"n": "禄丰县",
				"pc": "532300"
			}, {
				"c": "532500",
				"n": "红河哈尼族彝族自治州",
				"pc": "530000"
			}, {
				"c": "532501",
				"n": "个旧市",
				"pc": "532500"
			}, {
				"c": "532502",
				"n": "开远市",
				"pc": "532500"
			}, {
				"c": "532503",
				"n": "蒙自市",
				"pc": "532500"
			}, {
				"c": "532523",
				"n": "屏边苗族自治县",
				"pc": "532500"
			}, {
				"c": "532524",
				"n": "建水县",
				"pc": "532500"
			}, {
				"c": "532525",
				"n": "石屏县",
				"pc": "532500"
			}, {
				"c": "532526",
				"n": "弥勒县",
				"pc": "532500"
			}, {
				"c": "532527",
				"n": "泸西县",
				"pc": "532500"
			}, {
				"c": "532528",
				"n": "元阳县",
				"pc": "532500"
			}, {
				"c": "532529",
				"n": "红河县",
				"pc": "532500"
			}, {
				"c": "532530",
				"n": "金平苗族瑶族傣族自治县",
				"pc": "532500"
			}, {
				"c": "532531",
				"n": "绿春县",
				"pc": "532500"
			}, {
				"c": "532532",
				"n": "河口瑶族自治县",
				"pc": "532500"
			}, {
				"c": "532600",
				"n": "文山壮族苗族自治州",
				"pc": "530000"
			}, {
				"c": "532601",
				"n": "文山市",
				"pc": "532600"
			}, {
				"c": "532622",
				"n": "砚山县",
				"pc": "532600"
			}, {
				"c": "532623",
				"n": "西畴县",
				"pc": "532600"
			}, {
				"c": "532624",
				"n": "麻栗坡县",
				"pc": "532600"
			}, {
				"c": "532625",
				"n": "马关县",
				"pc": "532600"
			}, {
				"c": "532626",
				"n": "丘北县",
				"pc": "532600"
			}, {
				"c": "532627",
				"n": "广南县",
				"pc": "532600"
			}, {
				"c": "532628",
				"n": "富宁县",
				"pc": "532600"
			}, {
				"c": "532800",
				"n": "西双版纳傣族自治州",
				"pc": "530000"
			}, {
				"c": "532801",
				"n": "景洪市",
				"pc": "532800"
			}, {
				"c": "532822",
				"n": "勐海县",
				"pc": "532800"
			}, {
				"c": "532823",
				"n": "勐腊县",
				"pc": "532800"
			}, {
				"c": "532900",
				"n": "大理白族自治州",
				"pc": "530000"
			}, {
				"c": "532901",
				"n": "大理市",
				"pc": "532900"
			}, {
				"c": "532922",
				"n": "漾濞彝族自治县",
				"pc": "532900"
			}, {
				"c": "532923",
				"n": "祥云县",
				"pc": "532900"
			}, {
				"c": "532924",
				"n": "宾川县",
				"pc": "532900"
			}, {
				"c": "532925",
				"n": "弥渡县",
				"pc": "532900"
			}, {
				"c": "532926",
				"n": "南涧彝族自治县",
				"pc": "532900"
			}, {
				"c": "532927",
				"n": "巍山彝族回族自治县",
				"pc": "532900"
			}, {
				"c": "532928",
				"n": "永平县",
				"pc": "532900"
			}, {
				"c": "532929",
				"n": "云龙县",
				"pc": "532900"
			}, {
				"c": "532930",
				"n": "洱源县",
				"pc": "532900"
			}, {
				"c": "532931",
				"n": "剑川县",
				"pc": "532900"
			}, {
				"c": "532932",
				"n": "鹤庆县",
				"pc": "532900"
			}, {
				"c": "533100",
				"n": "德宏傣族景颇族自治州",
				"pc": "530000"
			}, {
				"c": "533102",
				"n": "瑞丽市",
				"pc": "533100"
			}, {
				"c": "533103",
				"n": "芒市",
				"pc": "533100"
			}, {
				"c": "533122",
				"n": "梁河县",
				"pc": "533100"
			}, {
				"c": "533123",
				"n": "盈江县",
				"pc": "533100"
			}, {
				"c": "533124",
				"n": "陇川县",
				"pc": "533100"
			}, {
				"c": "533300",
				"n": "怒江傈僳族自治州",
				"pc": "530000"
			}, {
				"c": "533321",
				"n": "泸水县",
				"pc": "533300"
			}, {
				"c": "533323",
				"n": "福贡县",
				"pc": "533300"
			}, {
				"c": "533324",
				"n": "贡山独龙族怒族自治县",
				"pc": "533300"
			}, {
				"c": "533325",
				"n": "兰坪白族普米族自治县",
				"pc": "533300"
			}, {
				"c": "533400",
				"n": "迪庆藏族自治州",
				"pc": "530000"
			}, {
				"c": "533421",
				"n": "香格里拉县",
				"pc": "533400"
			}, {
				"c": "533422",
				"n": "德钦县",
				"pc": "533400"
			}, {
				"c": "533423",
				"n": "维西傈僳族自治县",
				"pc": "533400"
			}, {
				"c": "540000",
				"n": "西藏自治区",
				"pc": "86"
			}, {
				"c": "540100",
				"n": "拉萨市",
				"pc": "540000"
			}, {
				"c": "540101",
				"n": "市辖区",
				"pc": "540100"
			}, {
				"c": "540102",
				"n": "城关区",
				"pc": "540100"
			}, {
				"c": "540121",
				"n": "林周县",
				"pc": "540100"
			}, {
				"c": "540122",
				"n": "当雄县",
				"pc": "540100"
			}, {
				"c": "540123",
				"n": "尼木县",
				"pc": "540100"
			}, {
				"c": "540124",
				"n": "曲水县",
				"pc": "540100"
			}, {
				"c": "540125",
				"n": "堆龙德庆县",
				"pc": "540100"
			}, {
				"c": "540126",
				"n": "达孜县",
				"pc": "540100"
			}, {
				"c": "540127",
				"n": "墨竹工卡县",
				"pc": "540100"
			}, {
				"c": "542100",
				"n": "昌都地区",
				"pc": "540000"
			}, {
				"c": "542121",
				"n": "昌都县",
				"pc": "542100"
			}, {
				"c": "542122",
				"n": "江达县",
				"pc": "542100"
			}, {
				"c": "542123",
				"n": "贡觉县",
				"pc": "542100"
			}, {
				"c": "542124",
				"n": "类乌齐县",
				"pc": "542100"
			}, {
				"c": "542125",
				"n": "丁青县",
				"pc": "542100"
			}, {
				"c": "542126",
				"n": "察雅县",
				"pc": "542100"
			}, {
				"c": "542127",
				"n": "八宿县",
				"pc": "542100"
			}, {
				"c": "542128",
				"n": "左贡县",
				"pc": "542100"
			}, {
				"c": "542129",
				"n": "芒康县",
				"pc": "542100"
			}, {
				"c": "542132",
				"n": "洛隆县",
				"pc": "542100"
			}, {
				"c": "542133",
				"n": "边坝县",
				"pc": "542100"
			}, {
				"c": "542200",
				"n": "山南地区",
				"pc": "540000"
			}, {
				"c": "542221",
				"n": "乃东县",
				"pc": "542200"
			}, {
				"c": "542222",
				"n": "扎囊县",
				"pc": "542200"
			}, {
				"c": "542223",
				"n": "贡嘎县",
				"pc": "542200"
			}, {
				"c": "542224",
				"n": "桑日县",
				"pc": "542200"
			}, {
				"c": "542225",
				"n": "琼结县",
				"pc": "542200"
			}, {
				"c": "542226",
				"n": "曲松县",
				"pc": "542200"
			}, {
				"c": "542227",
				"n": "措美县",
				"pc": "542200"
			}, {
				"c": "542228",
				"n": "洛扎县",
				"pc": "542200"
			}, {
				"c": "542229",
				"n": "加查县",
				"pc": "542200"
			}, {
				"c": "542231",
				"n": "隆子县",
				"pc": "542200"
			}, {
				"c": "542232",
				"n": "错那县",
				"pc": "542200"
			}, {
				"c": "542233",
				"n": "浪卡子县",
				"pc": "542200"
			}, {
				"c": "542300",
				"n": "日喀则地区",
				"pc": "540000"
			}, {
				"c": "542301",
				"n": "日喀则市",
				"pc": "542300"
			}, {
				"c": "542322",
				"n": "南木林县",
				"pc": "542300"
			}, {
				"c": "542323",
				"n": "江孜县",
				"pc": "542300"
			}, {
				"c": "542324",
				"n": "定日县",
				"pc": "542300"
			}, {
				"c": "542325",
				"n": "萨迦县",
				"pc": "542300"
			}, {
				"c": "542326",
				"n": "拉孜县",
				"pc": "542300"
			}, {
				"c": "542327",
				"n": "昂仁县",
				"pc": "542300"
			}, {
				"c": "542328",
				"n": "谢通门县",
				"pc": "542300"
			}, {
				"c": "542329",
				"n": "白朗县",
				"pc": "542300"
			}, {
				"c": "542330",
				"n": "仁布县",
				"pc": "542300"
			}, {
				"c": "542331",
				"n": "康马县",
				"pc": "542300"
			}, {
				"c": "542332",
				"n": "定结县",
				"pc": "542300"
			}, {
				"c": "542333",
				"n": "仲巴县",
				"pc": "542300"
			}, {
				"c": "542334",
				"n": "亚东县",
				"pc": "542300"
			}, {
				"c": "542335",
				"n": "吉隆县",
				"pc": "542300"
			}, {
				"c": "542336",
				"n": "聂拉木县",
				"pc": "542300"
			}, {
				"c": "542337",
				"n": "萨嘎县",
				"pc": "542300"
			}, {
				"c": "542338",
				"n": "岗巴县",
				"pc": "542300"
			}, {
				"c": "542400",
				"n": "那曲地区",
				"pc": "540000"
			}, {
				"c": "542421",
				"n": "那曲县",
				"pc": "542400"
			}, {
				"c": "542422",
				"n": "嘉黎县",
				"pc": "542400"
			}, {
				"c": "542423",
				"n": "比如县",
				"pc": "542400"
			}, {
				"c": "542424",
				"n": "聂荣县",
				"pc": "542400"
			}, {
				"c": "542425",
				"n": "安多县",
				"pc": "542400"
			}, {
				"c": "542426",
				"n": "申扎县",
				"pc": "542400"
			}, {
				"c": "542427",
				"n": "索县",
				"pc": "542400"
			}, {
				"c": "542428",
				"n": "班戈县",
				"pc": "542400"
			}, {
				"c": "542429",
				"n": "巴青县",
				"pc": "542400"
			}, {
				"c": "542430",
				"n": "尼玛县",
				"pc": "542400"
			}, {
				"c": "542500",
				"n": "阿里地区",
				"pc": "540000"
			}, {
				"c": "542521",
				"n": "普兰县",
				"pc": "542500"
			}, {
				"c": "542522",
				"n": "札达县",
				"pc": "542500"
			}, {
				"c": "542523",
				"n": "噶尔县",
				"pc": "542500"
			}, {
				"c": "542524",
				"n": "日土县",
				"pc": "542500"
			}, {
				"c": "542525",
				"n": "革吉县",
				"pc": "542500"
			}, {
				"c": "542526",
				"n": "改则县",
				"pc": "542500"
			}, {
				"c": "542527",
				"n": "措勤县",
				"pc": "542500"
			}, {
				"c": "542600",
				"n": "林芝地区",
				"pc": "540000"
			}, {
				"c": "542621",
				"n": "林芝县",
				"pc": "542600"
			}, {
				"c": "542622",
				"n": "工布江达县",
				"pc": "542600"
			}, {
				"c": "542623",
				"n": "米林县",
				"pc": "542600"
			}, {
				"c": "542624",
				"n": "墨脱县",
				"pc": "542600"
			}, {
				"c": "542625",
				"n": "波密县",
				"pc": "542600"
			}, {
				"c": "542626",
				"n": "察隅县",
				"pc": "542600"
			}, {
				"c": "542627",
				"n": "朗县",
				"pc": "542600"
			}, {
				"c": "610000",
				"n": "陕西省",
				"pc": "86"
			}, {
				"c": "610100",
				"n": "西安市",
				"pc": "610000"
			}, {
				"c": "610101",
				"n": "市辖区",
				"pc": "610100"
			}, {
				"c": "610102",
				"n": "新城区",
				"pc": "610100"
			}, {
				"c": "610103",
				"n": "碑林区",
				"pc": "610100"
			}, {
				"c": "610104",
				"n": "莲湖区",
				"pc": "610100"
			}, {
				"c": "610111",
				"n": "灞桥区",
				"pc": "610100"
			}, {
				"c": "610112",
				"n": "未央区",
				"pc": "610100"
			}, {
				"c": "610113",
				"n": "雁塔区",
				"pc": "610100"
			}, {
				"c": "610114",
				"n": "阎良区",
				"pc": "610100"
			}, {
				"c": "610115",
				"n": "临潼区",
				"pc": "610100"
			}, {
				"c": "610116",
				"n": "长安区",
				"pc": "610100"
			}, {
				"c": "610122",
				"n": "蓝田县",
				"pc": "610100"
			}, {
				"c": "610124",
				"n": "周至县",
				"pc": "610100"
			}, {
				"c": "610125",
				"n": "户县",
				"pc": "610100"
			}, {
				"c": "610126",
				"n": "高陵县",
				"pc": "610100"
			}, {
				"c": "610200",
				"n": "铜川市",
				"pc": "610000"
			}, {
				"c": "610201",
				"n": "市辖区",
				"pc": "610200"
			}, {
				"c": "610202",
				"n": "王益区",
				"pc": "610200"
			}, {
				"c": "610203",
				"n": "印台区",
				"pc": "610200"
			}, {
				"c": "610204",
				"n": "耀州区",
				"pc": "610200"
			}, {
				"c": "610222",
				"n": "宜君县",
				"pc": "610200"
			}, {
				"c": "610300",
				"n": "宝鸡市",
				"pc": "610000"
			}, {
				"c": "610301",
				"n": "市辖区",
				"pc": "610300"
			}, {
				"c": "610302",
				"n": "渭滨区",
				"pc": "610300"
			}, {
				"c": "610303",
				"n": "金台区",
				"pc": "610300"
			}, {
				"c": "610304",
				"n": "陈仓区",
				"pc": "610300"
			}, {
				"c": "610322",
				"n": "凤翔县",
				"pc": "610300"
			}, {
				"c": "610323",
				"n": "岐山县",
				"pc": "610300"
			}, {
				"c": "610324",
				"n": "扶风县",
				"pc": "610300"
			}, {
				"c": "610326",
				"n": "眉县",
				"pc": "610300"
			}, {
				"c": "610327",
				"n": "陇县",
				"pc": "610300"
			}, {
				"c": "610328",
				"n": "千阳县",
				"pc": "610300"
			}, {
				"c": "610329",
				"n": "麟游县",
				"pc": "610300"
			}, {
				"c": "610330",
				"n": "凤县",
				"pc": "610300"
			}, {
				"c": "610331",
				"n": "太白县",
				"pc": "610300"
			}, {
				"c": "610400",
				"n": "咸阳市",
				"pc": "610000"
			}, {
				"c": "610401",
				"n": "市辖区",
				"pc": "610400"
			}, {
				"c": "610402",
				"n": "秦都区",
				"pc": "610400"
			}, {
				"c": "610403",
				"n": "杨陵区",
				"pc": "610400"
			}, {
				"c": "610404",
				"n": "渭城区",
				"pc": "610400"
			}, {
				"c": "610422",
				"n": "三原县",
				"pc": "610400"
			}, {
				"c": "610423",
				"n": "泾阳县",
				"pc": "610400"
			}, {
				"c": "610424",
				"n": "乾县",
				"pc": "610400"
			}, {
				"c": "610425",
				"n": "礼泉县",
				"pc": "610400"
			}, {
				"c": "610426",
				"n": "永寿县",
				"pc": "610400"
			}, {
				"c": "610427",
				"n": "彬县",
				"pc": "610400"
			}, {
				"c": "610428",
				"n": "长武县",
				"pc": "610400"
			}, {
				"c": "610429",
				"n": "旬邑县",
				"pc": "610400"
			}, {
				"c": "610430",
				"n": "淳化县",
				"pc": "610400"
			}, {
				"c": "610431",
				"n": "武功县",
				"pc": "610400"
			}, {
				"c": "610481",
				"n": "兴平市",
				"pc": "610400"
			}, {
				"c": "610500",
				"n": "渭南市",
				"pc": "610000"
			}, {
				"c": "610501",
				"n": "市辖区",
				"pc": "610500"
			}, {
				"c": "610502",
				"n": "临渭区",
				"pc": "610500"
			}, {
				"c": "610521",
				"n": "华县",
				"pc": "610500"
			}, {
				"c": "610522",
				"n": "潼关县",
				"pc": "610500"
			}, {
				"c": "610523",
				"n": "大荔县",
				"pc": "610500"
			}, {
				"c": "610524",
				"n": "合阳县",
				"pc": "610500"
			}, {
				"c": "610525",
				"n": "澄城县",
				"pc": "610500"
			}, {
				"c": "610526",
				"n": "蒲城县",
				"pc": "610500"
			}, {
				"c": "610527",
				"n": "白水县",
				"pc": "610500"
			}, {
				"c": "610528",
				"n": "富平县",
				"pc": "610500"
			}, {
				"c": "610581",
				"n": "韩城市",
				"pc": "610500"
			}, {
				"c": "610582",
				"n": "华阴市",
				"pc": "610500"
			}, {
				"c": "610600",
				"n": "延安市",
				"pc": "610000"
			}, {
				"c": "610601",
				"n": "市辖区",
				"pc": "610600"
			}, {
				"c": "610602",
				"n": "宝塔区",
				"pc": "610600"
			}, {
				"c": "610621",
				"n": "延长县",
				"pc": "610600"
			}, {
				"c": "610622",
				"n": "延川县",
				"pc": "610600"
			}, {
				"c": "610623",
				"n": "子长县",
				"pc": "610600"
			}, {
				"c": "610624",
				"n": "安塞县",
				"pc": "610600"
			}, {
				"c": "610625",
				"n": "志丹县",
				"pc": "610600"
			}, {
				"c": "610626",
				"n": "吴起县",
				"pc": "610600"
			}, {
				"c": "610627",
				"n": "甘泉县",
				"pc": "610600"
			}, {
				"c": "610628",
				"n": "富县",
				"pc": "610600"
			}, {
				"c": "610629",
				"n": "洛川县",
				"pc": "610600"
			}, {
				"c": "610630",
				"n": "宜川县",
				"pc": "610600"
			}, {
				"c": "610631",
				"n": "黄龙县",
				"pc": "610600"
			}, {
				"c": "610632",
				"n": "黄陵县",
				"pc": "610600"
			}, {
				"c": "610700",
				"n": "汉中市",
				"pc": "610000"
			}, {
				"c": "610701",
				"n": "市辖区",
				"pc": "610700"
			}, {
				"c": "610702",
				"n": "汉台区",
				"pc": "610700"
			}, {
				"c": "610721",
				"n": "南郑县",
				"pc": "610700"
			}, {
				"c": "610722",
				"n": "城固县",
				"pc": "610700"
			}, {
				"c": "610723",
				"n": "洋县",
				"pc": "610700"
			}, {
				"c": "610724",
				"n": "西乡县",
				"pc": "610700"
			}, {
				"c": "610725",
				"n": "勉县",
				"pc": "610700"
			}, {
				"c": "610726",
				"n": "宁强县",
				"pc": "610700"
			}, {
				"c": "610727",
				"n": "略阳县",
				"pc": "610700"
			}, {
				"c": "610728",
				"n": "镇巴县",
				"pc": "610700"
			}, {
				"c": "610729",
				"n": "留坝县",
				"pc": "610700"
			}, {
				"c": "610730",
				"n": "佛坪县",
				"pc": "610700"
			}, {
				"c": "610800",
				"n": "榆林市",
				"pc": "610000"
			}, {
				"c": "610801",
				"n": "市辖区",
				"pc": "610800"
			}, {
				"c": "610802",
				"n": "榆阳区",
				"pc": "610800"
			}, {
				"c": "610821",
				"n": "神木县",
				"pc": "610800"
			}, {
				"c": "610822",
				"n": "府谷县",
				"pc": "610800"
			}, {
				"c": "610823",
				"n": "横山县",
				"pc": "610800"
			}, {
				"c": "610824",
				"n": "靖边县",
				"pc": "610800"
			}, {
				"c": "610825",
				"n": "定边县",
				"pc": "610800"
			}, {
				"c": "610826",
				"n": "绥德县",
				"pc": "610800"
			}, {
				"c": "610827",
				"n": "米脂县",
				"pc": "610800"
			}, {
				"c": "610828",
				"n": "佳县",
				"pc": "610800"
			}, {
				"c": "610829",
				"n": "吴堡县",
				"pc": "610800"
			}, {
				"c": "610830",
				"n": "清涧县",
				"pc": "610800"
			}, {
				"c": "610831",
				"n": "子洲县",
				"pc": "610800"
			}, {
				"c": "610900",
				"n": "安康市",
				"pc": "610000"
			}, {
				"c": "610901",
				"n": "市辖区",
				"pc": "610900"
			}, {
				"c": "610902",
				"n": "汉滨区",
				"pc": "610900"
			}, {
				"c": "610921",
				"n": "汉阴县",
				"pc": "610900"
			}, {
				"c": "610922",
				"n": "石泉县",
				"pc": "610900"
			}, {
				"c": "610923",
				"n": "宁陕县",
				"pc": "610900"
			}, {
				"c": "610924",
				"n": "紫阳县",
				"pc": "610900"
			}, {
				"c": "610925",
				"n": "岚皋县",
				"pc": "610900"
			}, {
				"c": "610926",
				"n": "平利县",
				"pc": "610900"
			}, {
				"c": "610927",
				"n": "镇坪县",
				"pc": "610900"
			}, {
				"c": "610928",
				"n": "旬阳县",
				"pc": "610900"
			}, {
				"c": "610929",
				"n": "白河县",
				"pc": "610900"
			}, {
				"c": "611000",
				"n": "商洛市",
				"pc": "610000"
			}, {
				"c": "611001",
				"n": "市辖区",
				"pc": "611000"
			}, {
				"c": "611002",
				"n": "商州区",
				"pc": "611000"
			}, {
				"c": "611021",
				"n": "洛南县",
				"pc": "611000"
			}, {
				"c": "611022",
				"n": "丹凤县",
				"pc": "611000"
			}, {
				"c": "611023",
				"n": "商南县",
				"pc": "611000"
			}, {
				"c": "611024",
				"n": "山阳县",
				"pc": "611000"
			}, {
				"c": "611025",
				"n": "镇安县",
				"pc": "611000"
			}, {
				"c": "611026",
				"n": "柞水县",
				"pc": "611000"
			}, {
				"c": "620000",
				"n": "甘肃省",
				"pc": "86"
			}, {
				"c": "620100",
				"n": "兰州市",
				"pc": "620000"
			}, {
				"c": "620101",
				"n": "市辖区",
				"pc": "620100"
			}, {
				"c": "620102",
				"n": "城关区",
				"pc": "620100"
			}, {
				"c": "620103",
				"n": "七里河区",
				"pc": "620100"
			}, {
				"c": "620104",
				"n": "西固区",
				"pc": "620100"
			}, {
				"c": "620105",
				"n": "安宁区",
				"pc": "620100"
			}, {
				"c": "620111",
				"n": "红古区",
				"pc": "620100"
			}, {
				"c": "620121",
				"n": "永登县",
				"pc": "620100"
			}, {
				"c": "620122",
				"n": "皋兰县",
				"pc": "620100"
			}, {
				"c": "620123",
				"n": "榆中县",
				"pc": "620100"
			}, {
				"c": "620200",
				"n": "嘉峪关市",
				"pc": "620000"
			}, {
				"c": "620201",
				"n": "市辖区",
				"pc": "620200"
			}, {
				"c": "620300",
				"n": "金昌市",
				"pc": "620000"
			}, {
				"c": "620301",
				"n": "市辖区",
				"pc": "620300"
			}, {
				"c": "620302",
				"n": "金川区",
				"pc": "620300"
			}, {
				"c": "620321",
				"n": "永昌县",
				"pc": "620300"
			}, {
				"c": "620400",
				"n": "白银市",
				"pc": "620000"
			}, {
				"c": "620401",
				"n": "市辖区",
				"pc": "620400"
			}, {
				"c": "620402",
				"n": "白银区",
				"pc": "620400"
			}, {
				"c": "620403",
				"n": "平川区",
				"pc": "620400"
			}, {
				"c": "620421",
				"n": "靖远县",
				"pc": "620400"
			}, {
				"c": "620422",
				"n": "会宁县",
				"pc": "620400"
			}, {
				"c": "620423",
				"n": "景泰县",
				"pc": "620400"
			}, {
				"c": "620500",
				"n": "天水市",
				"pc": "620000"
			}, {
				"c": "620501",
				"n": "市辖区",
				"pc": "620500"
			}, {
				"c": "620502",
				"n": "秦州区",
				"pc": "620500"
			}, {
				"c": "620503",
				"n": "麦积区",
				"pc": "620500"
			}, {
				"c": "620521",
				"n": "清水县",
				"pc": "620500"
			}, {
				"c": "620522",
				"n": "秦安县",
				"pc": "620500"
			}, {
				"c": "620523",
				"n": "甘谷县",
				"pc": "620500"
			}, {
				"c": "620524",
				"n": "武山县",
				"pc": "620500"
			}, {
				"c": "620525",
				"n": "张家川回族自治县",
				"pc": "620500"
			}, {
				"c": "620600",
				"n": "武威市",
				"pc": "620000"
			}, {
				"c": "620601",
				"n": "市辖区",
				"pc": "620600"
			}, {
				"c": "620602",
				"n": "凉州区",
				"pc": "620600"
			}, {
				"c": "620621",
				"n": "民勤县",
				"pc": "620600"
			}, {
				"c": "620622",
				"n": "古浪县",
				"pc": "620600"
			}, {
				"c": "620623",
				"n": "天祝藏族自治县",
				"pc": "620600"
			}, {
				"c": "620700",
				"n": "张掖市",
				"pc": "620000"
			}, {
				"c": "620701",
				"n": "市辖区",
				"pc": "620700"
			}, {
				"c": "620702",
				"n": "甘州区",
				"pc": "620700"
			}, {
				"c": "620721",
				"n": "肃南裕固族自治县",
				"pc": "620700"
			}, {
				"c": "620722",
				"n": "民乐县",
				"pc": "620700"
			}, {
				"c": "620723",
				"n": "临泽县",
				"pc": "620700"
			}, {
				"c": "620724",
				"n": "高台县",
				"pc": "620700"
			}, {
				"c": "620725",
				"n": "山丹县",
				"pc": "620700"
			}, {
				"c": "620800",
				"n": "平凉市",
				"pc": "620000"
			}, {
				"c": "620801",
				"n": "市辖区",
				"pc": "620800"
			}, {
				"c": "620802",
				"n": "崆峒区",
				"pc": "620800"
			}, {
				"c": "620821",
				"n": "泾川县",
				"pc": "620800"
			}, {
				"c": "620822",
				"n": "灵台县",
				"pc": "620800"
			}, {
				"c": "620823",
				"n": "崇信县",
				"pc": "620800"
			}, {
				"c": "620824",
				"n": "华亭县",
				"pc": "620800"
			}, {
				"c": "620825",
				"n": "庄浪县",
				"pc": "620800"
			}, {
				"c": "620826",
				"n": "静宁县",
				"pc": "620800"
			}, {
				"c": "620900",
				"n": "酒泉市",
				"pc": "620000"
			}, {
				"c": "620901",
				"n": "市辖区",
				"pc": "620900"
			}, {
				"c": "620902",
				"n": "肃州区",
				"pc": "620900"
			}, {
				"c": "620921",
				"n": "金塔县",
				"pc": "620900"
			}, {
				"c": "620922",
				"n": "瓜州县",
				"pc": "620900"
			}, {
				"c": "620923",
				"n": "肃北蒙古族自治县",
				"pc": "620900"
			}, {
				"c": "620924",
				"n": "阿克塞哈萨克族自治县",
				"pc": "620900"
			}, {
				"c": "620981",
				"n": "玉门市",
				"pc": "620900"
			}, {
				"c": "620982",
				"n": "敦煌市",
				"pc": "620900"
			}, {
				"c": "621000",
				"n": "庆阳市",
				"pc": "620000"
			}, {
				"c": "621001",
				"n": "市辖区",
				"pc": "621000"
			}, {
				"c": "621002",
				"n": "西峰区",
				"pc": "621000"
			}, {
				"c": "621021",
				"n": "庆城县",
				"pc": "621000"
			}, {
				"c": "621022",
				"n": "环县",
				"pc": "621000"
			}, {
				"c": "621023",
				"n": "华池县",
				"pc": "621000"
			}, {
				"c": "621024",
				"n": "合水县",
				"pc": "621000"
			}, {
				"c": "621025",
				"n": "正宁县",
				"pc": "621000"
			}, {
				"c": "621026",
				"n": "宁县",
				"pc": "621000"
			}, {
				"c": "621027",
				"n": "镇原县",
				"pc": "621000"
			}, {
				"c": "621100",
				"n": "定西市",
				"pc": "620000"
			}, {
				"c": "621101",
				"n": "市辖区",
				"pc": "621100"
			}, {
				"c": "621102",
				"n": "安定区",
				"pc": "621100"
			}, {
				"c": "621121",
				"n": "通渭县",
				"pc": "621100"
			}, {
				"c": "621122",
				"n": "陇西县",
				"pc": "621100"
			}, {
				"c": "621123",
				"n": "渭源县",
				"pc": "621100"
			}, {
				"c": "621124",
				"n": "临洮县",
				"pc": "621100"
			}, {
				"c": "621125",
				"n": "漳县",
				"pc": "621100"
			}, {
				"c": "621126",
				"n": "岷县",
				"pc": "621100"
			}, {
				"c": "621200",
				"n": "陇南市",
				"pc": "620000"
			}, {
				"c": "621201",
				"n": "市辖区",
				"pc": "621200"
			}, {
				"c": "621202",
				"n": "武都区",
				"pc": "621200"
			}, {
				"c": "621221",
				"n": "成县",
				"pc": "621200"
			}, {
				"c": "621222",
				"n": "文县",
				"pc": "621200"
			}, {
				"c": "621223",
				"n": "宕昌县",
				"pc": "621200"
			}, {
				"c": "621224",
				"n": "康县",
				"pc": "621200"
			}, {
				"c": "621225",
				"n": "西和县",
				"pc": "621200"
			}, {
				"c": "621226",
				"n": "礼县",
				"pc": "621200"
			}, {
				"c": "621227",
				"n": "徽县",
				"pc": "621200"
			}, {
				"c": "621228",
				"n": "两当县",
				"pc": "621200"
			}, {
				"c": "622900",
				"n": "临夏回族自治州",
				"pc": "620000"
			}, {
				"c": "622901",
				"n": "临夏市",
				"pc": "622900"
			}, {
				"c": "622921",
				"n": "临夏县",
				"pc": "622900"
			}, {
				"c": "622922",
				"n": "康乐县",
				"pc": "622900"
			}, {
				"c": "622923",
				"n": "永靖县",
				"pc": "622900"
			}, {
				"c": "622924",
				"n": "广河县",
				"pc": "622900"
			}, {
				"c": "622925",
				"n": "和政县",
				"pc": "622900"
			}, {
				"c": "622926",
				"n": "东乡族自治县",
				"pc": "622900"
			}, {
				"c": "622927",
				"n": "积石山保安族东乡族撒拉族自治县",
				"pc": "622900"
			}, {
				"c": "623000",
				"n": "甘南藏族自治州",
				"pc": "620000"
			}, {
				"c": "623001",
				"n": "合作市",
				"pc": "623000"
			}, {
				"c": "623021",
				"n": "临潭县",
				"pc": "623000"
			}, {
				"c": "623022",
				"n": "卓尼县",
				"pc": "623000"
			}, {
				"c": "623023",
				"n": "舟曲县",
				"pc": "623000"
			}, {
				"c": "623024",
				"n": "迭部县",
				"pc": "623000"
			}, {
				"c": "623025",
				"n": "玛曲县",
				"pc": "623000"
			}, {
				"c": "623026",
				"n": "碌曲县",
				"pc": "623000"
			}, {
				"c": "623027",
				"n": "夏河县",
				"pc": "623000"
			}, {
				"c": "630000",
				"n": "青海省",
				"pc": "86"
			}, {
				"c": "630100",
				"n": "西宁市",
				"pc": "630000"
			}, {
				"c": "630101",
				"n": "市辖区",
				"pc": "630100"
			}, {
				"c": "630102",
				"n": "城东区",
				"pc": "630100"
			}, {
				"c": "630103",
				"n": "城中区",
				"pc": "630100"
			}, {
				"c": "630104",
				"n": "城西区",
				"pc": "630100"
			}, {
				"c": "630105",
				"n": "城北区",
				"pc": "630100"
			}, {
				"c": "630121",
				"n": "大通回族土族自治县",
				"pc": "630100"
			}, {
				"c": "630122",
				"n": "湟中县",
				"pc": "630100"
			}, {
				"c": "630123",
				"n": "湟源县",
				"pc": "630100"
			}, {
				"c": "632100",
				"n": "海东地区",
				"pc": "630000"
			}, {
				"c": "632121",
				"n": "平安县",
				"pc": "632100"
			}, {
				"c": "632122",
				"n": "民和回族土族自治县",
				"pc": "632100"
			}, {
				"c": "632123",
				"n": "乐都县",
				"pc": "632100"
			}, {
				"c": "632126",
				"n": "互助土族自治县",
				"pc": "632100"
			}, {
				"c": "632127",
				"n": "化隆回族自治县",
				"pc": "632100"
			}, {
				"c": "632128",
				"n": "循化撒拉族自治县",
				"pc": "632100"
			}, {
				"c": "632200",
				"n": "海北藏族自治州",
				"pc": "630000"
			}, {
				"c": "632221",
				"n": "门源回族自治县",
				"pc": "632200"
			}, {
				"c": "632222",
				"n": "祁连县",
				"pc": "632200"
			}, {
				"c": "632223",
				"n": "海晏县",
				"pc": "632200"
			}, {
				"c": "632224",
				"n": "刚察县",
				"pc": "632200"
			}, {
				"c": "632300",
				"n": "黄南藏族自治州",
				"pc": "630000"
			}, {
				"c": "632321",
				"n": "同仁县",
				"pc": "632300"
			}, {
				"c": "632322",
				"n": "尖扎县",
				"pc": "632300"
			}, {
				"c": "632323",
				"n": "泽库县",
				"pc": "632300"
			}, {
				"c": "632324",
				"n": "河南蒙古族自治县",
				"pc": "632300"
			}, {
				"c": "632500",
				"n": "海南藏族自治州",
				"pc": "630000"
			}, {
				"c": "632521",
				"n": "共和县",
				"pc": "632500"
			}, {
				"c": "632522",
				"n": "同德县",
				"pc": "632500"
			}, {
				"c": "632523",
				"n": "贵德县",
				"pc": "632500"
			}, {
				"c": "632524",
				"n": "兴海县",
				"pc": "632500"
			}, {
				"c": "632525",
				"n": "贵南县",
				"pc": "632500"
			}, {
				"c": "632600",
				"n": "果洛藏族自治州",
				"pc": "630000"
			}, {
				"c": "632621",
				"n": "玛沁县",
				"pc": "632600"
			}, {
				"c": "632622",
				"n": "班玛县",
				"pc": "632600"
			}, {
				"c": "632623",
				"n": "甘德县",
				"pc": "632600"
			}, {
				"c": "632624",
				"n": "达日县",
				"pc": "632600"
			}, {
				"c": "632625",
				"n": "久治县",
				"pc": "632600"
			}, {
				"c": "632626",
				"n": "玛多县",
				"pc": "632600"
			}, {
				"c": "632700",
				"n": "玉树藏族自治州",
				"pc": "630000"
			}, {
				"c": "632721",
				"n": "玉树县",
				"pc": "632700"
			}, {
				"c": "632722",
				"n": "杂多县",
				"pc": "632700"
			}, {
				"c": "632723",
				"n": "称多县",
				"pc": "632700"
			}, {
				"c": "632724",
				"n": "治多县",
				"pc": "632700"
			}, {
				"c": "632725",
				"n": "囊谦县",
				"pc": "632700"
			}, {
				"c": "632726",
				"n": "曲麻莱县",
				"pc": "632700"
			}, {
				"c": "632800",
				"n": "海西蒙古族藏族自治州",
				"pc": "630000"
			}, {
				"c": "632801",
				"n": "格尔木市",
				"pc": "632800"
			}, {
				"c": "632802",
				"n": "德令哈市",
				"pc": "632800"
			}, {
				"c": "632821",
				"n": "乌兰县",
				"pc": "632800"
			}, {
				"c": "632822",
				"n": "都兰县",
				"pc": "632800"
			}, {
				"c": "632823",
				"n": "天峻县",
				"pc": "632800"
			}, {
				"c": "640000",
				"n": "宁夏回族自治区",
				"pc": "86"
			}, {
				"c": "640100",
				"n": "银川市",
				"pc": "640000"
			}, {
				"c": "640101",
				"n": "市辖区",
				"pc": "640100"
			}, {
				"c": "640104",
				"n": "兴庆区",
				"pc": "640100"
			}, {
				"c": "640105",
				"n": "西夏区",
				"pc": "640100"
			}, {
				"c": "640106",
				"n": "金凤区",
				"pc": "640100"
			}, {
				"c": "640121",
				"n": "永宁县",
				"pc": "640100"
			}, {
				"c": "640122",
				"n": "贺兰县",
				"pc": "640100"
			}, {
				"c": "640181",
				"n": "灵武市",
				"pc": "640100"
			}, {
				"c": "640200",
				"n": "石嘴山市",
				"pc": "640000"
			}, {
				"c": "640201",
				"n": "市辖区",
				"pc": "640200"
			}, {
				"c": "640202",
				"n": "大武口区",
				"pc": "640200"
			}, {
				"c": "640205",
				"n": "惠农区",
				"pc": "640200"
			}, {
				"c": "640221",
				"n": "平罗县",
				"pc": "640200"
			}, {
				"c": "640300",
				"n": "吴忠市",
				"pc": "640000"
			}, {
				"c": "640301",
				"n": "市辖区",
				"pc": "640300"
			}, {
				"c": "640302",
				"n": "利通区",
				"pc": "640300"
			}, {
				"c": "640303",
				"n": "红寺堡区",
				"pc": "640300"
			}, {
				"c": "640323",
				"n": "盐池县",
				"pc": "640300"
			}, {
				"c": "640324",
				"n": "同心县",
				"pc": "640300"
			}, {
				"c": "640381",
				"n": "青铜峡市",
				"pc": "640300"
			}, {
				"c": "640400",
				"n": "固原市",
				"pc": "640000"
			}, {
				"c": "640401",
				"n": "市辖区",
				"pc": "640400"
			}, {
				"c": "640402",
				"n": "原州区",
				"pc": "640400"
			}, {
				"c": "640422",
				"n": "西吉县",
				"pc": "640400"
			}, {
				"c": "640423",
				"n": "隆德县",
				"pc": "640400"
			}, {
				"c": "640424",
				"n": "泾源县",
				"pc": "640400"
			}, {
				"c": "640425",
				"n": "彭阳县",
				"pc": "640400"
			}, {
				"c": "640500",
				"n": "中卫市",
				"pc": "640000"
			}, {
				"c": "640501",
				"n": "市辖区",
				"pc": "640500"
			}, {
				"c": "640502",
				"n": "沙坡头区",
				"pc": "640500"
			}, {
				"c": "640521",
				"n": "中宁县",
				"pc": "640500"
			}, {
				"c": "640522",
				"n": "海原县",
				"pc": "640500"
			}, {
				"c": "650000",
				"n": "新疆维吾尔自治区",
				"pc": "86"
			}, {
				"c": "650100",
				"n": "乌鲁木齐市",
				"pc": "650000"
			}, {
				"c": "650101",
				"n": "市辖区",
				"pc": "650100"
			}, {
				"c": "650102",
				"n": "天山区",
				"pc": "650100"
			}, {
				"c": "650103",
				"n": "沙依巴克区",
				"pc": "650100"
			}, {
				"c": "650104",
				"n": "新市区",
				"pc": "650100"
			}, {
				"c": "650105",
				"n": "水磨沟区",
				"pc": "650100"
			}, {
				"c": "650106",
				"n": "头屯河区",
				"pc": "650100"
			}, {
				"c": "650107",
				"n": "达坂城区",
				"pc": "650100"
			}, {
				"c": "650109",
				"n": "米东区",
				"pc": "650100"
			}, {
				"c": "650121",
				"n": "乌鲁木齐县",
				"pc": "650100"
			}, {
				"c": "650200",
				"n": "克拉玛依市",
				"pc": "650000"
			}, {
				"c": "650201",
				"n": "市辖区",
				"pc": "650200"
			}, {
				"c": "650202",
				"n": "独山子区",
				"pc": "650200"
			}, {
				"c": "650203",
				"n": "克拉玛依区",
				"pc": "650200"
			}, {
				"c": "650204",
				"n": "白碱滩区",
				"pc": "650200"
			}, {
				"c": "650205",
				"n": "乌尔禾区",
				"pc": "650200"
			}, {
				"c": "652100",
				"n": "吐鲁番地区",
				"pc": "650000"
			}, {
				"c": "652101",
				"n": "吐鲁番市",
				"pc": "652100"
			}, {
				"c": "652122",
				"n": "鄯善县",
				"pc": "652100"
			}, {
				"c": "652123",
				"n": "托克逊县",
				"pc": "652100"
			}, {
				"c": "652200",
				"n": "哈密地区",
				"pc": "650000"
			}, {
				"c": "652201",
				"n": "哈密市",
				"pc": "652200"
			}, {
				"c": "652222",
				"n": "巴里坤哈萨克自治县",
				"pc": "652200"
			}, {
				"c": "652223",
				"n": "伊吾县",
				"pc": "652200"
			}, {
				"c": "652300",
				"n": "昌吉回族自治州",
				"pc": "650000"
			}, {
				"c": "652301",
				"n": "昌吉市",
				"pc": "652300"
			}, {
				"c": "652302",
				"n": "阜康市",
				"pc": "652300"
			}, {
				"c": "652323",
				"n": "呼图壁县",
				"pc": "652300"
			}, {
				"c": "652324",
				"n": "玛纳斯县",
				"pc": "652300"
			}, {
				"c": "652325",
				"n": "奇台县",
				"pc": "652300"
			}, {
				"c": "652327",
				"n": "吉木萨尔县",
				"pc": "652300"
			}, {
				"c": "652328",
				"n": "木垒哈萨克自治县",
				"pc": "652300"
			}, {
				"c": "652700",
				"n": "博尔塔拉蒙古自治州",
				"pc": "650000"
			}, {
				"c": "652701",
				"n": "博乐市",
				"pc": "652700"
			}, {
				"c": "652722",
				"n": "精河县",
				"pc": "652700"
			}, {
				"c": "652723",
				"n": "温泉县",
				"pc": "652700"
			}, {
				"c": "652800",
				"n": "巴音郭楞蒙古自治州",
				"pc": "650000"
			}, {
				"c": "652801",
				"n": "库尔勒市",
				"pc": "652800"
			}, {
				"c": "652822",
				"n": "轮台县",
				"pc": "652800"
			}, {
				"c": "652823",
				"n": "尉犁县",
				"pc": "652800"
			}, {
				"c": "652824",
				"n": "若羌县",
				"pc": "652800"
			}, {
				"c": "652825",
				"n": "且末县",
				"pc": "652800"
			}, {
				"c": "652826",
				"n": "焉耆回族自治县",
				"pc": "652800"
			}, {
				"c": "652827",
				"n": "和静县",
				"pc": "652800"
			}, {
				"c": "652828",
				"n": "和硕县",
				"pc": "652800"
			}, {
				"c": "652829",
				"n": "博湖县",
				"pc": "652800"
			}, {
				"c": "652900",
				"n": "阿克苏地区",
				"pc": "650000"
			}, {
				"c": "652901",
				"n": "阿克苏市",
				"pc": "652900"
			}, {
				"c": "652922",
				"n": "温宿县",
				"pc": "652900"
			}, {
				"c": "652923",
				"n": "库车县",
				"pc": "652900"
			}, {
				"c": "652924",
				"n": "沙雅县",
				"pc": "652900"
			}, {
				"c": "652925",
				"n": "新和县",
				"pc": "652900"
			}, {
				"c": "652926",
				"n": "拜城县",
				"pc": "652900"
			}, {
				"c": "652927",
				"n": "乌什县",
				"pc": "652900"
			}, {
				"c": "652928",
				"n": "阿瓦提县",
				"pc": "652900"
			}, {
				"c": "652929",
				"n": "柯坪县",
				"pc": "652900"
			}, {
				"c": "653000",
				"n": "克孜勒苏柯尔克孜自治州",
				"pc": "650000"
			}, {
				"c": "653001",
				"n": "阿图什市",
				"pc": "653000"
			}, {
				"c": "653022",
				"n": "阿克陶县",
				"pc": "653000"
			}, {
				"c": "653023",
				"n": "阿合奇县",
				"pc": "653000"
			}, {
				"c": "653024",
				"n": "乌恰县",
				"pc": "653000"
			}, {
				"c": "653100",
				"n": "喀什地区",
				"pc": "650000"
			}, {
				"c": "653101",
				"n": "喀什市",
				"pc": "653100"
			}, {
				"c": "653121",
				"n": "疏附县",
				"pc": "653100"
			}, {
				"c": "653122",
				"n": "疏勒县",
				"pc": "653100"
			}, {
				"c": "653123",
				"n": "英吉沙县",
				"pc": "653100"
			}, {
				"c": "653124",
				"n": "泽普县",
				"pc": "653100"
			}, {
				"c": "653125",
				"n": "莎车县",
				"pc": "653100"
			}, {
				"c": "653126",
				"n": "叶城县",
				"pc": "653100"
			}, {
				"c": "653127",
				"n": "麦盖提县",
				"pc": "653100"
			}, {
				"c": "653128",
				"n": "岳普湖县",
				"pc": "653100"
			}, {
				"c": "653129",
				"n": "伽师县",
				"pc": "653100"
			}, {
				"c": "653130",
				"n": "巴楚县",
				"pc": "653100"
			}, {
				"c": "653131",
				"n": "塔什库尔干塔吉克自治县",
				"pc": "653100"
			}, {
				"c": "653200",
				"n": "和田地区",
				"pc": "650000"
			}, {
				"c": "653201",
				"n": "和田市",
				"pc": "653200"
			}, {
				"c": "653221",
				"n": "和田县",
				"pc": "653200"
			}, {
				"c": "653222",
				"n": "墨玉县",
				"pc": "653200"
			}, {
				"c": "653223",
				"n": "皮山县",
				"pc": "653200"
			}, {
				"c": "653224",
				"n": "洛浦县",
				"pc": "653200"
			}, {
				"c": "653225",
				"n": "策勒县",
				"pc": "653200"
			}, {
				"c": "653226",
				"n": "于田县",
				"pc": "653200"
			}, {
				"c": "653227",
				"n": "民丰县",
				"pc": "653200"
			}, {
				"c": "654000",
				"n": "伊犁哈萨克自治州",
				"pc": "650000"
			}, {
				"c": "654002",
				"n": "伊宁市",
				"pc": "654000"
			}, {
				"c": "654003",
				"n": "奎屯市",
				"pc": "654000"
			}, {
				"c": "654021",
				"n": "伊宁县",
				"pc": "654000"
			}, {
				"c": "654022",
				"n": "察布查尔锡伯自治县",
				"pc": "654000"
			}, {
				"c": "654023",
				"n": "霍城县",
				"pc": "654000"
			}, {
				"c": "654024",
				"n": "巩留县",
				"pc": "654000"
			}, {
				"c": "654025",
				"n": "新源县",
				"pc": "654000"
			}, {
				"c": "654026",
				"n": "昭苏县",
				"pc": "654000"
			}, {
				"c": "654027",
				"n": "特克斯县",
				"pc": "654000"
			}, {
				"c": "654028",
				"n": "尼勒克县",
				"pc": "654000"
			}, {
				"c": "654200",
				"n": "塔城地区",
				"pc": "650000"
			}, {
				"c": "654201",
				"n": "塔城市",
				"pc": "654200"
			}, {
				"c": "654202",
				"n": "乌苏市",
				"pc": "654200"
			}, {
				"c": "654221",
				"n": "额敏县",
				"pc": "654200"
			}, {
				"c": "654223",
				"n": "沙湾县",
				"pc": "654200"
			}, {
				"c": "654224",
				"n": "托里县",
				"pc": "654200"
			}, {
				"c": "654225",
				"n": "裕民县",
				"pc": "654200"
			}, {
				"c": "654226",
				"n": "和布克赛尔蒙古自治县",
				"pc": "654200"
			}, {
				"c": "654300",
				"n": "阿勒泰地区",
				"pc": "650000"
			}, {
				"c": "654301",
				"n": "阿勒泰市",
				"pc": "654300"
			}, {
				"c": "654321",
				"n": "布尔津县",
				"pc": "654300"
			}, {
				"c": "654322",
				"n": "富蕴县",
				"pc": "654300"
			}, {
				"c": "654323",
				"n": "福海县",
				"pc": "654300"
			}, {
				"c": "654324",
				"n": "哈巴河县",
				"pc": "654300"
			}, {
				"c": "654325",
				"n": "青河县",
				"pc": "654300"
			}, {
				"c": "654326",
				"n": "吉木乃县",
				"pc": "654300"
			}, {
				"c": "659000",
				"n": "自治区直辖县级行政区划",
				"pc": "650000"
			}, {
				"c": "659001",
				"n": "石河子市",
				"pc": "659000"
			}, {
				"c": "659002",
				"n": "阿拉尔市",
				"pc": "659000"
			}, {
				"c": "659003",
				"n": "图木舒克市",
				"pc": "659000"
			}, {
				"c": "659004",
				"n": "五家渠市",
				"pc": "659000"
			}, {
				"c": "710000",
				"n": "台湾省",
				"pc": "86"
			}, {
				"c": "810000",
				"n": "香港特别行政区",
				"pc": "86"
			}, {
				"c": "820000",
				"n": "澳门特别行政区",
				"pc": "86"
			}];
			return regions;
		};
	})();