
(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceLocationController', InvoiceLocationController);

    InvoiceLocationController.$inject = ['$q', '$scope', '$state','$stateParams', 'InvoiceLocation',  'MessageService'];

    function InvoiceLocationController($q, $scope, $state, $stateParams, InvoiceLocation,  MessageService) {
        var vm = this;

        vm.del = del;
        vm.changeStatue = changeStatue;

        function loadAll () {
            InvoiceLocation.getAll({}, function(data){
                vm.ilocations = data;
            });
        };

        vm.ilocationsAll = InvoiceLocation.getAll({});
        $q.all([vm.ilocationsAll.$promise]).then(function() {
            vm.ilocations = vm.ilocationsAll.data;
        });


        function changeStatue (obj){
            if (obj.enable) {
                obj.enable = false;
            } else {
                obj.enable = true;
            }
            console.log(obj);
            InvoiceLocation.update(obj, function(){
                $state.go('invoice-location', null, { reload: true});
            })
        };

        function del (id) {
            console.log(id);
            InvoiceLocation.del({id: id}, function(){
                loadAll();
            })
        }
    }
})();
