(function() {
    'use strict';

    angular
        .module('backApp')
        .factory('Module', Module);

    Module.$inject = ['$resource'];

    function Module ($resource) {
        /*模块的列表配置*/
        var modules = [
            {
                name:"商户",
                icon:"store.png",
                id:"store",
                auth:['ROLE_STORE','ROLE_LOGO_SETTING','ROLE_MESSAGE_TEMPLATE'],
                menus:[
                    {
                        name:"商户设置",
                        id:"store",
                        auth:["ROLE_STORE"]
                    },
                    // {
                    //     name:"作品设置",
                    //     id:"store-work",
                    //     auth:["ROLE_STORE"]
                    // },
                    // {
                    //     name:"店铺装修",
                    //     id:"logo-setting",
                    //     auth:["ROLE_LOGO_SETTING"]
                    // },
                    {
                        name:"站内广播",
                        id:"message-notification",
                        auth:["ROLE_MESSAGE_TEMPLATE"]
                    },
                    {
                        name:"自定义色调",
                        id:"hue",
                        auth:["ROLE_LOGO_SETTING"]
                    },
                    // {
                    //     name: "商户充值",
                    //     id: "pay-orderNew",
                    //     auth: ["ROLE_RECHARGE"]
                    // },
                    // {
                    //     name: "消费明细",
                    //     id: "consumption",
                    //     auth: ["ROLE_BILLING_DETAIL"]
                    // },
                    // {
                    //     name: "充值记录",
                    //     id: "pay-order",
                    //     auth: ["ROLE_RECHARGE_RECORD"]
                    // },
                    {
                        name:"电商配置",
                        id:"configuration2",
                        auth:["ROLE_STORE"]
                    },
                    {
                        name:"DIY编辑器",
                        id:"diy-editor-setting",
                        auth:["ROLE_STORE"]
                    },
                    {
                        name:"图库存储",
                        id:"mapDepot",
                        auth:["ROLE_STORE"]
                    },
                ]
            },
            {
                name:"商品",
                icon:"product.png",
                id:"propertyCommercial",
                auth:['ROLE_PRODUCT','ROLE_PRODUCT_XML','ROLE_CATEGORY','ROLE_PROPERTY', 'ROLE_TASK_BILL_FORM'],
                menus:[
                    {
                        name:"商品规格",
                        id:"propertyCommercial",
                        auth:["ROLE_PROPERTY"]
                    },
                    {
                        name:"商品分类",
                        id:"category",
                        auth:["ROLE_CATEGORY"]
                    },
                    {
                        name:"商品模板",
                        id:"mould",
                        auth:["ROLE_PRODUCT_XML"]
                    },
                    {
                        name:"商品管理",
                        id:"product",
                        auth:["ROLE_PRODUCT"]
                    },
                    {
                        name:"自定义任务单",
                        id:"taskbill-forms",
                        auth:["ROLE_TASK_BILL_FORM"]
                    },
                ]
            },
            {
                name:"模板",
                icon:"productxml.png",
                id:"product-xml",
                auth:['ROLE_THEME_CATEGORY','ROLE_PRODUCT_XML','ROLE_DESIGNER_CONNETCING','ROLE_MASK','ROLE_BACKGROUND','ROLE_FONT_XML','ROLE_CLIPART','ROLE_FONT_MANAGEMENT', 'ROLE_FRAME_CONFIG'],
                menus:[
                    {
                        name:"模板列表",
                        id:"product-xml",
                        auth:["ROLE_PRODUCT_XML"]
                    },
                    {
                        name:"字体管理",
                        id:"font-management",
                        auth:["ROLE_FONT_MANAGEMENT"]
                    },
                    {
                        name:"素材管理",
                        id:"material",
                        auth:["ROLE_CLIPART"]
                    },
                    {
                        name:"背景管理",
                        id:"setting",
                        auth:["ROLE_BACKGROUND"]
                    },
                    {
                        name:"蒙版管理",
                        id:"masking",
                        auth:["ROLE_MASK"]
                    },
                    {
                        name:"文字模板",
                        id:"text-xml",
                        auth:["ROLE_FONT_XML"]
                    },
                    {
                        name:"边框",
                        id:"frame-config",
                        auth:["ROLE_FRAME_CONFIG"]
                    },
                    {
                        name:"推荐图片",
                        id:"recommend-pic",
                        auth:["ROLE_PRODUCT_XML"]
                    },
                    {
                        name:"模板分类",
                        id:"theme-classify",
                        classType:"XML",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"背景分类",
                        id:"theme-classify",
                        classType:"BG",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"蒙版分类",
                        id:"theme-classify",
                        classType:"MASK",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"素材分类",
                        id:"theme-classify",
                        classType:"CLIPART",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"文字模板分类",
                        id:"theme-classify",
                        classType:"FONT",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"边框分类",
                        id:"theme-classify",
                        classType:"BORDER",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"推荐图片分类",
                        id:"theme-classify",
                        classType:"RECOMMENDPIC",
                        auth:["ROLE_THEME_CATEGORY"]
                    },
                    {
                        name:"场景模型",
                        id:"scene",
                        auth:["ROLE_THEME_CATEGORY"]
                    }
                ]
            },
            // {
            //     name:"客户",
            //     icon:"iconyinyue",
            //     id:"customer",
            //     auth:['ROLE_CUSTOMER'],
            //     menus:[
            //         {
            //             name:"客户",
            //             id:"customer",
            //             auth:["ROLE_CUSTOMER"]
            //         }
            //     ]
            // },
            {
                name:"订单",
                icon:"order.png",
                id:"sales-order",
                auth:['ROLE_SALES_ORDER'],
                menus:[
                    {
                        name:"全部订单",
                        id:"sales-order",
                        auth:["ROLE_SALES_ORDER"]
                    }
                ]
            },
            {
                name:"数据",
                icon:"data.png",
                id:"product-analysis",
                auth:['ROLE_STATISTICS'],
                menus:[
                    {
                        name:"商品统计",
                        id:"product-analysis",
                        auth:["ROLE_STATISTICS"]
                    }
                ]
            },
            {
                name:"用户",
                icon:"user_model.png",
                id:"role",
                auth:['ROLE_ROLE','ROLE_USER'],
                menus:[
                    {
                        name:"角色",
                        id:"role",
                        auth:['ROLE_ROLE']
                    },
                    {
                        name:"用户",
                        id:"store-user",
                        auth:['ROLE_USER']
                    }
                ]
            },
            {
                name:"发票",
                icon:"invoice.png",
                id:"invoice",
                auth:['ROLE_INVOICE_LIST','ROLE_INVOICE_SUBMIT','ROLE_INVOICE_INFOMATION','ROLE_INVOICE_EXPRESS'],
                menus:[
                    {
                        name:"发票列表",
                        id:"invoice",
                        auth:['ROLE_INVOICE_LIST']
                    },

                    {
                        name:"发票申请",
                        id:"pay-order2",
                        auth:['ROLE_INVOICE_SUBMIT']
                    },
                    {
                        name:"开票信息",
                        id:"invoice-setting-list",
                        auth:['ROLE_INVOICE_INFOMATION']
                    },
                    {
                        name:"发票寄送",
                        id:"invoice-location",
                        auth:['ROLE_INVOICE_EXPRESS']
                    }
                ]
            },
            {
                name:"财务",
                icon:"caiwu2.png",
                id:"caiwu",
                auth:['ROLE_RECHARGE','ROLE_BILLING_DETAIL','ROLE_RECHARGE_RECORD','ROLE_STORE'],
                menus:[
                    {
                        name: "商户充值",
                        id: "pay-orderNew",
                        auth: ["ROLE_RECHARGE"]
                    },
                    {
                        name: "消费明细",
                        id: "consumption",
                        auth: ["ROLE_BILLING_DETAIL"]
                    },
                    {
                        name: "充值记录",
                        id: "pay-order",
                        auth: ["ROLE_RECHARGE_RECORD"]
                    },
                    {
                        name:"授信记录",
                        id:"creditExtension",
                        auth:["ROLE_STORE"]
                    }
                ]
            }
        ];
        return modules;
    }
})();
