(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('UpdateProductXmlController', UpdateProductXmlController);

    UpdateProductXmlController.$inject = ['$state', '$scope', 'ParseLinks', 'MessageService', 'paginationConstants', '$uibModal', 'pagingParams',
        'Qinius', 'ProductXmls', 'ProductXmlFileServes', '$localStorage', 'Store', 'Principal','$uibModalInstance','xmlId'];

    function UpdateProductXmlController($state, $scope, ParseLinks, MessageService, paginationConstants, $uibModal, pagingParams,
                                        Qinius, ProductXmls, ProductXmlFileServes, $localStorage, Store, Principal,$uibModalInstance,xmlId) {

        var vm = this;
        $scope.selectAll = false;
        vm.clear = clear;
        vm.productXmls = [];
        vm.xml = xmlId;


        loadAll()
        function loadAll() {
            $scope.batchArr =[];
            ProductXmls.getAllByCondition({
                items: [{key: "id", op: "!=", value: vm.xml.id},
                    {key: "storeId", op: "=", value: $localStorage.user.storeId},
                    {key: "sourceId", op: "=", value: vm.xml.sourceId},
                    {key: "status", op: "=", value: "Approved"}],
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                return ['id,desc'];
            }

            function onSuccess(data, headers) {
                vm.totalItems = headers('X-Total-Count');
                vm.productXmls = data.data;
            }

            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }
        $scope.afterXml = null;
        // $scope.chooseAll = function() {
        //     $scope.selectAll = !$scope.selectAll;
        //     $scope.batchArr = [];
        //     angular.forEach(vm.productXmls, function(xml, i) {
        //         if ($scope.selectAll) {
        //             xml.select = true;
        //             $scope.batchArr.push(xml);
        //         } else {
        //             $scope.batchArr = [];
        //             xml.select = false;
        //         }
        //     });
        // }

        $scope.chooseOne = function (xml) {
           if ($scope.afterXml != null){
               $scope.afterXml.select = false;
           }
            xml.select = !xml.select;
            // 选中
            if(xml.select){
                $scope.afterXml = xml;
            }else{
                // 未选中
                $scope.afterXml = null;
            }

            // 判断全选按钮
            // var flag = true;
            // angular.forEach(vm.productXmls,function (xml,i) {
            //     if(!xml.select){
            //         $scope.selectAll = false;
            //         flag = false;
            //     }
            //     // 全部选中
            //     if(i==vm.productXmls.length-1&&flag){
            //         $scope.selectAll = true;
            //     }
            // });
        }

        $scope.confirm= function (){
            if ($scope.afterXml != null){
                var afterId = $scope.afterXml.id;
                var beforeId = vm.xml.id;
                ProductXmls.updateProductXml({beforeId:beforeId, afterId:afterId},function (data){
                    $uibModalInstance.close(data);
                    MessageService.success("保存成功");
                },function(){
                    MessageService.error("保存失败");
                })
            }else {
                MessageService.error("请选择同源模板后确认更新");
            }
        }

        $('.modal-body-scroll1').on('scroll', function(event){
            var realScrollHeight=event.currentTarget.clientHeight+event.currentTarget.scrollTop;
            if(vm.productXmls.length < vm.totalItems){
                if((event.currentTarget.scrollHeight-2)<realScrollHeight && realScrollHeight < (event.currentTarget.scrollHeight+2)){
                    vm.page++;
                    loadAll()
                }
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
