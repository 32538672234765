(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DiyEditorSettingDialogController', DiyEditorSettingDialogController);

    DiyEditorSettingDialogController.$inject = ['$q', '$timeout','UidService', '$scope', 'MessageService', 'entity', '$stateParams', '$state', 'Qinius' ,'Store',
    'ListService','FontManagement','DiyEditorSetting','$localStorage','$uibModal'];

    function DiyEditorSettingDialogController ($q, $timeout,UidService, $scope, MessageService, entity, $stateParams, $state, Qinius,Store,
        ListService,FontManagement,DiyEditorSetting,$localStorage, $uibModal) {
        var vm = this;
        vm.data = entity.data;
        vm.clear = clear;
        vm.save = save;

        // 选中tab(模板,文字,素材,图片)
        vm.pageBox = null;
        //右侧边栏展示名
        vm.rightIndex = null;
        /**
         * imgself  默认icon 不会改变
         * img      显示默认icon或者自定义icon
         * isShow   是否展示
         * seq      排序
         * seqValye 排序设置名称（保存时判断）
         * value    icon名称
         * value2   是否展示（icon名）
         * */
        vm.posterBox=[
            {name:'模板', img:'content/images/editor/mubanshape.svg',isShow:false,seq:0,value:'templateIcon',value2:'templateShow',seqValye:'templateSeq',imgself:'content/images/editor/mubanshape.svg'},
            {name:'文字', img:'content/images/editor/wenzishape.svg',isShow:false,seq:1,value:'wordIcon',value2:'wordShow',seqValye:'wordSeq',imgself:'content/images/editor/wenzishape.svg'},
            {name:'素材', img:'content/images/editor/sucaishape.svg',isShow:false,seq:2,value:'clipartIcon',value2:'clipartShow',seqValye:'clipartSeq',imgself:'content/images/editor/sucaishape.svg'},
            {name:'图片', img:'content/images/editor/tupianshape.svg',isShow:false,seq:3,value:'imgIcon',value2:'imgShow',seqValye:'imgSeq',imgself:'content/images/editor/tupianshape.svg'},
        ]

        vm.tokens = Qinius.get();
        vm.domains = Qinius.getDomain();

        FontManagement.getAll({items: [], sort: ["id,asc"]}, function (res) {
            vm.fontList = angular.copy(res.data);
            vm.fontList1 = angular.copy(res.data);
            $scope.item = vm.fontList[0];
        });

        $q.all([vm.domains.$promise, vm.tokens.$promise]).then(function(){
            vm.token = vm.tokens.token;
            $scope.domain = 'http://' + vm.domains.domains[0] + '/';
            if(!vm.data.storeId){vm.data.storeId=$localStorage.user.storeId}
            if(!vm.data.mainColor){vm.data.mainColor = '#ff6e33';}
            if(!vm.data.delWordColor){vm.data.delWordColor = '#0a0a0a';}
            if(!vm.data.wordStyleColor){vm.data.wordStyleColor = '#0a0a0a';}
            if(!vm.data.editWordColor){vm.data.editWordColor = '#0a0a0a';}
            if(!vm.data.templateColor){vm.data.templateColor = '#333333';}
            if(!vm.data.clipartColor){vm.data.clipartColor = '#333333';}
            if(!vm.data.wordColor){vm.data.wordColor = '#333333';}
            if(!vm.data.imgColor){vm.data.imgColor = '#333333';}
            if(!vm.data.changeImgColor){vm.data.changeImgColor = '#0a0a0a';}
            if(!vm.data.filterColor){vm.data.filterColor = '#0a0a0a';}
            if(!vm.data.rotationColor){vm.data.rotationColor = '#0a0a0a';}
            if(!vm.data.upColor){vm.data.upColor = '#0a0a0a';}
            if(!vm.data.downColor){vm.data.downColor = '#0a0a0a';}
            if(!vm.data.flipColor){vm.data.flipColor = '#0a0a0a';}
            if(!vm.data.restoreColor){vm.data.restoreColor = '#0a0a0a';}
            if(!vm.data.spreadColor){vm.data.spreadColor = '#0a0a0a';}
            if(!vm.data.retractColor){vm.data.retractColor = '#0a0a0a';}
            if(!vm.data.wordText){vm.data.wordText="文字"}
            if(!vm.data.templateText){vm.data.templateText="模板"}
            if(!vm.data.clipartText){vm.data.clipartText="素材"}
            if(!vm.data.imgText){vm.data.imgText="图片"}
            if(vm.data.templateShow){
                vm.posterBox[0].isShow = true;
                vm.posterBox[0].seq = vm.data.templateSeq;
            }
            if(vm.data.wordShow){
                vm.posterBox[1].isShow = true;
                vm.posterBox[1].seq = vm.data.wordSeq;
            }
            if(vm.data.clipartShow){
                vm.posterBox[2].isShow = true;
                vm.posterBox[2].seq = vm.data.clipartSeq;
            }
            if(vm.data.imgShow){
                vm.posterBox[3].isShow = true;
                vm.posterBox[3].seq = vm.data.imgSeq;
            }
            vm.posterBox.sort(function (a, b) {
                return a.seq - b.seq;
            })
            if(vm.data.templateSelf && vm.data.templateIcon){ $scope.changeSelf('templateSelf',1); }
            if(vm.data.clipartSelf && vm.data.clipartIcon){ $scope.changeSelf('clipartSelf',1); }
            if(vm.data.wordIconSelf && vm.data.wordIcon){ $scope.changeSelf('wordIconSelf',1); }
            if(vm.data.imgSelf && vm.data.imgIcon){ $scope.changeSelf('imgSelf',1); }
        })

        window.dragStart = function (event) {
            var target = event.target;
            $(".diy-editor-poster-box").addClass('diy-drop-active')
        };

        var content = $(".diy-editor-poster-box")[0];
        content.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content.ondragover = ignoreDrag;
        content.ondrop = drop;

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
            var dataset = e.target.dataset;
        }

        function drop(e) {
            ignoreDrag(e);
            var data = e.dataTransfer.getData("Text");
            if (data.indexOf('mubanshape') != '-1') {
                selectTab('templateIcon')
                vm.data.templateShow = true;
            }else if (data.indexOf('wenzishape') != '-1') {
                selectTab('wordIcon')
                vm.data.wordShow = true;
            }else if (data.indexOf('sucaishape') != '-1') {
                selectTab('clipartIcon')
                vm.data.clipartShow = true;
            }else if (data.indexOf('tupianshape') != '-1') {
                selectTab('imgIcon')
                vm.data.imgShow = true;
            }
            $(".diy-editor-poster-box").removeClass('diy-drop-active')
            $scope.$digest()
            console.log(vm.data)
        }

        function selectTab(attr){
            for (var i = 0; i < vm.posterBox.length; i++) {
                if(vm.posterBox[i].value == attr){
                    vm.posterBox[i].isShow = true;
                    break
                }
            }
        }

        $scope.editBox = function(box){
            vm.pageBox = box.value;
            vm.rightIndex = box.value;
            console.log(vm.rightIndex)
        }

        $scope.deleteTab = function(e,box){
            fn(e);
            box.isShow = false;
            vm.pageBox = null;
            vm.rightIndex = null;
        }

        $scope.changeSelf = function(attr,flag){
            if(flag){
                vm.data[attr] = true;
            }else{
                vm.data[attr] = false;
            }
            // 四个tab icon
            for (var i = 0; i < vm.posterBox.length; i++) {
                if(attr=='templateSelf'&&vm.posterBox[i].value=='templateIcon' || attr=='clipartSelf'&&vm.posterBox[i].value=='clipartIcon' ||
                    attr=='wordIconSelf'&&vm.posterBox[i].value=='wordIcon' || attr=='imgSelf'&&vm.posterBox[i].value=='imgIcon'){
                    if(flag && vm.data[vm.posterBox[i].value]){
                        vm.posterBox[i].img = vm.data[vm.posterBox[i].value];
                    }else{
                        vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);
                    }
                    break
                }
            }
        }
        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }
        window.updateImgFile = function ($event) {
            console.log($event)
            console.log($event.target)
            $timeout(function () {
                var file = $event.target.files[0];
                updateImg(file,$event.target.dataset.flag)
            });
        };

        function updateImg(file,attr) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(file);//创建Image的对象的url
            img.onload = function () {
                // 建议尺寸40px*40px
                var w = Math.abs(this.width - 40);
                var h = Math.abs(this.height - 40);
                if (w > 11 || h > 11) {
                    MessageService.error("建议图片尺寸：40px*40px");
                    return
                }
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', 'diy-' + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        console.log(data);
                        vm.data[attr] = $scope.domain+data.key;
                        if(attr=='wordIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='wordIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='clipartIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='clipartIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='templateIcon'){
                            for (var i = 0; i < vm.pageBox.length; i++) {
                                if(vm.posterBox[i].value=='templateIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        if(attr=='imgIcon'){
                            for (var i = 0; i < vm.posterBox.length; i++) {
                                if(vm.posterBox[i].value=='imgIcon'){vm.posterBox[i].img = $scope.domain+data.key;break;}
                            }
                        }
                        $scope.$digest();
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            }
        }

        $scope.removeFile = function(attr) {
            vm.data[attr] = null;
            if(attr=='wordIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='wordIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='clipartIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='clipartIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='templateIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='templateIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
            if(attr=='imgIcon'){
                for (var i = 0; i < vm.posterBox.length; i++) {
                    if(vm.posterBox[i].value=='imgIcon'){vm.posterBox[i].img = angular.copy(vm.posterBox[i].imgself);break;}
                }
            }
        }

        $scope.addTextFont = function(){
            if(!vm.data.supportFonts){
                vm.data.supportFonts = [];
            }
            vm.data.supportFonts.push({fontManagement:null})
            console.log(vm.data.supportFonts)
        }

        $scope.delFont = function(index){
            vm.data.supportFonts.splice(index,1)
        }

        $scope.addColor = function(){
            if(!vm.data.supportFontColors){
                vm.data.supportFontColors = [];
            }
            vm.data.supportFontColors.push({color:'#333333'})
            console.log(vm.data.supportFonts)
        }

        $scope.delColor = function(index){
            vm.data.supportFontColors.splice(index,1)
        }

        $scope.chooseFont = function () {
            console.log(vm.data)
        }

        var sort = null;
        var sortArray = [];
        setTimeout(function () {
            sort = new Sortable(document.getElementById('my-ui-list1'), {
                swap: true,
                filter: '.filtered',
                animation: 150,
                onEnd: function (/**Event*/evt) {
                    console.log('onEnd.foo:', [evt.item, evt.from]);
                }
            });
        },500)

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go('diy-editor-setting', null, { reload: 'diy-editor-setting' });
        }

        function save () {
            sortArray = [];
            angular.forEach($('#my-ui-list1').find('.slide-content1'), function (item, index) {
                var seq = item.getAttribute("data-sort-tag");
                var value = angular.copy(vm.posterBox[seq]);
                value.seq = index;
                sortArray.push(value)
            });
            vm.posterBox = angular.copy(sortArray);
            console.log(vm.posterBox)
            angular.forEach(vm.posterBox,function (box) {
                if(box.isShow){
                    vm.data[box.value2] = true;
                    vm.data[box.seqValye] = box.seq;
                }else{
                    vm.data[box.value2] = false;
                }
            })
            console.log(vm.data)
            // sort.destroy();
            // return
            vm.isSaving = true;
            DiyEditorSetting.update(vm.data, onSaveSuccess, onSaveError);
            // return
        }

        function onSaveSuccess (result) {
            $scope.$emit('backApp:navigationSettingUpdate', result);
            $state.go('diy-editor-setting', null, { reload: 'diy-editor-setting' });
            // $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        vm.multilingual = function (title, name, key, data){
            if(!vm.data[data]){
                vm.data[data] = [];
            }
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.data[data],
                            key: key,
                            fieldValue: name,
                            fieldName: title
                        };
                    }]
                }
            }).result.then(function(res) {
                if (res) {
                    vm.data[data] = res;
                    console.log(vm.data)
                }
            })
        }
    }
})();
