(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotificationDialogController', MessageNotificationDialogController);

    MessageNotificationDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'MessageNotification', 'MessageService',
     '$state', '$q', '$uibModal'];

    function MessageNotificationDialogController ($timeout, $scope, $stateParams, entity, MessageNotification, MessageService,
     $state, $q, $uibModal) {
        var vm = this;

        $q.all([entity.$promise]).then(function(data){
            vm.messageNotification = entity.data;
            if (vm.messageNotification.id) {
                vm.title = "编辑";
            }else{
                vm.title = "新建";
            }
        })
      
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;

        vm.multilingual = multilingual;
        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.messageNotification.messageNotificationLanguages, 
                            key:"content",
                            fieldValue:vm.messageNotification.content,
                            fieldName:"消息内容"
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.messageNotification.messageNotificationLanguages=data;
                }
            });
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $state.go("message-notification", {reload:true})
        }

        $scope.validata = function(){
            if (vm.messageNotification.content == null || vm.messageNotification.content == "") {
                MessageService.error("请输入通知内容")
                return false;
            }
            if (vm.messageNotification.seq == null || vm.messageNotification.seq == "") {
                MessageService.error("请输入排序")
                return false;
            }
            if (vm.messageNotification.pageType == null || vm.messageNotification.pageType == "") {
                MessageService.error("请选择展示页面")
                return false;
            }
            if (vm.messageNotification.validate == null || vm.messageNotification.validate == "") {
                MessageService.error("请选择有效日期")
                return false;
            }
            return true;
        }

        function save () {
            if (!$scope.validata()) {
                return;
            }
            vm.isSaving = true;
            vm.messageNotification.type = 'Store';
            if (vm.messageNotification.id) {
                MessageNotification.update(vm.messageNotification, onSaveSuccess, onSaveError);
            } else {
                MessageNotification.createByBack(vm.messageNotification, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('platformApp:messageNotificationUpdate', result);
            $state.go("message-notification", {reload:true})
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.validate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
