(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('RoleController', RoleController);

    RoleController.$inject = ['$scope', '$state', 'Role', 'ParseLinks',  'pagingParams', 'paginationConstants', 'MessageService','Authority','ListService'];

    function RoleController ($scope, $state, Role, ParseLinks, pagingParams, paginationConstants, MessageService,Authority,ListService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.save = save;
        vm.save2 = save2;
        Authority.getAllByCondition({items:[]},function (res) {
            vm.authorities = res.data;
        });
        vm.role = null;
        vm.mods = [];

        loadAll();

        $scope.delete = function(id,e){
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Role.delete({id: id},function () {
                    if(vm.role&&vm.role.id == id){
                        vm.role = null;
                    }
                    loadAll();
                });
            },function(){},true)
        }


        function loadAll () {
            if (pagingParams.search) {
                Role.byCondition2({
                    items: [{key:"name",op:"like",value:pagingParams.search},{key:"type",op:"=",value:"STORE"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Role.byCondition2({
                    items: [{key:"type",op:"=",value:"STORE"}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.roles = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message);
                }

            }
            function onError(error) {
                MessageService.error("角色获取失败");
            }
        }


        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.delbox = function () {
            vm.role = null;
        }

        function save () {
            if(vm.roleName== null || vm.roleName == ""){
                MessageService.error("请输入名称");
                return ;
            }
            var data = {
                name: vm.roleName,
                type: 'STORE',
            }
            vm.isSaving = true;
            Role.byStore(data, onSaveSuccess, onSaveError);
        }
        function save2 () {
            var data = $scope.toPost();
            data.type = 'STORE';
            vm.isSaving = true;
            Role.update(data, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (res) {
            if(res.status == 200){
                $scope.$emit('backApp:roleUpdate', res.data);
                MessageService.success("保存成功！");
                loadAll();
            }else {
                MessageService.error(res.message);
            }
        }

        function onSaveError () {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

        $scope.chooseRole = function(role){
            Role.get({id : role.id},function (res) {
                if(res.status==200){
                    vm.role = res.data;
                    $scope.groupBy();
                    setAuthorities();
                }
            })
        }

        function setAuthorities(){
            for (var i = 0; i < vm.role.authorities.length; i++) {
                var au =  vm.role.authorities[i];
                var result = ListService.inList(au, vm.mods, ["module"]);
                if (result) {
                    for (var j = result.authorities.length - 1; j >= 0; j--) {
                        var authority = result.authorities[j];
                        if(authority.id == au.id){
                            authority.selected = true;
                            break;
                        }
                    }
                }
            }

            for (var i = vm.mods.length - 1; i >= 0; i--) {
                var mod = vm.mods[i];
                for (var j = mod.authorities.length - 1; j >= 0; j--) {
                    var au = mod.authorities[j];
                    if (!au.selected) {
                        mod.unAllSelect = true;
                        break;
                    }
                }
                if (mod.unAllSelect) {
                    mod.selected = false;
                }else {
                    mod.selected = true;
                }
            }
            console.log(vm.mods)
        }

        $scope.toPost = function (){
            var postData  ={};
            postData.authorities = [];
            postData.id = vm.role.id;
            postData.name = vm.role.name;
            postData.type = vm.role.type;
            postData.number = vm.role.number;
            postData.cancelled = vm.role.cancelled;
            postData.storeId = vm.role.storeId;
            for (var i = vm.mods.length - 1; i >= 0; i--) {
                var mod = vm.mods[i];
                for (var j = mod.authorities.length - 1; j >= 0; j--) {
                    var au = mod.authorities[j];
                    if (au.selected) {
                        postData.authorities.push(au);
                    }
                }
            }
            return postData;
        };

        $scope.chooseAuthorty = function(mod, au){
            au.selected = !au.selected;
            if (!au.selected) {
                mod.selected = false;
            }
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var auth = mod.authorities[i];
                if (!auth.selected) {
                    mod.selected = false;
                    return;
                }
            }
            mod.selected = true;
        }

        $scope.chooseMod = function(mod){
            if (!mod) {
                return;
            }
            mod.selected = !mod.selected;
            for (var i = mod.authorities.length - 1; i >= 0; i--) {
                var au = mod.authorities[i];
                au.selected = mod.selected;
            }
        }

        $scope.groupBy = function(){
            vm.mods = [];
            if(vm.authorities == null || vm.authorities.length == 0 || vm.role.type == null || vm.role.type == ""){
                return null;
            }
            for (var i = vm.authorities.length - 1; i >= 0; i--) {
                var authority = vm.authorities[i];
                if (authority.jhiType != vm.role.type) {
                    continue;
                }
                var e = ListService.inList(authority,vm.mods ,["module"]);
                if(!e){
                    var r = {module:authority.module,authorities:[]};
                    vm.mods.push(r);
                }

                for (var j = vm.mods.length - 1; j >= 0; j--) {
                    var mod = vm.mods[j];
                    if(authority.module == mod.module){
                        authority.selected = false;
                        mod.authorities.push(authority);
                        break;
                    }
                }
            }
        };

        $scope.authoritySelected = function(authority){
            authority.selected = !authority.selected;

        };

    }
})();
