(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('creditExtension', {
                parent: 'entity',
                url: '/creditExtension?page&sort&search&startTime&endTime',
                data: {
                    authorities: ["ROLE_PROPERTY"],
                    pageTitle: 'global.menu.entities.commoditySpecification'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/creditExtension/creditExtension.html',
                        controller: 'CreditExtensionController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    endTime: null,
                    startTime: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            endTime: $stateParams.endTime,
                            startTime: $stateParams.startTime
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
