(function () {
    'use strict';

    var editorPreviewMini = {
        restrict: 'EAC',
        replace: true,
        transclude: true,
        templateUrl: 'app/entities/product-ec/editor-preview-mini.html',
        // controller: 'articlePreviewController',
        controllerAs: 'vm',
        bindings: {
            fileName: '='
        },
        controller: ['$scope', '$stateParams', 'Qinius', '$http', '$q', 'Convert', 'FontManagement',
            'Article', 'Music', 'ItemValue', 'Store', 'MessageService', '$state', '$compile', '$timeout',
            function ($scope, $stateParams, Qinius, $http, $q, Convert, FontManagement,
                      Article, Music, ItemValue, Store, MessageService, $state, $compile, $timeout) {
                var vm = this;
                vm.aid = $stateParams.aid;
                vm.orderArticleId = $stateParams.orderArticleId;
                if (vm.aid) {
                    vm.article = Article.get({id: vm.aid});
                } else {
                    vm.article = Article.get({id: vm.orderArticleId});
                }
                $q.all([vm.article.$promise]).then(function() {
                    vm.article = vm.article.data;
                    Convert.xmlToDocument({
                        identifier: vm.fileName,
                        provider: 'qiniu'
                    }, function (res) {

                        vm.pages=[];
                        res.pages.page.forEach(function (page) {
                            if(!page.backup){
                                vm.pages.push(page)
                            }
                        })

                        vm.windowH = 500;
                        vm.windowW = document.documentElement.clientWidth / 2 - 30;
                        console.log(vm.windowW)
                        angular.forEach(vm.pages, function (page, index) {
                            (function (page, index) {
                                if (page.editbox == null) {
                                    page.editbox = page.trimbox;
                                }
                                page.editbox = {
                                    width: transitionPx(page.editbox.width),
                                    height: transitionPx(page.editbox.height),
                                    x: transitionPx(page.editbox.x),
                                    y: transitionPx(page.editbox.y)
                                };
                                page.mediabox = {
                                    width: transitionPx(page.mediabox.width),
                                    height: transitionPx(page.mediabox.height),
                                    x: transitionPx(page.mediabox.x),
                                    y: transitionPx(page.mediabox.y)
                                };
                                page.trimbox = {
                                    width: transitionPx(page.trimbox.width),
                                    height: transitionPx(page.trimbox.height),
                                    x: transitionPx(page.trimbox.x),
                                    y: transitionPx(page.trimbox.y)
                                };
                                page.custom = {};
                                if(vm.windowH / page.mediabox.height >= vm.windowW / page.mediabox.width ){
                                    page.custom.ratio = vm.windowW / page.mediabox.width;
                                }else {
                                    page.custom.ratio = vm.windowH / page.mediabox.height;
                                }
                                page.custom.width = page.mediabox.width;
                                page.custom.height = page.mediabox.height;
                                //条形码
                                if(page.barcode){
                                    page.barcode.geometry = {
                                        width: transitionPx(page.barcode.geometry.width),
                                        height: transitionPx(page.barcode.geometry.height),
                                        x: transitionPx(page.barcode.geometry.x),
                                        y: transitionPx(page.barcode.geometry.y)
                                    };
                                }

                                //背景
                                if(page.background && page.background.resource && page.background.resource.identifier){
                                    getBackgroundSource($http, page.background.resource,page.mediabox.width,page.mediabox.height,page.background.resource, $scope);
                                    page.background.offsetx = transitionPx(page.background.offsetx);
                                    page.background.offsety = transitionPx(page.background.offsety);
                                }
                                if(!page.levels.level){
                                    page.levels.level = [];
                                }
                                angular.forEach(page.levels.level, function (level, i) {
                                    if(level.imageart){
                                        level.imageart.lock = true;
                                        level.imageart.offsetx = transitionPx(level.imageart.offsetx);
                                        level.imageart.offsety = transitionPx(level.imageart.offsety);
                                        level.imageart.geometry = {
                                            width: transitionPx(level.imageart.geometry.width),
                                            height: transitionPx(level.imageart.geometry.height),
                                            x: transitionPx(level.imageart.geometry.x),
                                            y: transitionPx(level.imageart.geometry.y)
                                        };
                                        level.imageart.rotation.angle = -level.imageart.rotation.angle;
                                        if(level.imageart.resource.identifier){
                                            getBackgroundSource($http, level.imageart.resource,level.imageart.geometry.width,level.imageart.geometry.height,level.imageart.resource, $scope);
                                        }
                                    }
                                    if(level.imagebox){
                                        level.imagebox.lock = true;
                                        level.imagebox.geometry = {
                                            width: transitionPx(level.imagebox.geometry.width),
                                            height: transitionPx(level.imagebox.geometry.height),
                                            x: transitionPx(level.imagebox.geometry.x),
                                            y: transitionPx(level.imagebox.geometry.y)
                                        };
                                        level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                                        level.imagebox.useradded = true;
                                        level.imagebox.bokehtypes = level.imagebox.bokehtype;
                                        if(level.imagebox.image){
                                            level.imagebox.image.offsetx = transitionPx(level.imagebox.image.offsetx);
                                            level.imagebox.image.offsety = transitionPx(level.imagebox.image.offsety);
                                            if(level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                                                getBackgroundSource1(level.imagebox.image.resource,level.imagebox.geometry.width,level.imagebox.geometry.height,level.imagebox.image, level.imagebox.bokehtypes, $scope)
                                            }
                                        }
                                        if(level.imagebox.blurredrectangle){
                                            level.imagebox.blurredrectangle = {
                                                width: transitionPx(level.imagebox.blurredrectangle.width),
                                                height: transitionPx(level.imagebox.blurredrectangle.height),
                                                x: transitionPx(level.imagebox.blurredrectangle.x),
                                                y: transitionPx(level.imagebox.blurredrectangle.y)
                                            };
                                        }
                                    }
                                    if(level.textbox){
                                        level.textbox.lock = true;
                                        level.textbox.geometry = {
                                            width: transitionPx(level.textbox.geometry.width),
                                            height: transitionPx(level.textbox.geometry.height),
                                            x: transitionPx(level.textbox.geometry.x),
                                            y: transitionPx(level.textbox.geometry.y)
                                        };
                                        level.textbox.rotation.angle = -level.textbox.rotation.angle;
                                        if(level.textbox.fontuuid){
                                            //下载字体
                                            getFontCSS(FontManagement, level.textbox, $scope);
                                        }
                                        if (level.textbox.style.bold && level.textbox.style.italic) {
                                            level.textbox.styles = 'BOLD_ITALIC';
                                        } else if (level.textbox.style.bold) {
                                            level.textbox.styles = 'BOLD';
                                        } else if (level.textbox.style.italic) {
                                            level.textbox.styles = 'ITALIC';
                                        } else {
                                            level.textbox.styles = 'NORMAL';
                                        }
                                    }
                                    if (level.textdocument && level.textdocument.geometry) {
                                        var textdocument = angular.copy(level.textdocument);
                                        level.textdocument = {
                                            background: textdocument.background,
                                            geometry: {
                                                width: transitionPx(textdocument.geometry.width),
                                                height: transitionPx(textdocument.geometry.height),
                                                x: transitionPx(textdocument.geometry.x),
                                                y: transitionPx(textdocument.geometry.y)
                                            },
                                            rotation: {
                                                angle:-textdocument.rotation.angle||0
                                            },
                                            current: 'textdocument',
                                            levels: angular.copy(textdocument.levels),
                                            lock: true,
                                            transparency: textdocument.transparency,
                                            movable: textdocument.movable,
                                            useradded: textdocument.useradded,
                                            editable: textdocument.editable,
                                            scale:textdocument.geometry.width/page.mediabox.width
                                        }

                                        var backg = textdocument.background;
                                        var pageW = textdocument.geometry.width,
                                            pageH = textdocument.geometry.height;
                                        if(backg.resource.identifier){
                                            getBackgroundSource($http, backg.resource,pageW,pageH,backg, $scope);
                                            backg.offsetx = transitionPx(backg.offsetx);
                                            backg.offsety = transitionPx(backg.offsety);
                                        }

                                        angular.forEach(textdocument.levels.level, function (lev, l) {
                                            if (lev.imageart && lev.imageart.geometry) {
                                                var art = lev.imageart;
                                                $http({url: art.resource.identifier + '?imageInfo'}).success(function (data) {
                                                    level.textdocument.levels.level[l].imageart = {
                                                        geometry:{
                                                            width: transitionPx(art.geometry.width),
                                                            height: transitionPx(art.geometry.height),
                                                            x: transitionPx(art.geometry.x),
                                                            y: transitionPx(art.geometry.y)
                                                        },
                                                        rotation: angular.copy(art.rotation),
                                                        type: art.type,
                                                        resource: {
                                                            identifier:art.resource.identifier,
                                                            provider:art.resource.provider,
                                                            width:data.width,
                                                            height:data.height
                                                        },
                                                        scale:art.scale,
                                                        offsetx: transitionPx(art.offsetx),
                                                        offsety: transitionPx(art.offsety),
                                                        current: 'imageart',
                                                        lock: false,
                                                        transparency: art.transparency,
                                                        onlyshow: art.onlyshow,
                                                        movable: art.movable,
                                                        useradded: art.useradded,
                                                        editable: art.editable,
                                                    }
                                                }).error(function (error) {});
                                            }
                                            if (lev.textbox && lev.textbox.geometry) {
                                                var text = lev.textbox;
                                                level.textdocument.levels.level[l].textbox = {
                                                    geometry: {
                                                        width: transitionPx(text.geometry.width),
                                                        height: transitionPx(text.geometry.height),
                                                        x: transitionPx(text.geometry.x),
                                                        y: transitionPx(text.geometry.y)
                                                    },
                                                    identifier: text.identifier,
                                                    rotation: angular.copy(text.rotation),
                                                    angle: angular.copy(text.angle),
                                                    status: angular.copy(text.status),
                                                    style: angular.copy(text.style),
                                                    type: angular.copy(text.type),
                                                    text: angular.copy(text.text),
                                                    fontStyle: [{
                                                        style: '',
                                                        path: text.identifier
                                                    }],
                                                    current: 'textbox',
                                                    lock: false,
                                                    transparency: text.transparency,
                                                    fontuuid:text.fontuuid,
                                                    version:text.version
                                                }
                                                getFontCSS(FontManagement, text, $scope);
                                            }
                                        })
                                    }
                                })
                            })(page, index)
                        })
                        $timeout(function () {
                            console.log(vm.pages)
                            loadApp()
                            $scope.$digest();
                        },100)
                    })
                })
                function loadApp() {

                    var flipbook = $('.flipbook');

                    // Check if the CSS was already loaded

                    if (flipbook.width() == 0 || flipbook.height() == 0) {
                        setTimeout(loadApp, 10);
                        return;
                    }

                    $('.flipbook .flipbook-viewport-page').scissor();

                    // Create the flipbook

                    var _flipbook = $('.flipbook').turn({
                        width: vm.pages[0].custom.width * vm.pages[0].custom.ratio*2,
                        height: vm.pages[0].custom.height * vm.pages[0].custom.ratio,
                        accelerator: true,

                        // Enable gradients

                        gradients: true,

                        // Auto center this flipbook

                        autoCenter: true

                    });
                    $('.flipbook div').on("click", function (e) {
                        console.log(_flipbook.turn('page'))
                        // var _num = _flipbook.turn('page');
                        // var _num1 = "";
                        // if (_num % 2 === 0){
                        // 	_num1 = (_num-1) < 0 ? 0 : (_num-1);
                        //
                        // } else if (_num % 2 === 1){
                        // 	_num1 = _num+1;
                        // };
                        // console.log(_num1)
                        // _flipbook.turn('page', 2);
                        e.preventDefault()
                    })
                }
            }
        ]
    };

    angular
        .module('backApp')
        .component('editorPreviewMini', editorPreviewMini);
})();
