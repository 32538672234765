(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MaterialController', MaterialController);

    MaterialController.$inject = ['$scope', '$q', '$state', 'Material', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', '$uibModal', '$localStorage', 'ThemeClassify'];

    function MaterialController ($scope, $q, $state, Material, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, $uibModal, $localStorage, ThemeClassify) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.themeClassifyId = Number(pagingParams.themeClassifyId);
        vm.jump = jump;
        vm.storeId = JSON.parse(localStorage.getItem('back-user')).storeId;
        // 获取qiniu服务器
        vm.domains = Qinius.getDomain();
        vm.class =  ThemeClassify.getAllByCondition3({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'CLIPART'}]});
        // {key: "storeId", op: "=", value: vm.storeId},
        $q.all([vm.domains.$promise , vm.class.$promise]).then(function(){
            vm.class = vm.class.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        function loadAll () {
            var items = [];
            if (pagingParams.search) {
                items.push({key:"name,themeClassify.name",op:"like",value:pagingParams.search});
            }
            if (vm.themeClassifyId) {
                items.push({key:"themeClassify.id",op:"=",value:vm.themeClassifyId});
            }
            Material.byCondition2({
                items:items,
                page: pagingParams.page - 1,
                size: 44,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.data = res.data;
                    vm.page = pagingParams.page;
                }else {
                    MessageService.error(res.message);
                }
            }
            function onError(error) {
                MessageService.error("素材获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                themeClassifyId:vm.themeClassifyId,
                
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/material/material-dialog.html',
                controller: 'MaterialDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-right',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'add'
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.edit = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/material/material-dialog.html',
                controller: 'MaterialDialogController',
                controllerAs: 'vm',
                windowClass:'modal-right',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'edit',
                            data: data
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.look = function (data) {
            $uibModal.open({
                templateUrl: 'app/entities/material/material-dialog.html',
                controller: 'MaterialDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'look',
                            data: data
                        };
                    }]
                }
            });
        }
        $scope.delete = function(id){
            MessageService.confirm("确认删除该数据？",function(){
                Material.delete({id: id},function (res) {
                    if(res.status == 200){
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                    }
                });
            },function(){});
        };
    }
})();
