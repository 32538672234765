(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InitStepOneController', InitStepOneController);

    InitStepOneController.$inject = ['$scope', '$state', 'UidService', 'Store', 'Qinius', 'MessageService', '$q', '$timeout', '$uibModal'];

    function InitStepOneController ($scope, $state, UidService, Store, Qinius, MessageService, $q, $timeout, $uibModal) {
        var vm = this;

        vm.saveStep = saveStep;
        vm.domains = Qinius.getDomain();
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function () {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.store = vm.store.data;
            // 更新 初始化
            vm.store.initialized = true;
            Store.update(vm.store);
        });

        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            if (!file.name.endsWith(".jpg")) {
                MessageService.error("图片格式不对，上传失败");
                return;
            }
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "logo-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    imgSrop($scope.domain + data.key, 300, 160)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }

        function imgSrop(url, width, height){
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                vm.store.menuLogo = data;
            });
        }

        $scope.removeFile = function() {
            vm.store.menuLogo = null;
        }

        function saveStep() {
            // if(vm.store.storeType2==null){
            //     MessageService.error("商户未设置商户类型");
            //     return
            // }

            if (vm.store.id !== null) {
                vm.isSaving = true;
                Store.update(vm.store, onSaveSuccess, onSaveError);
            } else {
                vm.isSaving = true;
                Store.save(vm.store, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            $scope.jumpOver();
        }

        $scope.jumpOver = function () {
            $state.go('guide-commodity', null, { reload: true });
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }

        window.showTip = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            $(".step-tip-text"+index).show();
        }

        $(".step-tip-text").hide();
        window.hideTip = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            $(".step-tip-text"+index).hide();
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

    }
})();
