(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('TextXmlController', TextXmlController);

    TextXmlController.$inject = ['$scope', '$q', '$state', 'FontXmls', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Qinius', '$uibModal'];

    function TextXmlController ($scope, $q, $state, FontXmls, ParseLinks, paginationConstants, pagingParams, MessageService, Qinius, $uibModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.status = status;

        // 获取qiniu服务器
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            loadAll();
        })

        function loadAll () {
            if (pagingParams.search) {
                FontXmls.byCondition2({
                    items: [{key:"name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                FontXmls.byCondition2({
                    items:[],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.textXmls = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message);
                    return
                }

            }
            function onError(error) {
                MessageService.error("文字模板获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

 
        $scope.rejected = function(item){
            MessageService.confirm("确认要拒绝该文字模板", function(){
                FontXmls.reject({id:item.id, status:"Rejected", remarks:""},function(){
                    MessageService.success("拒绝成功");
                    loadAll();
                },function(){
                    MessageService.error("拒绝失败");
                })
            })
        };
         
        $scope.delete = function(id){
            MessageService.confirm("确认删除该背景？",function(){
                FontXmls.delete({id: id},function () {
                    MessageService.success("删除成功");
                    loadAll();
                });
            },function(){});
        };
        function status(item) {
            switch (item) {
                case 'Creating':
                    return '新建';
                    break;
                case 'Created':
                    return '已创建';
                    break;
                case 'Approved':
                    return '已通过';
                    break;
                case 'Rejected':
                    return '已拒绝';
                    break;
                case 'Cancelled':
                    return '已取消';
                    break;
                default:
                    return '暂无状态'
            }
        }
    }
})();
