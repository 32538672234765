(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('ECommerceSetting', ECommerceSetting);

    ECommerceSetting.$inject = ['$resource'];

    function ECommerceSetting($resource) {
        var resourceUrl = 'manager/' + 'api/e-commerce-settings/:path/:id';
        var service = $resource(resourceUrl, {}, {
            'save': {
                method: 'POST'
            },
            'edit': {
                method: 'PUT'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            "getECommerceSettingByStoreId": {
                method: "GET",
                params: {
                    path: "getECommerceSettingByStoreId"
                }
            },
            "getECommerceSettingByAppId": {
                method: "GET",
                params: {
                    path: "getECommerceSettingByAppId"
                }
            },
        });
        return service;
    }

})();
