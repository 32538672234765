(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Invoice', Invoice);

    Invoice.$inject = ['$resource', 'DateUtils'];

    function Invoice ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/invoice/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'GET'
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                    }
                    return data;
                }
            },
            "create":{
                method: 'GET',
                params: {
                    path: 'create'
                }
            },
            'update': { method:'PUT' },
            'del': {
                method: "DELETE"
            },
            'changeStatus': {
                method: 'GET',
                params: {
                    path: 'changeStatus'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },

        });
    }
})();
