(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('InvoiceSetting', InvoiceSetting);

    InvoiceSetting.$inject = ['$resource', 'DateUtils'];

    function InvoiceSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/invoice-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'GET'
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'del': {
                method: "DELETE"
            },
            'changeStatus': {
                method: 'GET',
                params: {
                    path: 'changeStatus'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            }
        });
    }
})();
