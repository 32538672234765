(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('LocationProvice', LocationProvice)
        .factory('LocationCity', LocationCity)
        .factory('LocationDistrict', LocationDistrict)
        .factory('InvoiceLocation', InvoiceLocation);

     LocationProvice.$inject = ['$resource']

    LocationCity.$inject = ['$resource']

    LocationDistrict.$inject = ['$resource']

    InvoiceLocation.$inject = ['$resource', 'DateUtils'];

    function LocationProvice($resource) {
        var resourceUrl = 'manager/' + 'api/location-provinces/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            },
            "other":{
                method:"GET",
                params:{
                    path:"other"
                }
            }
        });
    }

    function LocationCity($resource) {
        var resourceUrl = 'manager/' + 'api/location-cities/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            "findByProvince":{
                method:"GET",
                params:{
                    path:"findByProvince"
                }
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                }
            }
        });
    }

    function LocationDistrict($resource) {
        var resourceUrl = 'manager/' + 'api/location-districts/:path/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            "findByCities":{
                method:"GET",
                params:{
                    path:"findByCities"
                }
            },
            "findByName":{
                method:"GET",
                params:{
                    path:"findByName"
                },
            }

        });
    }


    function InvoiceLocation ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/invoice-locations/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'GET',
                params: {
                    path: 'all'
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'del': {
                method: "DELETE"
            }
        });
    }
})();
