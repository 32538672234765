(function () {
    'use strict';
    angular
        .module('backApp')
        .factory('CreditRecords', CreditRecords);

    CreditRecords.$inject = ['$resource'];

    function CreditRecords($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/credit-records/:path/:id';

        return $resource(resourceUrl, {}, {
            'save': {
                method: 'POST'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition": {
                method: "POST",
                params: {
                    path: "byCondition"
                }
            },
            "delete": {
                method: "DELETE"
            }
        });
    }

})();
