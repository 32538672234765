(function () {
    'use strict';

    angular
        .module('backApp')
        .factory('User', User)
        .factory('Charge', Charge)
        .factory('Accounts', Accounts);

    User.$inject = ['$resource'];
    Charge.$inject = ['$resource'];
    Accounts.$inject = ['$resource'];

    function User($resource) {
        var service = $resource('service/api/users/:path/:id', {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'activate': {method: 'GET', params: {path: "activate"}},
            'save': {
                method: 'POST'
            },
            'update': {
                method: 'PUT'
            },
            'delete': {
                method: 'DELETE'
            }
        });

        return service;
    }

    function Charge($resource) {
        var service = $resource('/security/api/charge-orders/:path/:id', {}, {
            'get': {
                method: "GET", isArray: true
                // transformResponse: function(data) {
                //     // console.log(data);
                //     // data = angular.fromJson(data);
                //     return data;
                // }
            }
        });

        return service;
    }


    function Accounts($resource) {
        var service = $resource('/security/api/account/resetPassword/:id', {}, {
            'get': {
                method: "GET", isArray: true,
                transformResponse: function(data) {
                    // console.log(data);
                    // data = angular.fromJson(data);
                    return data;
                }
            }
        });

        return service;
    }
})();
