(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Mould2Ds', Mould2Ds);

    Mould2Ds.$inject = ['$resource'];

    function Mould2Ds ($resource) {
        var resourceUrl =  'manager/' + 'api/mould-2-ds/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "getone":{
                method:"GET",
                params:{
                    path:"getone"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "batchCreate":{
                method:"GET",
                params:{
                    path:"batchCreate"
                }
            },
            "batchCreateByStore":{
                method:"GET",
                params:{
                    path:"batchCreateByStore"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                }
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                }
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                }
            },
            "save":{
                method:"POST",
            },
            "delete":{
                method:"DELETE",
            },
            "themeClassify":{
                method:"GET",
                params:{
                    path:"saveTheme"
                }
            },
            'update': {
                method:'PUT',
            },
        });
    }
})();
