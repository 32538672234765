(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('ToneSettings', ToneSettings);

    ToneSettings.$inject = ['$resource'];

    function ToneSettings ($resource) {
        var resourceUrl =  'manager/' + 'api/tone-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byStoreId': {
                method: 'GET',
                params:{
                    path:"byStoreId"
                }
            },
            "save":{
                method:"POST"
            },
            "delete":{
                method:"DELETE"
            },
            'update': { method:'PUT' },
        });
    }
})();
