(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('DiyEditorSetting', DiyEditorSetting);

    DiyEditorSetting.$inject = ['$resource'];

    function DiyEditorSetting ($resource) {
        var resourceUrl =  'manager/' + 'api/diy-editor-settings/:path/:id';
        return $resource(resourceUrl, {}, {
            'getOne4Platform': {
                method: 'GET',
                params: {
                    path: 'getOne4Platform'
                }
            },
            'byCondition1': {
                method: 'POST',
                params: {
                    path: 'byCondition1'
                }
            },
            "getAllByCondition1":{
                method:"POST",
                params:{
                    path:"getAllByCondition1"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'save': { method:'POST' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            'enable':{
                method: 'GET',
                params: {
                    path: 'enable'
                }
            }
        });
    }
})();
