(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('sales-order', {
                parent: 'entity',
                 url: '/sales-order?page&sort&search&status&startTime&endTime&productId&categoryId&deleted&productName&payRefundStatus',
                data: {
                    authorities: ['ROLE_SALES_ORDER'],
                    pageTitle: 'backApp.salesOrder.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-orders.html',
                        controller: 'SalesOrderController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            status: $stateParams.status,
                            startTime: $stateParams.startTime,
                            endTime:$stateParams.endTime,
                            productName:$stateParams.productName,
                            categoryId:$stateParams.categoryId,
                            deleted:$stateParams.deleted,
                            payRefundStatus:$stateParams.payRefundStatus
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('orderStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('sales-order-detail', {
                parent: 'sales-order',
                url: '/sales-order/{id}',
                data: {
                    authorities: ['ROLE_SALES_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-detail.html',
                        controller: 'SalesOrderDetailController',
                        controllerAs: 'vm',
                        windowClass:'modal-right',
                        backdrop: 'static',
                        size: 'xs',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('orderStatus');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return SalesOrder.get({
                                    id: $stateParams.id
                                }).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('sales-order.edit', {
                parent: 'sales-order',
                url: '/sales-order/{id}/edit/{status}',
                data: {
                    authorities: ['ROLE_SALES_ORDER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/sales-order/sales-order-dialog.html',
                        controller: 'SalesOrderDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('salesOrder');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['SalesOrder', '$stateParams',
                        function(SalesOrder, $stateParams) {
                            return SalesOrder.get({id : $stateParams.id}).$promise;
                        }
                    ]
                }
            })
            .state('sales-order-data-export', {
                parent: 'sales-order',
                url: '/sales-order-data-export',
                data: {
                    authorities: ['ROLE_SALES_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/sales-order-data-export.html',
                        controller: 'SalesOrderExportDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('salesOrder');
                                $translatePartialLoader.addPart('product');
                                $translatePartialLoader.addPart('global');
                                return $translate.refresh();
                            }],
                            entity: ['SalesOrder', function(SalesOrder) {
                                return {};
                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', {}, {
                            reload: false
                        });
                    }, function() {
                        $state.go('sales-order');
                    });
                }]
            })
            .state('order-view-article', {
                parent: 'sales-order',
                url: '/view/{fileName}/{itemValueId}',
                data: {
                    authorities: ['ROLE_SALES_ORDER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/sales-order/order-view-article.html',
                        controller: 'OrderViewArticleController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXmlFileServes', '$stateParams', function(ProductXmlFileServes, $stateParams) {

                            }]
                        }
                    }).result.then(function() {
                        $state.go('sales-order', null, {
                            reload: false
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
        ;
    }

})();
