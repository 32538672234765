(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('MessageService', MessageService);

    MessageService.$inject = ['$uibModal'];

    function MessageService($uibModal) {
        var service = {
            confirm: confirm,
            successConfirm: successConfirm,
            comment:comment,
            loading:loading,
            error:error,
            notice:notice,
            info:info,
            success:success, 
            notify:notify


        };

        var settings = {
            title: 'The notice title.', // The notice's title.
            title_escape: false, // Whether to escape the content of the title. (Not allow HTML.)
            // text: 'The notice text.', // The notice's text.
            text_escape: false, // Whether to escape the content of the text. (Not allow HTML.)
            styling: "bootstrap3", // What styling classes to use. (Can be either jqueryui or bootstrap or bootstrap3.)
            // addclass: "", // Additional classes to be added to the notice. (For custom styling.)
            // cornerclass: "", // Class to be added to the notice for corner styling.
            // nonblock: false, // Create a non-blocking notice. It lets the user click elements underneath it.
            // nonblock_opacity: .2, // The opacity of the notice (if it's non-blocking) when the mouse is over it.
            history: false, // Display a pull down menu to redisplay previous notices, and place the notice in the history.
            // width: "300px", // Width of the notice.
            // min_height: "16px", // Minimum height of the notice. It will expand to fit content.
            type: "notice", // Type of the notice. "notice", "info", "success", or "error".
            icon: true, // Set icon to true to use the default icon for the selected style/type, false for no icon, or a string for your own icon class.
            // animation: "fade", // The animation to use when displaying and hiding the notice. "none", "show", "fade", and "slide" are built in to jQuery. Others require jQuery UI. Use an object with effect_in and effect_out to use different effects.
            // animate_speed: "slow", // Speed at which the notice animates in and out. "slow", "def" or "normal", "fast" or number of milliseconds.
            // opacity: 1, // Opacity of the notice.
            // shadow: true, // Display a drop shadow.
            // closer: true, // Provide a button for the user to manually close the notice.
            // closer_hover: true, // Only show the closer button on hover.
            // sticker: true, // Provide a button for the user to manually stick the notice.
            // sticker_hover: true, // Only show the sticker button on hover.
            // hide: true, // After a delay, remove the notice.
            delay: 5000 // Delay in milliseconds before the notice is removed.
                // mouse_reset: true, // Reset the hide timer if the mouse moves over the notice.
                // remove: true, // Remove the notice's elements from the DOM after it is removed.
                // insert_brs: true, // Change new lines to br tags.
                // stack: {"dir1": "down", "dir2": "left", "push": "bottom", "spacing1": 25, "spacing2": 25}
        };
        var current;

        function notice(content) {
            var hash = angular.copy(settings);
            hash.type = 'notice';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function info(content) {
            var hash = angular.copy(settings);
            hash.type = 'info';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function success(content) {
            var hash = angular.copy(settings);
            hash.type = 'success';
            hash.title = content;
            hash.nonblock = {
                nonblock: true,
                nonblock_opacity: .2
            };
            return this.notify(hash);
        };

        function error(content) {
            var hash = angular.copy(settings);
            hash.type = 'error';
            hash.title = content;
            return this.notify(hash);
        };

        function notify(hash) {
            if (current) {
                current.remove();
            }
            current = new PNotify(hash);
            return current; 

            var modalInstance = $uibModal.open({
                template: '<div class="modal-body">' + //
                    '<div class="row" style="text-align:center;">' + //
                    '<h3>' + hash.title + '</h3>' + //
                    '</div>' + // 
                    '</div>', 
            });
        };

        function loading() {
            return $uibModal.open({
                template: '<i class="fa fa-5x fa-spinner fa-spin fa-white"></i>',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'loading-window'
            });
        };

        function confirm(msg, confirmCallback, cancelCallback, translate) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                }
            ];
            if(!!translate){
                var modalInstance = $uibModal.open({
                    template: '<div  style="position: relative;">' + //
                        '<div class="modal-top">操作</div>'+
                        '<div class="modal-row">' + //
                        '<span><label translate='+msg+'></label></span>' + //
                        '</div>' + //
                        '<div class="modal-foot2">' + //
                        '<button class="btn btn-warning2 mar-right-10" translate="entity.action.cancel" ng-click="cancel()"></button>' + //
                        '<button class="btn btn-primary width-80"  translate="entity.action.ok" ng-click="ok()"></button>' + //
                        '</div>' + //
                        '</div>',
                    controller: ModalInstanceCtrl,windowClass:'modal-center',
                });
            }else{
                var modalInstance = $uibModal.open({
                    template: '<div style="position: relative;">' + //
                        '<div class="modal-top">操作</div>'+
                        '<div class="modal-row">' + //
                        '<span>'+msg+'</span>' + //
                        '</div>' + //
                        '<div class="modal-foot2">' + //
                        '<button class="btn btn-warning2 mar-right-10"  ng-click="cancel()">取消</button>' + //
                        '<button class="btn btn-primary width-80" ng-click="ok()">确认</button>' + //
                        '</div>' + //
                        '</div>',
                    controller: ModalInstanceCtrl,windowClass:'modal-center',
                });
            }
            

            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });

        };

        function successConfirm(msg, confirmCallback, translate) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance',
                function($scope, $uibModalInstance) {

                    $scope.ok = function() {
                        $uibModalInstance.close('ok');
                    };

                }
            ];

            if(!!translate){
                 var modalInstance = $uibModal.open({
                    template: '<div class="modal-body">' + //
                        '<div class="row" style="text-align:center;">' + //
                        '<h3 style="cursor:pointer;" ng-click="ok()"><label translate='+msg+'></label></h3>',
                    controller: ModalInstanceCtrl
                });
            }else{
                var modalInstance = $uibModal.open({
                    template: '<div class="modal-body">' + //
                        '<div class="row" style="text-align:center;">' + //
                        '<h3 style="cursor:pointer;" ng-click="ok()">' + msg + '</h3>',
                    controller: ModalInstanceCtrl
                });
            }
            

           
            modalInstance.result.then(function() {
                if (typeof confirmCallback == 'function') {
                    confirmCallback();
                }
            });
        };

        function comment(msg, confirmCallback, cancelCallback, commentMust, translate) {
            var ModalInstanceCtrl = ['$scope', '$uibModalInstance', '$timeout',
                function($scope, $uibModalInstance, $timeout) {
                    $scope.ok = function(comment) {
                        if (commentMust && !comment) {
                            // return self.error('评论必填');
                            $scope.error = true;
                            return;
                        }
                        $scope.error = false;
                        $uibModalInstance.close(comment);
                    };
                    if (commentMust) {
                        $scope.placeholderMsg = "请输入评论(必填)";
                    } else {
                        $scope.placeholderMsg = "请输入评论(可空)";
                    }

                    $scope.cancel = function() {
                        $uibModalInstance.dismiss('cancel');
                    };
                    $timeout(function() {
                        $scope.$apply();
                    });
                }
            ];

            if(!!translate){
                var modalInstance = $uibModal.open({
                    template: '<div class="modal-body">' + //
                        '<div class="row" style="text-align:center;">' + //
                        '<h3><label translate='+msg+'></label></h3>' + //
                        '<input type="text" class="form-control highlight" ng-model="comment" placeholder="{{placeholderMsg}}">' + //
                        '<span style="color:red;float:left;margin-left:10px;" ng-show="error">必填</span>' + //
                        '</div>' + //
                        '<div class="row">' + //
                        '<button class="btn btn-warning pull-right" ng-click="cancel()">取消</button>' + //
                        '<button class="btn btn-primary pull-right" ng-click="ok(comment)">确认</button>' + //
                        '</div>' + //
                        '</div>',
                    controller: ModalInstanceCtrl
                });
            }else{
                var modalInstance = $uibModal.open({
                    template: '<div class="modal-body">' + //
                        '<div class="row" style="text-align:center;">' + //
                        '<h3>' + msg + '</h3>' + //
                        '<input type="text" class="form-control highlight" ng-model="comment" placeholder="{{placeholderMsg}}">' + //
                        '<span style="color:red;float:left;margin-left:10px;" ng-show="error">必填</span>' + //
                        '</div>' + //
                        '<div class="row">' + //
                        '<button class="btn btn-warning pull-right" ng-click="cancel()">取消</button>' + //
                        '<button class="btn btn-primary pull-right" ng-click="ok(comment)">确认</button>' + //
                        '</div>' + //
                        '</div>',
                    controller: ModalInstanceCtrl
                });
            }

            modalInstance.result.then(function(comment) {
                if (typeof confirmCallback == 'function') {
                    confirmCallback(comment);
                }
            }, function() {
                if (typeof cancelCallback == 'function') {
                    cancelCallback();
                }
            });
        };
        return service;
    }

})();
