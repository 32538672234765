(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceSettingListController', InvoiceSettingListController);

    InvoiceSettingListController.$inject =  ['$scope', '$q', '$state', 'InvoiceSetting', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService'];

    function InvoiceSettingListController ($scope, $q, $state, InvoiceSetting, ParseLinks, paginationConstants, pagingParams, MessageService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.del = del;

        loadAll();
        function loadAll () {
            if (pagingParams.search) {
                items.push({key:"title",op:"like",value:pagingParams.search});
                InvoiceSetting.byCondition({
                    items: items,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                InvoiceSetting.byCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.invoiceSettings = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message);
                }

            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function del (id) {
            InvoiceSetting.delete({id: id}, function(){
                loadAll();
                MessageService.success("删除成功");
            }, function (error) {
                MessageService.error("删除失败");
            });
        }
    }
})();
