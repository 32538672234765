(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotClearDialogController', MapDepotClearDialogController)

        MapDepotClearDialogController.$inject = ['$scope', '$state', 'Store', 'MessageService', '$timeout', 'StoragePayOrders', '$q', '$uibModalInstance','entity','StoragePrices1','$http','handlePackage'];

    function MapDepotClearDialogController($scope, $state, Store, MessageService, $timeout, StoragePayOrders, $q, $uibModalInstance, entity,StoragePrices1,$http,handlePackage) {
        var vm = this;
        vm.data = entity;
        // $q.all([ vm.data.$promise]).then(function() {
        //     if (vm.data.status && vm.data.status != 200) {
        //         MessageService.error(vm.data.message);
        //         return;
        //     }
        //     vm.data = vm.data.message;
        // });
        vm.save = function(){
            $uibModalInstance.close();
        }
        vm.save2 = function(){
           $uibModalInstance.close(vm.data);
        }
        //返回
        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.getStatus = function(){
            if (!vm.data || !vm.data.status) {
                return;
            }
            if (vm.data.cancelled) {
                return "已取消";
            }
            switch(vm.data.status){
                case "Calculating":
                    return "统计中";
                    break;
                case "Calculated":
                    return "待执行";
                    break;
                case "Executing":
                    return "计划中";
                    break;
                case "Executed":
                    return "已完成";
                    break;
                default:
                    return "";
            }
        }


    }
})();
