(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductDialogController', ProductDialogController);

    ProductDialogController.$inject = ['$timeout', '$scope', '$state', '$q', 'entity', 'Product', 'ProductXmlLines', 'UidService', 'Category',
        'MessageService', 'Qinius', 'Property', '$uibModal', '$stateParams', 'PlatformSetting'
    ];


    function ProductDialogController($timeout, $scope, $state, $q, entity, Product, ProductXmlLines, UidService, Category,
                                     MessageService, Qinius, Property, $uibModal, $stateParams, PlatformSetting) {
        var vm = this;
        localStorage.setItem('Production', 'edit');
        $scope.status = localStorage.getItem('Production');
        vm.product = entity.data;
        vm.clear = clear;
        vm.save = save;
        $scope.colspanLen = 3;
        vm.pTicketCheck = pTicketCheck;
        vm.platformSetting = PlatformSetting.get();
        // vm.paidProductXmlLines = ProductXmlLines.getPaidProductXmlLine();
        vm.domains = Qinius.getDomain();
        vm.title = "编辑"
        if(!vm.product.id){
            vm.title = "新建"
        }else if(vm.product.bokehType!="None"){
            vm.product.bokeh = true;
        }

        vm.categoriesAll = Category.storeGetAllByCondition({items: [{key:"usable",op:"=",value:'true'}]})

        var createModal = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass:'modal-right',
                controllerAs: 'vm',
                resolve: {
                    entity: item,
                }
            });
        };

         $scope.itemvalueDefault =  function(itemValue){
            for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                var value = vm.product.itemValues[i];
                if (value.id == itemValue.id) {
                    continue;
                }
                value.default = false;
            }
        }


        $scope.itemvalueLineDefault =  function(itemValue, line){
            for (var i = itemValue.itemValueLines.length - 1; i >= 0; i--) {
                var valueLine = itemValue.itemValueLines[i];
                if (line.id == valueLine.id) {
                    continue;
                }
                valueLine.default = false;
            }
        }


        $scope.amountChange = function (itemValue, amount) {
            if (itemValue == null || itemValue[amount] == null) {
                return;
            }
            if (!(/^-?\d+\.?\d{0,2}$/.test(itemValue[amount]))) {
                MessageService.error("金额只能输入小数点后两位数");
                itemValue[amount] = Math.round(itemValue[amount] * 100) / 100;
                return;
            }
        };

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        vm.multilingual2 = multilingual2;
        function multilingual2(item){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:item.productXmlLineLanguages,
                            key:"name",
                            fieldValue:item.name,
                            fieldName:"模板展示名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    item.productXmlLineLanguages=data;
                }
            });
        }

        $q.all([vm.domains.$promise, vm.product.$promise, vm.platformSetting.$promise, vm.categoriesAll.$promise]).then(function () {
            vm.platformSetting = vm.platformSetting.data;
            vm.categories = vm.categoriesAll.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itemValue = vm.product.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.productXmlLines && itemValue.productXmlLines.length > 0) {
                        itemValue.productXmlLines.sort(function(a, b){
                            return a.seq - b.seq;
                        })
                    }
                }
            }

            if (vm.product.objectProperties != null) {
                vm.product.defaultObjectProperties = [];

                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    vm.product.defaultObjectProperties.push(ob);
                }
            }
        }).then(function () {
        });

        function pTicketCheck(b) {
            vm.product.pTicket = b;
        }

        $scope.removeFile2 = function (itemValue) {
            itemValue.coverImg = null;
        };

        $scope.removeFile = function (coverImg) {
            vm.product.coverImg = null;
        };


        //标准印品文件移除
        $scope.removeFile3 = function (line) {
            line.fileIdentifier = null;
        };

        window.xmlLineProductFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get()+ ".pdf");
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.fileIdentifier = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        window.xmlLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                       for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.productXmlLines && item.productXmlLines.length>0) {
                                for (var j = item.productXmlLines.length - 1; j >= 0; j--) {
                                    var line = item.productXmlLines[j];
                                    if (line.uuid == uuid) {
                                        line.coverImg = $scope.domain + data.key;
                                        break;
                                    }
                                }
                            }

                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        //产品 coverImg更改
        window.updateImgFile1 = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.product.coverImg = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };


        // itemvalue封面图片修改
        window.itemvalueLineImgFile = function ($event) {
            $timeout(function () {
                var file = $event.target.files[0];
                var uuid = $event.target.id
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.cancelled || item.deleted) {
                                continue;
                            }
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.uploadFile = function (fileWatched, uuid) {
            $timeout(function () {
                var file = fileWatched;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader(); //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file); //将文件读取为DataURL,存在result中
                    reader.onload = function () { //  成功读取
                        $('.sweet-alert .confirm').trigger("click"); //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) { //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                            var item = vm.product.itemValues[i];
                            if (item.uuid == uuid) {
                                item.coverImg = data.key;
                                break;
                            }
                        }
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };

        $scope.buyXml = function (line) {
            console.log(line)
            // return
            ProductXmlLines.payProductXmlLine({id:line.id},function (res) {
                if(res.status == 200){
                    line.paid = true;
                    console.log(res.data);
                    // line = res.data;
                    MessageService.success(res.message);
                }else{
                    MessageService.error(res.message);
                }
            })
        }

        $scope.goToView = function (line) {
            console.log(line.productXmlIdentifier)
            if(!line.productXmlIdentifier){
                MessageService.error('请选择模板');
                return
            }
            if (!vm.product.bindingType) {
                MessageService.error('请设置翻页方式');
                return
            }
            createModal2('app/entities/product/product-view-article.html', 'ProductViewArticleController', line.productXmlIdentifier).result.then(
                function (data) {

                });
        };

        var createModal2 = function (url, controller,fileName) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity: {
                        fileName: fileName,
                        bindingtype:vm.product.bindingType,
                        product:vm.product,
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('product', null, {reload: 'product'});
        }

        $scope.validate = function () {
            if (vm.product.name == null || vm.product.name == "") {
                MessageService.error("请输入名称");
                return false;
            }
            return true;
        };


        function save() {
            if (!$scope.validate()) {
                return;
            }
            if (vm.product.bokeh == null || vm.product.bokeh == false) {
                vm.product.bokehType = "None";
            }
            vm.isSaving = true;
            if (vm.product.id !== null) {
                Product.update(vm.product, onSaveSuccess, onSaveError);
            } else {
                Product.save(vm.product, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(res) {
            if(res.status==200){
                $scope.$emit('backApp:productUpdate', res.data);
                $state.go('product', null, {reload: 'product'});
                MessageService.success("保存成功");
                vm.isSaving = false;
            }else{
                MessageService.error(res.message)
            }

        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".dialog-form").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".dialog-form").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow", "auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
        //关联模型
        $scope.addGltf = function () {
            $uibModal.open({
                templateUrl: 'app/entities/scene/scene-modal.html',
                controller: 'SceneModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return vm.product.moulds
                    }
                }
            }).result.then(function(result){
                if(result){
                    vm.product.moulds = result;
                }
            });
        }
        $scope.deleteMould = function (index) {
            vm.product.moulds.splice(index,1);
        }
    }
})();
