(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('invoice-setting-list', {
            parent: 'entity',
            url: '/invoice-setting-list?page&sort&search',
            data: {
                authorities: ["ROLE_INVOICE_INFOMATION"],
                pageTitle: 'global.menu.entities.invoiceSetting'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-setting/invoice-settings.html',
                    controller: 'InvoiceSettingListController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        
        .state('invoice-setting-detail', {
            parent: 'invoice-setting-list',
            url: '/invoice-setting-detail/{id}',
            data: {
                authorities: ["ROLE_INVOICE_INFOMATION"],
                pageTitle: 'home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-setting/invoice-setting-detail.html',
                    controller: 'InvoiceSettingController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invoice');
                    return $translate.refresh();
                }],
                entity: ['InvoiceSetting', '$stateParams', function(InvoiceSetting, $stateParams) {
                    return InvoiceSetting.get({id: $stateParams.id}).$promise;
                }]
            }
        })
        .state('invoice-setting', {
            parent: 'invoice-setting-list',
            url: '/invoice-setting/{id}',
            data: {
                authorities: ["ROLE_INVOICE_INFOMATION"],
                pageTitle: 'home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-setting/invoice-setting.html',
                    controller: 'InvoiceSettingController',
                    controllerAs: 'vm'
                }
            },
            
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invoice');
                    return $translate.refresh();
                }],
                entity: ['InvoiceSetting', '$stateParams',  function(InvoiceSetting, $stateParams) {
                    return InvoiceSetting.get({id: $stateParams.id}).$promise;
                }]
            }
        })
        .state('invoice-setting.new', {
            parent: 'invoice-setting-list',
            url: '/invoice-settingNew',
            data: {
                authorities: ["ROLE_INVOICE_INFOMATION"],
                pageTitle: 'home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/invoice-setting/invoice-setting.html',
                    controller: 'InvoiceSettingController',
                    controllerAs: 'vm'
                }
            },
            
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('invoice');
                    return $translate.refresh();
                }],
                entity: ['InvoiceSetting', '$stateParams',  function(InvoiceSetting, $stateParams) {
                    return {};
                }]
            }
        });
    }

})();