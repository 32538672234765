(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotClearRecordController', MapDepotClearRecordController);

        MapDepotClearRecordController.$inject = ['$scope', '$state', 'Store','MessageService', '$timeout', '$uibModal', 'paginationConstants','$q', 'StorageCleanSchedules', 'ParseLinks'];

    function MapDepotClearRecordController ($scope, $state, Store, MessageService, $timeout, $uibModal, paginationConstants,$q, StorageCleanSchedules, ParseLinks ) {
        var vm = this;
        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        init();
        function init(){
            var items = [];
            StorageCleanSchedules.byCondition({
                items: items,
                available: false,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.tableData = res.data;
            }, function (error) {
                MessageService.error("数据加载出错");
            });

        }

        $scope.check = function(item){
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot-clear-dialog.html',
                controller: 'MapDepotClearDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }],
                    entity: ['StorageCleanSchedules', function (StorageCleanSchedules) {
                        return item;
                    }]
                }
            }).result.then(function (result) {
                check(result);
            });
        }

        function check(item){
            if (!item) {
                return;
            }
            StorageCleanSchedules.doClean({
                id:item.id
            } , function(res){
                if (res.status == 200) {
                    MessageService.success("操作成功");
                    init();
                }
            })
        }

        $scope.calculate = function(item){
            if (!item || !item.id) {
                return;
            }
            StorageCleanSchedules.doCalculateData({id:item.id}, function (res) {
                MessageService.success("操作成功");
                init();
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        $scope.cancel = function(item){
            if (!item || !item.id) {
                return;
            }
            MessageService.confirm("确认取消当前清理计划？",function(){
                StorageCleanSchedules.delete({id:item.id}, function (res) {
                    MessageService.success("取消成功");
                    init();
                }, function (error) {
                    MessageService.error("数据加载出错");
                });
            },function(){});
        }

        $scope.view = function(item){
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot-clear-detail.html',
                controller: 'MapDepotClearDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }],
                    entity: ['StorageCleanSchedules', function (StorageCleanSchedules) {
                        return item;
                    }]
                }
            }).result.then(function () {
            });
        }

        $scope.getStatus = function(item){
            if (!item || !item.status) {
                return;
            }
            if (item.cancelled) {
                return "已取消";
            }
            switch(item.status){
                case "Calculating":
                    return "统计中";
                    break;
                case "Calculated":
                    return "待执行";
                    break;
                case "Executing":
                    return "计划中";
                    break;
                case "Executed":
                    return "已完成";
                    break;
                default:
                    return "";
            }
        }


        function transition() {
            init();
        }
    }
})();
