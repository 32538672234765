(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('diy-editor-setting', {
                parent: 'entity',
                url: '/diy-editor-setting',
                data: {
                    authorities: [],
                    pageTitle: 'global.menu.entities.editorDiy'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/diyEditorSetting/diy-editor-setting.html',
                        controller: 'DiyEditorSettingController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('diyEditorSetting');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
        .state('diy-editor-setting.edit', {
            parent: 'diy-editor-setting',
            url: '/{id}/edit',
            data: {
                authorities: [],
                pageTitle: 'global.menu.entities.editorDiy'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/diyEditorSetting/diy-editor-setting-dialog.html',
                    controller: 'DiyEditorSettingDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('diyEditorSetting');
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['DiyEditorSetting','$stateParams', function(DiyEditorSetting,$stateParams) {
                    return DiyEditorSetting.get({id:$stateParams.id}).$promise;
                }]
            }
        });
    }

})();
