(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FeedbackDetailController', FeedbackDetailController);

    FeedbackDetailController.$inject = ['$scope','$q','$timeout', '$rootScope', '$stateParams','MessageService' ,'$uibModalInstance', 'entity', 'Feedback','Qinius','UidService'];

    function FeedbackDetailController($scope, $q,$timeout,$rootScope, $stateParams,MessageService,$uibModalInstance, entity, Feedback,Qinius,UidService) {
        var vm = this;
        vm.feedback = entity;
        vm.askProblem = false;
        vm.askSubmit = false;
        vm.attachments = [];
        vm.imgs =[];
        console.log(entity);

        vm.feedback.feedbackLines.sort(function(a, b){
            return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });

        vm.clear = clear;
        vm.save = save;
        vm.unresolved = unresolved;
        vm.resolve = resolve;

        function resolve(id){
            Feedback.resolve({
               id:id
            }, onSuccess, onError);
        };

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.feedback.$promise]).then(function(){
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        });

        //上传附件
        window.updateImgFile = function ($event) {
            vm.imgs = [];
            $timeout(function () {
                for(var i=0;i<$event.target.files.length;i++){
                    var file = $event.target.files[i];
                    vm.imgs.push(file.name);
                    fileGet(file);
                }
                function fileGet(file){
                    var getBase64 = function (file, callback) {
                        var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                        reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                        reader.onload = function () {       //  成功读取
                            $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                        };
                        reader.onerror = function (error) {     //  出错
                        };
                    };
                    getBase64(file, function (result) {
                    });
                    var formData = new FormData();
                    formData.append('file', file);
                    formData.append('unique_names', false);
                    formData.append('key', "feedback-"+ UidService.get());
                    formData.append('token', vm.token);
                    $.ajax({
                        cache: true,
                        type: "POST",
                        url: 'https://upload.qiniup.com/',
                        data: formData,
                        async: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            console.log(data);
                            vm.attachments.push({provider:"qiniu", identifier:data.key,fileName:vm.imgs[i]});
                            console.log(vm.attachments);
                        },
                        error: function (request) {
                            MessageService.error("文件上传失败");
                        }
                    });

                }
            });
        };


        //文件下载
        $scope.downloadZipFile3 = function(attachment){
            var storeId = JSON.parse(localStorage.getItem('back-user')).storeId;
            window.open("fileserves/api/download/downloadZipFile3?key="+attachment.identifier+'&name='+attachment.fileName+'&storeId='+storeId);
        };

        $scope.validate = function(){
            if(vm.askContent == null || vm.askContent==""){
                MessageService.error("请输入追问内容");
                return false;
            }
            //if(vm.imgs== null || vm.imgs==""){
            //    MessageService.error("请上传附件");
            //    return false;
            //}
            return true;
        };

        $scope.removeFile = function(file,index) {
            vm.imgs.splice(index, 1);
            vm.attachments.splice(index,1);
            console.log(vm.imgs);
            console.log(vm.attachments);
        };

        function unresolved(){
            vm.askProblem = true;
            vm.askSubmit = true;
        };


        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.line ={};
            vm.line.attachments = vm.attachments;
            vm.line.feedbackId = vm.feedback.id;
            vm.line.description = vm.askContent;
            vm.line.type = "Asking";
            console.log(vm.line);
            Feedback.unresolved(vm.line, onSuccess, onError);
        }
        function onSuccess (result) {
            $scope.$emit('backApp:feedbackUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }
        function onError(error) {
            MessageService.error("状态修改失败");
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        var unsubscribe = $rootScope.$on('backApp:feedbackUpdate', function(event, result) {
            vm.feedback = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
