(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MouldDialogController', MouldDialogController);

    MouldDialogController.$inject = ['$timeout', '$scope', 'MessageService', '$state', 'entity', 'Mould', 'Category', 
    'Property', '$q', 'ListService', 'UidService', '$localStorage'];

    function MouldDialogController($timeout, $scope, MessageService, $state, entity, Mould, Category, Property,
     $q, ListService, UidService, $localStorage) {
        var vm = this;

        vm.mould = entity.data;
        vm.clear = clear;
        vm.save = save;
        vm.categoriesAll = Category.storeGetAllByCondition({
            items: [{key: "usable", op: "=", value: "true"}]
        });

        vm.title = "编辑"
        if(!vm.mould.id){
            vm.title = "新建"
        }
        $scope.validate = function(){
            if (vm.mould.name == null || vm.mould.name == "") {
                MessageService.error("请输入名称")
                return false;
            }

            if (vm.mould.categoryId == null || vm.mould.categoryId == "") {
                MessageService.error("请选择分类")
                return false;
            }

            if (vm.mould.mouldProperties == null || vm.mould.mouldProperties.length == 0) {
                MessageService.error("请选择属性")
                return false;
            }

            var arr = [];
            angular.forEach(vm.mould.mouldProperties,function (mould) {
                if(!mould.cancelled){
                    arr.push(mould);
                }
            })

            if (arr.length) {
                for (var i = 0; i < arr.length; i++) {
                    var item = arr[i];
                    if(!item.propertyId){
                        MessageService.error("请选择规格属性");
                        return false;
                        // break;
                    }
                }

                //筛选出重复的  比较propertyId
                vm.arr1 = uniqeByKeys(arr, ['propertyId']);
                console.log(vm.arr1)
                if(vm.arr1.length!=arr.length){
                    MessageService.error("请删除重复规格")
                    return false;
                }
            }
            return true;
        }

        function obj2key(obj, keys) {
            var n = keys.length,
                key = [];
            while (n--) {
                key.push(obj[keys[n]]);
            }
            return key.join('|');
        }

        //去重操作
        function uniqeByKeys(array, keys) {
            var arr = [];
            var hash = {};
            for (var i = 0, j = array.length; i < j; i++) {
                if(!array[i].cancelled&&!array[i].deleted){
                    var k = obj2key(array[i], keys);
                    if (!(k in hash)) {
                        hash[k] = true;
                        arr.push(array[i]);
                    }
                }else{
                    arr.push(array[i]);
                }

            }
            return arr;
        }

        $q.all([vm.categoriesAll.$promise]).then(function () {
            vm.categories = vm.categoriesAll.data;
            Property.getAllByCondition({
                items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}]
            },function (res) {
                if(res.status==200){
                    vm.properties = res.data;
                }else{
                    MessageService.error(res.message);
                }
            });

            console.log(vm.mould)
        })


        $scope.addItemValue = function () {
            var objectValue = {
                added: true,
                deleted: false,
                uuid: UidService.get()
            };
            if(!vm.mould.mouldProperties){
                vm.mould.mouldProperties = []
            }
            vm.mould.mouldProperties.push(objectValue)
        }

        $scope.deleteValue = function (value, properties) {
            if (value == null) {
                return;
            }

            if (!value.added) {
                value.deleted = true;
            } else {
                var ind = properties.indexOf(value);
                if (ind >= 0) {
                    properties.splice(ind, 1);
                }
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        })

        function clear() {
            $state.go('mould', null, {reload: 'mould'});
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            vm.isSaving = true;
            if (vm.mould.id !== null) {
                Mould.update(vm.mould, onSaveSuccess, onSaveError);
            } else {
                Mould.save(vm.mould, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(res) {
            if(res.status==200){
                $scope.$emit('platformApp:mouldUpdate', res.data);
                $state.go('mould', null, {reload: 'mould'});
                vm.isSaving = false;
                MessageService.success("保存成功");
            }else{
                MessageService.error(res.message);
            }

        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败");
        }


    }
})();
