(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceSettingController', InvoiceSettingController);

    InvoiceSettingController.$inject = ['$state','$scope', 'MessageService', 'InvoiceSetting', "entity", "$q"];

    function InvoiceSettingController($state, $scope, MessageService, InvoiceSetting, entity, $q) {

        var vm = this;

        vm.save = save;
        vm.editor = editor;

        vm.back = back;

        function back(){
            $state.go("invoice-setting-list", {id:vm.invoiceSetting.id});
        }

        vm.clear = clear;

        function clear(){
            $state.go("invoice-setting-detail", {id:vm.invoiceSetting.id});
        }
        vm.openTypeChange = openTypeChange;

        function editor (){
            $state.go("invoice-setting", {id:vm.invoiceSetting.id});
        }
        vm.invoiceSetting = entity;

        $q.all([vm.invoiceSetting.$promise]).then(function(){
            vm.invoiceSetting = vm.invoiceSetting.data;
            vm.title2 = "个人";
            if (vm.invoiceSetting != undefined &&vm.invoiceSetting != null && vm.invoiceSetting.id != null) {
                if (vm.invoiceSetting.openType != null) {
                    vm.openType = vm.invoiceSetting.openType != 'Company';
                }
                if (vm.invoiceSetting.invoiceType != null) {
                    vm.invoiceType = vm.invoiceSetting.invoiceType == 'Ordinary';
                }
            } else {
                vm.invoiceSetting = {};
                vm.openType = true;
                vm.invoiceType = true;
            }
        });


        $scope.validate = function(data) {
            if (!vm.openType) {
                if (data.title == null || data.title == "") {
                    MessageService.error("请输入发票抬头");
                    vm.isSaving = false;
                    return false;
                };
            } else {
                vm.invoiceSetting.title = "个人";
                vm.invoiceType = true;
            }

            // 增值税专用发票--不可为空， 增值税普通发票可为空
            if (!vm.invoiceType) {
                if (data.taxRegistrationNumber == null || data.taxRegistrationNumber == "") {
                    MessageService.error("请输入税务登记证号");
                    vm.isSaving = false;
                    return false;
                };


            }
            return true;
        }

        function save() {
            if (vm.openType) {
                vm.invoiceSetting.openType = "Personal";
            } else {
                vm.invoiceSetting.openType = "Company";
            }

            if (vm.invoiceType) {
                vm.invoiceSetting.invoiceType = "Ordinary";
            } else {
                vm.invoiceSetting.invoiceType = "Special";
            }
            if (!$scope.validate(vm.invoiceSetting)) {
                return;
            };
            vm.isSaving = true;
            InvoiceSetting.update(vm.invoiceSetting, function(result){
                vm.isSaving = false;
                MessageService.success("操作成功");
                $state.go("invoice-setting-detail",{id:result.data.id});
            }, function(){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }

        function openTypeChange(int) {
            vm.invoiceSetting.title = '';
        }
    }
})();
