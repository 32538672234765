(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('FileService2', FileService2)
        .factory('Music', Music)
        .factory('ProductXmlFileServes', ProductXmlFileServes)
        .factory('ItemValue', ItemValue)
        .factory('ProductXmlLines', ProductXmlLines)
        .factory('Product', Product)
        .factory('Vert', Vert)
        .factory('CommodityPrice', CommodityPrice)
        .factory('Tariffs', Tariffs)
        .factory('PlatformSetting', PlatformSetting);

    Product.$inject = ['$resource', 'DateUtils'];
    ProductXmlFileServes.$inject = ['$resource'];
    ItemValue.$inject = ['$resource'];
    ProductXmlLines.$inject = ['$resource'];

    FileService2.$inject = ['$resource'];
    Music.$inject = ['$resource', 'DateUtils'];
    Vert.$inject = ['$resource'];
    CommodityPrice.$inject = ['$resource'];
    Tariffs.$inject = ['$resource'];
    PlatformSetting.$inject = ['$resource'];

    function Music($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/music/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'allByCondition': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                }
            },
            'get': {
                method: 'GET'
            }
        });
    }

    function ItemValue ($resource) {
        var resourceUrl =  'manager/' + 'api/item-values/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }


    function ProductXmlFileServes ($resource) {
        var resourceUrl =  'fileserves/' + 'api/product-xmls/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'updatePageSize': {
                method: 'GET',
                params: {
                    path: 'updatePageSize'
                }
            },
            "updateCountTip":{
                method:"POST",
                params:{
                    path:"updateCountTip"
                }
            },
            "batchUpdateCountTip":{
                method:"GET",
                params:{
                    path:"batchUpdateCountTip"
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ProductXmlLines ($resource) {
        var resourceUrl =  'manager/' + 'api/product-xml-lines/:path';

        return $resource(resourceUrl, {}, {
            'payProductXmlLine': {
                method: 'GET',
                params: {
                    path: 'payProductXmlLine'
                }
            },
            'getPaidProductXmlLine' : {
                method: 'POST',
                params: {
                    path: 'getPaidProductXmlLine'
                }
            }
        });
    }

    function Vert ($resource) {
        var resourceUrl =  'fileserves/' + 'api/convert/:path/:storeId';

        return $resource(resourceUrl, {}, {
            "xmlToDocument":{
                method:"GET",
                params:{
                    path:"xmlToDocument"
                }
            },
            "imgFilter":{
                method: 'GET',
                params: {
                    path: "filter"
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }

    function FileService2 ($resource) {
        var resourceUrl =  'fileserves/' + 'api/download/:path/:id';
        return $resource(resourceUrl, {}, {
            'downloadZipFileCheck': {
                method: 'GET',
                params: {
                    path: 'downloadZipFileCheck'
                }
            },
            'downloadZipFile3': {
                method: 'GET',
                params: {
                    path: 'downloadZipFile3'
                }
            },
        });
    }



    function Product ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/products/:path/:id';

        return $resource(resourceUrl, {}, {
             
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                }
            },
            "updatePrice":{
                method:"GET",
                params:{
                    path:"updatePrice",
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                }
            },
            "shareProducts":{
                method:"GET",
                params:{
                    path:"shareProducts"
                }
            },

            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            },
            "getSupplierProducts":{
                method:"GET",
                params:{
                    path:"getSupplierProducts",
                }
            },
            "grounding":{
                method:"GET",
                params:{
                    path:"grounding",
                }
            },
            "grounded":{
                method:"GET",
                params:{
                    path:"grounded",
                }
            }
        });
    }

    function CommodityPrice ($resource) {
        var resourceUrl =  'manager/' + 'api/commodity-prices/:path/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPriceByProductAndStore': {
                method: 'GET',
                params: {
                    path: 'getPriceByProductAndStore'
                }
            }
        })
    }

    function Tariffs ($resource) {
        var resourceUrl =  'manager/' + 'api/tariffs/:path/:id';

        return $resource(resourceUrl, {}, {
            'findByItemValueLineId': {
                method: 'GET',
                params: {
                    path: 'findByItemValueLineId'
                }
            }
        });
    }

    function PlatformSetting ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/platform-settings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET'},
            'get': {
                method: 'GET'

            },
            'update': { method:'PUT' }
        });
    }

})();
