(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dataAnalysis', {
            parent: 'entity',
            url: '/da',
            data: {
                authorities: ['ROLE_STATISTICS'],
                pageTitle: 'global.menu.entities.da'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/da/da.html',
                    controller: 'DaController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sendRule');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('salesAnalysis', {
            parent: 'entity',
            url: '/salesAnalysis',
            data: {
                authorities: ['ROLE_STATISTICS'],
                pageTitle: 'global.menu.entities.da'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/da/salesAnalysis.html',
                    controller: 'SalesAnalysisController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('sendRule');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('userAnalysis', {
            parent: 'entity',
            url: '/userAnalysis',
            data: {
                authorities: ['ROLE_STATISTICS'],
                pageTitle: 'global.menu.entities.da'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/da/userAnalysis.html',
                    controller: 'UserAnalysisController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {  
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        ;
    }

})();
