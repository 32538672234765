(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreUserDialogController', StoreUserDialogController);

    StoreUserDialogController.$inject = ['$timeout', '$scope', 'StoreUser1', '$stateParams', '$interval', '$state', '$q', 'Store', 'entity', 'StoreUser', 'Role', 'MessageService', 'VerifyCode'];

    function StoreUserDialogController($timeout, $scope, StoreUser1, $stateParams, $interval, $state, $q, Store, entity, StoreUser, Role, MessageService, VerifyCode) {
        var vm = this;
        vm.storeUser = entity;
        console.log(vm.storeUser);
        vm.clear = clear;
        vm.save = save;
        vm.rolesAll = Role.getAllByCondition2({ items: [{ key: "type", op: "=", value: "STORE" }] });
        $q.all([vm.storeUser.$promise, vm.rolesAll.$promise]).then(function () {
            vm.roles = vm.rolesAll.data;
            if (!!vm.storeUser.id) {
                vm.title = "编辑"
            } else {
                vm.title = "新建"
            }
        });

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $state.go('store-user', null, { reload: 'store-user' });
        }
        // 新建验证
        $scope.validate = function () {
            if (vm.storeUser.name == null || vm.storeUser.name == "") {
                MessageService.error("请输入用户名称");
                return false;
            }
            if (!vm.storeUser.phone) {
                if (vm.phone == null || vm.phone == '') {
                    MessageService.error("请输入手机号码");
                    return false;
                }
                if (vm.phone.length != 11) {
                    MessageService.error("手机号码应为11位");
                    return false;
                }
                if (vm.verifyCode == null || vm.verifyCode == '') {
                    MessageService.error("验证码不能为空");
                    return false;
                }
            }
            if (vm.storeUser.roleId == null || vm.storeUser.roleId == "") {
                MessageService.error("请选择角色");
                return false;
            }
            return true;
        }
        // 编辑验证
        $scope.validate2 = function () {
            if (vm.storeUser.name == null || vm.storeUser.name == "") {
                MessageService.error("请输入用户名称");
                return false;
            }
            if (vm.storeUser.roleId == null || vm.storeUser.roleId == "") {
                MessageService.error("请选择角色");
                return false;
            }
            return true;
        }

        function save() {
            if (!$scope.validate()) {
                return;
            }
            if (vm.storeUser.id != null) {
                checkUser(function () {
                    vm.isSaving = true;
                    if (!vm.storeUser.phone) {
                        vm.storeUser.phone = vm.phone;
                        vm.storeUser.code = vm.verifyCode;
                    }
                    StoreUser1.update(vm.storeUser, onSaveSuccess, onSaveError);
                })
            } else {
                checkUser(function () {
                    vm.isSaving = true;
                    vm.storeUser.langKey = "zh-cn";
                    vm.storeUser.phone = vm.phone;
                    vm.storeUser.message = vm.verifyCode;
                    Store.creagteUser(vm.storeUser, onSaveSuccess, onSaveError);
                })
            }
        }
        function onSaveSuccess(result) {
            vm.isSaving = false;
            $scope.$emit('backApp:storeUserUpdate', result);
            $state.go('store-user', null, { reload: 'store-user' });
            MessageService.success("保存成功");
        }
        function onSaveError() {
            vm.storeUser.phone = null;
            vm.isSaving = false;
            MessageService.error("保存失败");
        }
        function checkUser(callback) {
            if (!vm.storeUser.phone) {
                var _data = { phone: vm.phone };
                if (!vm.storeUser.id) {
                    _data.username = vm.storeUser.name
                }
                StoreUser.checkUser2(_data, function (data) {
                    if (!data.data) {
                        MessageService.error("该手机号或者用户名已存在");
                    } else {
                        callback()
                    }
                }, function () {
                    MessageService.error("校验用户名时出错");
                });
            } else {
                callback()
            }
        }
        // 获取验证码
        $scope.paracont = "获取验证码";
        vm.disabled = false;
        //验证码的定时器
        var timer = null;
        $scope.getVerifyCode = function () {
            if (vm.phone == null || vm.phone == "") {
                MessageService.error("请先输入手机号码");
                return;
            }
            if (vm.phone.length != 11) {
                MessageService.error("手机号码应为11位");
                return;
            }
            if (vm.disabled) {
                return;
            }
            vm.countdown = 120;
            // 发送验证码到手机
            VerifyCode.getVerifyCode2({
                phone: vm.phone,
                incident: "新用户注册"
            }, function (msg) {
                if (msg.status == 200) {
                    MessageService.success(msg.message == null ? '短信发送成功' : msg.message);
                } else {
                    MessageService.error(msg.message == null ? '短信发送失败' : msg.message);
                    return;
                }
                clearInterval(timer);
                timer = $interval(function () {
                    if (vm.countdown > 0) {
                        vm.disabled = true;
                        vm.countdown--;
                        $scope.paracont = "重新发送(" + vm.countdown + ")";
                    } else {
                        vm.disabled = false;
                        $interval.cancel(timer);
                        $scope.paracont = "重发验证码";
                    }
                }, 1000);
            });
        };
    }
})();
