(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('configuration2', {
                parent: 'entity',
                url: '/configuration2',
                data: {
                    authorities: ["ROLE_STORE"],
                    pageTitle: 'global.menu.entities.configuration'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/configuration/configuration.html',
                        controller: 'ConfigurationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('configuration2.new', {
                parent: 'configuration2',
                url: '/add',
                data: {
                    authorities: ["ROLE_STORE"],
                    pageTitle: 'global.menu.entities.configuration'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/configuration/configuration-dialog.html',
                        controller: 'ConfigurationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function() {
                        return {
                            data:{
                                appId: null,
                                appSecret: null,
                                domain:null,
                                combinationNotifyUrl: "/api/sales-orders/combinationNotify",
                                articleStatusNotifyUrl:"/api/articles/updateStatus",
                                wechatInfoUrl:"/api/wechat-settings/getOne",
                                enable:true,
                                id: null
                            }
                        };
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('configuration2-dialog', {
                parent: 'configuration2',
                url: '/{id}/edit',
                data: {
                    authorities: ["ROLE_STORE"],
                    pageTitle: 'global.menu.entities.configuration'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/configuration/configuration-dialog.html',
                        controller: 'ConfigurationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: { 
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['ECommerceSetting', '$stateParams', function(ECommerceSetting, $stateParams) {
                        return ECommerceSetting.get({
                            id: $stateParams.id
                        }).$promise;
                    }]
                }
            })
    }

})();
