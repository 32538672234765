(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('message-notification', {
            parent: 'logo-setting',
            url: '/message-notification?page&sort&search&pageType',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'backApp.messageNotification.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/message-notification/message-notifications.html',
                    controller: 'MessageNotificationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        pageType: $stateParams.pageType,
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('messageNotification');
                    $translatePartialLoader.addPart('pageType');
                    $translatePartialLoader.addPart('messageType');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('message-notification-detail', {
            parent: 'message-notification',
            url: '/message-notification/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'backApp.messageNotification.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/message-notification/message-notification-detail.html',
                    controller: 'MessageNotificationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('messageNotification');
                    $translatePartialLoader.addPart('pageType');
                    $translatePartialLoader.addPart('messageType');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MessageNotification', function($stateParams, MessageNotification) {
                    return MessageNotification.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'message-notification',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
         .state('message-notification.new', {
                parent: 'message-notification',
                url: '/new',
                data: {
                    authorities: [],
                    pageTitle: 'backApp.messageNotification.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/message-notification/message-notification-dialog.html',
                        controller: 'MessageNotificationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('messageNotification');
                        $translatePartialLoader.addPart('pageType');
                        $translatePartialLoader.addPart('messageType');
                        return $translate.refresh();
                    }],
                    entity: function () {
                        return {
                            data:{
                                content: null,
                                validate: null,
                                top: null,
                                available: null,
                                seq: null,
                                protoId: null,
                                pageType: null,
                                type: null,
                                id: null
                            }
                        };
                    }
                }
            })
        .state('message-notification.edit', {
            parent: 'message-notification',
                url: '/{id}/edit',
                data: {
                    authorities: [],
                    pageTitle: 'backApp.messageNotification.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/message-notification/message-notification-dialog.html',
                        controller: 'MessageNotificationDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('messageNotification');
                        $translatePartialLoader.addPart('pageType');
                        $translatePartialLoader.addPart('messageType');
                        return $translate.refresh();
                    }],
                    entity: ['MessageNotification', '$stateParams' , function(MessageNotification, $stateParams) {
                        return MessageNotification.get({id : $stateParams.id}).$promise;
                    }]
                }
        });
    }

})();
