(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MessageNotificationController', MessageNotificationController);

    MessageNotificationController.$inject = ['$scope', '$state', 'MessageNotification', 'ParseLinks', 'Principal', 'paginationConstants', 'pagingParams', 'MessageService'];

    function MessageNotificationController ($scope, $state, MessageNotification, ParseLinks, Principal, paginationConstants, pagingParams, MessageService) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.jump = jump;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.pageType = pagingParams.pageType;
        console.log(vm.pageType)
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.time = null;
        vm.pages = ['请选择展示页面','图片库','预览页','编辑页','平铺页'];

        loadAll();

        $scope.delete = function(item){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                MessageNotification.delete({id: item.id},function () {
                    loadAll();
                    MessageService.success('删除成功');
                });
            },function(){},true)
        };

        function loadAll () {
            var items = [ ];
            if (pagingParams.search) {
                items.push({key:"content",op:"like",value:pagingParams.search});
            }
            if(vm.pageType){
                items.push({key:"pageType",op:"=",value:vm.pageType});
            }
            MessageNotification.byCondition2({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.messageNotifications = res.data;
                    vm.messageNotifications.sort(function(a, b){return a.seq- b.seq})
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message)
                }

            }
            function onError(error) {
                MessageService.error("数据获取失败");
            }
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                pageType: vm.pageType,
            });
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function jump(){
            loadPage(vm.jumpText <= 0 ? 1 : vm.jumpText);
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.searchPageType = function () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }
    }
})();
