(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ShopModalController', ShopModalController);

    ShopModalController.$inject = ['$timeout', '$state', '$uibModalInstance'];

    function ShopModalController ($timeout, $state, $uibModalInstance) {
        var vm = this;
        vm.save = save;

        function save() {
            $uibModalInstance.close();
            $state.go('store')
        }
    }
})();
