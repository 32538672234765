(function () {
    'use strict';
    angular
        .module('backApp')
        .factory("AgentManagement", AgentManagement)
        .factory('StoreUser', StoreUser)
        .factory('StoreUser1', StoreUser1);

    StoreUser.$inject = ['$resource'];
    StoreUser1.$inject = ['$resource'];
    AgentManagement.$inject = ['$resource'];

    function AgentManagement($resource) {
        var resourceUrl = 'manager/' + 'api/agent-managements/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            'updateAgents': {
                method: 'POST',
                params: {
                    path: 'updateAgents'
                },
            }
        });
    }

    function StoreUser($resource) {
        var resourceUrl = 'security/' + 'api/store-users/:path/:id';
        return $resource(resourceUrl, {}, {
            "register": {
                method: 'POST',
                params: {
                    path: "register"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                }
            },
            "resetPassword": {
                method: "GET",
                params: {
                    path: "resetPassword"
                }
            },
            'checkUser': {
                method: "POST",
                params: {
                    path: "checkUser"
                }
            },
            'checkUser2': {
                method: "GET",
                params: {
                    path: "checkUser2"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
    function StoreUser1($resource) {
        var resourceUrl = 'manager/' + 'api/store-users/:path/:id';

        return $resource(resourceUrl, {}, {
            "register": {
                method: 'POST',
                params: {
                    path: "register"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "resetPassword": {
                method: "GET",
                params: {
                    path: "resetPassword"
                }
            },
            "createDefaultRole": {
                method: "GET",
                params: {
                    path: "createDefaultRole"
                }
            },
            "changePassword2": {
                method: "POST",
                params: {
                    path: "changePassword2"
                }
            },
            'checkUser': {
                method: "POST",
                params: {
                    path: "checkUser"
                },
                isArray: true
            },
            'checkUser2': {
                method: "GET",
                params: {
                    path: "checkUser2"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT'
            },
            'deleteBatch': {
                method: 'GET',
                params: {
                    path: 'deleteBatch'
                }
            }
        });
    }
})();
