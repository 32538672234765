(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CategoryDialogController', CategoryDialogController);

    CategoryDialogController.$inject = ['$timeout', '$q', '$scope', '$state', 'entity', 'Qinius', 'Category',  'MessageService',
     'UidService', '$uibModal'];

    function CategoryDialogController ($timeout, $q, $scope, $state, entity, Qinius, Category,  MessageService,
     UidService, $uibModal) {
        var vm = this;

        vm.category = entity.data;
        vm.clear = clear;
        vm.save = save;
        vm.title = "编辑";
        if(!vm.category.id){
            vm.title = "新建"
        }

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise]).then(function(){
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        })
        
        vm.multilingual = multilingual;
        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.category.categoryLanguages, 
                            key:"name",
                            fieldValue:vm.category.name,
                            fieldName:"分类名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.category.categoryLanguages=data;
                }
            });
        }

        //模板背景图更改
        window.updateImgFile = function ($event) {
            $scope.$digest();
            $timeout(function () {
                var file = $event.target.files[0];
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //  成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                    };
                    reader.onerror = function (error) {     //  出错
                    };
                };
                getBase64(file, function (result) {
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('unique_names', false);
                formData.append('key', "category-"+ UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        vm.category.imgUrl = data.key;
                    },
                    error: function (request) {
                        MessageService.error("文件上传失败");
                    }
                });
            });
        };
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });
        function clear () {
            $state.go('category', null, { reload: 'category' });
        }

        $scope.validate = function(){
            if(vm.category.name == null || vm.category.name==""){
                MessageService.error("请输入分类名称");
                return false;
            }

            if(vm.category.seq == null || vm.category.seq < 0){
                MessageService.error("优先值不能小于零");
                return false;
            }

            if (vm.category.imgUrl == null || vm.category.imgUrl=="") {
                MessageService.error("请上传展示图片");
                return false;
            };
            if(vm.category.usable == null || vm.category.usable==""){
                vm.category.usable = false;
            }
            return true;
        }
        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.categoryStoreCreate(vm.category, onSaveSuccess, onSaveError);
            }
        }


        $scope.removeFile = function(file) {
            vm.category.imgUrl = null;
        }


        function onSaveSuccess (result) {
            if(result.status == 200){
                $scope.$emit('backApp:categoryUpdate', result.data);
                $state.go('category', null, { reload: 'category' });
                vm.isSaving = false;
                MessageService.success("保存成功");
            }else {
                MessageService.error(result.message);
                return
            }
        }

        function onSaveError () {
            MessageService.error("保存失败");
            vm.isSaving = false;
        }


    }
})();
