(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotController', MapDepotController)
        .filter('sizeTransition', function () {
            return function (size) {
                if (!size)
                    return 0;
                var num = 1000.00;
                if (size < num)
                    return size + "B";
                if (size < Math.pow(num, 2))
                    return (size / num).toFixed(2) + "KB"; //kb
                if (size < Math.pow(num, 3))
                    return (size / Math.pow(num, 2)).toFixed(2) + "MB"; //M
                if (size < Math.pow(num, 4))
                    return (size / Math.pow(num, 3)).toFixed(2) + "GB"; //G
                return (size / Math.pow(num, 4)).toFixed(2) + "TB"; //T
            }
        });

    MapDepotController.$inject = ['$scope', '$state', 'handlePackage', 'Store', 'MessageService', '$timeout', '$uibModal', 'StoreStorages', '$q', 'StoreStorages1'];

    function MapDepotController($scope, $state, handlePackage, Store, MessageService, $timeout, $uibModal, StoreStorages, $q, StoreStorages1) {
        var vm = this;
        Store.getCurrent(function (res) {
            vm.store = res.data;
            init()
        });
        vm.sumSize = 0;
        vm.useSize = 0;
        vm.percentage = 0;
        vm.months = [];
        for (var i = 1; i < 25; i++) {
            vm.months.push({
                name: i,
                id: i
            })
        }
        function init() {
            StoreStorages1.byStoreId({ storeId: vm.store.id }, function (res) {
                vm.data = res.data;
                vm.sumSize = Number(res.data.initSize) + Number(res.data.buySize);
                vm.useSize = Number(res.data.usedSize);
                vm.percentage = vm.useSize / vm.sumSize;
            });
        }

        $scope.progressColor = function (size) {
            return progressColor(size)
        };
        function progressColor(size) {
            if(size <= 0.5){
                return '#2486FF'
            }
            if(size <= 0.75){
                return '#A13EFD'
            }
            if(size > 0.75){
                return '#F72E2A'
            }
        }

        $scope.add = function () {
            $uibModal.open({
                templateUrl: 'app/entities/map-depot/map-depot1.html',
                controller: 'MapDepot1Controller',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', function ($translate) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (result) {
                if (result) {
                    init()
                }
            });
        }
    }
})();
