(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderDetail1Controller', PayOrderDetail1Controller);

    PayOrderDetail1Controller.$inject = ['$timeout', '$scope', '$stateParams', '$q', 'entity', 'CommodityPrice', 'Product', 'Store', '$state', 'PayOrder', '$http', 'MessageService', '$sce'];

    function PayOrderDetail1Controller ($timeout, $scope, $stateParams, $q, entity, CommodityPrice, Product, Store, $state, PayOrder, $http, MessageService, $sce) {
        var vm = this;
        Store.getCurrent({},function (res) {
            if(res.status == 200){
                vm.store = res.data;
            }else{
                MessageService.error(res.message);
            }
        });
        vm.pay = entity.data;
        vm.cancel = cancel;
        vm.isPay = false;
        vm.payOrderId = "";


        $scope.recharge = function() {
            if (vm.store.balance !=0 && vm.store.balance >= 100000000) {
                MessageService.error("当前账户余额较多，请消费后再充值");
                return;
            }
            clearInterval($scope.timer);
            PayOrder.get({id:vm.pay.id} ,function(res){
                if(res.status == 200){
                    vm.payOrderId = res.data.id;
                    if (res.data.type == "WeChat") {
                        $http({
                            url: '/back/manager/api/pay-orders/createQRCode/'+vm.payOrderId,
                            method: 'POST',
                            responseType: 'blob'
                        }).success(function(res) {
                            vm.isPay = true;
                            var blob = new Blob([res], {
                                type: "image/jpeg"
                            });
                            $scope.image = URL.createObjectURL(blob);
                            //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                            $scope.timer = setInterval(function() {
                                PayOrder.get({
                                    id: vm.payOrderId
                                }, function(val) {
                                    if (!!val && val.paid) {
                                        clearInterval($scope.timer);
                                        MessageService.success("支付完成");
                                        vm.isPay = false;
                                        loadAll();
                                    }
                                }, function() {});
                            }, 2000);
                        })
                    }else{
                        PayOrder.aliPay({
                            id:res.data.id,
                            returnUrl:window.location.href
                        },function(data){
                            if(!!data && data.status == 200){
                                $scope.trustHtml = $sce.trustAsHtml(data.data);
                            }
                        },function(){
                        });
                    }
                }else{
                    MessageService.error(res.message);
                }
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }

        $scope.savePay = function () {
            PayOrder.get({
                id: vm.payOrderId
            }, function(res) {
                clearInterval($scope.timer);
                vm.isPay = false;
                if(res.status == 200){
                    if (!!res.data && res.data.paid) {
                        $state.go('pay-order');
                        MessageService.success("支付完成");
                    }else {
                        MessageService.error("支付失败");
                    }
                }else{
                    MessageService.error(res.message);
                }
            }, function() {
                MessageService.error("支付失败");
            });
        }
        $scope.cancelPay = function () {
            clearInterval($scope.timer);
            vm.isPay = false;
        }

        $scope.delete = function () {
            MessageService.confirm("确认取消当前支付信息吗？",function(){
                PayOrder.delete({id: vm.pay.id},function (res) {
                    if(res.status == 200){
                        $state.go('pay-order')
                        MessageService.success('取消成功');
                    }else{
                        MessageService.error(res.message);
                    }
                });
            },function(){},true);
        }


        //返回
        function cancel() {
            $state.go('pay-order')
        }


        vm.type = type;
        function type(target) {
            switch (target) {
                case "WeChat":
                    return "微信";
                default:
                    return "";
            }
        }
    }
})();
