(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CategoryController', CategoryController);

    CategoryController.$inject = ['$scope', '$q', '$state', 'ParseLinks', 'pagingParams', 'paginationConstants', 'Category','Product', 'MessageService', 'Qinius'];

    function CategoryController ($scope, $q, $state, ParseLinks, pagingParams, paginationConstants, Category,Product, MessageService, Qinius) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        vm.domains = Qinius.getDomain();

        $q.all([vm.domains.$promise]).then(function(){
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            loadAll();
        })
        function loadAll () {
            if (pagingParams.search) {
                Category.storeByCondition({
                    items: [{key:"name",op:"like",value:pagingParams.search}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Category.storeByCondition({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.categories = res.data;
                    vm.page = pagingParams.page;
                    console.log(vm.categories);
                }else if(res.status == 404){
                    MessageService.error("分类获取失败");
                    return
                }else {
                    MessageService.error(res.message);
                    return
                }
            }
            function onError(error) {
                MessageService.error("分类获取失败");
            }
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Category.delete({id: id},function (res) {
                    if(res.status == 200){
                        MessageService.success("删除成功");
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                        return
                    }
                });
            },function(){},true);
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ?  1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.categories,function (category,i) {
                if($scope.selectAll){
                    category.select = true;
                    $scope.batchArr.push(category.id);
                    $scope.selectOne = true;
                }else{
                    category.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (category) {
            category.select = !category.select;
            // 选中
            if(category.select){
                $scope.batchArr.push(category.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(category.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.categories,function (category,i) {
                if(!category.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.categories.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Category.deleteBatch({ids: $scope.batchArr},function (res) {
                    if(res.status == 200){
                        MessageService.success("删除成功");
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                        return
                    }
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);
        }
    }
})();
