(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ConfigurationController', ConfigurationController);

    ConfigurationController.$inject = ['$scope', '$q', '$state', 'ECommerceSetting','Store','MessageService'];

    function ConfigurationController ($scope, $q, $state, ECommerceSetting,Store,MessageService) {
        var vm = this;
        Store.getCurrent({}, function (res) {
            if(res.status == 200){
                vm.store = res.data;
                ECommerceSetting.getECommerceSettingByStoreId({storeId:vm.store.id},function (resp) {
                    if(resp.status == 200){
                        vm.data = resp.data;
                    }else {
                        MessageService.error(resp.message);
                    }
                });
            }else {
                MessageService.error(res.message);
            }
        });
    }
})();
