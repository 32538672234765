(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDialog3Controller', StoreDialog3Controller);

    StoreDialog3Controller.$inject = ['$timeout', '$q', '$http', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Store', 'MessageService', 
    'PayOrder', 'RechargeSetting'];

    function StoreDialog3Controller ($timeout, $q, $http, $scope, $stateParams, $uibModalInstance, entity, Store, MessageService, 
        PayOrder, RechargeSetting) {
        var vm = this;

        vm.store = entity;
        vm.clear = clear;  
        vm.activation = activation;
        vm.prepare = false;
        $scope.timer = null;
        vm.payOrderId = null;

        vm.rechargeSettings  = RechargeSetting.getAllByCondition({sort:["id,asc"]});

        $q.all([vm.store.$promise, vm.rechargeSettings.$promise]).then(function(){
            vm.rechargeSettings.sort( function(a, b){return a.seq - b.seq;});
        },function(){})

        $scope.selectedRecgarge = function(rechargeSetting){
            vm.rechargeSetting = rechargeSetting;
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            clearInterval($scope.timer);
            $uibModalInstance.dismiss('cancel');
        }

        // 激活操作
        function activation(){
            if (vm.rechargeSetting == null || vm.rechargeSetting.id == null) {
                MessageService.error("请选择充值时间");
                return;
            }
            clearInterval($scope.timer);
            PayOrder.createPayOrder({rechargeSettingId:vm.rechargeSetting.id},function(data){
                vm.payOrderId = data.id;
                $http({
                    url: '/back/manager/api/stores/onLineActivation/'+vm.store.id,
                    method: 'GET',
                    params:{payOrderId: vm.payOrderId},
                    responseType: 'blob'
                    }).success(function(res){
                        vm.prepare = true;
                        var blob = new Blob([res], {type: "image/jpeg"});
                        $scope.image = URL.createObjectURL(blob);
                        //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                        $scope.timer = setInterval(function () {
                            PayOrder.get({id: vm.payOrderId}, function (val) {
                                if (!!val && val.paid) {
                                    clearInterval($scope.timer); 
                                    MessageService.success("支付完成");
                                    $uibModalInstance.close();
                                }
                            }, function () {
                            });
                        }, 2000);
                    })
                },function(){})
            
        } 
    }
})();
