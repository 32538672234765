(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('History', History)
        .factory('ExpressHistory', ExpressHistory)
        .factory('SalesOrder', SalesOrder)
        .factory('OrderArticle', OrderArticle);

    SalesOrder.$inject = ['$resource'];

    ExpressHistory.$inject = ['$resource'];

    History.$inject = ['$resource'];
    OrderArticle.$inject = ['$resource'];


    function OrderArticle ($resource) {
        var resourceUrl =  'service/' + 'api/order-articles/:path/:id';
        return $resource(resourceUrl, {}, {
            'getAuth': {
                method: 'GET',
                params: {
                    path: 'getAuth'
                }
            }
        });
    }
    function History ($resource) {
        var resourceUrl =  'service/' + 'api/histories/:path/:id';
        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            }
            // ----------销售分析使用----end-------
        });
    }


    function ExpressHistory ($resource) {
        var resourceUrl =  'service/' + 'api/order-express-histories/:path';
        return $resource(resourceUrl, {}, {
            'byOrderId':{
                method: 'GET',
                params: {
                    path: 'byOrderId'
                },
                isArray: true
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            }
        });
    }


    function SalesOrder ($resource) {
        var resourceUrl =  'service/' + 'api/sales-orders/:path/:id';

        return $resource(resourceUrl, {}, {
            'combinationNotify':{
                method: "GET",
                params: {
                    path: "combinationNotify"
                }
            },
            'justifyOrder':{
                method: "GET",
                params: {
                    path: "justifyOrder"
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            'byConditionPage': {
                method: 'GET',
                params: {
                    path: 'byConditionPage'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                },
                isArray: true
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                },
                isArray: true
            },
            "updateDelivery":{
                method:"POST",
                params:{
                    path:"updateDelivery"
                }
            },
            "delivery":{
                method:"GET",
                params:{
                    path:"delivery"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            // ----------销售分析使用-----------
            "getOrderNumAndSalesNum": {
                method: "GET",
                params: {
                    path: "getOrderNumAndSalesNum"
                }
            },
            "getOrderNums": {
                method: "GET",
                params: {
                    path: "getOrderNums"
                }
            },
            "yesterdayDate": {
                method: "GET",
                params: {
                    path: "yesterdayDate"
                }
            },
            "getListPage": {
                method: "GET",
                params: {
                    path: "getListPage"
                }
            },
            "getListPage2": {
                method: "GET",
                params: {
                    path: "getListPage2"
                }
            },
            "dateList": {
                method: "GET",
                params: {
                    path: "DateList"
                }
            },
            "getSalesNums": {
                method: "GET",
                params: {
                    path: "getSalesNums"
                }
            },
            "downloadFile": {
                method: "GET",
                params: {
                    path: "downloadFile"
                }
            },
            // ----------销售分析使用----end-------
            "productXmlAnalysis2": {
                method: "GET",
                params: {
                    path: "productXmlAnalysis2"
                }
            },
            "productAnalysis2": {
                method: "GET",
                params: {
                    path: "productAnalysis2"
                }
            },
             "payOrder": {
                method: "GET",
                params: {
                    path: "payOrder"
                }
            },
             "closeOrder": {
                method: "POST",
                params: {
                    path: "closeOrder"
                }
            },
            "rejects": {
                method: "GET",
                params: {
                    path: "rejects"
                }
            },
            "reject": {
                method: "GET",
                params: {
                    path: "reject"
                }
            },
            "approveRefund": {
                method: "GET",
                params: {
                    path: "approveRefund"
                }
            },
            "rejectRefund": {
                method: "GET",
                params: {
                    path: "rejectRefund"
                }
            },
            "getGuideData": {
                method: "GET",
                params: {
                    path: "getGuideData"
                }
            },
            "exportGuideData": {
                method: "GET",
                params: {
                    path: "exportGuideData"
                }
            },
            "download": {
                method: "GET",
                params: {
                    path: "byConditionPage/download"
                }
            }
        });
    }
})();
