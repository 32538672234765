(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderDialogController', PayOrderDialogController);

    PayOrderDialogController.$inject = ['$timeout', '$scope', '$stateParams', 'entity', 'PayOrder', 'MessageService',
    '$http', '$sce', '$state', 'PaidGiftSetting'];

    function PayOrderDialogController ($timeout, $scope, $stateParams,  entity, PayOrder, MessageService,
        $http, $sce, $state, PaidGiftSetting) {
        var vm = this;

        vm.store = entity.data;
        vm.prepare = false;
        vm.payMethod = "WeChat";
        vm.otherAmount = false;
        vm.amountId = "";
        PaidGiftSetting.getAllByCondition({},function (res) {
            if(res.status == 200){
                vm.payList = res.data;
            }else{
                MessageService.error(res.message);
            }
        });
        $scope.payCb = function(data){
            vm.otherAmount = false;
            vm.amount = data.amount;
            vm.amountId = data.id;
        };
        $scope.payCb1 = function(){
            vm.otherAmount = true;
            vm.amount = "";
            vm.amountId = "";
        };

        $scope.changePayMethod = function(method){
            if (method == null) {
                return;
            }
            vm.payMethod = method;
            console.log(vm.payMethod)
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        $scope.recharge = function() {
            if (vm.store.balance !=0 && vm.store.balance >= 100000000) {
                MessageService.error("当前账户余额较多，请消费后再充值");
                return;
            }
            if (vm.payMethod == null || vm.payMethod == "") {
                MessageService.error("请选择支付方式");
                return;
            }
            if (vm.amount == null || vm.amount == "") {
                MessageService.error("请输入充值金额");
                return;
            }
            vm.amount = Math.floor(vm.amount * 100) / 100;
            if (vm.amount<0.01) {
                MessageService.error("请输入有效充值金额");
                return;
            };
            clearInterval($scope.timer);
            var data = {
                type: vm.payMethod,
                amount: vm.amount,
                name: "商户充值"
            }
            PayOrder.createPayOrder2(data ,function(result){
                if(result.status==200){
                    vm.payOrderId = result.data.id;
                    if (result.data.type == "WeChat") {
                        $http({
                            url: '/manager/api/pay-orders/createQRCode/'+vm.payOrderId,
                            method: 'POST',
                            responseType: 'blob'
                        }).success(function(res) {
                            vm.prepare = true;
                            var blob = new Blob([res], {
                                type: "image/jpeg"
                            });
                            $scope.image = URL.createObjectURL(blob);
                            //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                            $scope.timer = setInterval(function() {
                                PayOrder.get({
                                    id: vm.payOrderId
                                }, function(val) {
                                    if(val.status==200){
                                        if (!!val.data && val.data.paid) {
                                            clearInterval($scope.timer);
                                            MessageService.success("支付完成");
                                            $state.go("pay-order",{reload:true});
                                        }
                                    }else{
                                        MessageService.error(val.message);
                                    }
                                }, function() {});
                            }, 2000);
                        })
                    }else{
                        PayOrder.aliPay({
                            id:result.data.id,
                            returnUrl:window.location.href
                        },function(data){
                            if(!!data && data.status == 200){
                                $scope.trustHtml = $sce.trustAsHtml(data.data);
                            }
                        },function(){
                        });
                    }
                }else{
                    MessageService.error(result.message);
                }

            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }
    }
})();
