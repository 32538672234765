(function() {
    'use strict';

    angular
        .module('backApp')
         .controller('MessageNotificationDetailController', MessageNotificationDetailController);

    MessageNotificationDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'MessageNotification',
     '$state', '$uibModal'];

    function MessageNotificationDetailController($scope, $rootScope, $stateParams, previousState, entity, MessageNotification,
     $state, $uibModal) {
        var vm = this;

        vm.messageNotification = entity.data;
        vm.previousState = previousState.name;
        vm.clear = clear;

        var unsubscribe = $rootScope.$on('platformApp:messageNotificationUpdate', function(event, result) {
            vm.messageNotification = result;
        });

        vm.multilingual = multilingual;
        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDetail.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.messageNotification.messageNotificationLanguages, 
                            key:"content",
                            fieldValue:vm.messageNotification.content,
                            fieldName:"消息内容"
                        };
                    }]
                }
            }).result.then(function(data) {
                
            });
        }
        
        function clear () {
            $state.go("message-notification", {reload:true})
        }

        $scope.$on('$destroy', unsubscribe);
    }
})();
