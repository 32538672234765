(function() {
    'use strict';
    angular
        .module('backApp')
        .factory("RechargeSetting", RechargeSetting)
        .factory('PlatformAbutment', PlatformAbutment)
        .factory('Store', Store)
        .factory('StoreWorkEditConfig', StoreWorkEditConfig)
        .factory('StoreOrderConfig', StoreOrderConfig)
        .factory('StoreConfig', StoreConfig)
        .factory('VerifyCode', VerifyCode)
        .factory('OrderSetting', OrderSetting);

    PlatformAbutment.$inject = ['$resource'];
    Store.$inject = ['$resource', 'DateUtils'];
    StoreConfig.$inject = ['$resource'];
    StoreWorkEditConfig.$inject = ['$resource'];
    StoreOrderConfig.$inject = ['$resource'];
    VerifyCode.$inject = ['$resource', 'DateUtils'];
    OrderSetting.$inject = ['$resource', 'DateUtils'];

    function PlatformAbutment($resource) {
        var resourceUrl = 'manager/' + 'api/platform-abutment/:path/:id';

        return $resource(resourceUrl, {}, {
            'getAll': {
                method: 'POST',
                params: {
                    path: 'getAllByCondition'
                },
                isArray: true
            },
            "getPlatformAbutment": {
                method: "GET",
                params: {
                    path: "getPlatformAbutment"
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save': {
                method: 'PUT'
            },
            'del': {
                method: 'GET',
                params: {
                    path: 'del'
                }
            },
            'changeState': {
                method: 'GET',
                params: {
                    path: 'changeState'
                }
            },
            'reloadToken': {
                method: 'GET',
                params: {
                    path: 'reloadToken'
                }
            }
        });
    }

    RechargeSetting.$inject = ['$resource', 'DateUtils'];

    function RechargeSetting($resource) {
        var resourceUrl = 'manager/' + 'api/recharge-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true
            },

            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                },
                isArray: true
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT'
            }
        })
    }

    function StoreConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
            }
        });
    }

    function StoreWorkEditConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-work-edit-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
            },
            'storeId':{
                method: 'GET',
                params: {
                    path: "storeId"
                },
            }
        });
    }

    function StoreOrderConfig($resource) {
        var resourceUrl = 'manager/' + 'api/store-order-configs/:path/:id';

        return $resource(resourceUrl, {}, {
            "updateLogisticsSetting": {
                method: "GET",
                params: {
                    path: "updateLogisticsSetting"
                }
            },
            "getLogisticsSetting": {
                method: "GET",
                params: {
                    path: "getLogisticsSetting"
                }
            },
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            'update': {
                method: 'PUT',
            },
            'storeId':{
                method: 'GET',
                params: {
                    path: "storeId"
                },
            }
        });
    }

    function Store($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/stores/:path/:id';

        return $resource(resourceUrl, {}, {
            'getCurrent': {
                method: 'GET',
                params: {
                    path: "current"
                },
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            "onLineActivation": {
                method: "GET",
                params: {
                    path: "onLineActivation"
                }
            },
            "activation": {
                method: "GET",
                params: {
                    path: "activation"
                }
            },
            "updateSkin": {
                method: "GET",
                params: {
                    path: "updateSkin"
                }
            },
            "getAllByCondition": {
                method: "POST",
                params: {
                    path: "getAllByCondition"
                },
                isArray: true
            },
            "creagteUser":{
                method:"POST",
                params:{
                    path:"createUser"
                }
            },
            "getStoreForAgent": {
                method: "GET",
                params: {
                    path: "getStoreForAgent"
                },
                isArray: true
            },
            "getStoreForJoin": {
                method: "GET",
                params: {
                    path: "getStoreForJoin"
                },
                isArray: true
            },
            'query': {
                method: 'GET',
                isArray: true
            },
            'signOut': {
                method: 'GET',
                params: {
                    path: "signOut"
                },
            },
            'batchSignOut': {
                method: 'GET',
                params: {
                    path: "batchSignOut"
                },
            },
            'get': {
                method: 'GET',
                transformResponse: function(data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.durationOfOperationTo = DateUtils.convertLocalDateFromServer(data.durationOfOperationTo);
                        data.activationDate = DateUtils.convertLocalDateFromServer(data.activationDate);
                        data.periodOfValidity = DateUtils.convertLocalDateFromServer(data.periodOfValidity);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.activationDate = DateUtils.convertLocalDateToServer(copy.activationDate);
                    copy.periodOfValidity = DateUtils.convertLocalDateToServer(copy.periodOfValidity);
                    return angular.toJson(copy);
                }
            },
            'create': {
                method: 'POST',
                params: {
                    path: 'create'
                },
                transformRequest: function(data) {
                    var copy = angular.copy(data);
                    copy.activationDate = DateUtils.convertLocalDateToServer(copy.activationDate);
                    copy.periodOfValidity = DateUtils.convertLocalDateToServer(copy.periodOfValidity);
                    return angular.toJson(copy);
                }
            }
        });
    }

    function VerifyCode($resource, DateUtils) {
        var resourceUrl = 'manager' + '/api/verify-codes/:path/:id';
        return $resource(resourceUrl, {}, {
            "getVerifyCode2": {
                method: "GET",
                params: {
                    path: "getVerifyCode2"
                }
            },
            "check": {
                method: "GET",
                params: {
                    path: "check"
                }
            }
        })
    }

    function OrderSetting($resource, DateUtils) {
        var resourceUrl = 'manager/' + 'api/timer-settings/:path/:id';

        return $resource(resourceUrl, {}, {
            "updateStoreTimerSetting":{
                method: 'PUT',
                params:{
                    path:"updateStoreTimerSetting"
                }
            },
            "getStoreOrderSetting2":{
                method: 'GET',
                params:{
                    path:"getStoreOrderSetting2"
                }
            },
            'update': {
                method: 'PUT'
            },
            'get': {
                method: 'GET',
                params: {
                    path: 'getOrderSetting'
                }
            }
        });
    }

})();
