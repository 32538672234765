(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$q', '$state', '$scope', 'Auth', 'Principal', 'ProfileService', 'LoginService', "$uibModal", '$localStorage', '$sessionStorage', 'Store', '$timeout',
        'Qinius','Module','MessageService'];

    function NavbarController($q, $state, $scope, Auth, Principal, ProfileService, LoginService, $uibModal, $localStorage, $sessionStorage, Store, $timeout,
                              Qinius,Module,MessageService) {
        var vm = this;
        var pageName = null;
        var arr = null;
        vm.modules = Module;
        console.log(Module)

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.account = $localStorage.user;
        vm.menu = $localStorage.menu1;

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.shrink = shrink;
        vm.sidebar2Shrik = sidebar2Shrik;
        vm.titleNavbar = titleNavbar;
        vm.passwords = passwords;
        vm.collapseNavbar = collapseNavbar;
        vm.lmenuClick = lmenuClick;

        vm.storeAll = Store.getCurrent();
        vm.domains = Qinius.getDomain();
        $q.all([vm.storeAll.$promise, vm.domains.$promise]).then(function(){
            vm.store = vm.storeAll.data;
            var currentDate = new Date();
            var currentDateStr = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
            if (!vm.store.periodOfValidity || new Date(currentDateStr).getTime() > new Date(vm.store.periodOfValidity).getTime()) {
                MessageService.error('商户已过有效期无法登录，请联系平台运营。');
                logout();
                return
            }
            vm.menulogo = vm.store.menuLogo;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            console.log($scope.domain + vm.menulogo)
            vm.auth = vm.account.authorities;
            if(vm.auth.length>0){
                for(var i=0;i<vm.auth.length;i++){
                    if(vm.modules.length>0){
                        for(var j=0;j<vm.modules.length;j++){
                            if(vm.modules[j].auth.length>0&&vm.modules[j].auth.indexOf(vm.auth[i])>-1){
                                vm.modules[j].visiable = true;
                                if(vm.modules[j].menus.length>0){
                                    for(var k=0;k<vm.modules[j].menus.length;k++){
                                        if(vm.modules[j].menus[k].auth.length>0&&vm.modules[j].menus[k].auth.indexOf(vm.auth[i])>-1){
                                            vm.modules[j].menus[k].visiable = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            //等待页面加载完成之后 自执行
            vm.subId = sessionStorage.getItem('subId');
            vm.classType = sessionStorage.getItem('classType');
            vm.outerIndex = sessionStorage.getItem('outerIndex');
            if(vm.subId && vm.outerIndex) {
                $timeout(function () {
                    angular.element('#z' + vm.outerIndex).trigger('click');
                    if(vm.classType=='undefined'||vm.classType==undefined||vm.classType==null||!vm.classType){
                        angular.element('#' + vm.subId).addClass('navOn');
                    }else {
                        vm.navId = vm.subId;
                    }
                }, 100);
            }

            //列表收缩 展开
            $(".navbar").on('click', '.list-menu', function(){
                if(!$(this).hasClass('on')){
                    $(this).parent().addClass('shell-nav-li-active').siblings('li').removeClass('shell-nav-li-active');
                    $(this).next('.shell-nav-body').slideDown(200).parent().siblings('li').find('.shell-nav-body').slideUp(200);
                    $(this).addClass('on').parent().siblings('li').find('.list-menu').removeClass('on');
                    $(this).find('.shell-nav-head-fr-show').addClass('rotateIcon').end().parent().siblings('li').find('.shell-nav-head-fr-show').removeClass('rotateIcon');
                    $(this).find('.shell-nav-head-title').addClass('shell-nav-head-title-active').end().parent().siblings('li').find('.shell-nav-head-title').removeClass('shell-nav-head-title-active');
                }else{
                    $(this).removeClass('on');
                    $(this).next('.shell-nav-body').slideUp(200).parent().siblings('li').find('.shell-nav-body').slideUp(200);
                    $(this).find('.shell-nav-head-fr-show').removeClass('rotateIcon');
                    $(this).find('.shell-nav-head-title').removeClass('shell-nav-head-title-active');
                    console.log("?????")
                }
            });
        })
          $scope.backTo = function(){
            sessionStorage.setItem("lmenu", 'a_marketing');
            $state.go("marketing");
            vm.lmenu = "a_marketing";

        }

        $scope.navSelect = function (nav,outerIndex) {
            if(nav.classType){
                $state.go(nav.id,{classType:nav.classType});
            }else{
                $state.go(nav.id);
            }
            if(nav.classType){
                vm.classType = nav.classType;
            }else{
                vm.classType = null;
            }
            sessionStorage.setItem('classType',nav.classType);//二级菜单的id
            if(!vm.classType&&vm.navId == nav.id){
                return
            }
            //模板分类
            if((vm.classType&&vm.classType==nav.classType)&&vm.navId == nav.id){
                return
            }
            angular.element('.sub_menu').removeClass('navOn');
            vm.navId = nav.id;

            sessionStorage.setItem('subId',nav.id);//二级菜单的id
            sessionStorage.setItem('outerIndex',outerIndex);//一级菜单index
            sessionStorage.removeItem('tableData');
        };

        // 菜单刷新后定位
        vm.lmenu = sessionStorage.getItem("lmenu") == null ? 'a_storeManagement' : sessionStorage.getItem("lmenu");
        if (vm.lmenu == "null" || vm.lmenu == 'a_storeManagement') {
            sessionStorage.setItem("lmenu", "a_storeManagement");
            lmenuClick('a_storeManagement');
        }
        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
            $(".titleNavbar").eq(vm.menu).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');

        });

        var $logo = $(".navbar-logo");
        var $bar1 = $(".sidebar1-btn");
        var $side1 = $(".sidebar1");
        var $bar2 = $(".sidebar2-btn");
        var $side2 = $(".sidebar2");
        var isHide = false;
        var $navbar = $(".navbar-wrap");
        window.showSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left').addClass('shidebar2-shrink-left');
        };

        window.hideSidebar2Shrink = function (event) {
            if ($navbar.hasClass("sidebar-collapse3")){
                $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
                return
            }
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left');
        };

        function lmenuClick(a_id) {
            vm.lmenu = a_id;
            sessionStorage.setItem("lmenu", a_id);
            // 首页直接显示
            // if (a_id == "a_home") {
            //     return;
            // }
            setTimeout(function() {
                 if ($side2[0] && $side2[0].children[0]) {
                    var childs = $side2[0].children[0];
                    if (childs.childElementCount<2) {
                        return;
                    }
                    for (var i = 2; i< childs.childElementCount; i++) {
                        var li = childs.children[i];
                        if (li.attributes["style"] && li.attributes["style"].value == "display: none;") {
                            continue;
                        }
                        if (li.attributes["ui-sref"]) {
                            // $state.go(li.attributes["ui-sref"].value);
                            return;
                        }
                    }
                 }
             }, 100);

        }

        function login() {
            collapseNavbar();
            LoginService.open();
        }

        function logout() {
            collapseNavbar();
            sessionStorage.setItem("lmenu", null);
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }


        var createModal = function(url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: true,
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('password');
                        return $translate.refresh();
                    }]
                }
            });
        };

        vm.show_logo = true;
        function shrink() {
            $navbar.toggleClass("sidebar-collapse");
            $side2.addClass('transition');
            if (isHide) {
                if ($navbar.hasClass("sidebar-collapse")) {
                    $bar1.removeClass('btn1-shrik1').addClass("btn1-shrik2");
                    $navbar.toggleClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
                    $(".wrap").removeClass("wrap-mar130").removeClass("wrap-mar160").toggleClass("wrap-mar60");
                } else {
                    $bar1.removeClass('btn1-shrik2').addClass("btn1-shrik1");
                    $(".wrap").removeClass("wrap-mar160").removeClass("wrap-mar60").toggleClass("wrap-mar130");
                    $navbar.toggleClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                }
            } else {$bar1.toggleClass('btn1-shrik1').toggleClass("btn1-shrik2");
                $(".wrap").toggleClass("wrap-mar160");
            }

            $logo.addClass('transition');
            $logo.toggleClass("navbar-logo3");
            if ($logo.hasClass("navbar-logo3")) {
                vm.show_logo = false;
            } else {
                vm.show_logo = true;
            }
            return false;
        }

        $scope.resetPassword = function(){
            createModal('app/account/password/password.html', 'PasswordController').result.then(
                function(data) {
                });
        };

        function titleNavbar(i) {
            var $titleNavbar = $(".titleNavbar");
            var $menu1 = $(".menu1");
            // click时清空一级菜单样式
            $titleNavbar.find($(".navbar-title")).css({
                background: '#3c434d'
            });
            $titleNavbar.find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
            //若二级菜单为隐藏状态：
            if ($titleNavbar.eq(i).find($menu1).is(':hidden')) {
                //箭头运动状态向下
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrows').addClass('arrowsTop');
                // click时改变一级菜单背景色和字体色
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                //显示当前二级菜单
                $titleNavbar.eq(i).find($menu1).slideDown(400);

                $localStorage.menu1 =i;
                //隐藏其他二级菜单
                $titleNavbar.eq(i).siblings().find($(".menu1")).hide();

            } else {
                $titleNavbar.eq(i).find($(".arrowsB")).removeClass('arrowsTop').addClass('arrows');
                $titleNavbar.eq(i).find($(".navbar-title")).css({
                    background: '#313840'
                });
                $titleNavbar.eq(i).find($menu1).slideUp(400);
                $titleNavbar.eq(i).siblings().find($menu1).hide();
            }
        }

        function sidebar2Shrik() {
            if(!($(".sidebar2-shrink").hasClass('shidebar2-shrink-left')||$(".sidebar2-shrink").hasClass('shidebar2-shrink-left2'))){
                return
            }

            $side2.removeClass('transition');
            $(".sidebar2-shrink").removeClass('shidebar2-shrink-left2');
            isHide = !isHide;

            if(isHide){
                $(".sidebar2").addClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik1').addClass("btn2-shrik2");
            }else {
                $(".sidebar2").removeClass("sidebar2-zIndex");
                $bar2.removeClass('btn2-shrik2').addClass("btn2-shrik1");
            }
            if ($navbar.hasClass("sidebar-collapse")) {
                $(".wrap").toggleClass("wrap-mar160").toggleClass("wrap-mar60");
                $navbar.removeClass("sidebar-collapse3").toggleClass("sidebar-collapse4");
            } else {
                $navbar.removeClass("sidebar-collapse2").removeClass("sidebar-collapse4").toggleClass("sidebar-collapse3");
                $(".wrap").removeClass("wrap-mar160").toggleClass("wrap-mar130");
            }
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function passwords() {
            delete $localStorage.pageName;
            pageName = window.location.hash;
            arr = pageName.split('#')[1].substring(1);
            $localStorage.pageName = arr
        }
    }
})();
