(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreDetailController', StoreDetailController);

    StoreDetailController.$inject = ['$scope', '$q', 'entity', 'Store', 'MessageService', 'Qinius', '$state', 'StoreWorkEditConfig', 'ECommerceSetting','UidService','$uibModal','JhiLanguageService'];

    function StoreDetailController($scope, $q, entity, Store, MessageService, Qinius, $state, StoreWorkEditConfig, ECommerceSetting, UidService, $uibModal,JhiLanguageService) {
        var vm = this;
        vm.isEdit = false;
        vm.isEdit2 = false;
        vm.isEdit3 = false;
        vm.isEdit4 = false;
        vm.store = entity;
        console.log(entity);
        vm.storeWorkEdit = StoreWorkEditConfig.storeId();
        vm.domains = Qinius.getDomain();
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        vm.availableLanguages = JhiLanguageService.availableLanguages();
        // {key: "中文", value: "zh-cn", selected: false, default:false},
        // {key: "英文", value: "en", selected: false, default:false}
        
        
        vm.types = [{key:"公众号",value:"WECHAT",selected:false}, {key:"小程序",value:"MINI",selected:false}, {key:"第三方",value:"THIRD",selected:false}]
        $q.all([vm.domains.$promise, vm.store.$promise, vm.storeWorkEdit.$promise]).then(function () {
            // vm.domains = vm.domains.data;
            vm.store = vm.store.data;
            vm.defalutLangKey = vm.store.defaultLangKey;
            if (vm.store.availableLangKey) {
                for (var i = vm.availableLanguages.length - 1; i >= 0; i--) {
                    var lang = vm.availableLanguages[i];
                    if (vm.store.availableLangKey.indexOf(lang.value) >= 0) {
                        lang.selected = true;
                    }
                    if (vm.defalutLangKey == lang.value){
                        lang.default = true;
                    }
                }
            }

            vm.storeWorkEdit = vm.storeWorkEdit.data;
            ECommerceSetting.getECommerceSettingByStoreId({storeId:vm.store.id},function (resp) {
                if(resp.status == 200){
                    vm.data = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            });
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if (vm.store.storeType2) {
                for (var i = vm.types.length - 1; i >= 0; i--) {
                    var t = vm.types[i];
                    if(vm.store.storeType2.indexOf(t.value)>=0){
                        t.selected = true;
                    }
                }
            }
        });

        var flag = true;
        window.showSkuList = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".paybatch-proName").addClass('text-decoration-underline');
                $(".paybatch-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList = function (e) {
            fn(e);
            $(".paybatch-tip").hide();
            $(".paybatch-proName").removeClass('text-decoration-underline');
            flag = true;
        }

        window.showSkuList2 = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var index = targ.dataset.index;
            if(flag){
                flag = false;
                $(".substitutione").addClass('text-decoration-underline');
                $(".substitutione-tip").show().css({left:e.clientX || 0,top:e.clientY || 0});
            }
        }

        window.hideSkuList2 = function (e) {
            fn(e);
            $(".substitutione-tip").hide();
            $(".substitutione").removeClass('text-decoration-underline');
            flag = true;
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            e = e || window.event;
            e.cancelBubble = true;
            e.stopPropagation();
            e.returnValue = false;
            e.preventDefault();
        }

        $scope.saveWork = function () {
            vm.isSaving = true;
            vm.isEdit = false;
            if (vm.storeWorkEdit.id !== null) {
                StoreWorkEditConfig.update(vm.storeWorkEdit, onSaveSuccess, onSaveError);
            } else {
                StoreWorkEditConfig.save(vm.storeWorkEdit, onSaveSuccess, onSaveError);
            }
        }

        $scope.cancelWork = function () {
            vm.isEdit = false;
            StoreWorkEditConfig.storeId(function (res) {
                vm.storeWorkEdit = res.data;
            });
        }

        function onSaveSuccess(result) {
            $scope.$emit('backApp:storeUpdate', result);
            vm.isSaving = false;
            MessageService.success("保存成功！");
        }

        function onSaveError() {
            vm.isSaving = false;
            MessageService.error("保存失败！");
        }

        $scope.saveData = function () {
            vm.isEdit2 = false;
            ECommerceSetting.edit(vm.data,function (res) {
                if(res.status == 200){
                    MessageService.success("修改成功");
                }else {
                    MessageService.error(res.message);
                }
            })
        }
        $scope.cancelData = function () {
            vm.isEdit2 = false;
            ECommerceSetting.getECommerceSettingByStoreId({storeId:vm.store.id},function (resp) {
                if(resp.status == 200){
                    vm.data = resp.data;
                }else {
                    MessageService.error(resp.message);
                }
            });
        }

        window.uploadPic = function ($event,attr) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],attr);
            }
        }

        function qiniuUpload(file,attr) {
            vm.attr = attr;
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "setting-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(attr=='login'){
                        imgSrop($scope.domain + data.key, 600, 300)
                    }else if(attr=='register'){
                        imgSrop($scope.domain + data.key, 600, 300)
                    }else{
                        imgSrop($scope.domain + data.key, 300, 160)
                    }

                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        function imgSrop(url, width, height){
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    if(vm.attr=='login'){
                        vm.store.webLoginLogo = data;
                    }else if(vm.attr=='register'){
                        vm.store.webRegisterLogo = data;
                    }else{
                        vm.store.menuLogo = data;
                    }
                    Store.update(vm.store, onSaveSuccess, onSaveError);
                }
            });
        }

        $scope.saveStore = function () {
            vm.isEdit3 = false;
            Store.update(vm.store, onSaveSuccess, onSaveError);
        }


        $scope.cancelStore = function () {
            vm.isEdit3 = false;
            Store.getCurrent(function (res) {
                vm.store = res.data;
            })
        }

        $scope.saveOperation = function () {
            vm.isEdit4 = false;
            vm.store.availableLangKey = [];
            vm.store.defaultLangKey = "";
            for (var i = vm.availableLanguages.length - 1; i >= 0; i--) {
                var t = vm.availableLanguages[i];
                if (t.selected) {
                    vm.store.availableLangKey.push(t.value)
                }
            }
            for (var j = vm.availableLanguages.length - 1;j >= 0; j--){
                if (vm.availableLanguages[j].default){
                    vm.store.defaultLangKey = vm.availableLanguages[j].value;
                }
            }
            Store.update(vm.store, onSaveSuccess, onSaveError);
        }

        $scope.cancelOperation = function () {
            vm.isEdit4 = false;
            Store.getCurrent(function (res) {
                vm.store = res.data;
                if (vm.store.availableLangKey) {
                    angular.forEach(vm.availableLanguages, function(l){
                        l.selected = false;
                        l.default = false;
                    })
                    for (var i = vm.availableLanguages.length - 1; i >= 0; i--) {
                        var lang = vm.availableLanguages[i];
                        if (vm.store.availableLangKey.indexOf(lang.value) >= 0) {
                            lang.selected = true;
                        };
                        if (vm.store.defaultLangKey == lang.value){
                            lang.default = true;
                        }
                    }
                }
            })
        }

        $scope.selectLang = function (lang) {
            var count = 0;
            for (var j=0; j<vm.availableLanguages.length;j++){
                if (vm.availableLanguages[j].selected){
                    count ++;
                }
            }
            if (count == 0 && !lang.selected){
                lang.selected = true;
                return
            }
            if (lang.default && !lang.selected){
                lang.default = false;
                for (var i = vm.availableLanguages.length - 1; i >= 0; i--){
                    var language = vm.availableLanguages[i];
                    if (language.selected){
                        language.default = true;
                        break
                    }
                }
            }
        }

        $scope.defaultLang = function (language){
            for (var i = vm.availableLanguages.length - 1; i >= 0; i--){
                var lang = vm.availableLanguages[i];
                if (lang.default){
                    if (lang.value == language.value){
                        return
                    }
                    lang.default = false;
                }
            }
            language.default = true;
            vm.store.defaultLangKey = language.value;
            Store.update(vm.store, onSaveSuccess, onSaveError);
        }
        vm.multilingual = function (title, name, key, data){
            if(!vm.storeWorkEdit[data]){
                vm.storeWorkEdit[data] = [];
            }
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.storeWorkEdit[data],
                            key: key,
                            fieldValue: name,
                            fieldName: title
                        };
                    }]
                }
            }).result.then(function(res) {
                if (res) {
                    vm.storeWorkEdit[data] = res;
                    console.log(vm.storeWorkEdit)
                }
            })
        }
    }
})();
