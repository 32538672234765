(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ProductDetailController', ProductDetailController);

    ProductDetailController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', '$state', 'entity', 'Qinius', 'MessageService', '$uibModal'];

    function ProductDetailController($scope, $q, $rootScope, $stateParams, $state, entity, Qinius, MessageService, $uibModal) {
        var vm = this;

        vm.product = entity.data;
        vm.clear = clear;
        vm.title = "编辑"
        if(!vm.product.id){
            vm.title = "新建"
        }else if(vm.product.bokehType!="None"){
            vm.product.bokeh = true;
        }


        vm.multilingual = multilingual;
        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDetail.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.product.productLanguages, 
                            key:"name",
                            fieldValue:vm.product.name,
                            fieldName:"商品名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                
            });
        }

        vm.multilingual2 = multilingual2;
        function multilingual2(item){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDetail.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:item.productXmlLineLanguages, 
                            key:"name",
                            fieldValue:item.name,
                            fieldName:"模板展示名称"
                        };
                    }]
                }
            }).result.then(function(data) {
                
            });
        }


        var createModal2 = function (url, controller, item) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                resolve: {
                    entity:item,
                }
            });
        };

        vm.domains = Qinius.getDomain();
        $q.all([vm.domains.$promise, vm.product.$promise]).then(function(){
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            if (vm.product.objectProperties !=null) {
                vm.product.defaultObjectProperties = [];

                for (var i = vm.product.objectProperties.length - 1; i >= 0; i--) {
                    var ob = vm.product.objectProperties[i];
                    vm.product.defaultObjectProperties.push(ob);

                }
            }

            if (vm.product.itemValues && vm.product.itemValues.length>0) {
                for (var i = vm.product.itemValues.length - 1; i >= 0; i--) {
                    var itemValue = vm.product.itemValues[i];
                    if (itemValue.cancelled) {
                        continue;
                    }
                    if (itemValue.productXmlLines && itemValue.productXmlLines.length > 0) {
                        itemValue.productXmlLines.sort(function(a, b){
                            return a.seq - b.seq;
                        })
                    }
                }
            }

        })

        function clear () {
            $state.go('product', {}, {reload: false});
        }

        $scope.getItemValueStr = function(itemValue){
            var valueStr="";
            if (itemValue == null || itemValue.propertyValues == null) {
                return valueStr;
            };
            for (var i = 0; i< itemValue.propertyValues.length  ; i++) {
                var propertyValue = itemValue.propertyValues[i];
                valueStr = valueStr+" "+propertyValue.value;
            };
            return valueStr;
        }

        var unsubscribe = $rootScope.$on('backApp:productUpdate', function(event, result) {
            vm.product = result;
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.goToView = function (line) {
            if (!vm.product.bindingType) {
                MessageService.error('请设置翻页方式');
                return
            }
            createModal('app/entities/product/product-view-article.html', 'ProductViewArticleController',line.productXmlIdentifier).result.then(
                function (data) {

                });
        };

        var createModal = function (url, controller,fileName) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',

                resolve: {
                    entity: {
                        fileName: fileName,
                        bindingtype:vm.product.bindingType
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        return $translate.refresh();
                    }]
                }
            });
        };

        $scope.goToReadImage = function (pic) {
            // 遮罩层下面内容不滚动
            $('body').css("overflow", "hidden");
            $(".origin-image-dialog").removeClass('hide1');
            $(".listDetail").addClass('opacity-zero');
            $(".origin-image-dialog img").attr('src', pic);
            $(".modal-content,.modal-dialog").addClass('clear-model');
        }

        // 滚动放大
        window.bbimg = function (o) {
            var pic = o.firstElementChild
            var zoom = parseInt(pic.style.zoom, 10) || 100;
            zoom += event.wheelDelta / 12;
            if (zoom > 0) pic.style.zoom = zoom + '%';
            return false;
        }

        $scope.clickToHide = function () {
            if ($scope.imageClick) {
                $scope.imageClick = false;
                return
            }
            $(".origin-image-dialog").addClass('hide1');
            $(".listDetail").removeClass('opacity-zero');
            $(".modal-content,.modal-dialog").removeClass('clear-model');
            $('body').css("overflow","auto");
        }


        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        window.moveImgStartDialog = function (ev) {
            fn(ev);
            $scope.imageClick = true;
            var oEvent = ev;
            dragBox.clientX = oEvent.clientX || 0;
            dragBox.clientY = oEvent.clientY || 0;
            drag = true;

            document.addEventListener("mousemove", moveImgOnDialog, true);
            document.addEventListener("mouseup", moveImgEndDialog, true);
        }

        //move事件
        function moveImgOnDialog(ev) {
            var oEvent = ev;
            var oDiv = document.getElementById('origin-pic');
            if (drag) {
                fn(ev);
                if (dragBox.coordX == undefined || dragBox.coordY == undefined) {
                    dragBox.coordX = 0;
                    dragBox.coordY = 0;
                }
                oDiv.style.marginLeft = (dragBox.coordX + oEvent.clientX - dragBox.clientX) + "px";
                oDiv.style.marginTop = (dragBox.coordY + oEvent.clientY - dragBox.clientY) + "px";
            }
            $scope.$digest();
            return false;
        }

        //鼠标松开事件
        function moveImgEndDialog() {
            drag = false;
            dragBox = {};
            var oDiv = document.getElementById('origin-pic');
            dragBox.coordX = parseFloat(oDiv.style.marginLeft);
            dragBox.coordY = parseFloat(oDiv.style.marginTop);
            document.removeEventListener("mousemove", moveImgOnDialog, true);
            document.removeEventListener("mouseup", moveImgEndDialog, true);
            $scope.$digest();
            return false;
        }


        //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }
    }
})();
