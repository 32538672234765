(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PropertyCommercialController', PropertyCommercialController);

    PropertyCommercialController.$inject = ['$scope', '$state', 'Property', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', '$localStorage'];

    function PropertyCommercialController ($scope, $state, Property, ParseLinks, paginationConstants, pagingParams, MessageService, $localStorage) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                Property.byCondition({
                    items: [{key:"name,alias",op:"like",value:pagingParams.search},{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Property.byCondition({
                    items: [{key: "storeId", op: "=", value: $localStorage.user.storeId}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.properties = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message);
                }

            }
            function onError(error) {
                MessageService.error("属性获取失败");
            }
        }

        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Property.delete({id: id},function (res) {
                    if(res.status == 200){
                        loadAll();
                    }else{
                        MessageService.error(res.message);
                    }
                });
            },function(){},true);
        }


        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0  ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.properties,function (property,i) {
                if($scope.selectAll){
                    property.select = true;
                    $scope.batchArr.push(property.id);
                    $scope.selectOne = true;
                }else{
                    property.select = false;
                    $scope.selectOne = false;
                }
            })
        }

        $scope.chooseOne = function (property) {
            property.select = !property.select;
            // 选中
            if(property.select){
                $scope.batchArr.push(property.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(property.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.properties,function (property,i) {
                if(!property.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.properties.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                Property.deleteBatch({ids: $scope.batchArr},function (res) {
                    if(res.status == 200){
                        MessageService.success("删除成功");
                        $scope.selectOne = false;
                        $scope.selectAll = false;
                        loadAll();
                    }else{
                        MessageService.error(res.message);
                    }
                    
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);
        }
    }
})();
