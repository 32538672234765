(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('DiyEditorSettingCreateController', DiyEditorSettingCreateController);

    DiyEditorSettingCreateController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'DiyEditorSetting', 'MessageService','$uibModal'];

    function DiyEditorSettingCreateController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, DiyEditorSetting, MessageService,$uibModal) {
        var vm = this;
        vm.data = {};
        vm.cancel = cancel;
        vm.save = save;
        vm.multilingual= multilingual;

        function cancel() {
            $uibModalInstance.close();
        }
        function save() {
            if(!vm.data.editorType){
                MessageService.error("请选择适用编辑器");
                return
            }

            if(!vm.data.name){
                MessageService.error("请输入编辑器名称");
                return
            }
            console.log(vm.data)
            vm.isSaving = true;
            DiyEditorSetting.save(vm.data, onSaveSuccess, onSaveError);

            function onSaveSuccess (result) {
                MessageService.success("保存成功");
                $uibModalInstance.close(result);
                vm.isSaving = false;
            }

            function onSaveError () {
                MessageService.error("保存失败");
                vm.isSaving = false;
            }
        }

        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:vm.data.diyEditorSettingLanguages,
                            key: 'name',
                            fieldValue: vm.data.name,
                            fieldName: 'DIY编辑器名称'
                        };
                    }]
                }
            }).result.then(function(data) {
                if (data) {
                    vm.data.diyEditorSettingLanguages =data;
                }
            })
        }
    }
})();
