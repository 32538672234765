(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('LogoSettingEditController', LogoSettingEditController);

    LogoSettingEditController.$inject = ['$scope', '$q', 'UidService', '$rootScope', '$state', 'MessageService', 'Store', 'Qinius', '$uibModal'];

    function LogoSettingEditController($scope, $q, UidService, $rootScope, $state, MessageService, Store, Qinius, $uibModal) {
        var vm = this;

        vm.save = save;

        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function(){
            vm.store = vm.store.data;
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        })

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        window.uploadPic = function ($event,attr) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0],attr);
            }
        }

        function qiniuUpload(file,attr) {
            vm.attr = attr;
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "setting-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(attr=='login'){
                        imgSrop($scope.domain + data.key, 600, 300)
                    }else if(attr=='register'){
                        imgSrop($scope.domain + data.key, 600, 300)
                    }else{
                        imgSrop($scope.domain + data.key, 300, 160)
                    }

                    $scope.$apply();
                    // Store.update(vm.store, onSaveSuccess, onSaveError);
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        function imgSrop(url, width, height){
            $uibModal.open({
                templateUrl: 'app/entities/initialization/modal/img-crop.html',
                controller: 'ImgCropController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            url: url,
                            width: width,
                            height: height
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    if(vm.attr=='login'){
                        vm.store.webLoginLogo = data;
                    }else if(vm.attr=='register'){
                        vm.store.webRegisterLogo = data;
                    }else{
                        vm.store.menuLogo = data;
                    }
                }
            });
        }

        function onSaveSuccess(result) {
            MessageService.success("信息更新成功");
            $state.go("logo-setting", null, { reload: 'logo-setting' });
            vm.isSaving = false;
        }

        function onSaveError(result) {
            MessageService.error("操作失败，请稍后再试！");
            vm.isSaving = false;
        }


        $scope.clear = function (){
            $state.go("logo-setting", null, { reload: 'logo-setting' });
        }

        function save() {
            Store.update(vm.store, onSaveSuccess, onSaveError);
        }
    }
})();
