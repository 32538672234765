(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('CreditExtensionController', CreditExtensionController);

    CreditExtensionController.$inject = ['$scope', '$state', 'CreditRecords', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService', 'Store','DateUtils','$localStorage'];

    function CreditExtensionController ($scope, $state, CreditRecords, ParseLinks, paginationConstants, pagingParams, MessageService, Store,DateUtils,$localStorage) {
        var vm = this;
        vm.search = search;
        vm.transition = transition;
        vm.datePickerOpenStatus = {};
        vm.openCalendar =  openCalendar;
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.startTime = pagingParams.startTime ? new Date(pagingParams.startTime):null;
        vm.endTime = pagingParams.endTime? new Date(pagingParams.endTime):null;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        vm.tableData = {
            page:1,
            size:20
        }
        init();
        function init() {
            var _items = [{"key":"storeId", op:"=", value:$localStorage.user.storeId}];
            if (vm.endTime != null) {
                _items.push({"key":"createdDate", op:"<=", value:DateUtils.convertLocalDateToServer(vm.endTime)});
            }
            if (vm.startTime != null) {
                _items.push({"key":"createdDate", op:">=", value:DateUtils.convertLocalDateToServer(vm.startTime)});
            }
            CreditRecords.byCondition({
                items:_items,
                page: vm.tableData.page-1,
                size: vm.tableData.size,
                sort: ["id,desc"]
            },function (res,headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.creditData = res.data;
                }else {
                    MessageService.error(res.message);
                }
            })
        }

        function search() {
            vm.transition();
        }
        function transition() {
           $state.transitionTo($state.$current, {
                page: vm.page,
                startTime: vm.startTime?DateUtils.convertLocalDateToServer(vm.startTime):"",
                endTime: vm.endTime?DateUtils.convertLocalDateToServer(vm.endTime):""

            });
        }
    }
})();
