(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('HueController', HueController);

    HueController.$inject = ['$scope', '$q', 'UidService', '$rootScope', '$stateParams', 'MessageService', 'Store', 'Qinius', 'ToneSettings'];

    function HueController($scope, $q, UidService, $rootScope, $stateParams, MessageService, Store, Qinius, ToneSettings) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });

        // 导航栏默认色调
        vm.webNavbarBackColor = '#1e1f22';
        vm.webTitleColor = '#ffffff';
        vm.webMainButtonBackColor = '#ff5722';
        vm.webMainButtonTextColor = '#ffffff';
        vm.webSecondButtonBackColor = '#2e2e2e';
        vm.webSecondButtonTextColor = '#ffffff';

        // 侧边栏默认色调
        vm.sidebarBackColor = '#1e1f22';
        vm.sidebarUnselectButtonForeColor = '#999999';
        vm.sidebarSelectedButtonForeColor = '#ffffff';

        // 工具栏默认色调
        vm.toolbarBackColor = '#ffffff';
        vm.toolbarButtonBackColor = '#ededed';
        vm.toolbarButtonDefaultColor = '#000000';
        vm.toolbarFloatButtonBackColor = '#ff5722';
        vm.toolbarSelectedButtonBackColor = '#ff5722';

        // 通用颜色
        vm.genericColor = '#ff5722';


        vm.data={};
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function(){
            vm.store = vm.store.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            init();
        })

        function init(){
            ToneSettings.byStoreId({id:vm.store.id}, function (res) {
                if(res.status == 200){
                    if(res.data){
                        vm.data = res.data;
                    }
                }else{
                    MessageService.error(res.message)
                }
            }, function (error) {});
        }

        window.uploadPic = function ($event, key) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0], key);
            }
        }

        function qiniuUpload(file, key) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "logo-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    if(key == 'previewBkWeb'){
                        vm.data.previewBkWeb = $scope.domain + data.key;
                    }else if(key == 'previewBkMobile'){
                        vm.data.previewBkMobile = $scope.domain + data.key;
                    }else {
                        vm.data.logo = $scope.domain + data.key;
                    }
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        }
        $scope.removeFile = function(key) {
            if(key == 'previewBkWeb'){
                vm.data.previewBkWeb = null;
            }else if(key == 'previewBkMobile'){
                vm.data.previewBkMobile = null;
            }else {
                vm.data.logo = null;
            }
        }
        $scope.chooseTextColor = function () {
            console.log(vm.data.mobileTextSelectedColor)
            document.body.style.setProperty('--themeColor1', vm.data.mobileTextSelectedColor);
        }
        $scope.save = function () {
            if(vm.data.id){
                ToneSettings.update(vm.data, function (res) {
                    if(res.status == 200){
                        MessageService.success("更改成功")
                        init()
                    }else{
                        MessageService.error(res.message)
                    }
                }, function (error) {});
            }else {
                ToneSettings.save(vm.data, function (res) {
                    if(res.status == 200){
                        MessageService.success("更改成功")
                        init()
                    }else{
                        MessageService.error(res.message)
                    }
                }, function (error) {});
            }
        }

        // 重置颜色
        $scope.resetColor = function () {
            $('#resetIcon').addClass('rotation360')
            setTimeout(function () {
                $('#resetIcon').removeClass('rotation360')
            },1000)

            vm.data.webNavbarBackColor = vm.webNavbarBackColor;
            vm.data.webTitleColor = vm.webTitleColor;
            vm.data.webMainButtonBackColor = vm.webMainButtonBackColor;
            vm.data.webMainButtonTextColor = vm.webMainButtonTextColor;
            vm.data.webSecondButtonBackColor = vm.webSecondButtonBackColor;
            vm.data.webSecondButtonTextColor = vm.webSecondButtonTextColor;
    
            vm.data.sidebarBackColor = vm.sidebarBackColor;
            vm.data.sidebarUnselectButtonForeColor = vm.sidebarUnselectButtonForeColor;
            vm.data.sidebarSelectedButtonForeColor = vm.sidebarSelectedButtonForeColor;
    
            vm.data.toolbarBackColor = vm.toolbarBackColor;
            vm.data.toolbarButtonBackColor = vm.toolbarButtonBackColor;
            vm.data.toolbarButtonDefaultColor = vm.toolbarButtonDefaultColor;
            vm.data.toolbarFloatButtonBackColor = vm.toolbarFloatButtonBackColor;
            vm.data.toolbarSelectedButtonBackColor = vm.toolbarSelectedButtonBackColor;
    
            vm.data.genericColor =  vm.genericColor;
        }
    }

    // $scope.goToReadImage = function (pic) {
    //     // 遮罩层下面内容不滚动
    //     $('body').css("overflow", "hidden");
    //     $(".origin-image-dialog").removeClass('hide1');
    //     $(".dialog-form").addClass('opacity-zero');
    //     $(".origin-image-dialog img").attr('src', pic);
    //     $(".modal-content,.modal-dialog").addClass('clear-model');
    // }
})();
