(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('PayOrderController', PayOrderController);

    PayOrderController.$inject = ['$scope', '$state', 'PayOrder', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'MessageService', 'Store', '$http', '$sce', '$timeout', 'DateUtils'];

    function PayOrderController ($scope, $state, PayOrder, ParseLinks, AlertService, paginationConstants, pagingParams, MessageService, Store, $http, $sce, $timeout, DateUtils) {
        var vm = this;
        Store.getCurrent({},function (res) {
            if(res.status == 200){
                vm.store = res.data;
            }else{
                MessageService.error(res.message);
            }
        });
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.openCalendar = openCalendar;
        vm.transition = transition;
        vm.isPay = false;
        vm.payTypeList = [
            {name: '全部',id:''},
            {name: '微信',id:'WeChat'},
            {name: '支付宝',id:'Alipay'}
        ]
        vm.statesList = [
            {name: '所有状态',id:''},
            {name: '已支付',id:'true'},
            {name: '待支付',id:'false'}
        ]
        vm.data = {
            startTime: pagingParams.startTime? new Date(pagingParams.startTime):null,
            endTime: pagingParams.endTime? new Date(pagingParams.endTime):null,
            type: pagingParams.type ? pagingParams.type : '',
            paid: pagingParams.paid ? pagingParams.paid : '',
            searchQuery: pagingParams.search
        }
        vm.datePickerOpenStatus = {
            startTime: false,
            endTime: false
        };
        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        loadAll();

        function loadAll () {
            var items = [];
            if(vm.data.startTime){
                items.push({key:"createdDate",op:">=",value:new Date(Date.parse(vm.data.startTime) -8 * 60 * 60 * 1000)})
            }
            if(vm.data.endTime){
                items.push({key:"createdDate",op:"<=",value:new Date(Date.parse(vm.data.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            }
            if(vm.data.type){
                items.push({key:"type",op:"=",value:vm.data.type})
            }
            if(vm.data.paid){
                items.push({key:"paid",op:"=",value:vm.data.paid})
            }
            if(vm.data.searchQuery){
                items.push({key:"number",op:"like",value:vm.data.searchQuery})
            }
            PayOrder.byCondition({
                items: items,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(res, headers) {
                if(res.status==200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.payOrders = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message)
                }

            }
            function onError(error) {
                MessageService.error("充值订单获取失败");
            }
        }
        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.data.searchQuery,
                startTime: vm.data.startTime?DateUtils.convertLocalDateToServer(vm.data.startTime):"",
                endTime: vm.data.endTime?DateUtils.convertLocalDateToServer(vm.data.endTime):"",
                type: vm.data.type,
                paid: vm.data.paid
            });
        }

        function search () {
            vm.links = null;
            vm.page = 1;
            loadAll();
        }
        function search () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }


        $scope.recharge = function(item) {
            if (vm.store.balance !=0 && vm.store.balance >= 100000000) {
                MessageService.error("当前账户余额较多，请消费后再充值");
                return;
            }
            clearInterval($scope.timer);
            PayOrder.get({id:item.id} ,function(res){
                if(res.status == 200){
                    vm.payOrderId = res.data.id;
                    if (res.data.type == "WeChat") {
                        $http({
                            url: '/back/manager/api/pay-orders/createQRCode/'+vm.payOrderId,
                            method: 'POST',
                            responseType: 'blob'
                        }).success(function(res) {
                            vm.isPay = true;
                            var blob = new Blob([res], {
                                type: "image/jpeg"
                            });
                            $scope.image = URL.createObjectURL(blob);
                            //启动一个定时器判断是否完成支付，完成则关闭页面 取消定时器
                            $scope.timer = setInterval(function() {
                                PayOrder.get({
                                    id: vm.payOrderId
                                }, function(val) {
                                    if (!!val && val.paid) {
                                        clearInterval($scope.timer);
                                        MessageService.success("支付完成");
                                        vm.isPay = false;
                                        loadAll();
                                    }
                                }, function() {});
                            }, 2000);
                        })
                    }else{
                        PayOrder.aliPay({
                            id:res.data.id,
                            returnUrl:window.location.href
                        },function(data){
                            if(!!data && data.status == 200){
                                $scope.trustHtml = $sce.trustAsHtml(data.data);
                            }
                        },function(){
                        });
                    }
                }else{
                    MessageService.error(res.message);
                }
            },function(){
                MessageService.error("创建充值订单失败，请稍后再试");
            })
        }

        $scope.savePay = function () {
            PayOrder.get({
                id: vm.payOrderId
            }, function(res) {
                clearInterval($scope.timer);
                vm.isPay = false;
                if(res.status == 200){
                    loadAll();
                    if (!!res.data && res.data.paid) {
                        MessageService.success("支付完成");
                    }else {
                        MessageService.error("支付失败");
                    }
                }else{
                    MessageService.error(res.message);
                }
                
            }, function() {
                MessageService.error("支付失败1");
            });
        }
        $scope.cancelPay = function () {
            clearInterval($scope.timer);
            vm.isPay = false;
        }
        $scope.cancel = function (data) {
            MessageService.confirm("确认取消当前支付信息吗？",function(){
                PayOrder.delete({id: data.id},function (res) {
                    if(res.status == 200){
                        loadAll();
                        MessageService.success('取消成功');
                    }else{
                        MessageService.error(res.message);
                    }
                    
                });
            },function(){},true);
        }
    }
})();
