(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PageMySuffixController', PageMySuffixController);

    PageMySuffixController.$inject = ['$state', '$scope', '$timeout', 'Qinius', '$uibModal', '$http', 'FontManagement', '$q',
        'pagingParams', 'paginationConstants', 'MessageService', 'ProductXmlFileServes', '$stateParams', 'ImageService', 'ClipArt', 'UidService'];

    function PageMySuffixController($state, $scope, $timeout, Qinius, $uibModal, $http, FontManagement, $q,
                                    pagingParams, paginationConstants, MessageService, ProductXmlFileServes, $stateParams, ImageService, ClipArt, UidService) {

        var vm = this;

        $(".navbar").hide();
        $(".nav-bg").show();
        $(".wrap").hide();
        vm.pages = [];
        vm.token = "";
        vm.url = null;
        $scope.visibleLeft = false;
        $scope.visibleRight = false;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.pid = $stateParams.id;
        vm.isCopy = $stateParams.copy;
        vm.bleedUp = null;
        vm.bleedBottom = null;
        vm.bleedLeft = null;
        vm.bleedRight = null;
        $scope.currentPage = [];
        localStorage.removeItem('ctrlKeyArray');

        //键盘操作array
        vm.CtrlKeyArray = {imageboxes: [], textboxes: []};
        // vm.clipArts = ClipArt.getAllByCondition({items:[]});
        //得到原始模板框中最大宽高比例
        var $con = $(".content-scroll");
        var originalScale = $con.width() * 0.8 / ($con.height() * 0.8);

        /**
         * 出血统一改成固定3mm
         * liu
         */
        var unitTransform = function (mm) {
            // return Math.ceil(mm / 25.4 * 300);         //mm换算成px
            // return Math.ceil(mm *3.78);         //mm换算成px
            return (mm * 3) - 0;         //mm换算成px
        };
        var unitTransformPx = function (px) {
            // return Math.floor(px / 300 * 25.4);        //px换算成mm
            // return Math.floor(px *50/189);        //px换算成mm
            return (px * 1 / 3).toFixed(2) - 0;        //px换算成mm
        };
        $scope.border = unitTransform(3);  //36px   出血值
        $scope.borderTop = unitTransform(3);
        $scope.borderBottom = unitTransform(3);
        $scope.borderLeft = unitTransform(3);
        $scope.borderRight = unitTransform(3);

        vm.page = {
            name: '',
            reprint: '',
            categoryId: ''
        };


        vm.fontNames = [];

        vm.dpiChoose = [{data: 'mm(毫米)', id: 0}, {data: 'px(像素)', id: 1}];
        $scope.dpi = vm.dpiChoose[0];

        var timer = null;
        $scope.bgImgs = {bgImg: []};
        $scope.masks = {mask: []};
        $scope.template = {
            pageI: 0,
            widthM: 420,
            heightM: 285,
            currentMenu: 'page',
            angle: 0,
            textAngle: 0,
            textFont: '',
            text: '',
            textColor: '#000000',
            textSize: 16,
            bgImgs: {
                bgImg: []
            },
            masks: {
                mask: []
            },
            pages: {
                page: [{
                    bgImg: '',
                    background: {
                        resource: {}
                    },
                    imageboxes: {
                        imagebox: []
                    },
                    textboxes: {
                        textbox: []
                    },
                    barcode: {},
                    hasBarCode: false,
                    widthM: 420,
                    heightM: 285,
                    scale: 1,
                    proportion: 1,
                    seq: 1,
                    spread: false,
                    backup: false
                }]
            }
        };

        $scope.updateOnlyShow = function(flag){
            var art = $scope.template.pages.page[$scope.template.pageI].imagearts.imageart[$scope.template.artI];
            art.onlyShow = flag;
            $scope.template.artOnlyShow = flag;
        }

        function getAll() {
            if ($stateParams.id) {
                ProductXmlFileServes.get({id: $stateParams.id}, function (resp) {
                    vm.page = {
                        name: resp.name,
                        categoryName: resp.categoryName,
                        categoryId: resp.categoryId,
                        reprint: resp.reprint,
                        provider: resp.provider,
                        identifier: resp.identifier,
                        type: resp.type,
                        customStoreId: resp.customStoreId,
                        customStoreName: resp.customStoreName
                    };

                    $scope.template.bgImgs = angular.copy(resp.document.bgImgs);
                    $scope.template.masks = angular.copy(resp.document.masks);
                    $scope.template.pages = angular.copy(resp.document.pages);

                    if ($scope.template.pages.page.length > 1) {
                        $scope.visibleRight = true;
                    }

                    angular.forEach(resp.document.bgImgs.bgImg, function (bgImg, i) {
                        var newImg = document.createElement('img');
                        newImg.crossOrigin = "Anonymous";
                        newImg.src = vm.url + bgImg.identifier;
                        $timeout(function () {
                            var w = newImg.width;
                            var h = newImg.height;
                            if (w < h) {
                                $scope.template.bgImgs.bgImg[i].width = 50;
                            } else {
                                $scope.template.bgImgs.bgImg[i].height = 50;
                            }
                            $scope.template.bgImgs.bgImg[i].isEdit = true;
                        });
                    });
                    angular.forEach(resp.document.masks.mask, function (mask, i) {
                        $scope.template.masks.mask[i] = {
                            resource: {
                                'identifier': mask.resource.identifier,
                                'provider': mask.resource.provider
                            },
                            // 'bgImg': imagecanvas.toDataURL(),
                            'height': 50,
                            'isEdit': true
                        };
                    });
                    angular.forEach(resp.document.pages.page, function (page, i) {
                        $scope.template.pages.page[i] = {
                            seq: i + 1,
                            widthM: page.trimbox.width,
                            heightM: page.trimbox.height,
                            width: unitTransform(page.trimbox.width),
                            height: unitTransform(page.trimbox.height),
                            spread: page.spread,
                            backup: page.backup,
                            scale: 1,
                            barcode: {},
                            uuid: page.uuid,
                            hasBarCode: page.barcode ? true : false,
                            barWidthM: page.barcode ? page.barcode.width : 0,
                            barHeightM: page.barcode ? page.barcode.height : 0,
                            barXM: page.barcode ? page.barcode.x : 0,
                            barYM: page.barcode ? page.barcode.y : 0,
                            background: angular.copy(page.background),
                            hasBgImg: page.background && page.background.resource.identifier ? true : false,
                            bgImg: page.background && page.background.resource.identifier ? vm.url + page.background.resource.identifier + '?imageMogr2/interlace/1' : '',
                            imageboxes: angular.copy(page.imageboxes),
                            textboxes: angular.copy(page.textboxes),
                            imagearts: angular.copy(page.imagearts),
                            unit: 'mm'
                        };
                        if ($scope.template.pages.page[i].uuid == null) {
                            $scope.template.pages.page[i].uuid = UidService.get();
                        }
                        if (page.barcode) {
                            $scope.template.pages.page[i].barcode = {
                                width: unitTransform(page.barcode.width),
                                height: unitTransform(page.barcode.height),
                                x: unitTransform(page.barcode.x),
                                y: unitTransform(page.barcode.y),
                                barWidthM: page.barcode.width,
                                barHeightM: page.barcode.height,
                                barXM: page.barcode.x,
                                barYM: page.barcode.y
                            }
                        }

                        if (page.imagearts) {
                            var tempPage = $scope.template.pages.page[i];
                            angular.forEach(page.imagearts.imageart, function (art, n) {
                                tempPage.imagearts.imageart[n] = {
                                    geometry: getGeometry(art.geometry),
                                    rotation: angular.copy(art.rotation),
                                    type: art.type,
                                    onlyShow:art.onlyShow,
                                    resource: angular.copy(art.resource),
                                    widthM: art.geometry.width,
                                    heightM: art.geometry.height,
                                    offsetXM: art.geometry.x,
                                    offsetYM: art.geometry.y,
                                    unit: 'mm'
                                }
                            });
                            console.log($scope.template.pages.page)
                        }

                        angular.forEach(page.imageboxes.imagebox, function (image, j) {
                            var imagecanvas = document.createElement('canvas');
                            var imagecontext = imagecanvas.getContext('2d');
                            var newImg = document.createElement('img');
                            newImg.crossOrigin = "Anonymous";
                            newImg.src = 'content/images/shattered.png';
                            newImg.onload = function () {
                                newImg.onload = null;
                                var width = newImg.width;
                                var height = newImg.height;
                                var masks = document.createElement('img');
                                masks.crossOrigin = "anonymous";
                                masks.onload = function () {
                                    masks.onload = null;
                                    imagecanvas.width = width;
                                    imagecanvas.height = height;
                                    imagecontext.drawImage(masks, 0, 0, width, height);
                                    imagecontext.globalCompositeOperation = 'source-atop';
                                    imagecontext.drawImage(newImg, 0, 0);
                                    $scope.template.pages.page[i].imageboxes.imagebox[j].bgImg = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                                    $scope.$apply();
                                };
                                masks.src = vm.url + image.mask.resource.identifier;
                            };
                            newImg.src = 'content/images/shattered.png';

                            var tempPage = $scope.template.pages.page[i];
                            tempPage.imageboxes.imagebox[j] = {
                                geometry: getGeometry(image.geometry),
                                rotation: angular.copy(image.rotation),
                                mask: angular.copy(image.mask),
                                image: angular.copy(image.image),
                                widthM: image.geometry.width,
                                heightM: image.geometry.height,
                                offsetXM: image.geometry.x,
                                offsetYM: image.geometry.y,
                                unit: 'mm'
                            }
                        });
                        angular.forEach(page.textboxes.textbox, function (text, k) {
                            $scope.template.pages.page[i].textboxes.textbox[k] = {
                                geometry: getGeometry(text.geometry),
                                identifier: text.identifier,
                                rotation: angular.copy(text.rotation),
                                angle: angular.copy(text.angle),
                                status: angular.copy(text.status),
                                style: angular.copy(text.style),
                                type: angular.copy(text.type),
                                text: angular.copy(text.text),
                                fontStyle: [{
                                    style: '',
                                    path: text.identifier
                                }],
                                unit: 'mm'
                            };

                            $scope.template.textWidthM = $scope.template.pages.page[i].textboxes.textbox[k].widthM = text.geometry.width;
                            $scope.template.textHeightM = $scope.template.pages.page[i].textboxes.textbox[k].heightM = text.geometry.height;
                            $scope.template.pages.page[i].textboxes.textbox[k].xM = $scope.template.pages.page[i].textboxes.textbox[k].textXM = text.geometry.x;
                            $scope.template.pages.page[i].textboxes.textbox[k].yM = $scope.template.pages.page[i].textboxes.textbox[k].textYM = text.geometry.y;

                            switch (text.style.bold + '' + text.style.italic) {
                                case 'falsefalse':
                                    text.styles = 'NORMAL';
                                    break;
                                case 'falsetrue':
                                    text.styles = 'ITALIC';
                                    break;
                                case 'truefalse':
                                    text.styles = 'BOLD';
                                    break;
                                default:
                                    text.styles = 'BOLD_ITALIC';
                                    break;
                            }
                            $http({
                                url: 'fileserves/api/convert/textToImg',
                                method: 'POST',
                                data: {
                                    identifier: text.identifier,
                                    text: text.text,
                                    fontSize: text.style.size,
                                    style: text.styles,
                                    width: $scope.template.pages.page[i].textboxes.textbox[k].geometry.width,
                                    height: $scope.template.pages.page[i].textboxes.textbox[k].geometry.height,
                                    color: text.style.color,
                                    family: text.style.font
                                },
                                responseType: 'blob'
                            }).success(function (data, status, headers, config) {
                                var createObjectURL = function (blob) {
                                    return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                                };
                                var fileURL = URL.createObjectURL(data);
                                $scope.template.pages.page[i].textboxes.textbox[k].picCode = fileURL;
                            }).error(function () {
                                MessageService.error("文字生成失败，请重新选择字体");
                            });
                        });
                        setOriginalWaH(unitTransform(page.trimbox.width), unitTransform(page.trimbox.height), i);
                    });

                    $scope.template.widthM = resp.document.pages.page[0].trimbox.width;
                    $scope.template.width = unitTransform($scope.template.widthM);
                    $scope.template.heightM = resp.document.pages.page[0].trimbox.height;
                    $scope.template.height = unitTransform($scope.template.heightM);
                    $scope.currentPage = $scope.template.pages.page[0];
                    $scope.template.textI = 0;
                    setUlWidth();
                })
            } else {
                setOriginalWaH(unitTransform(420), unitTransform(285), 0);
                $scope.template.width = unitTransform(420);
                $scope.template.widthM = 420;
                $scope.template.height = unitTransform(285);
                $scope.template.heightM = 285;
                $scope.currentPage = angular.copy($scope.template.pages.page[0]);
            }
        }


        var data = new Date().getTime();

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        $scope.domains = Qinius.getDomain();

        $scope.files = new Array;

        $scope.history = [angular.copy($scope.template)];
        $scope.currentHistoryIndex = 0;
        $scope.pageHistory = [{
            history: [angular.copy($scope.template.pages.page[$scope.template.pageI])],
            currentHistoryIndex: 0
        }];
        $q.all([$scope.domains.$promise]).then(function () {
            vm.url = 'https://' + $scope.domains.domains[0] + '/';
            getAll();
            $scope.confirmName();
        });

        /**
         *
         * 自定义元素右键菜单样式  复制、粘贴、删除
         *
         */
        document.onkeydown = function (event) {
            if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 67) {
                $scope.copyimg();
                event.returnvalue = false;
            } else if ((event.ctrlKey == true || event.metaKey == true) && event.keyCode == 86) {
                $scope.pasteimg();
                event.returnvalue = false;
            }
        };

        //复制
        $scope.copyimg = function () {
            console.log(vm.CtrlKeyArray);
            localStorage.setItem('ctrlKeyArray', JSON.stringify(vm.CtrlKeyArray));
        };
        //粘贴
        $scope.pasteimg = function () {
            var index = $scope.template.pageI;
            var temp = JSON.parse(localStorage.getItem('ctrlKeyArray'));

            if (temp != null) {
                if ($scope.template.pages.page[index].imageboxes == null) {
                    $scope.template.pages.page[index].imageboxes = {};
                }
                if ($scope.template.pages.page[index].imageboxes.imagebox == null) {
                    $scope.template.pages.page[index].imageboxes.imagebox = [];
                }
                if ($scope.template.pages.page[index].textboxes == null) {
                    $scope.template.pages.page[index].textboxes = {};
                }
                if ($scope.template.pages.page[index].textboxes.textbox == null) {
                    $scope.template.pages.page[index].textboxes.textbox = [];
                }
                angular.forEach(temp.imageboxes, function (item) {
                    item.offsetXM += 12;
                    item.offsetYM += 12;
                    item.geometry.x = unitTransform(item.offsetXM);
                    item.geometry.y = unitTransform(item.offsetYM);
                    $scope.template.pages.page[index].imageboxes.imagebox.push(item);
                    $scope.template.imgI = $scope.template.pages.page[index].imageboxes.imagebox.length - 1;
                });
                angular.forEach(temp.textboxes, function (item) {
                    item.xM += 20;
                    item.yM += 20;
                    item.geometry.x = unitTransform(item.xM);
                    item.geometry.y = unitTransform(item.yM);
                    $scope.template.pages.page[index].textboxes.textbox.push(item);
                });
            }
            $scope.digest;
            if ($scope.template.pages.page[index].textboxes && $scope.template.pages.page[index].textboxes.textbox) {
                angular.forEach($scope.template.pages.page[index].textboxes.textbox, function (item, index) {
                    var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[index];
                    toImg(text);
                    updateHistorys();
                })
            }
            $timeout(function () {
                $scope.selectPages(index);
            }, 200);

            localStorage.setItem('ctrlKeyArray', JSON.stringify(temp));
        };
        //删除
        $scope.deleteTemplate = function () {
            vm.CtrlKeyArray = {imageboxes: [], textboxes: []};
            $scope.rollback(1);
        };

        $scope.updateDpi = function (data, attr, flag) {
            $scope.dpi = data;

            var i = $scope.template.pageI;
            var page = $scope.template.pages.page[i];

            var arr = {};
            var arr2 = {};

            if (data.id == 1) {
                if (attr == 'page') {
                    $(".temp-px").removeClass('hide1');
                    $(".temp-mm").addClass('hide1');
                    page.unit = 'px';
                    arr = ThreeHundredDpi($scope.template.widthM, $scope.template.heightM);
                    $scope.template.widthPx = arr.px1;
                    $scope.template.heightPx = arr.px2;
                }
                if (attr == 'Img') {
                    var img = page.imageboxes.imagebox[$scope.template.imgI];
                    img.unit = 'px';
                    $(".img-wah-px").removeClass('hide1');
                    $(".img-wah-mm").addClass('hide1');
                    arr = ThreeHundredDpi($scope.template.imgWidthM, $scope.template.imgHeightM);
                    $scope.template.imgWidthPx = arr.px1;
                    $scope.template.imgHeightPx = arr.px2;

                    $(".img-xay-px").removeClass('hide1');
                    $(".img-xay-mm").addClass('hide1');
                    arr2 = ThreeHundredDpi($scope.template.offsetXM, $scope.template.offsetYM);

                    $scope.template.offsetXPx = arr2.px1;
                    $scope.template.offsetYPx = arr2.px2;
                }
                if (attr == 'text') {
                    var textbox = page.textboxes.textbox[$scope.template.textI];
                    textbox.unit = 'px';
                    $(".text-wah-px").removeClass('hide1');
                    $(".text-wah-mm").addClass('hide1');
                    arr = ThreeHundredDpi($scope.template.textWidthM, $scope.template.textHeightM);
                    $scope.template.textWidthPx = arr.px1;
                    $scope.template.textHeightPx = arr.px2;

                    $(".text-xay-px").removeClass('hide1');
                    $(".text-xay-mm").addClass('hide1');
                    arr2 = ThreeHundredDpi($scope.template.textXM, $scope.template.textYM);

                    $scope.template.textXPx = arr2.px1;
                    $scope.template.textYPx = arr2.px2;
                }
                if (attr == 'Art') {
                    var art = page.imagearts.imageart[$scope.template.artI];
                    art.unit = 'px';
                    $(".art-wah-px").removeClass('hide1');
                    $(".art-wah-mm").addClass('hide1');
                    arr = ThreeHundredDpi($scope.template.artWidthM, $scope.template.artHeightM);
                    $scope.template.artWidthPx = arr.px1;
                    $scope.template.artHeightPx = arr.px2;

                    $(".art-xay-px").removeClass('hide1');
                    $(".art-xay-mm").addClass('hide1');
                    arr2 = ThreeHundredDpi($scope.template.artXM, $scope.template.artYM);

                    $scope.template.artXPx = arr2.px1;
                    $scope.template.artYPx = arr2.px2;
                }
                if (attr == 'Barcode') {
                    var bar = page.barcode;
                    bar.unit = 'px';
                    $(".bar-wah-px").removeClass('hide1');
                    $(".bar-wah-mm").addClass('hide1');
                    arr = ThreeHundredDpi($scope.template.barWidthM, $scope.template.barHeightM);
                    $scope.template.barWidthPx = arr.px1;
                    $scope.template.barHeightPx = arr.px2;

                    $(".bar-xay-px").removeClass('hide1');
                    $(".bar-xay-mm").addClass('hide1');
                    arr2 = ThreeHundredDpi($scope.template.barXM, $scope.template.barYM);
                    $scope.template.barXPx = arr2.px1;
                    $scope.template.barYPx = arr2.px2;
                }
            } else {
                if (attr == 'page') {
                    $(".temp-px").addClass('hide1');
                    $(".temp-mm").removeClass('hide1');
                    page.unit = 'mm';
                    arr = ThreeHundredDpiToMm($scope.template.widthPx, $scope.template.heightPx);
                    $scope.template.widthM = arr.mm1;
                    $scope.template.heightM = arr.mm2
                }
                if (attr == 'Img') {
                    var img = page.imageboxes.imagebox[$scope.template.imgI];
                    $(".img-wah-px").addClass('hide1');
                    $(".img-wah-mm").removeClass('hide1');
                    img.unit = 'mm';
                    arr = ThreeHundredDpiToMm($scope.template.imgWidthPx, $scope.template.imgHeightPx);
                    $scope.template.imgWidthM = arr.mm1;
                    $scope.template.imgHeightM = arr.mm2

                    $(".img-xay-px").addClass('hide1');
                    $(".img-xay-mm").removeClass('hide1');
                    arr2 = ThreeHundredDpiToMm($scope.template.offsetXPx, $scope.template.offsetYPx);
                    $scope.template.offsetXM = arr2.mm1;
                    $scope.template.offsetYM = arr2.mm2;
                }
                if (attr == 'text') {
                    var textbox = page.textboxes.textbox[$scope.template.textI];
                    $(".text-wah-px").addClass('hide1');
                    $(".text-wah-mm").removeClass('hide1');
                    textbox.unit = 'mm';
                    arr = ThreeHundredDpiToMm($scope.template.textWidthPx, $scope.template.textHeightPx);
                    $scope.template.textWidthM = arr.mm1;
                    $scope.template.textHeightM = arr.mm2

                    $(".text-xay-px").addClass('hide1');
                    $(".text-xay-mm").removeClass('hide1');
                    arr2 = ThreeHundredDpiToMm($scope.template.textXPx, $scope.template.textYPx);
                    $scope.template.textXM = arr2.mm1;
                    $scope.template.textYM = arr2.mm2;
                }
                if (attr == 'Art') {
                    var art = page.imagearts.imageart[$scope.template.artI];
                    $(".art-wah-px").addClass('hide1');
                    $(".art-wah-mm").removeClass('hide1');
                    art.unit = 'mm';
                    arr = ThreeHundredDpiToMm($scope.template.artWidthPx, $scope.template.artHeightPx);
                    $scope.template.artWidthM = arr.mm1;
                    $scope.template.artHeightM = arr.mm2

                    $(".art-xay-px").addClass('hide1');
                    $(".art-xay-mm").removeClass('hide1');
                    arr2 = ThreeHundredDpiToMm($scope.template.artXPx, $scope.template.artYPx);
                    $scope.template.artXM = arr2.mm1;
                    $scope.template.artYM = arr2.mm2;
                }
                if (attr == 'Barcode') {
                    var bar = page.barcode;
                    bar.unit = 'mm';
                    $(".bar-wah-px").addClass('hide1');
                    $(".bar-wah-mm").removeClass('hide1');
                    arr = ThreeHundredDpiToMm($scope.template.barWidthPx, $scope.template.barHeightPx);
                    $scope.template.barWidthM = arr.mm1;
                    $scope.template.barHeightM = arr.mm2

                    $(".bar-xay-px").addClass('hide1');
                    $(".bar-xay-mm").removeClass('hide1');
                    arr2 = ThreeHundredDpiToMm($scope.template.barXPx, $scope.template.barYPx);
                    $scope.template.barXM = arr2.mm1;
                    $scope.template.barYM = arr2.mm2;
                }
            }
        }

        // mm---px
        function ThreeHundredDpi(attr1, attr2) {
            // Math.floor(px / 300 * 25.4)   (px--mm)
            // Math.ceil(mm / 25.4 * 300)   (mm---px)
            var px1 = parseFloat((attr1 / 25.4 * 300 - 0)).toFixed(2) - 0;
            var px2 = parseFloat((attr2 / 25.4 * 300 - 0)).toFixed(2) - 0;
            return {
                px1: px1,
                px2: px2
            }
        }

        // px---mm
        function ThreeHundredDpiToMm(attr1, attr2) {
            var mm1 = parseFloat((attr1 / 300 * 25.4 - 0)).toFixed(2) - 0;
            var mm2 = parseFloat((attr2 / 300 * 25.4 - 0)).toFixed(2) - 0;
            return {
                mm1: mm1,
                mm2: mm2
            }

        }

        $scope.selectPageo = function (e, $index, page) {
            if (page.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];
            } else {
                $scope.dpi = vm.dpiChoose[1];
            }
        };

        /**
         * 宽高坐标从 mm 换算成 px
         * @param obj
         * @param index
         * @returns {{height: number, width: number, x: number, y: number}}
         */
        function getGeometry(obj) {
            return {
                height: unitTransform(obj.height),
                width: unitTransform(obj.width),
                x: unitTransform(obj.x),
                y: unitTransform(obj.y)
            }
        }

        var content = $(".content-container")[0];
        content.ondragenter = ignoreDrag;
        // 拖动文件的鼠标指针位置放置区之上时发生
        content.ondragover = ignoreDrag;
        content.ondrop = drop;

        function ignoreDrag(e) {
            // 确保其他元素不会取得该事件
            e.stopPropagation();
            e.preventDefault();
        }

        function drop(e) {
            ignoreDrag(e);

            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            var data = e.dataTransfer.getData("Text");

            var s = $('#' + data)[0].src;
            var mask = $('#' + data)[0].getAttribute('data-mask');
            var dataIndex = $('#' + data)[0].getAttribute('data-index');
            var s1 = s.split(/\//)[3];
            var bgImgArr = $scope.template.bgImgs.bgImg[dataIndex];
            // 判读是否是占位框
            if (data.indexOf('dragtargetMask') != '-1') {
                s1 = $('#' + data).attr('data-mask');
                if (currentPage.imageboxes.imagebox.length) {
                    var index = e.target.dataset.index;

                    ImageService.convertImgToBase64(vm.url + s1, function (s) {
                        var imagecanvas = document.createElement('canvas');
                        var imagecontext = imagecanvas.getContext('2d');
                        var img = document.getElementsByClassName('page-item')[0].getElementsByClassName('mask')[index];
                        img.src = 'content/images/shattered.png';
                        var newImg = document.createElement('img');
                        newImg.crossOrigin = "Anonymous";
                        newImg.src = img.src;
                        newImg.onload = function () {
                            newImg.onload = null;
                            var width = newImg.width;
                            var height = newImg.height;
                            img.setAttribute('data-mask', s);
                            var masks = document.createElement('img');
                            masks.src = img.getAttribute('data-mask');
                            masks.crossOrigin = "anonymous";
                            masks.onload = function () {
                                masks.onload = null;
                                imagecanvas.width = width;
                                imagecanvas.height = height;
                                imagecontext.drawImage(masks, 0, 0, width, height);
                                imagecontext.globalCompositeOperation = 'source-atop';
                                imagecontext.drawImage(img, 0, 0);
                                img.src = ImageService.dataURLtoBlob(imagecanvas.toDataURL());
                                $scope.currentPage.imageboxes.imagebox[index].bgImg = img.src;
                                currentPage.imageboxes.imagebox[index].bgImg = img.src;
                                currentPage.imageboxes.imagebox[index].mask.resource.identifier = s1;
                                currentPage.imageboxes.imagebox[index].mask.resource.provider = "qiniu";
                                updateHistorys();
                                $scope.$digest();
                            };
                        };


                    });
                }
            } else if (data.indexOf('dragtargetClipArt') != '-1') {
                //判读是否是素材
                var identifier = e.dataTransfer.getData("Art");

                if (!currentPage.imagearts) {
                    currentPage.imagearts = {
                        imageart: []
                    };
                }
                var newImg = document.createElement('img');
                newImg.crossOrigin = "Anonymous";
                newImg.src = vm.url + identifier + '?imageView2';
                newImg.onload = function () {
                    newImg.onload = null;
                    var width = newImg.width;
                    var height = newImg.height;


                    $(".art-wah-px").addClass('hide1');
                    $(".art-wah-mm").removeClass('hide1');
                    $(".art-xay-px").addClass('hide1');
                    $(".art-xay-mm").removeClass('hide1');
                    var arr = ThreeHundredDpiToMm(width, height);
                    $scope.template.artWidthM = arr.mm1;
                    $scope.template.artHeightM = arr.mm2;

                    $scope.template.artXM = 12 + currentPage.imagearts.imageart.length * 2;
                    $scope.template.artYM = 12 + currentPage.imagearts.imageart.length * 2;

                    $scope.template.angle = 0;
                    currentPage.imagearts.imageart.push({
                        geometry: {
                            width: unitTransform($scope.template.artWidthM),
                            height: unitTransform($scope.template.artHeightM),
                            x: unitTransform($scope.template.artXM),
                            y: unitTransform($scope.template.artYM)
                        },
                        rotation: {
                            angle: 0
                        },
                        // 模板使用，微信端不可进行操作
                        type: 'static',
                        resource: {
                            identifier: identifier,
                            provider: 'qiniu'
                        },
                        bgImg: '',
                        widthM: $scope.template.artWidthM,
                        heightM: $scope.template.artHeightM,
                        offsetXM: $scope.template.artXM,
                        offsetYM: $scope.template.artYM,
                        zoom: 1,
                        unit: 'mm'
                    });

                    $scope.template.artI = currentPage.imagearts.imageart.length - 1;
                    updateHistorys();
                    $scope.template.currentMenu = 'Art';
                    $scope.$digest();
                };
            } else {
                currentPage.hasBgImg = true;

                if (!bgImgArr.isEdit) {
                    $("#bgImg" + $scope.template.pageI).attr('src', s);
                    currentPage.bgImg = s;
                    $scope.currentPage.bgImg = s;
                } else {
                    $("#bgImg" + $scope.template.pageI).attr('src', vm.url + bgImgArr.identifier);
                    currentPage.bgImg = vm.url + bgImgArr.identifier;
                    $scope.currentPage.bgImg = vm.url + bgImgArr.identifier;
                }

                if (timer) {
                    clearInterval(timer);
                }
                timer = setInterval(function () {
                    if (bgImgArr.identifier) {
                        clearInterval(timer);
                        timer = null;
                        currentPage.background.resource.identifier = bgImgArr.identifier;
                        currentPage.background.resource.provider = "qiniu";
                        updateHistorys();
                    }
                }, 600);
                $scope.$digest();
            }

            // 取得拖放进来的文件
            var datas = e.dataTransfer;
            var files = datas.files;
            // 将其传给真正的处理文件的函数
            var file = files[0];

            if (!file) {
                return
            }
            var reader = new FileReader();
            reader.onload = function (e) {
                currentPage.bgImg = e.target.result;
                $scope.currentPage.bgImg = e.target.result;

                var formData = new FormData();
                formData.append('file', file);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);

                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        currentPage.background.resource.identifier = data.key;
                        currentPage.background.resource.provider = "qiniu";
                    },
                    error: function (request) {
                        console.log("error");
                    }
                });
                updateHistorys();
                $scope.$digest();
            };
            reader.readAsDataURL(file);
        }

        window.dragStart = function (event) {
            var target = event.target;
            event.dataTransfer.setData("Text", target.id);
            if (target.id.indexOf('dragtargetClipArt') != '-1') {
                event.dataTransfer.setData("Art", target.dataset.clipart);
            }
        };

        //撤销，重做按钮事件
        $scope.rollback = function (forward) {
            var i = $scope.template.pageI;
            if ((!forward && !$scope.pageHistory[i].currentHistoryIndex)) {
                return;
            }

            if (forward) {
                $scope.template.pages.page[i] = {
                    bgImg: '',
                    background: {
                        resource: {}
                    },
                    imageboxes: {
                        imagebox: []
                    },
                    textboxes: {
                        textboxes: []
                    },
                    barcode: {},
                    hasBarCode: false,
                    scale: 1,
                    widthM: $scope.template.pages.page[i].widthM,
                    heightM: $scope.template.pages.page[i].heightM,
                    width: $scope.template.pages.page[i].width,
                    height: $scope.template.pages.page[i].height,
                    spread: $scope.template.pages.page[i].spread,
                    backup: $scope.template.pages.page[i].backup
                };
                $scope.template.currentMenu = 'page';
                $scope.currentPage = $scope.template.pages.page[i];
            } else {
                $scope.pageHistory[i].currentHistoryIndex = $scope.pageHistory[i].currentHistoryIndex - 1;
                $scope.pageHistory[i].history.pop();
                $scope.template.pages.page[i] = angular.copy($scope.pageHistory[i].history[$scope.pageHistory[i].currentHistoryIndex]);
                if (!$scope.template.pages.page[i].imageboxes.imagebox.length && $scope.template.currentMenu == 'Img') {
                    $scope.template.currentMenu = 'page';
                }
                if (!($scope.template.pages.page[i].textboxes.textbox && $scope.template.pages.page[i].textboxes.textbox.length) && $scope.template.currentMenu == 'text') {
                    $scope.template.currentMenu = 'page';
                }
            }
            $scope.currentPage = $scope.template.pages.page[i];
            $scope.template.widthM = $scope.currentPage.widthM;
            $scope.template.heightM = $scope.currentPage.heightM;
            setOriginalWaH(unitTransform($scope.currentPage.widthM), unitTransform($scope.currentPage.heightM), $scope.template.pageI);
            setUlWidth();
            angular.forEach($scope.template.pages.page, function (item, index) {
                if (!item.bgImg) {
                    $('#bgImg' + index).attr('src', '');            //添加src
                }
            });
        };


        $scope.zoomIn = function () {
            changeImgOffset(true);
        };

        $scope.zoomOut = function () {
            changeImgOffset(false);
        };

        $scope.reduction = function () {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            currentPage.scale = 1;
            $(".page-item").css({'margin': 'auto'});
            updateHistorys();
        };

        $scope.pageIsSpread = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                currentPage.spread = true;
            } else {
                currentPage.spread = false;
            }
            updateHistorys();
        };

        $scope.pageIsBackup = function (flag) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            if (flag) {
                currentPage.backup = true;
            } else {
                currentPage.backup = false;
            }
            updateHistorys();
        };

        $scope.exitPage = function () {
            restoreZindex();
            MessageService.confirm("确认要退出吗？", function () {
                $state.go('product-xml', {reload: true})
            }, function () {
            });
        };


        function setOriginalWaH(width, height, i) {
            var w = width;
            var h = height;

            if (originalScale < width / height) {
                // console.log("宽定，高不定");
                //宽定，高不定
                width = $con.width() * 0.8;
                height = $con.width() * 0.8 * h / w;
            } else {
                // console.log("高定，宽不动");
                //高定，宽不动
                height = $con.height() * 0.8;
                width = $con.height() * 0.8 * w / h
            }
            $scope.template.pages.page[i].width = width;
            $scope.template.pages.page[i].height = height;
            $scope.template.pages.page[i].proportion = (w / width);
            $scope.currentPage = $scope.template.pages.page[i];
        }

        window.onresize = function () {
            // console.log("resize");
            angular.forEach($scope.template.pages.page, function (page, i) {
                setOriginalWaH(unitTransform(page.widthM), unitTransform(page.heightM), i);
            });
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            // console.log($scope.template.pageI)
        };

        function restoreZindex() {
            angular.forEach($scope.currentPage.imageboxes.imagebox, function (imagebox, i) {
                $("#mask" + i).css({'z-index': i + 200});
            });
        }

        $scope.confirmName = function () {
            createModal('app/entities/page/pagesmySuffix-save.html', 'PageMySuffixSaveController').result.then(
                function (data) {
                    $scope.borderTop = unitTransform(data.bleedTop);
                    $scope.borderBottom = unitTransform(data.bleedBottom);
                    $scope.borderLeft = unitTransform(data.bleedLeft);
                    $scope.borderRight = unitTransform(data.bleedRight);

                    if (!vm.pid || vm.isCopy) {
                        vm.page = {
                            name: data.name,
                            reprint: data.reprint,
                            categoryId: data.category,
                            themeClassify: data.themeClassify,
                            type: data.type,
                            pattern:data.pattern,
                            replace:data.replace
                        };
                    } else {
                        vm.page.name = data.name;
                        vm.page.reprint = data.reprint;
                        vm.page.pattern = data.pattern;
                        vm.page.categoryId = data.category;
                        vm.page.themeClassify = data.themeClassify;
                        vm.page.type = data.type;
                        vm.page.replace = data.replace;

                    }
                    if (vm.page.type == "Custom") {
                        vm.page.customStoreId = data.customStore.id;
                        vm.page.customStoreName = data.customStore.name;
                    } else {
                        vm.page.customStoreId = null;
                        vm.page.customStoreName = null;
                    }

                    ClipArt.getAllByCondition({
                        items: [{
                            key: "themeClassify.id",
                            value: vm.page.themeClassify,
                            op: "="
                        }]
                    }, function (data) {
                        vm.clipArts = data;
                    }, function () {
                        MessageService.error("素材获取失败")
                    })

                });
        }

        $scope.savePage = function () {
            restoreZindex();
            generateJsonFile();
        };

        var createModal = function (url, controller) {
            return $uibModal.open({
                templateUrl: url,
                controller: controller,
                backdrop: 'static',
                keyboard: true,
                windowClass: "jh-modal-md",
                controllerAs: 'vm',
                params: {
                    copy: vm.isCopy
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page)
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('page');
                        return $translate.refresh();
                    }]
                }
            });
        };
        /************************---模板---***************************---start---*******************/
        //增加模板
        $scope.addPage = function () {
            var seq = $scope.template.pages.page.length + 1;
            $scope.template.pages.page.push({
                bgImg: '',
                seq: seq,
                background: {
                    resource: {}
                },
                imageboxes: {imagebox: []},
                textboxes: {textbox: []},
                imagearts: {imageart: []},
                barcode: {},
                hasBarCode: false,
                scale: 1,
                uuid: UidService.get(),
                widthM: $scope.template.pages.page[$scope.template.pageI].widthM,
                heightM: $scope.template.pages.page[$scope.template.pageI].heightM,
                width: $scope.template.pages.page[$scope.template.pageI].width,
                height: $scope.template.pages.page[$scope.template.pageI].height,
                spread: false,
                backup: false
            });
            $scope.template.pageI = $scope.template.pages.page.length - 1;
            updateHistorys();
            $scope.template.currentMenu = 'page';
            $scope.visibleLeft = true;
            $scope.visibleRight = false;
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];

            $scope.template.width = unitTransform($scope.template.widthM);
            $scope.template.height = unitTransform($scope.template.heightM);
            setOriginalWaH($scope.template.width, $scope.template.height, $scope.template.pageI);
            setUlWidth();
        };

        var setUlWidth = function () {
            var w = 30, w1 = 70;
            angular.forEach($scope.template.pages.page, function (page) {
                w += page.width * 42 / page.height + 16;
                w1 += (page.width + $scope.borderLeft + $scope.borderRight) * 42 / (page.height + $scope.borderTop +$scope.borderBottom) + 16;
            });
            $(".sort-ul").width(w + 'px');
            $(".nav-ol").width(w1 + 'px');
        };

        $scope.sortableOptions = {
            // 数据有变化
            update: function (e, ui) {
                $timeout(function () {
                    var resArr = [];
                    for (var i = 0; i < $scope.template.pages.page.length; i++) {
                        resArr.push($scope.template.pages.page[i].imageboxes.imagebox.length);
                    }
                })
            },

            // 完成动作
            stop: function (e, ui) {
                //do nothing
                isCanLeftAndRightPage();
            },
            items: "li:not(.not-sortable)"
        };

        // var dataWatcher = $scope.$watchCollection("data", function(){
        //     console.log("watcher");
        //     $scope.data = orderByFilter($scope.data, ["age"]);
        //     //销毁监听器
        //     dataWatcher();
        // });

        //模板预览点击时
        $scope.selectPages = function (index) {
            if (index == null) {
                return;
            }
            if (index !== $scope.template.pageI) {
                $(".bg-image").attr('src', '');
            }
            $scope.template.pageI = index;
            $scope.template.currentMenu = 'page';
            isCanLeftAndRightPage();
        };

        /**
         * 上一张，下一张
         */
        $scope.pageLeft = function () {
            $scope.template.pageI = $scope.template.pageI - 1;
            $(".bg-image").attr('src', '');
            isCanLeftAndRightPage();
        };

        $scope.pageRight = function () {
            $scope.template.pageI = $scope.template.pageI + 1;
            $(".bg-image").attr('src', '');
            isCanLeftAndRightPage();
        };

        //点击页面显示左右方向
        function isCanLeftAndRightPage() {
            $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            // var bg = null;
            // if($scope.currentPage.bgImg){
            //     bg = $scope.currentPage.bgImg;
            // }else if($scope.currentPage.background.resource.identifier){
            //     bg = vm.url+$scope.currentPage.background.resource.identifier;
            // }else{
            //     bg = ''
            // }
            // console.log(bg);
            // $(".bg-image").attr('src',bg);
            if ($scope.currentPage.background && $scope.currentPage.background.resource) {
               $(".bg-image").attr('src', vm.url + $scope.currentPage.background.resource.identifier + '?imageslim');
            }
            $scope.template.widthM = $scope.currentPage.widthM;
            $scope.template.heightM = $scope.currentPage.heightM;
            if ($scope.template.pages.page.length == 1) {
                $scope.visibleLeft = false;
                $scope.visibleRight = false;
            } else if ($scope.template.pageI == 0) {
                $scope.visibleLeft = false;
                $scope.visibleRight = true;
            } else if ($scope.template.pageI == $scope.template.pages.page.length - 1) {
                $scope.visibleLeft = true;
                $scope.visibleRight = false;
            } else {
                $scope.visibleLeft = true;
                $scope.visibleRight = true;
            }

            if ($scope.currentPage.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];
                $(".temp-px").addClass('hide1');
                $(".temp-mm").removeClass('hide1');
            } else {
                $scope.dpi = vm.dpiChoose[1];
                $(".temp-px").removeClass('hide1');
                $(".temp-mm").addClass('hide1');
                var arr = ThreeHundredDpi($scope.template.widthM, $scope.template.heightM);
                $scope.template.widthPx = arr.px1;
                $scope.template.heightPx = arr.px2;
            }
        }

        /**
         * 放大缩小及还原
         */
        function changeImgOffset(zoomIn) {
            var i = $scope.template.pageI;
            var currentPage = $scope.template.pages.page[i];
            var number = 1.2;
            var marTop = 0;

            if (zoomIn) {
                //限制放大倍数
                if (currentPage.scale * number > 2) {
                    return
                }
                currentPage.scale = currentPage.scale * number;
                setTimeout(function () {
                    marTop = parseInt($(".page-item").css('margin-top'));
                    if (marTop < 0) {
                        $(".page-item").css({'margin': '0 auto'});
                    }
                }, 10);
            } else {
                if (currentPage.scale == 1) {
                    return;
                }
                currentPage.scale = currentPage.scale / number;
                setTimeout(function () {
                    marTop = parseInt($(".page-item").css('margin-top'));
                    if (marTop >= 0) {
                        $(".page-item").css({'margin': 'auto'});
                    } else {
                        $(".page-item").css({'margin': '0 auto'});
                    }
                }, 10);
            }
            updateHistorys();
        }

        //模板背景图更改
        window.updateImgFile = function ($event) {
            // SweetAlert.swal('', '初始化图片中...', 'info');
            $scope.$digest();
            $timeout(function () {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var file = $event.target.files[0];

                $scope.files[$scope.template.pageI] = file;

                //$scope.uploadMul($event.target.files);
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //	成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                        callback(reader.result);
                    };
                    reader.onerror = function (error) {     //	出错
                        console.log('Error: ', error);
                        alert(error);
                    };
                };

                $event.target.value = null;
                // $event.target.files[0] = null;
                getBase64(file, function (result) {
                    currentPage.bgImg = result;
                    currentPage.hasBgImg = true;
                    updateHistorys();
                    $scope.$digest();

                });
                // var $inputFile = $('#imgSelect');
                // $inputFile.closest('#form1').submit();

                var formData = new FormData();
                formData.append('file', file);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);

                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        currentPage.background.resource.identifier = data.key;
                        currentPage.background.resource.provider = "qiniu";
                    },
                    error: function (request) {
                        console.log("error");
                    }
                });
            });
        };

        $scope.isShowBg = true;
        $scope.isShowMask = false;
        $scope.isClipArt = false;
        $scope.showBgbar = function () {
            $scope.isShowBg = true;
            $scope.isShowMask = false;
            $scope.isClipArt = false;
        };

        $scope.showMaskbar = function () {
            $scope.isShowBg = false;
            $scope.isShowMask = true;
            $scope.isClipArt = false;
        };

        $scope.showClipArtbar = function () {
            $scope.isShowBg = false;
            $scope.isShowMask = false;
            $scope.isClipArt = true;
        };

        window.updateBg = function ($event) {
            var len = $scope.template.bgImgs.bgImg.length;
            $scope.$digest();
            var files = $event.target.files;
            // console.log(files[0]);
            getBgImg(files, 0, len);
        };

        function getBgImg(files, i, len) {
            var url = window.URL || window.webkitURL;
            var img = new Image();              //手动创建一个Image对象
            img.src = url.createObjectURL(files[i]);//创建Image的对象的url

            img.crossOrigin = "Anonymous";
            img.onload = function () {
                img.onload = null;
                var w = img.width;
                var h = img.height;
                if (w < h) {
                    $scope.template.bgImgs.bgImg.push({
                        'identifier': '',
                        'provider': 'qiniu',
                        'bgImg': img.src,
                        'width': 50
                    });
                } else {
                    $scope.template.bgImgs.bgImg.push({
                        'identifier': '',
                        'provider': 'qiniu',
                        'bgImg': img.src,
                        'height': 50
                    });
                }
                $scope.$digest();
                var formData = new FormData();
                formData.append('file', files[i]);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        $scope.template.bgImgs.bgImg[parseInt(len + i)].identifier = data.key;
                        $("#dragtarget" + parseInt(len + i)).attr('data-mask', data.key);
                        $scope.$digest();
                        i++;
                        if (i < files.length) {
                            getBgImg(files, i, len);
                        }
                    },
                    error: function (request) {
                        console.log("error");
                    }
                });
            };
        }

        window.updateMask = function ($event) {
            $scope.$digest();
            var files = $event.target.files;
            angular.forEach(files, function (file, i) {
                var url = window.URL || window.webkitURL;

                var pic = url.createObjectURL(file);

                var formData = new FormData();
                formData.append('file', file);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);
                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: true,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        var imagecanvas = document.createElement('canvas');
                        var imagecontext = imagecanvas.getContext('2d');
                        var newImg = document.createElement('img');
                        newImg.crossOrigin = "Anonymous";
                        newImg.src = 'content/images/white.png';
                        newImg.onload = function () {
                            newImg.onload = null;
                            var width = newImg.width;
                            var height = newImg.height;
                            newImg.setAttribute('data-mask', pic);
                            var masks = document.createElement('img');
                            masks.src = newImg.getAttribute('data-mask');
                            masks.crossOrigin = "anonymous";
                            masks.onload = function () {
                                masks.onload = null;
                                imagecanvas.width = width;
                                imagecanvas.height = height;
                                imagecontext.drawImage(masks, 0, 0, width, height);
                                imagecontext.globalCompositeOperation = 'source-atop';
                                imagecontext.drawImage(newImg, 0, 0);
                                $scope.template.masks.mask.push({
                                    resource: {
                                        'identifier': data.key,
                                        'provider': 'qiniu'
                                    },
                                    'bgImg': ImageService.dataURLtoBlob(imagecanvas.toDataURL()),
                                    'height': 50,
                                    'isEdit': false
                                });
                                updatePageHistory();
                                $scope.$digest();
                            };
                        };

                    },
                    error: function (request) {
                        console.log("error");
                    }
                });
            });
        };


        //模板宽高onchange
        $scope.updateTemplateHeightAndWidth = function (attr) {
            console.log(attr);
            var i = $scope.template.pageI;
            var page = $scope.template.pages.page[i];
            if (page.unit == 'px') {
                var aa = unitTransform($scope.template.widthPx / 300 * 25.4)
                var bb = unitTransform($scope.template.heightPx / 300 * 25.4)
                $scope.template.pages.page[i].width = $scope.template.width = aa;
                $scope.template.pages.page[i].height = $scope.template.height = bb;
                $scope.template.pages.page[i].widthM = $scope.template.widthM = $scope.template.widthPx / 300 * 25.4;
                $scope.template.pages.page[i].heightM = $scope.template.widthM = $scope.template.heightPx / 300 * 25.4;
            } else {
                $scope.template.pages.page[i].width = $scope.template.width = unitTransform($scope.template.widthM);
                $scope.template.pages.page[i].height = $scope.template.height = unitTransform($scope.template.heightM);
                $scope.template.pages.page[i].widthM = $scope.template.widthM;
                $scope.template.pages.page[i].heightM = $scope.template.heightM;
            }
            // $scope.template.pages.page[i].width = $scope.template.width = unitTransform($scope.template.widthM);
            // $scope.template.pages.page[i].height = $scope.template.height = unitTransform($scope.template.heightM);
            setOriginalWaH($scope.template.width, $scope.template.height, i);
            setUlWidth();
            $timeout(updateHistorys, 600);
        };


        var updateHistorys = function () {
            updateHistory();
            updatePageHistory();
        };

        //删除模板
        $scope.deletePage = function (index) {
            if ($scope.template.pages.page.length == 1 || $scope.template.pageI == null || $scope.template.pageI < 0) {
                return;
            }
            if (index == null && ($scope.template.pageI == null || $scope.template.pageI < 0)) {
                return;
            }
            if (index == null) {
                index = $scope.template.pageI;
            }

            $scope.template.pages.page.splice(index, 1);
            $scope.template.pageI = index == 0 ? 0 : index - 1;
            var len = $scope.template.pages.page.length;
            if (len > 1) {
                $scope.template.pages.page[len - 1].spread = false;
            }
            setUlWidth();
            updateHistorys();
        };

        $scope.deleteBg = function (index) {
            $scope.template.bgImgs.bgImg.splice(index, 1);
            updateHistorys();
        };

        window.bgOver = function (e) {
            var targ = e.target ? e.target : e.srcElement;
            var i = targ.dataset.index;
            $(".bg-del" + i).show();
        };
        window.bgLeave = function (e) {
            var targ = e.target ? e.target : e.srcElement;
            var i = targ.dataset.index;
            $(".bg-del" + i).hide();
        };

        $scope.deleteMask = function (index) {
            $scope.template.masks.mask.splice(index, 1);
            updateHistorys();
        };

        window.maskOver = function (e) {
            var targ = e.target ? e.target : e.srcElement;
            var i = targ.dataset.index;
            $(".mask-del" + i).show();
        };
        window.maskLeave = function (e) {
            var targ = e.target ? e.target : e.srcElement;
            var i = targ.dataset.index;
            $(".mask-del" + i).hide();
        };
        //删除模板背景图片
        $scope.deleteBgImg = function () {
            if ($scope.template.pages.page[$scope.template.pageI].bgImg == '') {
                return;
            }
            $scope.template.pages.page[$scope.template.pageI].bgImg = '';
            $scope.template.pages.page[$scope.template.pageI].background.resource.identifier = '';
            $scope.template.pages.page[$scope.template.pageI].hasBgImg = false;
            updateHistorys();
        };
        /************************---模板---***************************--- end---*******************/


        /************************---素材---***************************---start---*******************/
        // 素材点击时
        $scope.selectArt = function (e, $index, art) {
            fn(e);
            $scope.template.artI = $index;
            $scope.template.currentMenu = 'Art';
            $scope.template.artOnlyShow = art.onlyShow;
            $scope.template.artWidthM = art.widthM;
            $scope.template.artHeightM = art.heightM;
            $scope.template.artXM = art.offsetXM;
            $scope.template.artYM = art.offsetYM;
            $scope.template.angle = art.rotation.angle;
        };

        $scope.updateArtParameter = function (p1, p2, p3, p4) {
            var art = $scope.template.pages.page[$scope.template.pageI].imagearts.imageart[$scope.template.artI];

            if (art.unit == 'px') {
                var aa = unitTransform($scope.template[p4] / 300 * 25.4);
                art.geometry[p1] = aa;
                art[p3] = $scope.template[p4] / 300 * 25.4;
            } else {
                art[p3] = $scope.template[p2];
                art.geometry[p1] = unitTransform($scope.template[p2]);
            }
            updateHistorys();
        };
        //图片占位框旋转角度
        $scope.updateArtRotate = function () {
            var art = $scope.template.pages.page[$scope.template.pageI].imagearts.imageart[$scope.template.artI];
            art.rotation.angle = $scope.template.angle;
            updateHistorys();
        };

        //删除素材
        $scope.deleteArtBox = function () {
            $scope.template.pages.page[$scope.template.pageI].imagearts.imageart.splice($scope.template.artI, 1);
            updateHistorys();
        };

        // 素材鼠标事件
        var dragArt = {};
        window.moveArtStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.artI;
            $scope.template.artI = imgIndex;
            dragArt.clientX = e.clientX || 0;
            dragArt.clientY = e.clientY || 0;
            dragArt.coordX = targ.offsetParent.offsetLeft;
            dragArt.coordY = targ.offsetParent.offsetTop;
            drag = true;

            var art = $scope.template.pages.page[$scope.template.pageI].imagearts.imageart[$scope.template.artI];
            if (art.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];

                $(".art-wah-px").addClass('hide1');
                $(".art-wah-mm").removeClass('hide1');

                $(".art-xay-px").addClass('hide1');
                $(".art-xay-mm").removeClass('hide1');
            } else {
                $(".art-wah-px").removeClass('hide1');
                $(".art-wah-mm").addClass('hide1');

                $(".art-xay-px").removeClass('hide1');
                $(".art-xay-mm").addClass('hide1');
                $scope.dpi = vm.dpiChoose[1];
                var arr = ThreeHundredDpi(art.widthM, art.heightM);
                $scope.template.artWidthPx = arr.px1;
                $scope.template.artHeightPx = arr.px2;

                var arr2 = ThreeHundredDpi(art.offsetXM, art.offsetYM);
                $scope.template.artXPx = arr2.px1;
                $scope.template.artYPx = arr2.px2;
            }

            document.addEventListener("mousemove", moveArtOn, true);
            document.addEventListener("mouseup", moveArtEnd, true);
        };

        //move事件
        function moveArtOn(e) {
            fn(e);
            $scope.$digest();
            setZindex($scope.template.artI);
            if (e.target.nodeName == 'SPAN') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var pressArt = currentPage.imagearts.imageart[$scope.template.artI];
                var angle = (pressArt.rotation.angle) * Math.PI / 180;
                // 0-90度的夹角
                var angle2 = (90 - pressArt.rotation.angle) * Math.PI / 180;
                // 100-180度的夹角
                var angle3 = (180 - pressArt.rotation.angle) * Math.PI / 180;
                var angle4 = (-90 + pressArt.rotation.angle) * Math.PI / 180;
                // 180-270
                var angle5 = (270 - pressArt.rotation.angle) * 2 * Math.PI / 360;
                // 280-360
                var angle6 = (360 - pressArt.rotation.angle) * 2 * Math.PI / 360;

                var imgOffsetX = (dragArt.coordX + e.clientX - dragArt.clientX);
                var imgOffsetY = (dragArt.coordY + e.clientY - dragArt.clientY);
                var imgWidth = pressArt.geometry.width * currentPage.scale / currentPage.proportion;
                var imgHeight = pressArt.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;

                var lar = ($scope.borderLeft+$scope.borderRight)* currentPage.scale/ currentPage.proportion;
                var tab = ($scope.borderTop+$scope.borderBottom)* currentPage.scale/ currentPage.proportion;

                // 斜边
                var r = Math.sqrt(imgWidth * imgWidth + imgHeight * imgHeight);
                // 斜边夹角度数
                var rAngle = (Math.asin(imgHeight / r) * 180 / Math.PI);
                // 斜边夹角弧度
                var yrtAngle = (rAngle + pressArt.rotation.angle) * Math.PI / 180;

                // 右上角坐标
                var nativeX = imgOffsetX + imgWidth;
                var y0 = imgOffsetY - imgHeight;
                var xrt = (nativeX - imgOffsetX) * Math.cos(angle) - (imgOffsetY - y0) * Math.sin(angle) + imgOffsetX;
                var yrt = imgOffsetY - r * Math.sin(yrtAngle) + imgHeight;

                // 右下角坐标
                var xrb = (imgOffsetX) + imgWidth * Math.cos(angle).toFixed(2);
                //100-180
                var yrb = imgHeight - imgWidth * Math.sin(angle3) + imgOffsetY;
                //270-360
                var yrb2 = imgWidth * Math.sin(angle6) + imgHeight + imgOffsetY;

                // 左上角坐标
                var xlt = imgOffsetX + imgHeight * Math.cos(angle5).toFixed(2);
                var ylt = imgOffsetY + imgHeight + imgHeight * Math.sin(angle4).toFixed(2);
                // 270-360
                var ylt2 = imgHeight - imgHeight * Math.cos(angle6).toFixed(2) + imgOffsetY;


                if (1) {
                    if (imgOffsetX <= 0) {
                        imgOffsetX = 0;
                    }

                    if (imgOffsetX >= (width + lar - imgWidth)) {
                        imgOffsetX = width + lar - imgWidth;
                    }
                    if (imgOffsetY <= 0) {
                        imgOffsetY = 0;
                    }

                    if (imgOffsetY >= (height + tab - imgHeight)) {
                        imgOffsetY = height + tab - imgHeight;
                    }

                    // var x0 = (imgWidth+1)/2+imgOffsetX;
                    // var y0 = (imgHeight+1)/2+imgOffsetY;
                    // var xx = (x0 - xcenter) cosθ - (y0 - ycenter)*sinθ + x0;
                    // var yy = (x0 - xcenter) sinθ + (y0 - ycenter)*cosθ + y0;
                }

                pressArt.geometry.x = imgOffsetX / currentPage.scale * currentPage.proportion;
                pressArt.geometry.y = imgOffsetY / currentPage.scale * currentPage.proportion;

                $scope.template.artXM = pressArt.offsetXM = (imgOffsetX / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;//px-->mm
                $scope.template.artYM = pressArt.offsetYM = (imgOffsetY / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;//px-->mm

                if (pressArt.unit == 'px') {
                    var arr2 = ThreeHundredDpi($scope.template.artXM, $scope.template.artYM);
                    $scope.template.artXPx = arr2.px1;
                    $scope.template.artYPx = arr2.px2;
                }
            }
            return false;
        }

        //鼠标松开事件
        function moveArtEnd() {
            drag = false;
            dragArt = {};
            document.removeEventListener("mousemove", moveArtOn, true);
            document.removeEventListener("mouseup", moveArtEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        /**
         *放大素材
         * @param e
         */
        window.resizeArtStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离

            var imgIndex = targ.dataset.index || $scope.template.artI;
            $scope.template.artI = imgIndex;

            dragFlag = true;
            document.addEventListener("mousemove", resizeArtOn, true);
            document.addEventListener("mouseup", resizeArtEnd, true);
            setZindex(e.target.dataset.index);
        };
        function resizeArtOn(e) {
            if (dragFlag) {
                fn(e);
                $scope.template.currentMenu = 'Art';
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = targ.dataset.index || $scope.template.artI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var pressArt = $scope.template.pages.page[$scope.template.pageI].imagearts.imageart[$scope.template.artI];
                if (pressArt == null) {
                    return;
                }
                // 移动的时候该div的层级设置最高
                // $("#mask" + imgIndex).css({'z-index': 300});
                //20 小图标的宽高
                var w = (dragMask.coordX + 10 + e.clientX - dragMask.clientX - pressArt.geometry.x / pageIndex.proportion * pageIndex.scale) * pageIndex.proportion / pageIndex.scale;
                var h = (dragMask.coordY + 10 + e.clientY - dragMask.clientY - pressArt.geometry.y / pageIndex.proportion * pageIndex.scale) * pageIndex.proportion / pageIndex.scale;
                if (w < 20 || h < 20) {
                    return
                }
                pressArt.geometry.width = w;
                pressArt.geometry.height = h;
                pressArt.widthM = $scope.template.artWidthM = unitTransformPx(pressArt.geometry.width);
                pressArt.heightM = $scope.template.artHeightM = unitTransformPx(pressArt.geometry.height);

                if (pressArt.unit == 'px') {
                    var arr = ThreeHundredDpi($scope.template.artWidthM, $scope.template.artHeightM);
                    $scope.template.artWidthPx = arr.px1;
                    $scope.template.artHeightPx = arr.px2;
                }

            }
            $scope.$digest();
            return false;
        }

        function resizeArtEnd() {
            console.log("end");
            dragFlag = false;
            document.removeEventListener("mousemove", resizeArtOn, true);
            document.removeEventListener("mouseup", resizeArtEnd, true);
            updateHistorys();
            $scope.$digest();
        }


        /************************---素材---***************************--- end ---*******************/

        /************************---蒙版---***************************---start---*******************/
        //增加图片占位框
        $scope.addImg = function () {
            restoreZindex();
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (!currentPage.imageboxes.imagebox) {
                currentPage.imageboxes.imagebox = [];
            }
            $scope.template.imgWidthM = 50;
            $scope.template.imgHeightM = 45;

            $scope.template.offsetXM = 12 + currentPage.imageboxes.imagebox.length * 2;
            $scope.template.offsetYM = 12 + currentPage.imageboxes.imagebox.length * 2;

            $(".img-xay-px").addClass('hide1');
            $(".img-xay-mm").removeClass('hide1');
            $(".img-wah-px").addClass('hide1');
            $(".img-wah-mm").removeClass('hide1');


            $scope.template.angle = 0;

            var len = currentPage.imagearts ? currentPage.imagearts.imageart.length : 0;

            currentPage.imageboxes.imagebox.push({
                geometry: {
                    height: unitTransform($scope.template.imgHeightM),
                    width: unitTransform($scope.template.imgWidthM),
                    x: unitTransform($scope.template.offsetXM),
                    y: unitTransform($scope.template.offsetYM)
                },
                rotation: {
                    angle: 0
                },
                mask: {
                    resource: {
                        identifier: "",
                        provider: 'qiniu'
                    }
                },
                bgImg: '',
                widthM: $scope.template.imgWidthM,
                heightM: $scope.template.imgHeightM,
                offsetXM: $scope.template.offsetXM,
                offsetYM: $scope.template.offsetYM,
                zIndex: currentPage.imageboxes.imagebox.length + len + 200,
                zoom: 1,
                unit: 'mm'
            });

            $scope.template.imgI = currentPage.imageboxes.imagebox.length - 1;
            updateHistorys();
            $scope.template.currentMenu = 'Img';
            console.log($scope.template.pages.page);
        };
        //图片占位框点击时
        $scope.selectImg = function (e, $index, img) {
            $scope.template.imgI = $index;
            $scope.template.currentMenu = 'Img';
            $scope.template.imgHeightM = img.heightM;
            $scope.template.imgWidthM = img.widthM;
            $scope.template.offsetXM = img.offsetXM;
            $scope.template.offsetYM = img.offsetYM;
            $scope.template.angle = img.rotation.angle;
            setZindex($index);
            $("#maskSelect").val("");
            // console.log
            e.stopPropagation();
        };

        function setZindex(index) {
            angular.forEach($scope.currentPage.imageboxes.imagebox, function (imagebox, i) {
                $("#mask" + i).css({'z-index': i + 200});
                if (i == index) {
                    $("#mask" + index).css({'z-index': 300});
                }
            });
        }

        //图片占位框鼠标事件
        var drag;
        var dragBox = {};
        var dragMask = {};
        var pressImg;
        var pressText;
        $scope.buttonShows = false;
        window.moveImgStart = function (e) {
            fn(e);
            $scope.buttonShows = true;
            var targ = e.target ? e.target : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.imgI;
            console.log('imgIndex:' + imgIndex);
            $scope.template.imgI = imgIndex;
            dragBox.clientX = e.clientX || 0;
            dragBox.clientY = e.clientY || 0;
            dragBox.coordX = targ.offsetParent.offsetLeft;
            dragBox.coordY = targ.offsetParent.offsetTop;
            drag = true;
            var imgbox = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[imgIndex];

            if (imgbox.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];

                $(".img-wah-px").addClass('hide1');
                $(".img-wah-mm").removeClass('hide1');

                $(".img-xay-px").addClass('hide1');
                $(".img-xay-mm").removeClass('hide1');
            } else {

                $(".img-wah-px").removeClass('hide1');
                $(".img-wah-mm").addClass('hide1');

                $(".img-xay-px").removeClass('hide1');
                $(".img-xay-mm").addClass('hide1');
                $scope.dpi = vm.dpiChoose[1];
                var arr = ThreeHundredDpi(imgbox.widthM, imgbox.heightM);
                $scope.template.imgWidthPx = arr.px1;
                $scope.template.imgHeightPx = arr.px2;
                var arr2 = ThreeHundredDpi(imgbox.offsetXM, imgbox.offsetYM);
                $scope.template.offsetXPx = arr2.px1;
                $scope.template.offsetYPx = arr2.px2;
            }


            document.addEventListener("mousemove", moveImgOn, true);
            document.addEventListener("mouseup", moveImgEnd, true);

            //如果是按住ctrl键 实现多选复制
            if (e.ctrlKey == true || e.metaKey == true) {
                var mm = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[imgIndex];
                vm.CtrlKeyArray.imageboxes[imgIndex] = angular.copy(mm);
                console.log(vm.CtrlKeyArray)
            } else {
                vm.CtrlKeyArray.imageboxes = [];
                vm.CtrlKeyArray.textboxes = [];
                var cc = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[imgIndex];
                vm.CtrlKeyArray.imageboxes[0] = angular.copy(cc);
                console.log(vm.CtrlKeyArray);
            }
        };

        //图片占位框
        window.contextMenu = function (e) {
            e.preventDefault();
            //显示自定义菜单
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.imgI;
            $scope.template.imgI = imgIndex;

            var menu = document.getElementById('menus');
            var mm = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[imgIndex];
            menu.style.display = 'block';
            menu.style.left = (mm.offsetXM + mm.widthM) + 'px';
            menu.style.top = (mm.offsetYM + 30) + 'px';
        };
        //文本框
        window.contextMenuText = function (e) {
            e.preventDefault();
            //显示自定义菜单
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.imgI;
            $scope.template.imgI = imgIndex;
            var menu = document.getElementById('menus');
            var mm = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[imgIndex];
            menu.style.display = 'block';
            menu.style.left = mm.xM + 'px';
            menu.style.top = (mm.yM + 30) + 'px';
        };
        //关闭右键菜单
        window.onclick = function (e) {
            //用户触发click事件就可以关闭了，因为绑定在window上，按事件冒泡处理，不会影响菜单的功能
            document.getElementById('menus').style.display = 'none';
        };

        //move事件
        function moveImgOn(e) {
            fn(e);
            $scope.$digest();
            setZindex($scope.template.imgI);
            if (e.target.nodeName == 'SPAN') {
                return;
            }
            if (drag) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var pressImg = currentPage.imageboxes.imagebox[$scope.template.imgI];
                var angle = (pressImg.rotation.angle) * Math.PI / 180;
                // 0-90度的夹角
                var angle2 = (90 - pressImg.rotation.angle) * Math.PI / 180;
                // 100-180度的夹角
                var angle3 = (180 - pressImg.rotation.angle) * Math.PI / 180;
                var angle4 = (-90 + pressImg.rotation.angle) * Math.PI / 180;
                // 180-270
                var angle5 = (270 - pressImg.rotation.angle) * 2 * Math.PI / 360;
                // 280-360
                var angle6 = (360 - pressImg.rotation.angle) * 2 * Math.PI / 360;

                var imgOffsetX = (dragBox.coordX + e.clientX - dragBox.clientX);
                var imgOffsetY = (dragBox.coordY + e.clientY - dragBox.clientY);
                var imgWidth = pressImg.geometry.width * currentPage.scale / currentPage.proportion;
                var imgHeight = pressImg.geometry.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;

                var lar = ($scope.borderLeft+$scope.borderRight)* currentPage.scale/ currentPage.proportion;
                var tab = ($scope.borderTop+$scope.borderBottom)* currentPage.scale/ currentPage.proportion;

                // 斜边
                var r = Math.sqrt(imgWidth * imgWidth + imgHeight * imgHeight);
                // 斜边夹角度数
                var rAngle = (Math.asin(imgHeight / r) * 180 / Math.PI);
                // 斜边夹角弧度
                var yrtAngle = (rAngle + pressImg.rotation.angle) * Math.PI / 180;

                // 右上角坐标
                var nativeX = imgOffsetX + imgWidth;
                var y0 = imgOffsetY - imgHeight;
                var xrt = (nativeX - imgOffsetX) * Math.cos(angle) - (imgOffsetY - y0) * Math.sin(angle) + imgOffsetX;
                var yrt = imgOffsetY - r * Math.sin(yrtAngle) + imgHeight;

                // 右下角坐标
                var xrb = (imgOffsetX) + imgWidth * Math.cos(angle).toFixed(2);
                //100-180
                var yrb = imgHeight - imgWidth * Math.sin(angle3) + imgOffsetY;
                //270-360
                var yrb2 = imgWidth * Math.sin(angle6) + imgHeight + imgOffsetY;

                // 左上角坐标
                var xlt = imgOffsetX + imgHeight * Math.cos(angle5).toFixed(2);
                var ylt = imgOffsetY + imgHeight + imgHeight * Math.sin(angle4).toFixed(2);
                // 270-360
                var ylt2 = imgHeight - imgHeight * Math.cos(angle6).toFixed(2) + imgOffsetY;

                if (angle == 0) {
                    if (imgOffsetX <= 0) {
                        imgOffsetX = 0;
                    }

                    if (imgOffsetX >= (width + lar - imgWidth)) {
                        imgOffsetX = width + lar - imgWidth;
                    }
                    if (imgOffsetY <= 0) {
                        imgOffsetY = 0;
                    }

                    if (imgOffsetY >= (height + tab - imgHeight)) {
                        imgOffsetY = height + tab - imgHeight;
                    }
                } else {

                    if (pressImg.rotation.angle <= 90) {
                        // 左
                        if (imgOffsetX <= imgHeight * Math.cos(angle2).toFixed(2)) {
                            imgOffsetX = imgHeight * Math.cos(angle2).toFixed(2);
                        }
                        // 右
                        if (xrb >= width + lar) {
                            imgOffsetX = width + lar - imgWidth * Math.cos(angle).toFixed(2)
                        }
                        // 上
                        if (yrt <= 0) {
                            imgOffsetY = r * Math.sin(yrtAngle) - imgHeight;
                        }
                        // 下
                        if (imgOffsetY >= (height + tab - imgHeight)) {
                            imgOffsetY = height + tab - imgHeight;
                        }
                    }

                    if (pressImg.rotation.angle > 90 && pressImg.rotation.angle <= 180) {

                        if (xrt <= 0) {
                            imgOffsetX = (imgOffsetY - y0) * Math.sin(angle) - (nativeX - imgOffsetX) * Math.cos(angle);
                        }
                        if (imgOffsetX >= width + lar) {
                            imgOffsetX = width + lar
                        }

                        if (yrb <= 0) {
                            imgOffsetY = imgWidth * Math.sin(angle3) - imgHeight;
                        }

                        if (ylt >= (height + tab)) {
                            imgOffsetY = height + tab - imgHeight * Math.sin(angle4).toFixed(2) - imgHeight;
                        }
                    }

                    if (pressImg.rotation.angle > 180 && pressImg.rotation.angle <= 270) {

                        if (xrb <= 0) {
                            imgOffsetX = -imgWidth * Math.cos(angle).toFixed(2);
                        }
                        if (xlt >= width + lar) {
                            imgOffsetX = width + lar - imgHeight * Math.cos(angle5).toFixed(2)
                        }

                        if (imgOffsetY <= -imgHeight) {
                            imgOffsetY = -imgHeight;
                        }

                        if (yrt >= (height + tab)) {
                            imgOffsetY = height + tab - imgHeight + r * Math.sin(yrtAngle);
                        }
                    }

                    if (pressImg.rotation.angle > 270 && pressImg.rotation.angle <= 360) {
                        if (imgOffsetX <= 0) {
                            imgOffsetX = 0;
                        }
                        if (xrt >= width + lar) {
                            imgOffsetX = width + lar + (imgOffsetY - y0) * Math.sin(angle) - (nativeX - imgOffsetX) * Math.cos(angle)
                        }

                        if (ylt2 <= 0) {
                            imgOffsetY = imgHeight * Math.cos(angle6).toFixed(2) - imgHeight;
                        }

                        if (yrb2 >= (height + tab)) {
                            imgOffsetY = height + tab - imgHeight - imgWidth * Math.sin(angle6);
                        }
                    }
                }

                pressImg.geometry.x = imgOffsetX / currentPage.scale * currentPage.proportion;
                pressImg.geometry.y = imgOffsetY / currentPage.scale * currentPage.proportion;

                $scope.template.offsetXM = pressImg.offsetXM = (pressImg.geometry.x / 3).toFixed(2) - 0;//px-->mm
                $scope.template.offsetYM = pressImg.offsetYM = (pressImg.geometry.y / 3).toFixed(2) - 0;//px-->mm

                if (pressImg.unit == 'px') {
                    var arr2 = ThreeHundredDpi($scope.template.offsetXM, $scope.template.offsetYM);
                    $scope.template.offsetXPx = arr2.px1;
                    $scope.template.offsetYPx = arr2.px2;
                }
            }
            return false;
        }

        //鼠标松开事件
        function moveImgEnd() {
            pressImg = null;
            drag = false;
            dragBox = {};
            dragFlag = false;
            dragMask = {};
            document.removeEventListener("mousemove", moveImgOn, true);
            document.removeEventListener("mouseup", moveImgEnd, true);
            updateHistorys();
            $scope.$digest();
        }

        /**
         *放大占位框
         * @param e
         */
        var dragFlag = false;
        window.resizeStart = function (e) {
            fn(e);
            var targ = e.target ? e.target : e.srcElement;
            $scope.template.imgI = targ.dataset.index;
            dragMask.clientX = e.clientX || 0;          //鼠标到页面左边的距离
            dragMask.clientY = e.clientY || 0;          //鼠标到页面顶部的距离
            dragMask.coordX = targ.offsetParent.offsetLeft + targ.offsetLeft;        //鼠标到模板左边的距离
            dragMask.coordY = targ.offsetParent.offsetTop + targ.offsetTop;         //鼠标到模板顶部的距离
            dragFlag = true;
            $scope.template.currentMenu = 'Img';
            document.addEventListener("mousemove", resizeOn, true);
            document.addEventListener("mouseup", resizeEnd, true);
            setZindex(e.target.dataset.index);
        };
        function resizeOn(e) {
            if (dragFlag) {
                fn(e);
                var targ = e.target ? e.target : e.srcElement;
                if (targ == null) {
                    return;
                }
                var imgIndex = $scope.template.imgI;
                if (imgIndex == null) {
                    return;
                }
                var pageIndex = $scope.template.pages.page[$scope.template.pageI];
                if (pageIndex == null) {
                    return;
                }
                var pressImg = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[imgIndex];
                if (pressImg == null) {
                    return;
                }
                // 移动的时候该div的层级设置最高
                $("#mask" + imgIndex).css({'z-index': 300});
                //20 小图标的宽高
                pressImg.geometry.width = (dragMask.coordX + 10 + e.clientX - dragMask.clientX - pressImg.geometry.x / pageIndex.proportion * pageIndex.scale) * pageIndex.proportion / pageIndex.scale;
                pressImg.geometry.height = (dragMask.coordY + 10 + e.clientY - dragMask.clientY - pressImg.geometry.y / pageIndex.proportion * pageIndex.scale) * pageIndex.proportion / pageIndex.scale;
                pressImg.widthM = $scope.template.imgWidthM = unitTransformPx(pressImg.geometry.width);
                pressImg.heightM = $scope.template.imgHeightM = unitTransformPx(pressImg.geometry.height);

                if (pressImg.unit == 'px') {
                    var arr = ThreeHundredDpi($scope.template.imgWidthM, $scope.template.imgHeightM);
                    $scope.template.imgWidthPx = arr.px1;
                    $scope.template.imgHeightPx = arr.px2;
                }

            }
            $scope.$digest();
            return false;
        }

        function resizeEnd() {
            console.log("end");
            pressImg = null;
            dragFlag = false;
            dragMask = {};
            document.removeEventListener("mousemove", resizeOn, true);
            document.removeEventListener("mouseup", resizeEnd, true);
            $scope.template.currentMenu = 'Img';
            updateHistorys();
            $scope.$digest();
        }

        //防止事件冒泡，默认事件
        function fn(e) {
            // e = e || window.event;
            // e.cancelBubble = true;
            // e.stopPropagation();
            // e.returnValue = false;
            // e.preventDefault();

            if (e && e.stopPropagation) {

                // 因此它支持W3C的stopPropagation()方法

                e.stopPropagation();

            } else {

                // 否则，我们需要使用IE的方式来取消事件冒泡

                window.event.cancelBubble = true;

            }

            // 阻止默认浏览器动作(W3C)

            if (e && e.preventDefault) {

                e.preventDefault();

            } else {

                // IE中阻止函数器默认动作的方式

                window.event.returnValue = false;

            }
        }

        //蒙版图更改
        window.updateMaskFile = function ($event) {
            $timeout(function () {
                var currentImg = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[$scope.template.imgI];
                var file = $event.target.files[0];
                currentImg.masks = file;
                var getBase64 = function (file, callback) {
                    var reader = new FileReader();      //用来把文件读入内存，并且读取文件中的数据
                    reader.readAsDataURL(file);         //将文件读取为DataURL,存在result中
                    reader.onload = function () {       //	成功读取
                        $('.sweet-alert .confirm').trigger("click");    //trigger触发被选元素的指定事件类型
                        callback(reader.result);
                    };
                    reader.onerror = function (error) {     //	出错
                        console.log('Error: ', error);
                        alert(error);
                    };
                };

                // $("#maskSelect")[0].value = $event.target.value;
                currentImg.fileName = $event.target.value;
                // $event.target.value = null;
                // $event.target.files[0] = null;
                getBase64(file, function (result) {
                    currentImg.masks = result;
                    updateHistorys();
                    $scope.$digest();
                });
                var formData = new FormData();
                formData.append('file', file);
                formData.append('key', "product-" + UidService.get());
                formData.append('token', vm.token);

                $.ajax({
                    cache: true,
                    type: "POST",
                    url: 'https://upload.qiniup.com/',
                    data: formData,
                    async: false,
                    contentType: false,
                    processData: false,
                    success: function (data) {
                        currentImg.mask.resource.identifier = data.key;
                        currentImg.mask.resource.provider = "qiniu";
                        MessageService.success("蒙版上传成功");
                        // $("#commonLayout_appcreshi").parent().html(data);
                    },
                    error: function (request) {
                        MessageService.error("蒙版上传失败");
                        console.log("error");
                    }
                });

                var imagecanvas = document.createElement('canvas');
                var imagecontext = imagecanvas.getContext('2d');
                var img = document.getElementsByClassName('page-item')[$scope.template.pageI].getElementsByClassName('mask')[$scope.template.imgI];
                // img.src = 'content/images/otis_redding.png';
                img.src = 'content/images/shattered.png';
                var newImg = document.createElement('img');
                newImg.crossOrigin = "Anonymous";
                newImg.src = img.src;
                newImg.onload = function () {
                    newImg.onload = null;
                    var width = newImg.width;
                    var height = newImg.height;
                    img.setAttribute('data-mask', currentImg.masks);
                    var masks = document.createElement('img');
                    masks.src = img.getAttribute('data-mask');
                    masks.onload = function () {
                        masks.onload = null;
                        imagecanvas.width = width;
                        imagecanvas.height = height;
                        imagecontext.drawImage(masks, 0, 0, width, height);
                        imagecontext.globalCompositeOperation = 'source-atop';
                        imagecontext.drawImage(img, 0, 0);
                        img.src = imagecanvas.toDataURL();
                        updateHistorys();
                        $scope.$digest();
                    };
                };
            });
        };

        $scope.updateImgParameter = function (p1, p2, p3, p4) {
            var img = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[$scope.template.imgI];
            console.log(img.unit)
            if (img.unit == 'px') {
                $scope.dpi = vm.dpiChoose[1];
                var aa = unitTransform($scope.template[p4] / 300 * 25.4);
                img.geometry[p1] = aa;
                img[p3] = $scope.template[p4] / 300 * 25.4;

            } else {
                $scope.dpi = vm.dpiChoose[0];
                img[p3] = $scope.template[p2];
                img.geometry[p1] = unitTransform($scope.template[p2]);

            }
            updateHistorys();
            setUlWidth();
        };

        function ToggleClass(attr, flag) {
            if (flag) {
                $("." + attr + "-wah-px").addClass('hide1');
                $("." + attr + "-wah-mm").removeClass('hide1');
                $("." + attr + "-xay-px").addClass('hide1');
                $("." + attr + "-xay-mm").removeClass('hide1');
            } else {
                $("." + attr + "-wah-px").removeClass('hide1');
                $("." + attr + "-wah-mm").addClass('hide1');
                $("." + attr + "-xay-px").removeClass('hide1');
                $("." + attr + "-xay-mm").addClass('hide1');
            }

        }


        //图片占位框旋转角度
        $scope.updateImgRotate = function () {
            var img = $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox[$scope.template.imgI];
            img.rotation.angle = $scope.template.angle;
            updateHistorys();
        };

        //删除图片占位框
        $scope.deleteImgBox = function () {
            $scope.template.pages.page[$scope.template.pageI].imageboxes.imagebox.splice($scope.template.imgI, 1);
            updateHistorys();
        };

        /************************---蒙版---***************************---end ---*******************/

        /************************---文本---***************************---start---*******************/
        //增加文本框
        $scope.addText = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];
            if (!currentPage.textboxes.textbox) {
                currentPage.textboxes = {
                    textbox: []
                };
            }
            $(".text-xay-px").addClass('hide1');
            $(".text-wah-px").addClass('hide1');
            $(".text-xay-mm").removeClass('hide1');
            $(".text-wah-mm").removeClass('hide1');

            $scope.template.textWidthM = 40;
            $scope.template.textHeightM = 8;
            $scope.template.textXM = (20 + currentPage.textboxes.textbox.length * 2);
            $scope.template.textYM = (20 + currentPage.textboxes.textbox.length * 2);
            $scope.template.text = "";
            $scope.template.textAngle = 0;
            $scope.template.textSize = 16;
            var currentGeometry = {
                width: unitTransform($scope.template.textWidthM),
                height: unitTransform($scope.template.textHeightM),
                x: unitTransform($scope.template.textXM),
                y: unitTransform($scope.template.textYM)
            };
            currentPage.textboxes.textbox.push({
                geometry: currentGeometry,
                rotation: {
                    angle: 0
                },
                style: {
                    bold: $scope.template.bold ? true : false,
                    color: $scope.template.textColor,
                    font: $scope.template.textFont,
                    italic: $scope.template.italic ? true : false,
                    size: $scope.template.textSize
                },
                text: '',
                fontStyle: $scope.template.fontStyle,
                picCode: '',
                type: 'static',
                status: 'dynamic',
                xM: $scope.template.textXM,
                yM: $scope.template.textYM,
                widthM: $scope.template.textWidthM,
                heightM: $scope.template.textHeightM,
                unit: 'mm'
            });
            $scope.template.textI = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox.length - 1;
            $scope.template.currentMenu = 'text';
            // $scope.currentText = {width: currentGeometry.width, height: 24};
            $scope.updateTextFont(vm.fontNames[0]);
            updateHistorys();
            console.log($scope.template.pages.page)
        };

        //文本框点击时
        $scope.selectText = function (e, $index, text) {
            fn(e);
            $scope.template.textI = $index;
            $scope.template.currentMenu = 'text';
            $scope.template.textWidthM = text.widthM;
            $scope.template.textHeightM = text.heightM;
            $scope.template.textYM = text.yM;
            $scope.template.textXM = text.xM;
            $scope.template.text = text.text;
            $scope.template.textFont = text.style.font;
            $scope.template.textSize = text.style.size;
            $scope.template.textColor = text.style.color;
            $scope.template.textAngle = text.rotation.angle;
            chargeFontPath(text.identifier);
            console.log(text.unit);

            if (text.unit == 'mm') {
                $scope.dpi = vm.dpiChoose[0];

                $(".text-wah-px").addClass('hide1');
                $(".text-wah-mm").removeClass('hide1');

                $(".text-xay-px").addClass('hide1');
                $(".text-xay-mm").removeClass('hide1');
            } else {

                $(".text-wah-px").removeClass('hide1');
                $(".text-wah-mm").addClass('hide1');

                $(".text-xay-px").removeClass('hide1');
                $(".text-xay-mm").addClass('hide1');
                $scope.dpi = vm.dpiChoose[1];
                var arr = ThreeHundredDpi(text.widthM, text.heightM);
                $scope.template.textWidthPx = arr.px1;
                $scope.template.textHeightPx = arr.px2;

                var arr2 = ThreeHundredDpi(text.xM, text.yM);
                $scope.template.textXPx = arr2.px1;
                $scope.template.textYPx = arr2.px2;
            }

            // e.stopPropagation();
        };
        //文本框鼠标事件
        var dragText;
        var dragTextBox = {};
        window.moveTextStart = function (e) {
            fn(e);
            $scope.buttonShows = true;
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            var imgIndex = targ.dataset.index || $scope.template.textI;
            $scope.template.textI = imgIndex;

            pressText = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[imgIndex];
            dragTextBox.clientX = e.clientX || 0;
            dragTextBox.clientY = e.clientY || 0;
            dragTextBox.coordX = targ.offsetLeft;
            dragTextBox.coordY = targ.offsetTop;
            dragText = true;

            document.addEventListener("mousemove", moveTextOn, true);
            document.addEventListener("mouseup", moveTextEnd, true);

            if (e.ctrlKey == true || e.metaKey == true) {
                var mm = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[imgIndex];
                vm.CtrlKeyArray.textboxes[imgIndex] = angular.copy(mm);
                console.log(vm.CtrlKeyArray);
            } else {
                vm.CtrlKeyArray.textboxes = [];
                vm.CtrlKeyArray.imageboxes = [];
                var cc = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[imgIndex];
                vm.CtrlKeyArray.textboxes[0] = angular.copy(cc);
                console.log(vm.CtrlKeyArray);
            }

        };

        //文本框move事件
        function moveTextOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();
            if (dragText) {

                var currentPage = $scope.template.pages.page[$scope.template.pageI];
                var pressText = currentPage.textboxes.textbox[$scope.template.textI];

                var $textStyle = $("#text" + $scope.template.pageI + $scope.template.textI);
                var $pageStyle = $(".content-container");


                var x = $scope.borderLeft * currentPage.scale/ currentPage.proportion;
                var y = $scope.borderTop * currentPage.scale/ currentPage.proportion;

                var lar = ($scope.borderLeft+$scope.borderRight)* currentPage.scale/ currentPage.proportion;
                var tab = ($scope.borderTop+$scope.borderBottom)* currentPage.scale/ currentPage.proportion;

                var offsetsX = (dragTextBox.coordX + e.clientX - dragTextBox.clientX);
                var offsetsY = (dragTextBox.coordY + e.clientY - dragTextBox.clientY);

                var w = pressText.picCode ? pressText.geometry.width * currentPage.scale / currentPage.proportion : 70;
                var h = pressText.picCode ? pressText.geometry.height * currentPage.scale / currentPage.proportion : 24;

                var width = $pageStyle.width();
                var height = $pageStyle.height();


                if (offsetsX <= x) {
                    offsetsX = x;
                }

                if (offsetsX >= (width - lar - w)) {
                    offsetsX = width - lar - w;
                }
                if (offsetsY <= y) {
                    offsetsY = y;
                }

                if (offsetsY >= (height - tab - h)) {
                    offsetsY = height - tab - h;
                }
                pressText.geometry.x = offsetsX / currentPage.scale * currentPage.proportion;
                pressText.geometry.y = offsetsY / currentPage.scale * currentPage.proportion;

                $scope.template.textXM = pressText.xM = (offsetsX / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;
                $scope.template.textYM = pressText.yM = (offsetsY / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;

                if (pressText.unit == 'px') {
                    var arr2 = ThreeHundredDpi($scope.template.textXM, $scope.template.textYM);
                    $scope.template.textXPx = arr2.px1;
                    $scope.template.textYPx = arr2.px2;
                }

            }
            return false;
        }

        //文本框鼠标松开
        function moveTextEnd() {
            updateHistorys();
            $scope.$digest();
            pressText = null;
            dragText = false;
            document.removeEventListener("mousemove", moveTextOn, true);
            document.removeEventListener("mouseup", moveTextEnd, true);
            dragTextBox = {};
        }

        $scope.updateTextParameter = function (p1, p2, p3, p4) {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];

            if (text.unit == 'px') {
                var aa = unitTransform($scope.template[p4] / 300 * 25.4);
                text.geometry[p1] = aa;
                text[p3] = $scope.template[p4] / 300 * 25.4;
            } else {
                text.geometry[p1] = unitTransform($scope.template[p2]);
                text[p3] = $scope.template[p2];
            }
            toImg(text);
            updateHistorys();
        };


        $scope.updateTextRotate = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.rotation.angle = $scope.template.textAngle;
            updateHistorys();
        };

        $scope.updateTextSize = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.style.size = $scope.template.textSize;
            toImg(text);
            updateHistorys();
        };

        $scope.updateTextColor = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.style.color = $scope.template.textColor;
            toImg(text);
            updateHistorys();
        };

        $scope.updateTextContent = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.text = $scope.template.text;

            toImg(text);
            updateHistorys();
        };
        function toImg(text) {
            switch (text.style.bold + '' + text.style.italic) {
                case 'falsefalse':
                    text.styles = 'NORMAL';
                    break;
                case 'falsetrue':
                    text.styles = 'ITALIC';
                    break;
                case 'truefalse':
                    text.styles = 'BOLD';
                    break;
                default:
                    text.styles = 'BOLD_ITALIC';
                    break;
            }
            //  1 inch = 72pt = 96px = 2.54cm =254mm   1pt =1.06px
            if (text.style.font == null) {
                MessageService.error("请先选择文字字体");
            }
            if (text.fontStyle == null) {
                MessageService.error("文字生成失败，请重新选择字体");
                return;
            }

            for (var i = text.fontStyle.length - 1; i >= 0; i--) {
                var st = text.fontStyle[i];
                if (st.style == text.styles) {
                    text.identifier = st.path;
                    break;
                }
            }

            $http({
                // url: '/manager/api/textToImg',
                url: 'fileserves/api/convert/textToImg',
                method: 'POST',
                data: {
                    identifier: text.identifier,
                    text: text.text,
                    fontSize: text.style.size,
                    style: text.styles,
                    width: text.geometry.width,
                    height: text.geometry.height,
                    color: text.style.color,
                    family: text.style.font
                },
                responseType: 'blob'
            }).success(function (data, status, headers, config) {
                var createObjectURL = function (blob) {
                    return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
                };
                var fileURL = URL.createObjectURL(data);
                console.log(data);
                console.log(fileURL);
                text.picCode = fileURL;
                console.log(text.geometry);
            }).error(function () {
                MessageService.error("文字生成失败，请重新选择字体");
            });
        }


        var $b = $("#textBold");
        var $i = $("#textItalic");
        $scope.updateTextFont = function (data) {
            if (data == undefined) {
                MessageService.error("文字添加失败，请上传字体!");
                return
            }
            $b.addClass("button-un");
            $i.addClass("button-un");
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.style.font = $scope.template.textFont = data.family;
            text.fontStyle = [];
            angular.forEach(data.fontStyles, function (fontStyle, i) {
                text.fontStyle[i] = {
                    style: fontStyle.style,
                    path: fontStyle.path
                };
            });
            angular.forEach(text.fontStyle, function (styles, j) {
                if (styles.style.indexOf('BOLD') >= 0) {
                    $b.removeClass("button-un");
                    $scope.template.bold = true;
                    text.style.bold = true;
                } else {
                    $scope.template.bold = false;
                    text.style.bold = false;
                }
                if (styles.style.indexOf('ITALIC') >= 0) {
                    $i.removeClass("button-un");
                    $scope.template.italic = true;
                    text.style.italic = true;
                } else {
                    $scope.template.italic = false;
                    text.style.italic = false;
                }
            });
            $scope.template.fontStyle = text.fontStyle;
            toImg(text);
            updateHistorys();
        };

        FontManagement.byCondition({
            page: pagingParams.page - 1,
            size: vm.itemsPerPage,
            sort: sort()
        }, function (msg) {
            angular.forEach(msg, function (msg, i) {
                vm.fontNames[i] = {
                    family: msg.family,
                    fontStyles: msg.fontStyles,
                    id: msg.id
                };
            });
            $scope.item = vm.fontNames[0];
        });
        function chargeFontPath(path) {
            angular.forEach(vm.fontNames, function (msg) {
                angular.forEach(msg.fontStyles, function (fontStyle) {
                    if (fontStyle.path == path) {
                        var numbers = $(".fontSel").find("option"); //获取select下拉框的所有值
                        for (var x = 0; x < vm.fontNames.length + 1; x++) {
                            if ($(numbers[x]).text() == msg.family) {
                                angular.forEach(vm.fontNames, function (num, i) {
                                    $(numbers[i]).removeAttr("selected");
                                });
                                $(numbers[x]).attr("selected", "selected");
                            }
                        }
                    }
                });
            })

        }

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        $scope.textStatic = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.status = 'dynamic' ? text.status = 'static' : text.status = 'dynamic';
        };

        $scope.textDynamic = function () {
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            text.status = 'static' ? text.status = 'dynamic' : text.status = 'static';
        };
        $scope.textBold = function () {
            if ($b.hasClass("button-un")) {
                return;
            }
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            if (text.fontStyle.length == 1) {

                return
            }
            text.style.bold ? text.style.bold = false : text.style.bold = true;
            $scope.template.bold = text.style.bold;
            toImg(text);
            updateHistorys();
        };

        $scope.textItalic = function () {
            if ($i.hasClass("button-un")) {
                return;
            }
            var text = $scope.template.pages.page[$scope.template.pageI].textboxes.textbox[$scope.template.textI];
            if (text.fontStyle.length == 1) {
                return
            }
            text.style.italic ? text.style.italic = false : text.style.italic = true;
            $scope.template.italic = text.style.italic;
            toImg(text);
            updateHistorys();
        };

        $scope.deleteTextBox = function () {
            $scope.template.pages.page[$scope.template.pageI].textboxes.textbox.splice($scope.template.textI, 1);
            updateHistorys();
        };
        /************************---文本---***************************--- end---*******************/


        /************************---条形码---***************************--- start---*******************/
        // 增加条形码
        $scope.addBarCode = function () {
            var currentPage = $scope.template.pages.page[$scope.template.pageI];

            $scope.template.barWidthM = 60;
            $scope.template.barHeightM = 30;
            $scope.template.barXM = 16;
            $scope.template.barYM = 16;
            $(".bar-xay-px").addClass('hide1');
            $(".bar-wah-px").addClass('hide1');
            $(".bar-xay-mm").removeClass('hide1');
            $(".bar-wah-mm").removeClass('hide1');

            currentPage.barcode = {
                height: unitTransform($scope.template.barHeightM),
                width: unitTransform($scope.template.barWidthM),
                x: unitTransform($scope.template.barXM),
                y: unitTransform($scope.template.barYM),
                barWidthM: $scope.template.barWidthM,
                barHeightM: $scope.template.barHeightM,
                barXM: $scope.template.barXM,
                barYM: $scope.template.barYM,
                zoom: 1,
                unit: 'mm'
            };
            currentPage.hasBarCode = true;
            updateHistorys();
            $scope.template.currentMenu = 'Barcode';
        };

        $scope.updateParameter = function (p, p1, p2, p4) {
            var arr = $scope.template.pages.page[$scope.template.pageI][p];

            if (arr.unit == 'px') {
                arr[p1] = unitTransform($scope.template[p4] / 300 * 25.4);
                arr[p2] = $scope.template[p4] / 300 * 25.4;
            } else {
                arr[p1] = unitTransform($scope.template[p2]);
                arr[p2] = $scope.template[p2];
            }
            updateHistorys();
        };


        //条形码点击时
        $scope.selectBar = function (e, currentPage) {
            fn(e);
            $scope.template.currentMenu = 'Barcode';
            $scope.template.barWidthM = currentPage.barcode.barWidthM;
            $scope.template.barHeightM = currentPage.barcode.barHeightM;
            $scope.template.barYM = currentPage.barcode.barYM;
            $scope.template.barXM = currentPage.barcode.barXM;
            // e.stopPropagation();
        };

        //条形码移动事件
        var dragBar;
        var dragBarBox = {};
        window.moveBarcodeStart = function (e) {
            fn(e);
            var targ = e.currentTarget ? e.currentTarget : e.srcElement;
            dragBarBox.clientX = e.clientX || 0;
            dragBarBox.clientY = e.clientY || 0;
            dragBarBox.coordX = targ.offsetLeft;
            dragBarBox.coordY = targ.offsetTop;
            dragBar = true;
            document.addEventListener("mousemove", moveBarcodeOn, true);
            document.addEventListener("mouseup", moveBarcodeOnEnd, true);
        };

        //条形码move事件
        function moveBarcodeOn(e) {
            $scope.$digest();
            e.stopPropagation();
            e.preventDefault();

            if (dragBar) {
                var currentPage = $scope.template.pages.page[$scope.template.pageI];

                var barOffsetX = (dragBarBox.coordX + e.clientX - dragBarBox.clientX);
                var barOffsetY = (dragBarBox.coordY + e.clientY - dragBarBox.clientY);
                var barWidth = currentPage.barcode.width * currentPage.scale / currentPage.proportion;
                var barHeight = currentPage.barcode.height * currentPage.scale / currentPage.proportion;
                var height = $scope.template.pages.page[$scope.template.pageI].height * currentPage.scale;
                var width = $scope.template.pages.page[$scope.template.pageI].width * currentPage.scale;

                var lar = ($scope.borderLeft+$scope.borderRight)* currentPage.scale/ currentPage.proportion;
                var tab = ($scope.borderTop+$scope.borderBottom)* currentPage.scale/ currentPage.proportion;

                if (barOffsetX <= 0) {
                    barOffsetX = 0;
                }

                if (barOffsetX >= (width + lar - barWidth)) {
                    barOffsetX = width + lar - barWidth;
                }
                if (barOffsetY <= 0) {
                    barOffsetY = 0;
                }

                if (barOffsetY >= (height + tab - barHeight)) {
                    barOffsetY = height + tab - barHeight;
                }

                currentPage.barcode.x = barOffsetX / currentPage.scale * currentPage.proportion;
                currentPage.barcode.y = barOffsetY / currentPage.scale * currentPage.proportion;

                $scope.template.barXM = currentPage.barcode.barXM = (barOffsetX / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;//px-->mm
                $scope.template.barYM = currentPage.barcode.barYM = (barOffsetY / currentPage.scale * currentPage.proportion / 3).toFixed(2) - 0;//px-->mm
            }
            return false;
        }

        //条形码鼠标松开
        function moveBarcodeOnEnd() {
            updateHistorys();
            $scope.$digest();
            dragBar = false;
            document.removeEventListener("mousemove", moveBarcodeOn, true);
            document.removeEventListener("mouseup", moveBarcodeOnEnd, true);
            dragBarBox = {};
        }


        $scope.deleteBarCode = function () {
            $scope.template.pages.page[$scope.template.pageI].barcode = {};
            $scope.template.pages.page[$scope.template.pageI].hasBarCode = false;
            $scope.template.currentMenu = 'page';
            updateHistorys();
        };

        /************************---条形码---***************************--- end---*******************/


            //历史纪录
        var updateHistory = function () {
                $scope.history.push(angular.copy($scope.template));
                if ($scope.history.length > 20) {
                    $scope.history.splice($scope.history.length - 4, $scope.history.length - 5)
                }
                $scope.currentHistoryIndex = $scope.history.length - 1;
                $scope.currentPage = $scope.template.pages.page[$scope.template.pageI];
            };

        //每一页的历史记录
        var updatePageHistory = function () {
            if (!$scope.pageHistory[$scope.template.pageI]) {
                $scope.pageHistory[$scope.template.pageI] = {
                    history: []
                }
            }
            $scope.pageHistory[$scope.template.pageI].history.push(angular.copy($scope.template.pages.page[$scope.template.pageI]));
            if ($scope.pageHistory[$scope.template.pageI].history.length > 20) {
                $scope.pageHistory[$scope.template.pageI].history.splice($scope.pageHistory[$scope.template.pageI].history.length - 4, $scope.pageHistory[$scope.template.pageI].history.length - 5)
            }
            $scope.pageHistory[$scope.template.pageI].currentHistoryIndex = $scope.pageHistory[$scope.template.pageI].history.length - 1;
        };


        //json下载文件中需要的内容
        var generateJsonFile = function () {
            $scope.isHasGeometry = true;
            var myTimer = setInterval(function () {
                if (timer == null) {
                    clearInterval(myTimer);
                    var template = angular.copy($scope.template);
                    template.reprint = vm.page.reprint;
                    template.pattern = vm.page.pattern;
                    template.replace = vm.page.replace;
                    angular.forEach(template.pages.page, function (item) {
                        item.centerRotation = true;
                        item.mediabox = {
                            height: item.heightM + unitTransformPx($scope.borderTop) + unitTransformPx($scope.borderBottom),
                            width: item.widthM + unitTransformPx($scope.borderLeft) + unitTransformPx($scope.borderRight),
                            x: 0,
                            y: 0
                        };
                        item.trimbox = {
                            height: item.heightM,
                            width: item.widthM,
                            x: unitTransformPx($scope.borderLeft),
                            y: unitTransformPx($scope.borderTop)
                        };
                        if (item.hasBarCode) {
                            item.barcode = {
                                height: item.barcode.barHeightM,
                                width: item.barcode.barWidthM,
                                x: item.barcode.barXM,
                                y: item.barcode.barYM
                            };
                        } else {
                            item.barcode = null;
                        }

                        if (item.imagearts) {
                            angular.forEach(item.imagearts.imageart, function (art, i) {
                                art.geometry = {
                                    height: art.heightM,
                                    width: art.widthM,
                                    x: art.offsetXM,
                                    y: art.offsetYM
                                };
                            });
                        }

                        angular.forEach(item.imageboxes.imagebox, function (img, i) {
                            delete img.bgImg;
                            if (img.heightM <= 1 || img.widthM <= 1) {
                                $scope.isHasGeometry = false;
                            }
                            img.geometry = {
                                height: img.heightM,
                                width: img.widthM,
                                x: img.offsetXM,
                                y: img.offsetYM
                            }
                        });

                        angular.forEach(item.textboxes.textbox, function (text, i) {
                            text.geometry = {
                                height: text.heightM,
                                width: text.widthM,
                                x: text.xM,
                                y: text.yM
                            }
                        });
                    });

                    if (!$scope.isHasGeometry) {
                        MessageService.error("请输入正确的占位框宽高！");
                        return
                    }

                    if ($stateParams.id && !$stateParams.copy) {
                        ProductXmlFileServes.update({
                            document: template,
                            reprint: vm.page.reprint,
                            pattern: vm.page.pattern,
                            replace : vm.page.replace,
                            name: vm.page.name,
                            categoryId: vm.page.categoryId,
                            id: $stateParams.id,
                            provider: vm.page.provider,
                            themeId: vm.page.themeClassify,
                            type: vm.page.type,
                            customStoreId: vm.page.customStoreId,
                            customStoreName: vm.page.customStoreName

                        }, success, error);
                    } else {
                        ProductXmlFileServes.save({
                            document: template,
                            reprint: vm.page.reprint,
                            pattern: vm.page.pattern,
                            replace:vm.page.replace,
                            name: vm.page.name,
                            categoryId: vm.page.categoryId,
                            themeId: vm.page.themeClassify,
                            type: vm.page.type,
                            customStoreId: vm.page.customStoreId,
                            customStoreName: vm.page.customStoreName
                        }, success, error);
                    }
                }
            }, 600);
        };

        function success(data) {
            MessageService.success("保存成功！");
            $state.go('product-xml', {reload: true})
        }

        function error() {
            MessageService.error("保存失败！");
        }


        $scope.getPageBgImg = function () {
            return $('#bgImg' + $scope.template.pageI).attr('src');
        };


        $scope.$watch('template.widthM', function (newValue, oldValue) {
        });

        $scope.toggleShowBorder = function () {
            updateHistory();
            updatePageHistory();
        };

        // ?imageMogr2/gravity/Center/crop/100x100
    }

})();
