(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('LogoSettingController', LogoSettingController);

    LogoSettingController.$inject = ['$scope', '$q', '$rootScope', '$stateParams', 'MessageService', 'Store', 'Qinius'];

    function LogoSettingController($scope, $q, $rootScope, $stateParams, MessageService, Store, Qinius) {
        var vm = this;

        vm.domains = Qinius.getDomain();
        vm.store = Store.getCurrent();
        $q.all([vm.domains.$promise, vm.store.$promise]).then(function(){
            vm.store = vm.store.data;
            // vm.domains = vm.domains.data;
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        })

        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
    }
})();
