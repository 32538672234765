(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ThemeClassifyController', ThemeClassifyController);

    ThemeClassifyController.$inject = ['$state', '$scope', 'ParseLinks', 'MessageService', 'paginationConstants', 'pagingParams', 'ThemeClassify', '$localStorage', '$uibModal'];

    function ThemeClassifyController($state, $scope, ParseLinks, MessageService, paginationConstants, pagingParams, ThemeClassify, $localStorage, $uibModal) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.jump = jump;
        vm.clear = clear;
        vm.search = search;
        vm.classType = pagingParams.classType || 'XML';
        vm.classList = [
            {valur: '模板分类', type: 'XML'},
            {valur: '背景分类', type: 'BG'},
            {valur: '蒙版分类', type: 'MASK'},
            {valur: '素材分类', type: 'CLIPART'},
            {valur: '文字模板分类', type: 'FONT'},
            {valur: '边框分类', type: 'BORDER'}
        ];

        loadAll();
        $scope.delete = function (id) {
            MessageService.confirm("确认要删除该数据么？", function () {
                ThemeClassify.del({id: id}, function () {
                    loadAll();
                });
            }, function () {
            });
        };

        $scope.changeStatue = function (id,e) {
            fn(e);
            ThemeClassify.changeStatue({id: id}, function () {
                loadAll();
            });
        }

         //防止事件冒泡，默认事件
        function fn(e) {
            if (e && e.stopPropagation) {
                // 因此它支持W3C的stopPropagation()方法
                e.stopPropagation();
            } else {
                // 否则，我们需要使用IE的方式来取消事件冒泡
                window.event.cancelBubble = true;
            }

            // 阻止默认浏览器动作(W3C)
            if (e && e.preventDefault) {
                e.preventDefault();
            } else {
                // IE中阻止函数器默认动作的方式
                window.event.returnValue = false;
            }
        }

        function loadAll() {
            if (pagingParams.search) {
                ThemeClassify.byCondition3({
                    items: [
                        {key: "name", op: "like", value: pagingParams.search},
                        {key: "storeId", op: "=", value: vm.classType == 'XML' ? $localStorage.user.storeId : ''},
                        {key: "type", op: "=", value: vm.classType}
                        ],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ThemeClassify.byCondition3({
                    items: [{key: "storeId", op: "=", value: vm.classType == 'XML' ? $localStorage.user.storeId : ''},{key: "type", op: "=", value: vm.classType}],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.storeData = [];
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.themeClassifyList = res.data;
                    vm.page = pagingParams.page;
                    for(var i=0; i<res.data.length; i++){
                        if(res.data[i].storeId){
                            vm.storeData.push(res.data[i])
                        }
                    }
                }else{
                    MessageService.error(res.message);
                    return
                }

            }

            function onError(error) {
                MessageService.error("数据加载出错");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        $(".navbar").show();
        $(".wrap").show().css({'margin': 'margin: 48px 0 0 130px;'});

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                classType: vm.classType
            });
        }

        function jump() {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            console.log(vm.page);
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }


        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.addClass = function () {
            $uibModal.open({
                templateUrl: 'app/entities/theme-classify/theme-classify-detail.html',
                controller: 'ThemeClassifyDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-right',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'add',
                            classIfyType: vm.classType
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.editClass = function (data) {
            var copy = angular.copy(data);
            $uibModal.open({
                templateUrl: 'app/entities/theme-classify/theme-classify-detail.html',
                controller: 'ThemeClassifyDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-right',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            state: 'edit',
                            data: copy
                        };
                    }]
                }
            }).result.then(function(item) {
                if(item){
                    loadAll()
                }
            });
        }
        $scope.classCb = function (item) {
            vm.classType = item.type;
            vm.transition();
            // loadAll()
        }
    }
})();
