(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('invoice', {
                parent: 'entity',
                url: '/invoice',
                data: {
                    authorities: ["ROLE_INVOICE_LIST"],
                    pageTitle: 'global.menu.entities.invoices'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice/invoice.html',
                        controller: 'InvoiceController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    statue: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search,
                            statue: $stateParams.statue
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoice');
                        return $translate.refresh();
                    }]
                }
            })
            .state('invoice.show', {
                parent: 'invoice',
                url: '/invoice/show/{id}',
                data: {
                    authorities: ["ROLE_INVOICE_LIST"]
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice/invoice-show.html',
                        controller: 'InvoiceShowController',
                        controllerAs: 'vm',
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoice');
                        return $translate.refresh();
                    }],
                    entity: ['Invoice', '$stateParams', function(Invoice, $stateParams) {
                        return Invoice.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('invoice-detail', {
                parent: 'invoice',
                url: '/invoice/{ids}',
                data: {
                    authorities: ["ROLE_INVOICE_LIST"],
                    pageTitle: 'home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/invoice/invoice-detail.html',
                        controller: 'InvoiceDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('invoice');
                        return $translate.refresh();
                    }],
                    entity: ['Invoice', '$stateParams', function(Invoice, $stateParams) {
                        return {};
                    }]
                }

            });
    }

})();