(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('product', {
                parent: 'entity',
                url: '/product?page&sort&search',
                data: {
                    authorities: ['ROLE_PRODUCT'],
                    pageTitle: 'global.menu.entities.templateManagement2'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/products.html',
                        controller: 'ProductController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id, desc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

            .state('product-detail', {
                parent: 'product',
                url: '/product/{id}',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-detail.html',
                        controller: 'ProductDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Product','$stateParams', function (Product,$stateParams) {
                        return Product.get({
                            id: $stateParams.id
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })


            .state('product.new', {
                parent: 'product',
                url: '/new',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-dialog-create.html',
                        controller: 'ProductDialogCreateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: function() {
                        return {
                            data:{
                                name: null,
                                sku: null,
                                cancelled: null,
                                price: null,
                                jsonFile: null,
                                pTicket: false,
                                id: null,
                                expandedObjectProperties:[],
                                defaultObjectProperties:[],
                                prototype: "StoreSelf"
                            }
                        };
                    }
                }
            })
            .state('product.edit', {
                parent: 'product',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-dialog.html',
                        controller: 'ProductDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Product','$stateParams', function (Product,$stateParams) {
                        return Product.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('product.edit2', {
                parent: 'product',
                url: '/{id}/edit2',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-dialog-create.html',
                        controller: 'ProductDialogCreateController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['Product','$stateParams', function (Product,$stateParams) {
                        return Product.get({id: $stateParams.id}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
 
            .state('product-view-article', {
                parent: 'product.new',
                url: '/{fileName}/{bindingType}',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/product/product-view-article.html',
                        controller: 'ProductViewArticleController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXmlFileServes', '$stateParams', function(ProductXmlFileServes, $stateParams) {

                            }]
                        }
                    });
                }]
            })
            .state('product-view-article2', {
                parent: 'product.edit',
                url: '/view/{fileName}',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/product/product-view-article.html',
                        controller: 'ProductViewArticleController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['ProductXmlFileServes', '$stateParams', function(ProductXmlFileServes, $stateParams) {

                            }]
                        }
                    }).result.then(function() {
                        $state.go('product', null, {
                            reload: 'product'
                        });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('product-putaway', {
                parent: 'product',
                url: '/product-putaway',
                data: {
                    authorities: ['ROLE_PRODUCT']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-putaway.html',
                        controller: 'ProductPutawayController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('productType');
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
