(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('StoreUserController', StoreUserController);

    StoreUserController.$inject = ['$scope', '$state', 'StoreUser', 'Account', 'ParseLinks',  'paginationConstants', 'pagingParams', 'MessageService', '$uibModal'];

    function StoreUserController ($scope, $state, StoreUser, Account, ParseLinks,   paginationConstants, pagingParams, MessageService, $uibModal) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.account = Account.get();
        vm.jump = jump;


        loadAll();
        $scope.delete = function(id){
            MessageService.confirm("entity.action.deleteConfirm",function(){
                StoreUser.delete({id: id},function () {
                    MessageService.success("删除成功");
                    loadAll();
                });
            },function(){},true);
        };

        $scope.resetPassword = function(user){
            MessageService.confirm("确认要该用户密码还原成初始密码？",function(){
                StoreUser.resetPassword({id: user.id},function () {
                    MessageService.success("该用户密码已设置为初始密码");
                    loadAll();
                });
            },function(){});
        }

        function loadAll () {
            if (pagingParams.search) {
                StoreUser.byCondition({
                    items:[{key:"name",op:"like",value:pagingParams.search}] ,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                StoreUser.byCondition({
                    items:[],
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.storeUsers = res.data;
                    vm.page = pagingParams.page;
                }else{
                    MessageService.error(res.message);
                }
            }
            function onError(error) {
                MessageService.error("用户加载失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText <= 0 ? 1 : vm.jumpText;
            loadPage(vm.page);
        }

        $scope.batchArr = [];
        $scope.selectOne = false;
        $scope.selectAll = false;

        $scope.chooseAll = function () {
            $scope.selectAll = !$scope.selectAll;
            $scope.batchArr = [];
            angular.forEach(vm.storeUsers,function (storeUser,i) {
                if($scope.selectAll){
                    storeUser.select = true;
                    $scope.batchArr.push(storeUser.id);
                    $scope.selectOne = true;
                }else{
                    storeUser.select = false;
                    $scope.selectOne = false;
                }
                // 当前登录用户
                if(storeUser.isCreated||vm.account.name==storeUser.name){
                    storeUser.select = false;
                }
            })
        }

        $scope.chooseOne = function (storeUser) {
            if(storeUser.isCreated||vm.account.name==storeUser.name){
                return
            }
            storeUser.select = !storeUser.select;
            // 选中
            if(storeUser.select){
                $scope.batchArr.push(storeUser.id);
            }else{
                // 未选中
                var ind = $scope.batchArr.indexOf(storeUser.id);
                if (ind >= 0) {
                    $scope.batchArr.splice(ind, 1);
                }
            }

            // 判断全选按钮
            var flag = true;
            angular.forEach(vm.storeUsers,function (storeUser,i) {
                if(!storeUser.select){
                    $scope.selectAll = false;
                    flag = false;
                }
                // 全部选中
                if(i==vm.storeUsers.length-1&&flag){
                    $scope.selectAll = true;
                }
            })

            // 批量按钮的显示
            if($scope.batchArr.length){
                $scope.selectOne = true;
            }else {
                $scope.selectOne = false;
            }
        }

        $scope.deleteBatch = function () {
            MessageService.confirm("entity.action.deleteConfirm",function(){
                StoreUser.deleteBatch({ids: $scope.batchArr},function () {
                    MessageService.success("删除成功");
                    $scope.selectOne = false;
                    $scope.selectAll = false;
                    loadAll();
                },function () {
                    MessageService.error("删除失败");
                })
            },function(){},true);
        }

        $scope.editorPhone = function(storeUser){
            $uibModal.open({
                templateUrl: 'app/entities/store-user/phone-editor.html',
                controller: 'PhoneEditorController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-right',
                resolve: {
                    entity: [function() {
                        return storeUser;
                    }]
                }
            }).result.then(function (backData) {
                if(backData){
                    loadAll()
                }
            });
        }
    }
})();
