(function () {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('store', {
                parent: 'entity',
                url: '/store',
                data: {
                    authorities: ['ROLE_STORE'],
                    pageTitle: 'global.menu.entities.store'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store/store-detail.html',
                        controller: 'StoreDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function ($stateParams, Store) {
                        return Store.getCurrent({}).$promise;
                    }]
                }
            })
            .state('store-work', {
                parent: 'store',
                url: '/work',
                data: {
                    authorities: ['ROLE_STORE'],
                    pageTitle: 'global.menu.entities.store'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store/store-workEdit.html',
                        controller: 'StoreWorkEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }]
                }
            })
            .state('store-work-edit', {
                parent: 'store',
                url: '/work/edit',
                data: {
                    authorities: ['ROLE_STORE'],
                    pageTitle: 'global.menu.entities.store'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store/store-workEdit-edit.html',
                        controller: 'StoreWorkEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }]
                }
            })


            .state('store.edit', {
                parent: 'store',
                url: '/edit',
                data: {
                    authorities: ['ROLE_STORE'],
                    pageTitle: 'backApp.store.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/store/store-dialog.html',
                        controller: 'StoreDialogController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('store');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('storeType');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Store', function ($stateParams, Store) {
                        return Store.getCurrent({}).$promise;
                    }]
                }
            })
            .state('store.edit2', {
                parent: 'store',
                url: '/{id}/edit2',
                data: {
                    authorities: ['ROLE_STORE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/store/store-dialog2.html',
                        controller: 'StoreDialog2Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Store', function (Store) {
                                return Store.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('store', null, {reload: 'store'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('store.edit3', {
                parent: 'store',
                url: '/{id}/edit3',
                data: {
                    authorities: ['ROLE_STORE']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/store/store-dialog3.html',
                        controller: 'StoreDialog3Controller',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Store', function (Store) {
                                return Store.get({id: $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('store', null, {reload: 'store'});
                    }, function () {
                        $state.go('^');
                    });
                }]
            });
    }

})();
