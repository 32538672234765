(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SalesOrderExportDialogController', SalesOrderExportDialogController);

    SalesOrderExportDialogController.$inject = ['$http', '$timeout', '$localStorage', '$stateParams',  '$q', '$uibModalInstance', '$scope',
     'Product', 'Store', 'MessageService', 'Category', 'DateUtils' ];

    function SalesOrderExportDialogController ($http, $timeout, $localStorage, $stateParams, $q, $uibModalInstance, $scope,
      Product, Store, MessageService, Category, DateUtils ) {
        var vm = this;
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startTime = false;
        vm.datePickerOpenStatus.endTime = false;
        vm.openCalendar = openCalendar;
        vm.dateformat = dateformat;
        vm.export = exportData;
        vm.account = $localStorage.user;

        vm.clear = clear;
        vm.categorysAll = Category.storeGetAllByCondition({items:[]});
        vm.productsAll = Product.getAllByCondition({items: []});
        vm.storeId = $localStorage.user.storeId;
        console.log(vm.storeId)

        
        $q.all([vm.categorysAll.$promise,vm.productsAll.$promise]).then(function() {
            vm.categorys = vm.categorysAll.data;
            vm.products = vm.productsAll.data;
        });

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function exportData(){
            if (!vm.endTime || !vm.startTime) {
                MessageService.error("请录入下单时间");
                return;
            }
            if ((vm.endTime -vm.startTime)/24/3600/1000>31) {
                MessageService.error("下单时间间距不能超过31天");
                return;
            }
            var json_str = getItems();
            var params = "";

            if (vm.startTime !=null) {
                params = params + "&startDate=" + new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000);
            };
            if (vm.endTime !=null) {
                params = params + "&endDate=" +new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000);
            };
 
            //商户名
            if (vm.storeId !=null) {
                params = params + "&storeId="+vm.storeId;
            };
           
            if (vm.status !=null && vm.status !="") {
                params = params + "&status="+vm.status;
            };
            if (vm.editorType !=null && vm.editorType !="") {
                params = params + "&editorType="+vm.editorType;
            };

            //商品名称
            if (vm.productId !=null) {
                params = params + "&productId="+vm.productId;
            };

            if (params!= "" ) {
                params = params.substring(1);
            }
            

            $http({
                url: 'service/api/sales-orders/byConditionPage/download?items=' + params,
                method: "GET",
                headers: {
                    'Content-type': 'application/json',
                },
                responseType: 'blob'
            }).success(function(data, status, headers, config) {
                var blob = new Blob([data], {
                    type: "application/vnd.ms-excel"
                });
                var objectUrl = URL.createObjectURL(blob);
                var a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display:none');
                a.setAttribute('href', objectUrl);
                var filename = "订单详情" + DateUtils.convertLocalDateToServer(Date.now()) + ".xls";
                a.setAttribute('download', filename);
                a.click();
                URL.revokeObjectURL(objectUrl);
                document.body.removeChild(a);
            }).error(function(data, status, headers, config) {});

        }

        function getItems() {
            var items=[];
            if (vm.endTime !=null) {
                items.push({key: "createdDate", op: "<=", value: new Date(Date.parse(vm.endTime) + 16 * 60 * 60 * 1000 - 1000)});
            };
            if (vm.startTime !=null) {
                items.push({key: "createdDate", op: ">=", value: new Date(Date.parse(vm.startTime) -8 * 60 * 60 * 1000)});
            };
            //商品分类
            if (vm.categoryId !=null) {
                items.push({key:"orderLines.orderArticle.categoryStoreId",op:"=",value:vm.categoryId});
            };
            //商品名称
            if (vm.productName !=null) {
                items.push({key:"orderLines.orderArticle.productName",op:"=",value:vm.productName});
            };
            //商户名
            if (vm.storeId !=null) {
                items.push({key:"storeId",op:"=",value:vm.storeId});
            };
            //订单来源
            if (vm.sourceType !=null) {
                items.push({key:"sourceType",op:"=",value:vm.sourceType});
            };
            if (vm.status !=null && vm.status !="") {
                items.push({key:"status",op:"=",value:vm.status});
            };
            if(vm.branchStore!=null){
                items.push({key:"branchStoreId",op:"=",value:vm.branchStore.id});
            }

            return items;
        }


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }




    }
})();
