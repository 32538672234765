(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('PageMySuffixSave2Controller', PageMySuffixSave2Controller);
    PageMySuffixSave2Controller.$inject = ['$scope', 'UidService', 'Category', '$uibModalInstance', 'MessageService', '$stateParams', 'ProductXmlFileServes',
    'ThemeClassify', 'ProductXmls', 'Store', 'xmlId', '$localStorage', 'pageIndex','Qinius','$q', 'StoreWorkEditConfig', '$uibModal'];

    function PageMySuffixSave2Controller( $scope, UidService, Category, $uibModalInstance, MessageService, $stateParams, ProductXmlFileServes,
        ThemeClassify, ProductXmls, Store, xmlId, $localStorage, pageIndex,Qinius,$q, StoreWorkEditConfig, $uibModal) {
        //编辑第三方的模板 弹出窗口

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.id = null;
        vm.multilingual= multilingual
        vm.domains = Qinius.getDomain();
        vm.modelData = [];
        Qinius.get(function (msg) {
            vm.token = msg.token;
        });
        vm.storeWorkEditAll = StoreWorkEditConfig.storeId();
        $q.all([vm.domains.$promise, vm.storeWorkEditAll.$promise]).then(function () {
            $scope.domain = 'https://' + vm.domains.domains[0] + '/';
            vm.storeWorkEdit = vm.storeWorkEditAll.data;
        });
        ThemeClassify.getAllByCondition3({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'XML'},{key: "storeId", op: "=", value: $localStorage.user.storeId}]},function (res) {
            if(res.status == 200){
                vm.themeClassifyList = res.data;
            }else {
                MessageService.error(res.message);
            }
        });
        Store.getCurrent(function (res) {
            if(res.status == 200){
                vm.store = res.data;
            }else {
                MessageService.error(res.message);
            }
        });

        vm.variableData = "";
        if(xmlId){
            console.log(xmlId)
            vm.id = xmlId;
            ProductXmlFileServes.get({id: vm.id}, function (resp) {
                if(resp.status == 200){
                    vm.customStore = resp.data.customStoreId;
                    vm.productXml = resp.data;
                    if(!vm.productXml.themeClassifies){
                        vm.productXml.themeClassifies = [];
                    }
                    if(vm.productXml.modelData){
                        vm.modelData = vm.productXml.modelData;
                        if(!vm.productXml.modelBlendent){
                            vm.productXml.modelBlendent = [];
                        }else{
                            vm.productXml.modelBlendent = vm.productXml.modelBlendent;
                        }
                    }
                    if (resp.data.document) {
                        vm.productXml.replace = resp.data.document.replace;
                    }
                    if (vm.productXml != null && vm.productXml.bindingType != null) {
                        if (vm.productXml.bindingType == 'Top') {
                            vm.repbindingTyperint = '上下滑动';
                        } else if (vm.productXml.bindingType == 'Left') {
                            vm.bindingType = '左右滑动（含封面）';
                        } else if (vm.productXml.bindingType == 'None') {
                            vm.bindingType = '单页展示';
                        }else{
                            vm.bindingType = '左右滑动（不含封面）';
                        }
                    }


                    vm.page = [];
                    for(var i=0; i<vm.productXml.document.pages.page.length; i++){
                        var _page = vm.productXml.document.pages.page[i];
                        vm.page.push({
                            index:i,
                            name: '第'+i+'页'
                        })
                        _page.levels.level.forEach(function (level) {
                            if(level.variable){
                                vm.variableData += level.name + "；";
                            }
                        })
                    }
                    console.log(vm.page)
                }else {
                    MessageService.error(resp.message);
                }

            });
        }

        Category.storeGetAllByCondition({items: [{key: "usable", op: "=", value: "true"}]},function (res) {
            if(res.status == 200){
                vm.categories = res.data;
            }else {
                MessageService.error(res.message);
            }
        });

        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data: vm.productXml.productXmlLanguages,
                            key: 'name',
                            fieldValue: vm.productXml.name,
                            fieldName: '模板名称'
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.productXml.productXmlLanguages=data;
                }
            });
        }

        //配色管理
        $scope.modelBlendentAdd = function () {
            if(!vm.productXml.modelBlendent){
                vm.productXml.modelBlendent = [];
            }
            vm.productXml.modelBlendent.push({
                name:'',
                uuid:getUuid(16, 16),
                cancelled: false,
                modelData:angular.copy(vm.modelData)
            })
        }

        function getUuid(len, radix) {
            var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            var uuid = [],
                i;
            radix = radix || chars.length;

            if (len) {
                // Compact form
                for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
            } else {
                // rfc4122, version 4 form
                var r;

                // rfc4122 requires these characters
                uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
                uuid[14] = '4';

                // Fill in random data.  At i==19 set the high bits of clock sequence as
                // per rfc4122, sec. 4.1.5
                for (i = 0; i < 36; i++) {
                    if (!uuid[i]) {
                        r = 0 | Math.random() * 16;
                        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                    }
                }
            }

            return uuid.join('');
        }
        $scope.modelBlendentSet = function (data) {
            vm.modelData.forEach(function (modelData2){
                data.modelData.forEach(function (modelData1){
                    if(modelData1.materialName == modelData2.materialName){
                        modelData1.page = modelData2.page;
                        if(modelData1.page>0 || modelData1.page === 0){
                            modelData1.color = null;
                        }
                    }
                })
            })
            $uibModal.open({
                templateUrl: 'app/entities/page/modal/blendent-modal.html',
                controller: 'BlendentModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return {
                            modelData: data.modelData,
                            modelPath: vm.productXml.modelPath
                        };
                    }]
                }
            }).result.then(function(res) {
                if(res){
                    data.modelData = res;
                }
            });
        }
        $scope.removeModelBlendent = function (index) {
            vm.productXml.modelBlendent.splice(index, 1)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.validate = function () {
            if (vm.productXml.categoryId == null || vm.productXml.categoryId == "") {
                MessageService.error("请选择分类");
                return false;
            }

            if (vm.productXml.name == null || vm.productXml.name == "") {
                MessageService.error("请输入作品名称");
                return false;
            }
            if (!vm.productXml.themeClassifies || vm.productXml.themeClassifies.length == 0) {
                MessageService.error("请选择模板分类");
                return false;
            }
            return true;
        };

        function save() {
            if (!$scope.validate()) {
                return;
            }
            var _data = angular.copy(vm.productXml);
            _data.document.replace = _data.replace;
            _data.modelData = vm.modelData;
            _data.modelType = _data.modelPath ? 'THREE' : 'TWO';
            ProductXmlFileServes.update(_data, function(data){
                MessageService.success("保存成功");
                $uibModalInstance.close(data);
            },function(){
                MessageService.error("保存失败");
            });
        }

        $scope.save2 = function(){
            if (!$scope.validate()) {
                return;
            }
            var _data = angular.copy(vm.productXml);
            _data.document.replace = _data.replace;
            _data.modelData = vm.modelData;
            _data.modelType = _data.modelPath ? 'THREE' : 'TWO';
            ProductXmlFileServes.update(_data,function(res){
                if(res.status == 200){
                    var url = vm.storeWorkEdit.productXmlUrl + '/#/page-my-suffix/'+res.data.identifier+'/'+vm.storeWorkEdit.productXmlKey+'/'+res.data.id+'/'+_data.pattern+'/'+_data.document.replace+'/'+ pageIndex;
                    if(_data.modelType=='THREE'){
                        url+='/'+_data.modelType+'/'+encodeURIComponent(JSON.stringify(_data.modelData))+'?modelPath='+encodeURIComponent(_data.modelPath) + '&xmlName=' + res.data.name
                    }else{
                        url+='?xmlName='+res.data.name
                    }
                    window.location.href = url;
                }else {
                    MessageService.error(res.message);
                }

            },function(){
                MessageService.error("保存失败");
            });
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }


        $scope.modelPathDelete = function(){
            vm.productXml.modelPath = "";
            vm.productXml.modelData = "";
            vm.productXml.modelBlendent = [];
            vm.productXml.modelType = "TWO";
            vm.modelData = [];
        }
        //上传3D模型
        window.uploadPic = function ($event) {
            var files = $event.target.files;
            if (files != null && files.length > 0) {
                qiniuUpload(files[0]);
            }
        }

        function qiniuUpload(file) {
            var formData = new FormData();
            formData.append('file', file);
            formData.append('unique_names', false);
            formData.append('key', "product-" + UidService.get());
            formData.append('token', vm.token);
            $.ajax({
                cache: true,
                type: "POST",
                url: 'https://upload.qiniup.com/',
                data: formData,
                async: true,
                contentType: false,
                processData: false,
                success: function (data) {
                    vm.productXml.modelPath = $scope.domain + data.key;
                    vm.productXml.modelType = "THREE";
                    gltfInfo(vm.productXml.modelPath)
                    $scope.$apply();
                },
                error: function (request) {
                    MessageService.error("图片上传失败");
                }
            });
        };

        function gltfInfo(path) {
            // 加载glTF格式的模型
            vm.modelData = [];
            vm.materialMap = [];
            var loader = new THREE.GLTFLoader();
            if (THREE.DRACOLoader) {
                var dracoLoader = new THREE.DRACOLoader();
                if (dracoLoader) {
                    loader.setDRACOLoader( dracoLoader );
                }
            }
            vm.modelLoad = true;
            loader.load(path, function (gltf) {
                gltf.scene.traverse(function(child){
                    if (child.isMesh) {
                        traverseMaterials(child, function(node,i){
                            if(vm.materialMap.indexOf(node.name) == -1){
                                var _modelData = {
                                    materialName:node.name,
                                    page:''
                                };
                                vm.modelData.push(_modelData);
                                vm.materialMap.push(node.name);
                            }
                        });
                    }
                });
                vm.modelLoad = false;
                $scope.$apply();
            }, function (xhr) {
                vm.schedule = (xhr.loaded / xhr.total * 100);
            }, function (error) {
                MessageService.error("3D模型上传失败，请稍后重试！")
                vm.modelLoad = false;
            })
        }

        function traverseMaterials(object, callback) {
            object.traverse(function(node){
                if (!node.isMesh) return;
                var materials = Array.isArray(node.material)
                    ? node.material
                    : [node.material];
                materials.forEach(callback);
            });
        }
    }

})();
