(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Customer', Customer);

    Customer.$inject = ['$resource', 'DateUtils'];

    function Customer ($resource, DateUtils) {
        var resourceUrl =  'manager/' + 'api/customers/:path/:id';

        return $resource(resourceUrl, {}, {
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            'query': { method: 'GET'},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastLogin = DateUtils.convertDateTimeFromServer(data.lastLogin);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            "todayNum":{
                method:"GET",
                params:{
                    path:"todayNum"
                }
            }
        });
    }
})();
