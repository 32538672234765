(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('ConfigurationDialogController', ConfigurationDialogController);

    ConfigurationDialogController.$inject = ['$scope', '$q', '$state', 'ECommerceSetting','Store','MessageService', 'entity'];

    function ConfigurationDialogController ($scope, $q, $state, ECommerceSetting,Store,MessageService, entity) {
        var vm = this;
        vm.data = entity.data;
        $scope.save = function () {
            ECommerceSetting.edit(vm.data,function (res) {
                if(res.status == 200){
                    MessageService.success("修改成功");
                    $state.go("configuration2");
                }else {
                    MessageService.error(resp.message);
                }
            })
        }
    }
})();
