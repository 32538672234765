(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Masking', Masking);

    Masking.$inject = ['$resource'];

    function Masking ($resource) {
        var resourceUrl =  'manager/' + 'api/materials/:path/:id';
        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            "byCondition":{
                method:"POST",
                params:{
                    path:"byCondition"
                }
            },
            "byCondition2":{
                method:"POST",
                params:{
                    path:"byCondition2"
                }
            },
            "byCondition3":{
                method:"POST",
                params:{
                    path:"byCondition3"
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                }
            },
            "getAllByCondition3":{
                method:"POST",
                params:{
                    path:"getAllByCondition3"
                }
            },
            "save":{
                method:"POST"
            },
            "delete":{
                method:"DELETE"
            },
            "themeClassify":{
                method:"GET",
                params:{
                    path:"saveTheme"
                }
            },
            "batchCreate":{
                method:"GET",
                params:{
                    path:"batchCreate"
                }
            },
            "batchCreateByStore":{
                method:"GET",
                params:{
                    path:"batchCreateByStore"
                }
            },
            'update': { method:'PUT' },
        });
    }
})();
