(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('FontManagementController', FontManagementController);

    FontManagementController.$inject = ['$scope', '$state', 'FontManagement', 'ParseLinks', 'paginationConstants', 'pagingParams', 'MessageService',
    '$localStorage', 'Qinius', '$q','$uibModal'];

    function FontManagementController ($scope, $state, FontManagement, ParseLinks, paginationConstants, pagingParams, MessageService,
     $localStorage, Qinius, $q,$uibModal) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.multilingual= multilingual;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.jump = jump;
        vm.account = $localStorage.user;
        vm.listStyle = pagingParams.style; //List
        loadAll();

        vm.domains = Qinius.getDomain();
        $q.all([ vm.domains.$promise]).then(function(){
            vm.domainQ = 'https://' + vm.domains.domains[0] + '/';
        })


        $scope.defaultShow = function(fontManagement){
            // MessageService.confirm("确认要设置为模板中默认展示？",function(){
            //     FontManagement.defaultShow({id: id},function (res) {
            //         if(res.status == 200){
            //             MessageService.success('设置默认字体成功');
            //             loadAll();
            //         }else {
            //             MessageService.error(res.message);
            //         }
            //     });
            // },function(){});
            if (!fontManagement.enable) {
                MessageService.error('当前字体不可设置为默认字体！');
                fontManagement.defaultShow = false
                return
            }
            FontManagement.defaultShow({id: fontManagement.id},function (res) {
                if(res.status == 200){
                    MessageService.success('设置默认字体成功');
                    loadAll();
                }else {
                    MessageService.error(res.message);
                }
            });
        }

        $scope.delete = function(id){
            MessageService.confirm("确认要删除该数据？",function(){
                FontManagement.delete({id: id},function (res) {
                    if(res.status == 200){
                        loadAll();
                    }else {
                        MessageService.error(res.message);
                    }
                });
            },function(){});
        };

        $scope.switchStyle = function(){
            if (vm.listStyle == 'Card') {
                vm.listStyle = 'List'
            }else{
                vm.listStyle = 'Card'
            }
            transition()
        }

        function loadAll () {
            if (vm.account == undefined || vm.account == null) {
                $state.go('home');
                return;
            }
            if (pagingParams.search) {
                FontManagement.byCondition2({
                    items:[{key:"family",value:pagingParams.search, op:"like"}],
                    page: pagingParams.page - 1,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                FontManagement.byCondition2({
                    page: pagingParams.page - 1,
                    size: 20,
                    sort: sort()
                },
                onSuccess, onError);

            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(res, headers) {
                if(res.status == 200){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    vm.fontManagements = res.data;
                    vm.page = pagingParams.page;
                }else {
                    MessageService.error(res.message);
                }
            }
            function onError(error) {
                MessageService.error("字体获取失败");
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                style:vm.listStyle
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function jump () {
            vm.page = vm.jumpText<=0?1:vm.jumpText;
            loadPage(vm.page);
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function multilingual(fontManagement){
            var languages = fontManagement.fontFamilyLanguages;
            var id = fontManagement.id;
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data:languages,
                            key: 'family',
                            fieldValue: fontManagement.family,
                            fieldName: '字体名称'
                        };
                    }]
                }
            }).result.then(function(data) {
                if (data) {
                    var familyLanguageVM ={}
                    familyLanguageVM.list = data;
                    familyLanguageVM.fontManagementId = id;
                }
                FontManagement.updateLanguage(familyLanguageVM,function (){
                    MessageService.success("保存成功");
                    loadAll()
                },function (){
                    MessageService.error("保存失败");
                });
            })
        }
    }
})();
