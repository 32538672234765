(function () {
    'use strict';

    angular
        .module('backApp')
        .factory('Register', Register)
        .factory('AccountP', AccountP);

    Register.$inject = ['$resource'];
    AccountP.$inject = ['$resource'];


    function Register ($resource) {
        var resourceUrl =  'security/' + 'api/register/:path';

        return $resource(resourceUrl, {}, {
            'checkLogin': { method: 'GET',params:{path:"checkLogin"}}

        });
    }
    function AccountP($resource) {
        var resourceUrl =  'security/' + 'api/store-users/:path/:id';
        var service = $resource(resourceUrl, {}, {
            'checkPassword': {
                method: "GET",
                params: {
                    path: "checkPassword"
                }
            }
        });

        return service;
    }
})();
