(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('MapDepotRecordController', MapDepotRecordController);

    MapDepotRecordController.$inject = ['$scope', '$state', 'Store','MessageService', '$timeout', '$uibModal', 'paginationConstants','$q', 'StoragePayOrders', 'ParseLinks'];

    function MapDepotRecordController ($scope, $state, Store, MessageService, $timeout, $uibModal, paginationConstants,$q, StoragePayOrders, ParseLinks ) {
        var vm = this;

        vm.page = 1;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        init();
        function init(){
            var items = [{key: "paid", op: "=", value: "true"}];
            StoragePayOrders.byCondition({
                items: items,
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: ["id,desc"]
            }, function (res, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.tableData = res.data;
            }, function (error) {
                MessageService.error("数据加载出错");
            });
        }

        function transition() {
            init();
        }

        $scope.currentDateLater = function(item) {
            if(!item.paidDate){
                return ""
            }
            var date = new Date(item.paidDate);
            var y = date.getFullYear() + item.periodOfValidity;
            var m = date.getMonth() + 1;
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            var minute = date.getMinutes();
            var second = date.getSeconds();
            minute = minute < 10 ? ('1' + minute) : minute;
            second = second < 10 ? ('0' + second) : second;
            return y + '-' + m + '-' + d;
            // return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
        }
    }
})();
