(function() {
    'use strict';
    angular
        .module('backApp')
        .factory('Role', Role)
        .factory('Authority', Authority);

    Role.$inject = ['$resource'];
    Authority.$inject = ['$resource'];

    function Role ($resource) {
        var resourceUrl = 'security/' +  'api/roles/:path/:id';

        return $resource(resourceUrl, {}, {
            'byStore': {
                method: 'POST',
                params: {
                    path: 'byStore'
                }
            },
            'byCondition2': {
                method: 'POST',
                params: {
                    path: 'byCondition2'
                }
            },
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition2":{
                method:"POST",
                params:{
                    path:"getAllByCondition2"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    function Authority ($resource) {
        var resourceUrl = 'security/' +  'api/authorities/:path/:id';

        return $resource(resourceUrl, {}, {
            'byCondition': {
                method: 'POST',
                params: {
                    path: 'byCondition'
                }
            },
            "getAllByCondition":{
                method:"POST",
                params:{
                    path:"getAllByCondition"
                }
            },
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
