(function() {
    'use strict';

    angular
        .module('backApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pay-order', {
            parent: 'pay-orderNew',
            url: '/pay-order?page&sort&search&startTime&endTime&type&paid',
            data: {
                authorities: ["ROLE_RECHARGE_RECORD"],
                pageTitle: 'global.menu.entities.payOrder'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-order/pay-orders.html',
                    controller: 'PayOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        startTime: $stateParams.startTime,
                        endTime: $stateParams.endTime,
                        type: $stateParams.type,
                        paid: $stateParams.paid
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payOrder');
                    $translatePartialLoader.addPart('settlementMethod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('pay-order2', {
            parent: 'entity',
            url: '/pay-order2',
            data: {
                authorities: ["ROLE_INVOICE_SUBMIT"],
                pageTitle: 'global.menu.entities.invoiceApplication'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-order/pay-orders2.html',
                    controller: 'PayOrderController2',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payOrder');
                    $translatePartialLoader.addPart('settlementMethod');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('invoice-new', {
            parent: 'pay-order',
            url: '/pay-order/invoice/{id}',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/invoice/invoice-detail.html',
                    controller: 'InvoiceNewController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('invoice');
                            return $translate.refresh();
                        }],
                        entity: ['PayOrder', function(PayOrder) {
                            return PayOrder.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pay-order', null, { reload: 'pay-order' });
                }, function() {
                    $state.go('pay-order');
                });
            }]
        })
        .state('pay-order-detail', {
            parent: 'entity',
            url: '/pay-order/{id}',
            data: {
                authorities: [],
                pageTitle: 'backApp.payOrder.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-order/pay-order-detail.html',
                    controller: 'PayOrderDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('payOrder');
                    $translatePartialLoader.addPart('settlementMethod');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'PayOrder', function($stateParams, PayOrder) {
                    return PayOrder.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'pay-order',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('pay-order-detail.edit', {
            parent: 'pay-order-detail',
            url: '/detail/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-order/pay-order-dialog.html',
                    controller: 'PayOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayOrder', function(PayOrder) {
                            return PayOrder.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pay-orderNew', {
            parent: 'entity',
            url: '/pay-orderNew',
            data: {
                authorities: ["ROLE_RECHARGE"],
                pageTitle: 'global.menu.entities.storeRecharge'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-order/pay-order-dialog.html',
                    controller: 'PayOrderDialogController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['Store', function(Store) {
                    return Store.getCurrent().$promise;
                }]
            }
        })
        .state('pay-order.edit', {
            parent: 'pay-order',
            url: '/{id}/edit',
            data: {
                authorities: []
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/pay-order/pay-order-dialog.html',
                    controller: 'PayOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PayOrder', function(PayOrder) {
                            return PayOrder.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('pay-order', null, { reload: 'pay-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pay-order.detail1', {
            parent: 'pay-order',
            url: '/{id}/detail1',
            data: {
                authorities: ["ROLE_RECHARGE"],
                pageTitle: 'global.menu.entities.storeRecharge'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/pay-order/pay-order-detail1.html',
                    controller: 'PayOrderDetail1Controller',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('store');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['PayOrder', '$stateParams', function(PayOrder, $stateParams) {
                    return PayOrder.get({id: $stateParams.id}).$promise;
                }]
            }
        })
         ;
    }

})();
