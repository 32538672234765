(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('SceneModalController', SceneModalController);

    SceneModalController.$inject = ['$scope','MessageService','entity','$uibModalInstance','Mould2Ds'];

    function SceneModalController ($scope, MessageService,entity,$uibModalInstance,Mould2Ds) {
        var vm = this;
        vm.searchQuery = "";
        vm.data = [];
        vm.selectData = angular.copy(entity)||[];
        vm.search = init;
        init();
        function init() {
            var items = [];
            if(vm.searchQuery){
                items.push({key:"name",op:"like",value:vm.searchQuery})
            }
            Mould2Ds.getAllByCondition2({
                items: items,
                sort: ["id,asc"]
            }, function (res) {
                vm.data = res.data;
                vm.data.forEach(function (data) {
                    vm.selectData.forEach(function (data1) {
                        if(data1.id == data.id){
                            data.selected = true;
                        }
                    })
                })
            }, function (error) {

            });
        }
        vm.cancel = function () {
            $uibModalInstance.close();
        }
        vm.save = function () {
            vm.selectData = [];
            vm.data.forEach(function (data) {
                if(data.selected){
                    vm.selectData.push(data);
                }
            })
            // if(vm.selectData.length == 0){
            //     MessageService.error("请至少选择一个模型")
            //     return
            // }
            $uibModalInstance.close(vm.selectData);
        }
    }
})();
