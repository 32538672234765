(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('TextXmlApproveController', TextXmlApproveController);

    TextXmlApproveController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Qinius', 'MessageService', 'ThemeClassify', 'FontXmls'];

    function TextXmlApproveController ($timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Qinius, MessageService, ThemeClassify, FontXmls) {
        var vm = this;

        vm.textXml = entity;
        vm.clear = clear;
        vm.save = save;
        vm.themes = ThemeClassify.getAllByCondition3({items:[{key: "used", op: "=", value: 'true'},{key: "type", op: "=", value: 'FONT'}]});
        vm.domains = Qinius.getDomain();
        $q.all([vm.themes.$promise,vm.domains.$promise,vm.textXml.$promise]).then(function(){
            vm.themeClassifies = vm.themes.data;
            vm.textXml = vm.textXml.data;
             $scope.domain = 'https://' + vm.domains.domains[0] + '/';
        })

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.validate = function(){
            if(vm.textXml.themeClassifyId == null || vm.textXml.themeClassifyId==""){
                MessageService.error("请选择分类");
                return false;
            }
            if(!vm.textXml.seq){
                MessageService.error("请输入排序");
                return false;
            }
            return true;
        };

        function save () {
            if(!$scope.validate()){
                return;
            }
            vm.isSaving = true;
            FontXmls.approve({id:vm.textXml.id, themeClassifyId: vm.textXml.themeClassifyId},function(res){
                if(res.status == 200){
                    MessageService.success("审核通过");
                    $uibModalInstance.close();
                }else {
                    vm.isSaving = false;
                    MessageService.error(res.message);
                }
            },function(){
                MessageService.error("通过失败");
                vm.isSaving = false;
            })
        };
    }
})();
