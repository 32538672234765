(function () {
    'use strict';

    angular
        .module('backApp')
        .controller('ThemeClassifyDetailController', ThemeClassifyDetailController);

    ThemeClassifyDetailController.$inject = ['$state', 'entity', 'MessageService', '$uibModalInstance', 'ThemeClassify' ,'$uibModal'];

    function ThemeClassifyDetailController($state, entity, MessageService, $uibModalInstance, ThemeClassify, $uibModal) {

        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.multilingual= multilingual;
        if(entity.state == 'add'){
            vm.themeClassify = {
                type: entity.classIfyType,
                name: '',
                seq: '',
                used: true,
            }
            console.log(vm.themeClassify)
        }else {
            vm.themeClassify = entity.data;
        }


        function multilingual(){
            $uibModal.open({
                templateUrl: 'app/entities/multilingualDialog/multilingualDialog.html',
                controller: 'MultilingualDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                windowClass:'modal-multilingual',
                resolve: {
                    entity: [function() {
                        return {
                            data: vm.themeClassify.themeClassifyLanguages,
                            key:'name',
                            fieldValue: vm.themeClassify.name,
                            fieldName:'分类名称'
                        };
                    }]
                }
            }).result.then(function(data) {
                if(data){
                    vm.themeClassify.themeClassifyLanguages=data;
                }
            });
        }


        function clear () {
            $uibModalInstance.close();
        };

        function save () {
            if(!vm.themeClassify.name){
                MessageService.error("请输入名称");
                return
            }
            if(vm.themeClassify.name == "推荐"){
                MessageService.error("不能使用'推荐'作为分类名字");
                return
            }
            if(!vm.themeClassify.seq || vm.themeClassify.seq < 0){
                MessageService.error("请输入正确的排序序号");
                return false;
            }
            vm.isSaving = true;
            ThemeClassify.save(vm.themeClassify, function(result){
                MessageService.success("操作完成");
                vm.isSaving = false;
                $uibModalInstance.close(result);
            },function(){
                MessageService.error("保存失败");
                vm.isSaving = false;
            });
        }
    }
})();
