(function() {
    'use strict';

    angular
        .module('backApp')
        .controller('InvoiceNewController', InvoiceNewController);

    InvoiceNewController.$inject = ['$q', '$state','$scope', '$uibModalInstance', 'entity', 'MessageService', 'Invoice', 'InvoiceLocation', 'RegionService', 'InvoiceSetting'];

    function InvoiceNewController($q, $state, $scope, $uibModalInstance, entity, MessageService, Invoice, InvoiceLocation, RegionService, InvoiceSetting) {

        var vm = this;
        vm.save = save;
        vm.payOrder = entity;
        vm.clear = clear;
        vm.invoiceSetting = InvoiceSetting.getAll();

        vm.invoiceLocations = InvoiceLocation.getAll({});
        $q.all([vm.invoiceLocations.$promise, vm.invoiceSetting.$promise, vm.payOrder.$promise]).then(function(){
            vm.payOrder = vm.payOrder.data;
            vm.invoiceLocations = vm.invoiceLocations.data;
            vm.invoiceSetting = vm.invoiceSetting.data;
            if (vm.invoiceLocations && vm.invoiceLocations.length > 0) {
                for (var i = vm.invoiceLocations.length - 1; i >= 0; i--) {
                    var location = vm.invoiceLocations[i];
                    if (location.enable) {
                        vm.selectedLocation = location;
                        break;
                    }
                }
            }
            getData();
        });

        function getData() {
            vm.title2 = "个人";
            console.log(entity);
            if (vm.invoiceSetting != null && vm.invoiceSetting.id != null) {
                if (vm.invoiceSetting.openType != null) {
                    vm.openType = vm.invoiceSetting.openType != 'Company';
                }
                if (vm.invoiceSetting.invoiceType != null) {
                    vm.invoiceType = vm.invoiceSetting.invoiceType == 'Ordinary';
                }
            } else {
                vm.openType = true;
                vm.invoiceType = true;
            }

            vm.invoice = {  "id":null,
                            "openType": vm.invoiceSetting.openType,
                            "title": vm.invoiceSetting.title,
                            "invoiceType": vm.invoiceSetting.invoiceType,
                            "taxRegistrationNumber": vm.invoiceSetting.taxRegistrationNumber,
                            "basicAccount": vm.invoiceSetting.basicAccount,
                            "bankName": vm.invoiceSetting.bankName,
                            "registeredAddress": vm.invoiceSetting.registeredAddress,
                            "registeredPhone": vm.invoiceSetting.registeredPhone,
                            "invoiceLocation":null,
                            "payOrder":vm.payOrder,
                            "invoiceState":null}
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

         $scope.validate = function(data) {
            if (!vm.openType) {
                if (data.title == null || data.title == "") {
                    MessageService.error("请输入发票抬头");
                    vm.isSaving = false;
                    return false;
                };
            } else {
                vm.invoice.title = "个人";
                vm.invoiceType = true;
            }

            // 增值税专用发票--不可为空， 增值税普通发票可为空
            if (!vm.invoiceType) {
                if (data.taxRegistrationNumber == null || data.taxRegistrationNumber == "") {
                    MessageService.error("请输入税务登记证号");
                    vm.isSaving = false;
                    return false;
                };

                if (data.bankName == null || data.bankName == "") {
                    MessageService.error("请输入基本开户银行名称");
                    vm.isSaving = false;
                    return false;
                };

                if (data.basicAccount == null || data.basicAccount == "") {
                    MessageService.error("请输入基本开户账号");
                    vm.isSaving = false;
                    return false;
                };

                if (data.registeredAddress == null || data.registeredAddress == "") {
                    MessageService.error("请输入注册场所地址");
                    vm.isSaving = false;
                    return false;
                };

                if (data.registeredPhone == null || data.registeredPhone == "") {
                    MessageService.error("请输入注册固定电话");
                    vm.isSaving = false;
                    return false;
                };

                if(!(/^(0|86|17951)?(13[0-9]|15[012356789]|17[3678]|18[0-9]|14[57]|166|198|199)[0-9]{8}$/.test(data.registeredPhone))){
                    MessageService.error("手机号码非法");
                    return false;
                }
            }

            if (data.invoiceLocation == null || data.invoiceLocation.id == null) {
                MessageService.error("请选择发票寄送地址");
                vm.isSaving = false;
                return false;
            };
            return true;
         }

        function save() {

            if (vm.openType) {
                vm.invoice.openType = "Personal";
            } else {
                vm.invoice.openType = "Company";
            }

            if (vm.invoiceType) {
                vm.invoice.invoiceType = "Ordinary";
            } else {
                vm.invoice.invoiceType = "Special";
            }
            vm.invoice.invoiceLocation = vm.selectedLocation;
            if (!$scope.validate(vm.invoice)) {
                return;
            };
             vm.isSaving = true;
            vm.invoice.payOrder = vm.payOrder;
            Invoice.update(vm.invoice, function(res){
                if(res.status == 200){
                    MessageService.success("操作成功");
                    $uibModalInstance.close(res.data);
                }else {
                    vm.isSaving = false;
                    MessageService.error(res.message);
                }
            }, function(){
                vm.isSaving = false;
                MessageService.error("操作失败");
            })
        }
    }
})();
